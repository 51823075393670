<script>
import Common from './common'

export default {
  name: 'CombinePrice', // 丙烷折线图
  mixins: [Common],
  props: {
    axisLabelRoute: {
      type: Boolean,
      default: true
    },
    // 是否初始化数据
    initData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      unitY: '',
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.CombinePrice)
    this.initData && this.getData()
  },
  methods: {
    /**
     * @description 处理数据的逻辑
     * @param {Object} response
     */
    dataHandler(response) {
      if (!this.$DataIsNotEmpty(response)) {
        this.errorHandler()
        return
      }
      const series = []
      const xData = new Set()
      this.unitY = Array.from(
        new Set(
          Object.values(response)
            .flat()
            .map(item => item.dataUnit)
        )
      )[0]
      Object.values(response).forEach(item => {
        series.push({
          name: item[0].breedName,
          type: 'line',
          smooth: false,
          data: []
        })
        item.forEach(item => {
          xData.add(this.$dayjs(item.dataDate).format('YYYY-MM-DD'))
          series[series.length - 1].data.push([
            this.$dayjs(item.dataDate).format('YYYY-MM-DD'),
            item.mainPrice,
            item.dataUnit
          ])
        })
      })
      // 如果只有一条线时显示蓝色
      if (series.length === 1) series[0].color = '#023985'
      this.setOption(series, Array.from(xData).sort())
    },
    setOption(series = [], xData) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        title: {
          show: false
        },
        grid: {
          top: this.amplify ? '8%' : '18%',
          bottom: '13%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          top: '2%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            startValue: xData.length - 30
          }
        ],
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            name: this.unitY
          }
        ],
        series
      }

      // 判断横坐标标签是否需要倾斜
      if (this.axisLabelRoute) {
        this.option.xAxis.axisLabel = {
          rotate: -30
        }
        // 标签倾斜时需要给右边留出足够显示标签的空白
        this.option.grid.right = 50
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="CombinePrice" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Author: bjp
 * @Description: 折柱混合
-->
<script>
import Common from '../common'
import { getCapacityChart, getYidleChart } from '@/apis/Breed/Supply'
import { formatDayJsTime } from '@/utils/formatData'

export default {
  name: 'LineBarMix',
  components: {},
  mixins: [Common], // 产能产量
  props: {
    temp: {
      type: Object,
      default: () => ({})
    },
    breedName: {
      type: String,
      default: ''
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chartData: [],
      formatTime: '',
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.capacityOrYield)
  },
  methods: {
    getData() {
      let url = ''
      if (this.temp.id === 'capacity') url = getCapacityChart
      else url = getYidleChart
      url(this.queryData)
        .then(res => {
          const { response } = res
          if (!response || !Object.keys(response).length) {
            this.errorHandler()
            return
          }
          this.dataHandler(response)
        })
        .catch(() => this.errorHandler())
    },
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      // this.formatTime = formatDayJsTime(this.queryData.queryField.frequencyName)
      this.chartData = response.data
      const unit = this.chartData[`${this.temp.name}单位`].find(v => v.value)?.value || ''
      this.setOption(unit)
    },
    setOption(unit) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        title: {
          text: (this.breedName || this.name) + this.temp.name,
          x: 'center',
          top: '5%',
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        xAxis: {
          type: 'category',
          data: this.$filterValue(this.chartData['日期'], '日度')
        },
        yAxis: [
          {
            name: unit,
            type: 'value',
            splitLine: {
              show: false
            }
          },
          {
            name: '%',
            type: 'value',
            axisLabel: {
              show: true,
              interval: 0,
              formatter: '{value}%'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          }
        ],
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%'
          }
        ],
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              const unitY = e.seriesName.includes('增长率') ? '%' : unit || ''
              html += `${e.marker}${e.seriesName}：${e.value || '-'}${unitY || ''}<br>`
            })
            return html
          }
        },
        grid: {
          top: this.magnifier ? '10%' : '20%',
          bottom: '20%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          bottom: '12%',
          textStyle: {
            color: '#121212',
            fontSize: 14
          },
          itemGap: 20, // 设置间距
          data: [
            {
              name: this.temp.name,
              icon: 'rect'
            },
            {
              name: `${this.temp.name}增长率`
            }
          ]
        },
        series: [
          {
            name: `${this.temp.name}增长率`,
            type: 'line',
            // color: '#3A466E',
            yAxisIndex: 1,
            data: this.$filterValue(this.chartData[`${this.temp.name}增长率`])
          },
          {
            name: this.temp.name,
            type: 'bar',
            // color: '#2263BD',
            data: this.$filterValue(this.chartData[this.temp.name])
          }
        ]
      }

      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="capacityOrYield" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'
import { getData } from '@/apis/server'

export default {
  name: 'PriceCompare',
  mixins: [Common], // 价格对比图
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.PriceCompare)
  },
  methods: {
    getData() {
      let breedIds = []
      // 添加查询条件
      if (this.queryData.upAndDownBreed) {
        breedIds = this.queryData.upAndDownBreed
      } else {
        breedIds = [this.id]
      }
      const { dataApi } = this.widget
      getData(dataApi, {
        queryField: {
          frequencyName: '日度',
          breedIds
        }
      })
        .then(({ response }) => {
          this.chartLoading = false
          this.myChart.clear()
          this.myChart.hideLoading()
          if (!response || !Object.keys(response).length) {
            this.errorHandler()
            return false
          }
          this.dataHandler(response)
        })
        .catch(() => {
          this.chartLoading = false
        })
    },
    dataHandler(response = {}) {
      const seriesList = []
      let xData = []
      let yAxis = []
      const unitArr = []

      Object.values(response).forEach(item => {
        xData.push(
          item.map(val => {
            !unitArr.includes(val.dataUnit) && unitArr.length < 2 && unitArr.push(val.dataUnit)
            return this.$dayjs(val.dataDate).format('YYYY-MM-DD')
          })
        )
      })

      xData = [...new Set(xData.flat(Infinity))].sort()
      // 一个单位一条Y轴
      yAxis = unitArr.map((item, i) => {
        return {
          name: item,
          type: 'value',
          scale: true,
          splitNumber: 5,
          splitLine: {
            show: i === 0,
            lineStyle: {
              type: 'dashed'
            }
          }
        }
      })

      this.queryData.upAndDownBreed.forEach(e => {
        Object.keys(response).forEach(res => {
          let index = unitArr.findIndex(v => v === response[res][0].dataUnit)
          index = index > -1 ? index : 0
          e === res &&
            seriesList.push({
              name: response[res][0].breedName,
              nameGap: 5,
              type: 'line',
              yAxisIndex: index,
              connectNulls: true,
              smooth: true,
              lineStyle: {
                width: 2
              },
              data: response[res]
                .sort(
                  (a, b) => this.$dayjs(a.dataDate).valueOf() - this.$dayjs(b.dataDate).valueOf()
                )
                .map(v => {
                  return [v.dataDate.split(' ')[0], v.mainPrice, v.dataUnit]
                })
            })
        })
      })
      if (seriesList.length === 1) seriesList[0].color = '#023985'
      this.setOption(xData, seriesList, yAxis)
    },
    setOption(barX = [], seriesList = [], yAxis = []) {
      const lastDate = barX[barX.length - 1]
      const startValue = this.$dayjs(lastDate)
        .subtract(6, 'month')
        .endOf('month')
        .format('YYYY-MM-DD')
      this.option = {
        title: {
          show: false
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}${
                e.value[1] ? e.value[2] || '' : ''
              }<br>`
            })
            return html
          }
        },
        legend: {
          type: 'scroll'
        },
        grid: {
          bottom: '12%',
          top: this.amplify ? '12%' : '23%',
          left: 10,
          right: 10,
          containLabel: true
        },
        dataZoom: [
          {
            type: 'inside',
            startValue
          },
          {
            type: 'slider',
            bottom: '3%',
            startValue
          }
        ],
        xAxis: {
          type: 'category',
          data: barX,
          boundaryGap: false
        },
        yAxis,
        series: seriesList
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
      if (yAxis.length === 1) return
      this.myChart.on('legendselectchanged', obj => {
        // 获取被取消的legend
        const cancelLegend = Object.keys(obj.selected).filter(v => !obj.selected[v])

        const option = this.myChart.getOption()
        let isLeftShow = true
        if (cancelLegend.length !== 0) {
          isLeftShow = option.series.every(v => {
            return v.yAxisIndex === 0 && cancelLegend.includes(v.name)
          })
        }

        option.yAxis[1].splitLine.show = !isLeftShow

        this.myChart.setOption(option)
        this.option = option
      })
    }
  }
}
</script>

<template>
  <div ref="PriceCompare" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Author: bjp
 * @Description: 供应-连续图
-->
<script>
import Common from '../common'

export default {
  name: 'OperatingRateContinuous', // 连续图
  mixins: [Common],
  props: {
    chartData: {
      type: [Object, Array],
      default: () => []
    },
    showName: {
      type: String,
      default: ''
    },
    temp: {
      type: Object,
      default: () => ({})
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      option: {}
    }
  },
  watch: {
    chartData: {
      handler() {
        this.dataHandler()
      },
      deep: true
    }
  },
  mounted() {
    this.initEchart(this.$refs.OperatingRateContinuous)
    this.dataHandler()
    this.chartLoading = true
  },
  methods: {
    /**
     * 处理数据的逻辑
     */
    dataHandler() {
      this.myChart.clear()
      this.chartLoading = false
      if (this.chartData.length === 0 || Object.keys(this.chartData).length === 0) {
        this.errorHandler()
        return
      }
      this.myChart.hideLoading()
      this.setOption()
    },
    setOption() {
      const chartData = JSON.parse(JSON.stringify(this.chartData))
      chartData.sort((a, b) => {
        return (
          this.$dayjs(a.dataDate)
            .format('YYYYMMDD')
            .replace(/[^0-9]/gi, '') -
          this.$dayjs(b.dataDate)
            .format('YYYYMMDD')
            .replace(/[^0-9]/gi, '')
        )
      })
      const seriesName = chartData[0].quotaAlias
      this.option = {
        title: {
          text: `${seriesName}`,
          x: 'center',
          top: 10,
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        dataZoom: {
          show: chartData.length > 0,
          bottom: '3%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => this.tooltipFormat(params, '%')
        },
        legend: {
          bottom: '12%'
        },
        grid: {
          top: this.magnifier ? '8%' : '15%',
          bottom: '23%',
          left: '2%',
          right: '3%',
          containLabel: true
        },
        xAxis: {
          show: chartData.length > 0,
          type: 'category',
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true
          },
          data: chartData.map(item => item.exhDate)
        },
        yAxis: {
          name: '%',
          type: 'value',
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            show: chartData.length > 0,
            name: `${seriesName}`,
            type: 'line',
            smooth: true,
            data: chartData.map(item => item.inputValue)
          }
        ]
      }
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="OperatingRateContinuous" v-loading="chartLoading" class="chart-container" />
</template>

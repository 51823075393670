<!--
 * @Description: 企业-企业主营产品产能
 * @Date: 2022-04-14 13:42:05
 * @LastEditTime: 2022-04-19 14:51:57
-->
<script>
import Common from './common.js'
import { getData } from '@/apis/server'
export default {
  name: 'EquipmentMaintenance', // 历年装置产能情况
  mixins: [Common],
  data() {
    return {
      tableLoading: false,
      tableData: [],
      allData: [],
      extraData: []
    }
  },
  computed: {
    tableHeader() {
      if (this.queryData.capacityType === '在产') {
        return [
          { label: '产品', prop: 'breedName' },
          { label: '产能（在产）', prop: 'inProCap', sort: true },
          { label: '单位', prop: 'unitName' },
          { label: '日期', prop: 'exhDate' }
        ]
      } else {
        const year = this.$dayjs().year()
        return [
          { label: '产品', prop: 'breedName' },
          { label: `${year}`, prop: 'growCap', sort: true },
          { label: `${year + 1}`, prop: 'nextGrowCap', sort: true },
          { label: `${year + 2}`, prop: 'afterGrowCap', sort: true },
          { label: '单位', prop: 'unitName' }
        ]
      }
    }
  },
  mounted() {
    this.tableLoading = true
  },
  methods: {
    getData() {
      this.tableLoading = true
      this.tableData = []
      const { dataApi } = this.widget
      getData(dataApi, {
        queryField: {
          companyId: this.$route.query.companyId,
          type: this.queryData.capacityType,
          sortType: 'desc'
        }
      })
        .then(({ response }) => {
          this.tableData = response
          this.$nextTick(() => {
            this.$refs.tableA.doLayout()
          })
        })
        .finally(() => {
          this.tableLoading = false
        })
    }
  }
}
</script>

<template>
  <div ref="EquipmentMaintenance" class="chart-container">
    <!-- 企业 -->
    <el-table ref="tableA" v-loading="tableLoading" :data="tableData" stripe>
      <el-table-column
        v-for="item in tableHeader"
        :key="item.label"
        :label="item.label"
        :prop="item.prop"
        :sortable="item.sort"
        align="center"
      />
    </el-table>
  </div>
</template>

<style lang="scss" scoped>
@include tableMaxHeight(230px);
// el-table 样式
// :deep(.el-table) {
//   width: 100%;
//   border-radius: 5px;
//   box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
//   height: 300px;
//   .gutter {
//     width: 0;
//     display: table-cell !important;
//   }
//   th,
//   td {
//     border-left: 2px solid #fff;
//   }
//   .el-table__header {
//     th {
//       background: #3a466e;
//       opacity: 0.5;
//       color: #fff;
//       font-size: 14px;
//       padding-top: 8px;
//       padding-bottom: 8px;
//       border-bottom: none;
//       > .cell {
//         line-height: 18px;
//       }
//       &:first-child {
//         border-left: none;
//       }
//       &:last-child {
//         border: none;
//         border-left: none;
//       }
//     }
//   }
//   .el-table__body {
//     overflow: hidden;
//     tr td {
//       height: 30px;
//       text-align: center;
//       border-bottom: none;
//     }
//     tr td:first-of-type {
//       border-left: none;
//     }
//   }
//   // 滚动条样式
//   .el-table__body-wrapper {
//     max-height: 240px;
//     overflow-y: auto;
//     // 滚动条样式
//     &::-webkit-scrollbar {
//       width: 10px; // 横向滚动条
//       height: 10px; // 纵向滚动条 必写
//     }
//     // 滚动条的滑块
//     &::-webkit-scrollbar-thumb {
//       background-color: #ddd;
//       border-radius: 3px;
//     }
//     &::-webkit-scrollbar-track {
//       width: 10px;
//     }
//   }
// }
</style>

<!--
 * @Author: bjp
 * @Description:供应格局-表格
-->
<script>
import Common from './common'
import { supplyTable } from '@/apis/Breed/Home'

export default {
  name: 'SupplyPatternTable',
  components: {},
  mixins: [Common],
  props: {
    interactors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableData: [],
      classStandard: this.interactors[0] ? this.interactors[0].defaultValue : '',
      parameter: '',
      tableLoading: false
    }
  },
  watch: {
    classStandard: {
      immediate: true,
      handler(val) {
        switch (val) {
          case '分企业':
            this.parameter = '企业'
            break
          case '分区域':
            this.parameter = '区域'
            break
          case '分工艺':
            this.parameter = '工艺路线'
            break
          default:
            break
        }
        if (val) this.getData()
      }
    }
  },
  methods: {
    async getData() {
      this.tableData = []
      this.tableLoading = true
      const query = {
        queryField: {
          breedId: this.id,
          ...this.queryData,
          classStandard: this.classStandard
        }
      }
      const { response } = await supplyTable(query)
      this.dataHandler(response)
    },
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      if (response && Object.keys(response).length) {
        response.data['分类明细'].forEach((item, index) => {
          this.tableData.push({
            [this.parameter]: item.value,
            产能: response.data['产能'][index].value,
            产能占比: response.data['产能占比'][index].value,
            单位: response.data['单位'][index].value
          })
        })
      }
      this.tableLoading = false
    }
  }
}
</script>

<template>
  <div class="PatternTable">
    <div v-if="interactors && interactors.length > 0" class="interactors">
      <div v-for="(interactor, index) in interactors" :key="`interactor_${index}`">
        <span>{{ interactor.description }}：</span>
        <el-select
          :key="interactor.id"
          v-model="classStandard"
          name=""
          size="mini"
          style="min-width: 90px; max-width: 115px"
          :multiple="interactor.multiple"
          :multiple-limit="interactor.multipleLimit"
          collapse-tags
        >
          <el-option
            v-for="dictData in interactor.listDictData"
            :key="dictData.id"
            :value="dictData.dictValue"
            :label="dictData.dictLabel"
            :disabled="dictData.disabled"
          />
        </el-select>
      </div>
    </div>
    <el-table v-loading="tableLoading" :data="tableData" stripe>
      <el-table-column :prop="parameter" :label="parameter" align="center" />
      <el-table-column prop="产能" label="产能" align="center" width="80">
        <template #default="scope"> {{ scope.row.产能 }}{{ scope.row.单位 }}</template>
      </el-table-column>
      <el-table-column
        prop="产能占比"
        label="产能占比"
        align="center"
        width="100"
        sortable
        :sort-method="(a, b) => Number(a['产能占比']) - Number(b['产能占比'])"
      />
    </el-table>
  </div>
</template>

<style lang="scss" scoped>
@include tableMaxHeight(200px);
@media screen and (max-width: 1440px) {
  @include tableMaxHeight(260px);
}

.PatternTable {
  overflow: hidden;

  .interactors {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
  }
}
</style>

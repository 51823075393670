<script>
export default {
  name: 'PriceForecast', // 价格预测
  props: {
    widget: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    const myChart = this.$echarts.init(document.querySelector('#PriceForecast'), 'charttheme')
    const option = {
      dataZoom: [
        {
          type: 'inside'
        },
        {
          type: 'slider',
          bottom: 20
        }
      ],
      title: {
        text: '华北地区',
        left: 'center',
        top: '5%',
        textStyle: {
          fontSize: '14px',
          fontWeight: 'normal'
        }
      },
      tooltip: {
        confine: true,
        trigger: 'axis',
        backgroundColor: 'rgba(102, 102, 102, 0.8)',
        borderWidth: 0,
        textStyle: {
          color: '#fff'
        }
      },
      grid: {
        top: '15%',
        right: 30
      },
      legend: {
        bottom: 40
      },
      xAxis: {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          interval: 0,
          rotate: -45,
          margin: 20
        },
        data: [
          '2021年第1周',
          '2021年第2周',
          '2021年第3周',
          '2021年第4周',
          '2021年第5周',
          '2021年第6周',
          '2021年第7周',
          '2021年第8周',
          '2021年第9周',
          '2021年第10周',
          '2021年第11周',
          '2021年第12周',
          '2021年第13周',
          '2021年第14周',
          '2021年第15周',
          '2021年第16周',
          '2021年第17周'
        ]
      },
      yAxis: [
        {
          type: 'value',
          splitLine: {
            show: false
          }
        },
        {
          type: 'value',
          min: -100,
          max: 100,
          interval: 25,
          axisLabel: {
            formatter: '{value} %'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        }
      ],
      series: [
        {
          name: '2017',
          type: 'line',
          data: [888, 777, 999, 823, 985, 821, 759, 889, 922, 978, 999, 998],
          smooth: true,
          symbol: 'none',
          lineStyle: {
            type: 'dashed',
            width: 2
          }
        },
        {
          name: '2018',
          type: 'line',
          data: [658, 577, 599, 523, 685, 721, 659, 689, 522, 678, 599, 598],
          smooth: true,
          symbol: 'none',
          lineStyle: {
            type: 'dashed',
            width: 2
          }
        },
        {
          name: '2019',
          type: 'line',
          data: [258, 377, 299, 223, 385, 121, 459, 389, 422, 478, 399, 398],
          smooth: true,
          symbol: 'none',
          lineStyle: {
            type: 'dashed',
            width: 2
          }
        },
        {
          name: '2020',
          type: 'line',
          data: [358, 457, 156, 323, 585, 421],
          smooth: true,
          symbol: 'none',
          lineStyle: {
            width: 2
          }
        },
        {
          name: 'T+1移率',
          type: 'bar',
          yAxisIndex: 1,
          data: [26, -34, 48],
          itemStyle: {
            color: '#6899DD'
          }
        },
        {
          name: 'T+2移率',
          type: 'bar',
          yAxisIndex: 1,
          data: [38, -42, 53],
          itemStyle: {
            color: '#2263BD'
          }
        },
        {
          name: 'T+3移率',
          type: 'bar',
          yAxisIndex: 1,
          data: [28, -32, 33],
          itemStyle: {
            color: '#3A466E'
          }
        }
      ],
      color: ['#8DADDA', '#6899DD', '#2263BD', '#e5a24a']
    }
    myChart.setOption(option)
  }
}
</script>

<template>
  <div id="PriceForecast" class="chart-container" />
</template>

<script>
// import PlaceHolder from '@/components/PlaceHolder'
import { mapActions } from 'vuex'

export default {
  created() {
    this.getTemplateList()
  },
  methods: {
    ...mapActions(['getTemplateList'])
  }
}
</script>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.el-message {
  z-index: 9999 !important;
}
</style>

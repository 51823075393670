<!--
 * @Author: cy
 * @Description: 柴油-中国柴油出口量
 * @Date: 2022-08-12 14:11:59
 * @LastEditTime: 2022-08-15 15:02:40
-->
<script>
// import OilSpecialLineBarMixOld from '@/widgets/Breed/OilSpecialLineBarMixOld'
export default {
  name: 'DieselOilChnExportVolume',
  // components: { OilSpecialLineBarMixOld },
  data() {
    return {
      series: [
        { legend: '出口量', key: '进出口数量', unit: '进出口数量单位' },
        { legend: '出口增速', key: '进出口数量环比' }
      ]
    }
  }
}
</script>

<template>
  <BreedOilSpecialLineBarMixOld v-bind="$attrs" :series="series" />
</template>

<!--
 * @Description: 价格评价
 * @Date: 2022-04-20 13:56:50
 * @LastEditTime: 2022-05-06 11:19:07
-->
<script>
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Common from './common.js'
import { getDataByGet } from '@/apis/server'
export default {
  mixins: [Common],
  data() {
    return {
      priceData: {},
      lastMonth: ''
    }
  },
  watch: {
    activeTag() {
      this.getData()
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.priceData = {}
      const params = {
        companyId: this.companyId,
        breedId: this.breedId
      }
      const { dataApi } = this.widget
      getDataByGet(dataApi, params).then(res => {
        this.priceData = res.response
        const now = this.priceData.exhDate

        if (!now) return
        this.$dayjs.extend(customParseFormat)
        this.lastMonth = this.$dayjs(now, 'YYYY年M月').subtract(1, 'month').format('YYYY年M月')
      })
    },
    // 文字颜色
    textColor(num) {
      if (num === undefined || num === null) return ''
      num = parseInt(num)
      return num === 0 ? 'gray-text' : num > 0 ? 'red-text' : 'green-text'
    },
    // 幅度文字
    amplitudeText(num) {
      if (num === undefined || num === null) return ''
      num = parseInt(num)
      return num === 0 ? '持平' : num > 0 ? '涨幅' : '跌幅'
    },
    // 价格文字
    priceText(num) {
      if (num === undefined || num === null) return ''
      num = parseInt(num)
      return num === 0 ? '持平' : num > 0 ? '上涨' : '下跌'
    },
    mathAbs(num) {
      if (num === undefined || num === null) return ''
      num = parseInt(num)
      return Math.abs(num)
    }
  }
}
</script>

<template>
  <div id="EnterpriseTextLine" class="company-textline">
    <!-- 主流价格 -->
    截止{{ priceData.exhDate }}，中国{{ priceData.breedName }}价格均价在
    <span :class="textColor(priceData.mainPrice)">{{ mathAbs(priceData.mainPrice) }}</span
    >元/吨。
    <!-- 价格涨跌幅 -->
    较{{ lastMonth }}{{ priceText(priceData.chg) }}
    <span :class="textColor(priceData.chg)"> {{ mathAbs(priceData.chg) }} </span
    >{{ priceData.priceDataUnit }}，
    <!-- 价格涨跌幅百分比 -->
    {{ amplitudeText(priceData.chgPercent) }}
    <span :class="textColor(priceData.chgPercent)"> {{ mathAbs(priceData.chgPercent) }}% </span>。
    <!-- 企业价格 -->
    其中，{{ priceData.securityName }}{{ priceData.exhDate }}{{ priceData.breedName }}出厂结算价格为
    <span :class="textColor(priceData.comPrice)"> {{ priceData.comPrice }} </span
    >{{ priceData.priceDataUnit }}。
    <!-- 企业涨跌幅 -->
    较{{ lastMonth }}{{ priceText(priceData.comChg) }}
    <span :class="textColor(priceData.comChg)"> {{ mathAbs(priceData.comChg) }} </span
    >{{ priceData.comPriceUint }}，
    <!-- 企业涨跌幅百分比 -->
    {{ amplitudeText(priceData.comPercent) }}
    <span :class="textColor(priceData.comPercent)"> {{ mathAbs(priceData.comPercent) }}% </span>。
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/styles/enterprise/companyText.scss';
</style>

import { Message } from 'element-ui'
import breed from './modules/breed.js'
import contrast from './modules/contrast.js'
import amplify from './modules/amplify.js'
import power from './modules/power.js'
import { storage } from '@/utils/common'
import { templateAdd, templateQueryByUserId } from '@/apis/myCustomization.js'

export default {
  state: {
    templateList: [],
    modularName: '', // 模块名称
    token: localStorage.getItem('token')
  },
  mutations: {
    changeTemplateList(state, templateList) {
      state.templateList = templateList
    },
    /**
     * @description: 切换modular-name
     * @param {*} state
     * @param {*} name
     */
    changeModularName(state, name) {
      state.modularName = name
      storage.set('modularName', name)
    }
  },
  actions: {
    /**
     * 获取所有模板
     * @param {Function} commit 提交mutation
     */
    async getTemplateList({ commit }) {
      const { response } = await templateQueryByUserId()
      const templateList = response.map(({ id, templateName }) => ({ id, templateName }))
      commit('changeTemplateList', templateList)
    },
    /**
     * 添加模板
     * @param {Function} dispatch 分发器
     * @param {String} templateName 模板名称
     */
    async addTemplate({ dispatch }, templateName) {
      if (!templateName) {
        Message.error('请输入模板名称！')
        return false
      }
      try {
        const { response } = await templateAdd({ templateName })
        if (response.includes('成功')) {
          Message.success(response)
          await dispatch('getTemplateList')
          return false
        }
        Message.error(response)
      } catch (e) {
        Message.error(e)
      }
    }
  },
  modules: { breed, contrast, amplify, power }
}

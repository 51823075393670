/*
 * @Author: cy
 * @Description: 柴油路由
 * @Date: 2022-08-11 11:35:14
 * @LastEditTime: 2022-08-22 14:00:28
 * meta参数：
 *  @title : 名称
 *  @isSpecial: 特殊品种新增页面
 *  @code 特殊品种页面code
 * props参数：
 *  @menuTitle: 当前显示路由名称
 */
export default {
  // 供应
  supply: [
    {
      path: 'DieselOilDeviceProcessCapacity',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilDeviceProcessCapacity',
      meta: {
        title: '常减压装置原油一次加工能力',
        isSpecial: true,
        code: '70213'
      }
    },
    {
      path: 'DieselOilMainProcessCapacity',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilMainProcessCapacity',
      meta: {
        title: '主营炼厂原油一次加工能力',
        isSpecial: true,
        code: '71145'
      }
    },
    {
      path: 'DieselOilIndependentProcessCapacity',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilIndependentProcessCapacity',
      meta: {
        title: '独立炼厂原油一次加工能力',
        isSpecial: true,
        code: '71144'
      }
    },
    {
      path: 'DieselOilMainUtilizationRate',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilMainUtilizationRate',
      meta: {
        title: '主营炼厂原油产能利用率',
        isSpecial: true,
        code: '67877'
      }
    },
    {
      path: 'DieselOilIndependentUtilizationRate',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilIndependentUtilizationRate',
      meta: {
        title: '独立炼厂原油产能利用率',
        isSpecial: true,
        code: '67876'
      }
    },
    {
      path: 'DieselOilMainDieselProduction',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilMainDieselProduction',
      meta: {
        title: '主营炼厂柴油产量',
        isSpecial: true,
        code: '79504'
      }
    },
    {
      path: 'DieselOilIndependentDieselProduction',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilIndependentDieselProduction',
      meta: {
        title: '独立炼厂柴油产量',
        isSpecial: true,
        code: '79450'
      }
    }
  ],
  // 利润
  costAndProfit: [
    {
      path: 'DieselOilMainOilProfit',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilMainOilProfit',
      meta: {
        title: '主营炼厂原油综合利润',
        isSpecial: true,
        code: '67881'
      }
    },
    {
      path: 'DieselOilIndependentOilProfit',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilIndependentOilProfit',
      meta: {
        title: '独立炼厂原油综合利润',
        isSpecial: true,
        code: '67879'
      }
    },
    {
      path: 'DieselOilExportProfit',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilExportProfit',
      meta: {
        title: '柴油出口利润',
        isSpecial: true,
        code: '141383'
      }
    },
    {
      path: 'DieselOilRetailProfit',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilRetailProfit',
      meta: {
        title: '柴油零售利润',
        isSpecial: true,
        code: '122267'
      }
    }
  ],
  // 需求
  demand: [
    {
      path: 'DieselOilSalesShandong',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilSalesShandong',
      meta: {
        title: '独立炼厂柴油销量（山东）',
        isSpecial: true,
        code: '79313'
      }
    },
    {
      path: 'DieselOilSalesNortheast',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilSalesNortheast',
      meta: {
        title: '独立炼厂柴油销量（东北）',
        isSpecial: true,
        code: '79311'
      }
    },
    {
      path: 'DieselOilSalesNorthwest',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilSalesNorthwest',
      meta: {
        title: '独立炼厂柴油销量（西北）',
        isSpecial: true,
        code: '79316'
      }
    },
    {
      path: 'DieselOilSalesRatioShandong',
      component: () => import('@/views/Breed/OilSpecialLine.vue'),
      name: 'DieselOilSalesRatioShandong',
      meta: {
        title: '独立炼厂柴油产销率（山东）',
        isSpecial: true,
        code: '97436'
      }
    },
    {
      path: 'DieselOilSalesRatioNortheast',
      component: () => import('@/views/Breed/OilSpecialLine.vue'),
      name: 'DieselOilSalesRatioNortheast',
      meta: {
        title: '独立炼厂柴油产销率（东北）',
        isSpecial: true,
        code: '97431'
      }
    },
    {
      path: 'DieselOilSalesRatioNorthwest',
      component: () => import('@/views/Breed/OilSpecialLine.vue'),
      name: 'DieselOilSalesRatioNorthwest',
      meta: {
        title: '独立炼厂柴油产销率（西北）',
        isSpecial: true,
        code: '97437'
      }
    }
  ],
  // 库存
  stock: [
    {
      path: 'DieselOilInventoryCN',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilInventoryCN',
      meta: {
        title: '独立炼厂柴油库存（全国）',
        isSpecial: true,
        code: '69943'
      }
    },
    {
      path: 'DieselOilCapacityCN',
      component: () => import('@/views/Breed/OilSpecialLine.vue'),
      name: 'DieselOilCapacityCN',
      meta: {
        title: '独立炼厂柴油库容（全国）',
        isSpecial: true,
        code: '69949'
      }
    },
    {
      path: 'DieselOilInventoryShandong',
      component: () => import('@/views/Breed/OilSpecialBarLineMix.vue'),
      name: 'DieselOilInventoryShandong',
      meta: {
        title: '独立炼厂柴油库存（山东）',
        isSpecial: true,
        code: '79104'
      }
    },
    {
      path: 'DieselOilCapacityShandong',
      component: () => import('@/views/Breed/OilSpecialLine.vue'),
      name: 'DieselOilCapacityShandong',
      meta: {
        title: '独立炼厂柴油库容（山东）',
        isSpecial: true,
        code: '141381'
      }
    }
  ]
}

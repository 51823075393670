<!--
 * @Author: bjp
 * @Description: 供应-连续图
-->
<script>
import Common from '../common'
import { getOperaContinuse } from '@/apis/Breed/Supply'
import { formatDayJsTime } from '@/utils/formatData'

export default {
  name: 'OperatingRateContinuous', // 连续图
  mixins: [Common],
  props: {
    showName: {
      type: String,
      default: ''
    },
    temp: {
      type: Object,
      default: () => ({})
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chartData: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.OperatingRateContinuous)
    this.chartLoading = true
  },
  methods: {
    async getData() {
      if (Object.keys(this.queryData).length > 0) {
        getOperaContinuse(this.queryData).then(res => {
          this.$emit('change-data', res.response)

          if (!res.response || !Object.keys(res.response).length) {
            this.myChart.clear()
            this.errorHandler()
            return
          }
          this.dataHandler(res.response)
        })
      } else {
        this.dataHandler()
      }
    },
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      if (response) {
        this.myChart.hideLoading()
        this.chartData = response.data
        this.myChart.hideLoading()
      } else {
        this.errorHandler()
        return
      }
      this.setOption()
      this.chartLoading = false
    },
    setOption() {
      const option = {
        title: {
          text: `${this.showName || this.name}开工率`,
          x: 'center',
          top: 0,
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        dataZoom: {
          show: Object.keys(this.chartData).length > 0,
          bottom: '3%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => this.tooltipFormat(params, '%')
        },
        legend: {
          bottom: '12%'
        },
        grid: {
          top: this.magnifier ? '8%' : '15%',
          bottom: '23%',
          left: '2%',
          right: '3%',
          containLabel: true
        },
        xAxis: {
          show: Object.keys(this.chartData).length > 0,
          type: 'category',
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true
          },
          data: this.$filterValue(
            this.chartData['日期'],
            formatDayJsTime(this.queryData.queryField.frequencyName)
          )
        },
        yAxis: {
          name: '%',
          type: 'value',
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            show: Object.keys(this.chartData).length > 0,
            name: '开工率(%)',
            type: 'line',
            smooth: true,
            data: this.$filterValue(this.chartData['开工率(%)'])
          }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div ref="OperatingRateContinuous" v-loading="chartLoading" class="chart-container" />
</template>

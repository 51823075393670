<script>
import Common from './common'

export default {
  name: 'NaturalGasPngExportAnalyze', // 下周原油市场心态调查
  mixins: [Common],
  props: {
    chartData: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      option: {}
    }
  },
  watch: {
    chartData: {
      handler(val) {
        this.dataHandler(val)
      },
      deep: true
    }
  },
  mounted() {
    this.initEchart(this.$refs.NaturalGasPngExportAnalyze)
    // this.getData()
    this.chartLoading = true
  },
  methods: {
    /**
     * @description 处理数据的逻辑
     */
    dataHandler(response) {
      if (!this.$DataIsNotEmpty(response)) {
        this.errorHandler()
        return
      }
      const series = []
      const xData = Array.from(
        new Set(
          Object.values(response)
            .flat(Infinity)
            .map(item => this.$dayjs(item.dataDate).format('YYYY-MM'))
            .filter(item => !!item)
            .sort()
        )
      )
      const keyArr = Array.from(new Set(Object.keys(response)))
      this.unitY = response[Object.keys(response)[0]][0].currVolumeUnit
      for (const value of keyArr) {
        series.push({
          name: value,
          type: 'bar',
          stack: 'none',
          data: xData.map(item => {
            const [temp = {}] = response[value].filter(
              res => this.$dayjs(res.dataDate).format('YYYY-MM') === item
            )
            return [this.$dayjs(temp.dataDate).format('YYYY-MM'), temp.currVolume]
          })
        })
      }
      // 如果只有一条线时显示蓝色
      this.setOption(series, xData)
    },
    setOption(series = [], xData) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      const startValue = xData[xData.length - 30] || xData[0]
      this.option = {
        title: {
          show: false
        },
        grid: {
          top: this.amplify ? '8%' : '15%',
          bottom: '13%',
          right: '22%',
          containLabel: true
        },
        legend: {
          x: 'right',
          type: 'scroll',
          orient: 'vertical',
          bottom: '13%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            left: '5%',
            right: '5%',
            startValue
          }
        ],
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            name: this.unitY
          }
        ],
        series
      }
      this.downLoadChartTitle('analysis')
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="NaturalGasPngExportAnalyze" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from '../common.js'
import { getVolumeContinuous } from '@/apis/Breed/CustomsData.js'

export default {
  name: 'CustomsVolumeBar',
  mixins: [Common], // 柱状图
  props: {
    breedName: {
      type: String,
      default: ''
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.CustomsVolumeBar)
  },
  methods: {
    getData() {
      getVolumeContinuous(this.queryData)
        .then(res => {
          this.chartLoading = false
          this.myChart.clear()
          const { response } = res
          if (!response || Object.keys(response.data).length === 0) {
            this.errorHandler()
            return
          }
          this.setOption(response.data)
          this.myChart.hideLoading()
        })
        .catch(() => {
          this.errorHandler()
        })
    },
    setOption(chartData = {}) {
      const dayFormat = {
        月度: 'YYYY年MM月',
        季度: 'YYYY年MM月',
        年度: 'YYYY年'
      }
      const data = chartData.时间.map((ele, i) => {
        return [this.$dayjs(ele.value).format('YYYY-MM-DD'), chartData.进出口数量[i].value]
      })
      const cusType = this.$route.path.includes('import') ? '进口' : '出口'
      const unit = chartData.进出口数量单位[0].value

      this.option = {
        title: {
          text: `${this.breedName || this.name}${cusType}量`,
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14
          },
          left: 'center',
          top: this.magnifier ? '5%' : '10%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}&nbsp;&nbsp;&nbsp;&nbsp;${e.value[1] || '-'}${
                unit || ''
              }<br>`
            })
            return html
          }
        },
        grid: {
          top: this.magnifier ? '10%' : '30%',
          bottom: '20%',
          left: '2%',
          right: '2%'
        },
        legend: {
          bottom: '10%'
        },
        dataset: {
          source: data
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%'
          }
        ],
        xAxis: {
          type: 'category',
          axisTick: {
            show: false
          }
        },
        yAxis: [
          {
            type: 'value',
            name: unit,
            splitNumber: 4,
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          }
        ],
        series: [
          {
            name: `${cusType}量`,
            type: 'bar',
            barMaxWidth: 50,
            color: '#2f5597'
          }
        ]
      }
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="CustomsVolumeBar" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Author: bjp
 * @Description: 需求-表观消费量
-->
<script>
import Common from '../common.js'
// import { formatDayJsTime } from '@/utils/formatData'
export default {
  name: 'ApparentConsumption',
  mixins: [Common], // 下游消费结构Bar
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    activeIndex: {
      type: String,
      default: '月度'
    },
    breedName: {
      type: String,
      default: ''
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dealData: [],
      barX: [],
      apparentSpeed: [],
      priceData: [],
      option: {}
    }
  },
  watch: {
    chartData: {
      handler() {
        this.dataHandler()
      },
      deep: true
    }
  },
  mounted() {
    this.initEchart(this.$refs.ApparentConsumption)
    this.chartData.length && this.dataHandler()
  },
  methods: {
    /**
     * 处理数据的逻辑
     */
    dataHandler() {
      this.chartLoading = false
      this.myChart.clear()
      this.dealData = []
      this.apparentSpeed = []
      this.priceData = []
      if (this.chartData && this.chartData.length > 0) {
        this.myChart.hideLoading()
        this.chartDataSort = JSON.parse(JSON.stringify(this.chartData))
        this.barX = this.chartDataSort
          .sort((a, b) => {
            return a.dataDate.replace(/[^0-9]/gi, '') - b.dataDate.replace(/[^0-9]/gi, '')
          })
          .map(barX => barX.dataDate.split(' ')[0])
        this.dealData = this.chartDataSort.map(item => item.appConsump)
        this.apparentSpeed = this.chartDataSort.map(item => item.appConsumpQq)
        this.priceData = this.chartDataSort.map(item => item.avgPrice)
      } else {
        this.errorHandler()
        return
      }
      this.chartInit()
    },
    chartInit() {
      const name = this.breedName || this.$route.query.name
      this.option = {
        title: {
          text: `${name}表观消费量`,
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14
          },
          left: 'center',
          top: '5%'
        },
        dataZoom: {
          show: true,
          bottom: '5%'
        },
        grid: {
          top: this.magnifier ? '10%' : '15%',
          left: '3%',
          right: '2%',
          bottom: '23%',
          containLabel: true
        },
        legend: {
          data: ['表观消费量', '消费增速', '价格'],
          bottom: '13%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: this.barX,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: [
          {
            alignTicks: true,
            type: 'value',
            name: this.chartDataSort[0].appConsumpUnit,
            // interval: 5,
            // max: 25,
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            alignTicks: true,
            type: 'value',
            name: '%',
            // interval: 3,
            axisLabel: {
              formatter: '{value}%'
            },
            splitLine: {
              show: false
            }
          },
          {
            type: 'value',
            alignTicks: true,
            position: 'left',
            offset: '36',
            name: this.chartDataSort[0].priceUnit,
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '表观消费量',
            type: 'bar',
            data: this.dealData
          },
          {
            name: '消费增速',
            type: 'line',
            yAxisIndex: 1,
            data: this.apparentSpeed
          },
          {
            name: '价格',
            type: 'line',
            yAxisIndex: 2,
            data: this.priceData
          }
        ]
        // color: ['#6899DD', '#232E54']
      }
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="ApparentConsumption" v-loading="chartLoading" class="chart-container" />
</template>

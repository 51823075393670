<!--
 * @Author: bjp
 * @Description:圆滑折线图
-->
<script>
import Common from '../common'
import { getTrendChart, getTrendChartNoProcess } from '@/apis/Breed/Cost'
import { formatDayJsTime } from '@/utils/formatData'

export default {
  name: 'LineSmooth',
  mixins: [Common],
  props: {
    temp: {
      type: Object,
      default: () => ({})
    },
    showSwitch: {
      type: Boolean,
      default: false
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      barX: [],
      dataListLine: [],
      option: {},
      keyList: [],
      ifAverage: {},
      seriesList: [],
      chartLoading: false
    }
  },
  mounted() {
    this.initEchart(this.$refs.LineSmooth)
  },
  methods: {
    async getData() {
      this.chartLoading = true
      this.dataListLine = []
      this.barX = []
      const query = { ...this.queryData }
      if (Object.keys(this.queryData).length > 0) {
        let getChartApi = getTrendChart
        // 没有工艺时替换api
        if (!this.queryData.queryField.processIds.length) {
          getChartApi = getTrendChartNoProcess
        }
        const { response } = await getChartApi(query)
        this.dataHandler(response)
      }
    },
    dataHandler(response) {
      this.myChart.clear()
      this.dataListLine = []
      this.barX = []
      this.seriesList = []
      this.ifAverage = {}
      if (Object.keys(response).length === 0) {
        this.errorHandler()
        return
      } else {
        this.myChart.hideLoading()
      }
      let unit = ''
      const formatTime = 'YYYY-MM-DD'
      // formatTime = formatDayJsTime(this.queryData.queryField.frequencyName)
      this.chartLoading = false
      for (const key in response) {
        if (!this.showSwitch && key === '均线') {
          continue
        }
        this.ifAverage[key] = response[key]
        if (!unit) unit = response[key][0].单位
        response[key].forEach(item => {
          this.barX.push(this.$dayjs(item['日期']).format(formatTime))
        })
      }
      this.barX = [...new Set(this.barX)].sort()
      this.seriesList = Object.keys(this.ifAverage).map(item => ({
        name: item,
        type: 'line',
        smooth: true,
        data: response[item].map(v => [this.$dayjs(v['日期']).format(formatTime), v.成本])
      }))
      // 将均线放到最后一项
      this.seriesList.forEach((v, i) => {
        if (v.name === '均线') {
          this.seriesList.push(v)
          this.seriesList.splice(i, 1)
        }
      })
      this.setOption(unit)
    },
    setOption(unit = '') {
      this.option = {
        title: {
          show: this.seriesList.length > 0,
          text: this.name + this.temp.name,
          x: 'center',
          top: 15,
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        xAxis: {
          type: 'category',
          data: this.barX
        },
        yAxis: [
          {
            type: 'value',
            scale: true,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            }
          }
        ],
        dataZoom: [
          {
            show: this.seriesList.length > 0,
            type: 'slider',
            left: '3%',
            right: '1%',
            bottom: '3%'
          }
        ],
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            const _unit = unit || ''
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}${
                e.value[1] ? _unit : ''
              }<br>`
            })
            return html
          }
        },
        grid: {
          top: this.magnifier ? '8%' : '18%',
          bottom: '20%',
          left: '1%',
          right: '1%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          bottom: '10%',
          textStyle: {
            color: '#121212',
            fontSize: 14
          },
          itemGap: 20 // 设置间距
        },
        // dataset: {
        //   dimensions: ['time', ...Object.keys(this.ifAverage)],
        //   source: this.dataListLine,
        // },
        series: this.seriesList
      }
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="LineSmooth" v-loading="chartLoading" class="chart-container" />
</template>

import { mapState } from 'vuex'
import { Object } from 'core-js'
import { getData } from '@/apis/server'
import { queryIndexName } from '@/apis/Breed/PriceCenter.js'

export default {
  name: 'Common',
  props: {
    widget: {
      type: Object,
      default: () => ({})
    },
    queryData: {
      type: [Object, Array],
      default: () => ({})
    },
    amplify: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      myChart: '',
      chartLoading: false,
      chartTitle: '',
      id: this.$route.query.id,
      name: this.$route.query.name,
      option: {}
    }
  },
  computed: {
    ...mapState(['breedSubMenu'])
  },
  methods: {
    /**
     * @description: 获取图表名称（价格模块）
     */
    async queryIndexName() {
      /**
       * 品种首页-现货价格  取queryData.frequencyName
       * 品种内页-主流价  取queryData.queryField?.frequencyName
       * 品种内页-市场价/国际价/企业价 取 queryData.queryField?.frequency
       */
      const frequencyName = this.queryData.queryField
        ? this.queryData.queryField?.frequencyName || this.queryData.queryField?.frequency
        : this.queryData.frequencyName || this.queryData.riverFrequency
      if (!this.priceType || !frequencyName) return
      let priceType = 0
      switch (this.priceType) {
        case '市场价':
          priceType = 1
          break
        case '企业价':
          priceType = 2
          break
        case '国际价':
          priceType = 3
          break
      }
      const { response } = await queryIndexName({
        priceType,
        indexCode: this.$route.query.multiCompare || '',
        breedId: parseInt(this.$route.query.id || this.$store.state.contrast.breedId),
        frequencyName
      })
      this.chartTitle = response
    },
    async getData() {
      this.chartLoading = true
      const { dataApi } = this.widget
      if (!dataApi) return
      let avg = null
      if (this.queryData && 'avg_line' in this.queryData) {
        avg = this.queryData.avg_line.length <= 0 ? [''] : this.queryData.avg_line
      }
      const query = {
        queryField: {
          breedId: this.$route.query.id,
          ...this.queryData
        }
      }
      if (avg) query.queryField.avg_line = avg
      try {
        const { response } = await getData(dataApi, query)
        this.chartLoading = false
        this.dataHandler(response)
      } catch (error) {
        this.errorHandler()
      }
    },
    dataHandler(response) {
      // this.setOption()
      console.log(response)
    },
    initEchart(dom) {
      // 存在echarts实例时不再重新实例化
      this.$echarts.init(dom).dispose()
      const chart = this.$echarts.getInstanceByDom(dom)
      if (chart !== undefined) return
      this.myChart = this.$echarts.init(dom, 'charttheme')

      // 当窗口缩放后echarts自动缩放
      window.addEventListener('resize', () => {
        this.myChart && this.myChart.resize()
      })

      // 路由切换时销毁echarts实例，防止内存泄漏
      this.$once('hook:beforeDestroy', () => {
        if (!this.myChart) return
        this.$echarts.dispose(this.myChart)
        this.myChart = null
        window.removeEventListener('resize', () => {
          this.myChart.resize()
        })
      })
    },
    setOption() {},
    // 空数据/接口错误处理
    errorHandler() {
      this.chartLoading = false
      this.myChart && this.myChart.clear()
      this.myChart &&
        this.myChart.showLoading({
          text: '暂无数据',
          fontSize: '30',
          color: '#ffffff',
          textColor: '#000',
          maskColor: 'rgba(255, 255, 255, 0.8)'
        })
    },
    /**
     * @description: tooltip通用格式化
     * @param {array} params
     * @param {string} unit
     * @return {*} html
     */
    tooltipFormat(params = [], unit = '') {
      if (params.length === 0) return
      let html = `${params[0].axisValue}<br>`
      params.forEach(e => {
        html += `${e.marker}${e.seriesName}：${e.value || '-'}${e.value ? unit || '' : ''}<br>`
      })
      return html
    },
    // 导出图片，给没有title的chart加title
    downLoadChartTitle() {
      // if (chartFlag === 'inner') {
      //   const moduleName = this.$parent.$parent.title || ''
      //   if (!this.option.title.text) {
      //     this.option.title.show = false
      //     this.option.title.text = `${this.name}-${moduleName}`
      //   }
      // } else if (chartFlag === 'analysis') {
      //   this.option.title.text = `${this.name}-${this.$store.state.amplify.title}`
      // }
      //
      //
      // 拿到放大的弹框里的title
      let moduleName = ''
      if (this.$parent.$parent) {
        moduleName = this.$parent.$parent.title
      }
      if (!this.option?.title || !this.option.title.text) {
        this.option.title.show = false
        if (moduleName) {
          this.option.title.text = `${this.name}-${moduleName}`
        } else {
          this.option.title.text = `${this.name}-${this.$store.state.amplify.title}`
        }
      }
    }
  },
  watch: {
    queryData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val || Object.keys(val).length === 0) return false
        this.query = val
        this.chartLoading = true
        this.queryIndexName()
        this.getData()
      }
    },
    '$route.query.id': function (newVal, oldVal) {
      if (oldVal && oldVal !== newVal) {
        this.getData()
      }
    }
  }
}

<script>
import Common from './common'
import { getData } from '@/apis/server'
export default {
  name: 'GrossProfitAndRate', // 毛利和毛利率
  mixins: [Common],
  data() {
    return {
      xData: [],
      profit: [],
      profitRate: []
    }
  },
  watch: {
    activeTag: {
      // immediate: true,
      handler() {
        this.chartLoading = true
        this.getData()
      }
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#GrossProfitAndRate'))
    this.getData()
  },
  methods: {
    getData() {
      this.chartLoading = true
      const { dataApi } = this.widget
      getData(dataApi, {
        queryField: {
          breedId: this.breedId,
          frequencyName: '月度',
          startTime: this.$dayjs()
            .subtract(this.$store.state.power.powerYear, 'year').startOf('year')
            .startOf('year')
            .format('YYYY-MM-DD HH:mm:ss'),
          endTime: this.$dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        }
      })
        .then(({ response }) => {
          this.chartLoading = false
          this.myChart.clear()
          this.myChart.hideLoading()
          if (!response || !response.data?.length) {
            this.errorHandler()
            return false
          }
          // 处理数据
          const data = Object.values(response)
            .flat(Infinity)
            .sort((a, b) => {
              if (a.key > b.key) {
                return 1
              }
              return -1
            })
          const xData = data.map(item => this.$dayjs(item.key).format('YYYY-MM-DD'))
          const profit = data.map(item => item.profit)
          const profitRate = data.map(item => item.profitRate)
          this.setOption(xData, profit, profitRate)
        })
        .finally(() => {
          this.chartLoading = false
        })
    },
    setOption(xData, profit, profitRate) {
      const option = {
        legend: {},
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: [
          {
            name: '毛利'
          },
          {
            name: '毛利率',
            position: 'right',
            splitLine: {
              show: false
            }
          }
        ],
        dataZoom: [
          { type: 'inside', start: 50 },
          { type: 'slider', start: 50 }
        ],
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        series: [
          {
            type: 'line',
            name: '毛利',
            data: profit
          },
          {
            type: 'line',
            name: '毛利率',
            yAxisIndex: 1,
            itemStyle: {
              // color: '#E28C1B'
            },
            data: profitRate
          }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div id="GrossProfitAndRate" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'

export default {
  name: 'CrudeOilPriceCompare', // 全球原油期货价格
  mixins: [Common],
  mounted() {
    this.initEchart(this.$refs.CrudeOilPriceCompare)
  },
  methods: {
    /**
     * @description 处理数据的逻辑
     */
    dataHandler() {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
    },
    setOption(barX = [], seriesList = [], yAxis = []) {
      const option = {
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}${
                e.value[1] ? e.value[2] || '' : ''
              }<br>`
            })
            return html
          }
        },
        legend: {
          top: '3%',
          type: 'scroll'
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: '3%',
            left: '5%',
            right: '3%'
          }
        ],
        grid: {
          bottom: '13%',
          right: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: barX,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis,
        series: seriesList
      }
      this.myChart.setOption(option, true)
    }
  }
}
</script>

<template>
  <div ref="CrudeOilPriceCompare" v-loading="chartLoading" class="chart-container" />
</template>

/**
 * 同步调用本地接口
 * @param obj 获取本地数据的参数
 * @returns 结果
 */
const fetchLocalDataForAsync = async (obj) => {
  try {
    const data = new Promise((resolve, reject) => {
      if (!window.CefViewQuery) {
        reject(new Error("window.CefViewQuery未定义"));
        return;
      }
      window.CefViewQuery({
        request: JSON.stringify(obj),
        onSuccess: resolve,
        onFailure: reject,
      });
    });
    return data;
  } catch (error) {
    return error;
  }
}

/**
 * 调用本地接口
 * @param cefObj cef框架获取本地数据的参数
 * @param nwObj nw框架获取本地数据的参数
 * @returns promise结果
 */
const fetchLocalData = async (cefObj, nwObj) => {
  try {
    if (window.CefViewQuery) {
      if (!cefObj) throw 'window.CefViewQuery未定义';
      const res = await fetchLocalDataForAsync(cefObj);
      return JSON.parse(res);
    } else {
      let expCallback = window.expCallback || window.parent.expCallback;
      if (!nwObj || !expCallback || !expCallback.next) throw 'window.expcallback未定义';
      // 在NW框架下，将异步操作包装为 Promise 对象
      return new Promise((resolve, reject) => {
        try {
          window.expCallback.next(nwObj);
          resolve(); // 返回空值，因为NW框架下没有返回数据
        } catch (error) {
          reject(error); // 如果发生错误，将错误传递给 Promise 的 reject 方法
        }
      });
    }
  } catch (error) {
    // 在异步函数中抛出的错误会被包装成一个被拒绝的 Promise
    return {
      successed: false,
      message: error
    };
  }
}

export default fetchLocalData
<!--
 * @Author: bjp
 * @Description: 供应-开工率
-->
<script>
import Common from '../common'
import { getOperaYearLine } from '@/apis/Breed/Supply'
import { getNumberOfString } from '@/utils/common'

export default {
  name: 'OperatingRate', // 行业开工率
  mixins: [Common],
  props: {
    showName: {
      type: String,
      default: ''
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      barX: [],
      chartData: [],
      dataSetSource: []
    }
  },
  mounted() {
    this.initEchart(this.$refs.OperatingRate)
  },
  methods: {
    getData() {
      if (Object.keys(this.queryData).length > 0) {
        getOperaYearLine(this.queryData)
          .then(res => {
            this.$emit('change-data', res.response)

            if (!res.response || !Object.keys(res.response).length) {
              this.myChart.clear()
              this.errorHandler()
              return
            }
            this.dataHandler(res.response)
          })
          .catch(() => this.errorHandler)
      } else {
        this.dataHandler()
      }
    },
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      if (response && Object.keys(response).length > 0) {
        let dataDate = []
        const series = Object.keys(response)
          .sort((a, b) => getNumberOfString(b) - getNumberOfString(a))
          .map(v => {
            return {
              name: v,
              type: 'line',
              data: response[v]
                .map(e => {
                  const date = e.exhDate
                  dataDate.push(date)
                  return [date, e.operateRate]
                })
                .sort((a, b) => getNumberOfString(a[0]) - getNumberOfString(b[0]))
            }
          })
        dataDate = [...new Set(dataDate)].sort(
          (a, b) => getNumberOfString(a) - getNumberOfString(b)
        )
        this.setOption(series, dataDate)
      } else {
        this.errorHandler()
      }
    },
    setOption(series = [], dataDate = []) {
      const option = {
        title: {
          text: `${this.showName || this.name}开工率`,
          x: 'center',
          top: 0,
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        dataZoom: {
          bottom: '3%'
        },
        legend: {
          bottom: '12%'
        },
        grid: {
          top: this.magnifier ? '8%' : '15%',
          bottom: '23%',
          left: '1%',
          right: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: dataDate
        },
        yAxis: {
          type: 'value',
          name: '%',
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div ref="OperatingRate" v-loading="chartLoading" class="chart-container" />
</template>

<script>
export default {
  name: 'ProfitDistribution', // 产业链利润分布
  data() {
    return {
      IndustrialChain: {
        mainBreed: {
          name: '丙烯',
          rate: -0.29
        },
        upstream: [
          {
            craft: 'CTP',
            raw: '动力煤',
            rate: 0.3,
            remark: '这是备注信息'
          },
          {
            craft: '炼油综合生产',
            raw: '原油',
            rate: 0.3,
            remark: '这是备注信息'
          },
          {
            craft: '常规精馏工艺',
            raw: '动力煤',
            rate: 0.3,
            remark: '这是备注信息'
          },
          {
            craft: 'MTP',
            raw: '动力煤',
            rate: 0.3,
            remark: '这是备注信息'
          }
        ],
        downstream: [
          {
            breed: 'PP',
            consumeRate: 0.64,
            grossProfitRate: 0.25
          },
          {
            breed: 'PP粉',
            consumeRate: 0.64,
            grossProfitRate: -0.25
          },
          {
            breed: '环氧丙烷',
            consumeRate: 0.64,
            grossProfitRate: 0.25
          },
          {
            breed: '丙烯腈',
            consumeRate: 0.64,
            grossProfitRate: 0.25
          },
          {
            breed: '辛醇',
            consumeRate: 0.64,
            grossProfitRate: 0.25
          }
        ]
      }
    }
  }
}
</script>

<template>
  <div class="profit-distribution">
    <!-- upper -->
    <div class="upper">
      <div class="upper-lst">
        <div v-for="item in IndustrialChain.upstream" :key="item.id" class="upper-i">
          <div class="raw-i">
            <div class="craft">
              {{ item.craft }}
            </div>
            <div class="product">
              <span class="name">{{ item.raw }}</span>
              <span class="rate" :class="[item.rate > 0 ? 'red' : 'green']">{{
                `${item.rate * 100}%`
              }}</span>
            </div>
            <div class="remark">
              {{ item.remark }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end upper -->
    <div class="mainPrd">
      <div class="product">
        <span class="name">{{ IndustrialChain.mainBreed.name }}</span>
        <span class="rate" :class="[IndustrialChain.mainBreed.rate > 0 ? 'red' : 'green']">{{
          `${(IndustrialChain.mainBreed.rate * 100).toFixed(2)}%`
        }}</span>
      </div>
    </div>
    <!-- downstream -->
    <div class="downstream">
      <div class="head-tit">
        <span class="tit" style="margin-left: 60px; width: 80px">消费占比</span>
        <span class="tit" style="margin-left: 70px; width: 200px">毛利率</span>
      </div>
      <div class="down-lst">
        <div v-for="item in IndustrialChain.downstream" :key="item.id" class="down-i">
          <div class="box-01">
            <span :class="[item.consumeRate > 0 ? 'red' : 'green']">{{
              `${item.consumeRate * 100}%`
            }}</span>
          </div>
          <div class="box-02">
            <div class="product">
              <span class="name">{{ item.breed }}</span>
              <span class="rate" :class="[item.grossProfitRate > 0 ? 'red' : 'green']">{{
                `${item.grossProfitRate * 100}%`
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end downstream -->
  </div>
</template>

<style scoped lang="scss">
$arrow: url(~@/assets/images/Breed/icon-arr.png);
$arrow_on_r: $arrow no-repeat right center;
$arrow_on_l: $arrow no-repeat left center;
$arrow_width: 60px;
.red {
  color: #ba0c2f;
}
.green {
  color: #1a9016;
}
@mixin line {
  content: '';
  position: absolute;
  width: 0;
  border-left: 1px solid #ba0c2f;
}
@mixin box {
  background: #f5f5f5;
  border-radius: 5px;
}
.profit-distribution {
  font-size: 14px;
  display: flex;
  align-items: center;
  .upper,
  .mainPrd,
  .downstream {
    flex-shrink: 0;
  }
  .upper {
    .upper-lst {
      position: relative;
      &::after {
        @include line;
        top: 25px;
        bottom: 25px;
        right: 0;
      }
      .upper-i {
        margin: 10px 0;
        padding-right: $arrow_width;
        background: $arrow_on_r;
      }
      .raw-i {
        position: relative;
        padding: 5px 10px;
        @include box;
        .remark {
          display: none;
          position: absolute;
          left: 100%;
          top: 5px;
          width: 100%;
          line-height: 1.4;
          padding: 5px;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
          &:before {
            content: '';
            position: absolute;
            left: -10px;
            width: 0;
            border-right: 10px solid rgba(0, 0, 0, 0.5);
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
          }
        }
        &:hover {
          .remark {
            display: block;
          }
        }
      }
    }
  }
  .mainPrd {
    padding: 0 $arrow_width;
    position: relative;
    .product {
      padding: 15px;
      @include box;
      span {
        margin: 0 5px;
      }
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 50px;
      background: $arrow_on_r;
      left: 0;
    }
    &::after {
      left: auto;
      right: 0;
    }
  }
  .downstream {
    margin-top: -20px;
    .head-tit {
      height: 20px;
      span {
        text-align: center;
        display: inline-block;
        font-weight: bold;
      }
    }
    .down-lst {
      position: relative;
      &::before {
        left: 0;
        top: 15px;
        bottom: 15px;
        @include line;
      }
    }
    .down-i {
      overflow: hidden;
      padding-left: $arrow_width;
      background: $arrow_on_l;
      margin: 10px 0;
      .box-01 {
        float: left;
        width: 80px;
        line-height: 14px;
        text-align: center;
        padding: 10px 0;
        @include box;
      }
      .box-02 {
        float: left;
        padding-left: $arrow_width;
        margin-left: 10px;
        background: $arrow_on_l;
        .product {
          padding: 10px;
          width: 180px;
          @include box;
        }
      }
    }
  }
  .craft {
    width: 160px;
    margin-bottom: 6px;
    text-align: center;
    line-height: 20px;
    background: #e25c61;
    border-radius: 10px;
    font-size: 12px;
    color: #fff;
  }
  .product {
    overflow: hidden;
    line-height: 14px;
    .name {
      float: left;
    }
    .rate {
      float: right;
    }
  }
}
</style>

<script>
import Common from './common'
export default {
  name: 'CrudeOilChinaExport', // 中国原油出口
  mixins: [Common],
  mounted() {
    this.initEchart(this.$refs.CrudeOilBar)
  },
  methods: {
    /**
     * @description 处理数据的逻辑
     * @param {Object} response
     */
    dataHandler(response) {
      if (!response.data || Object.keys(response.data).length === 0) {
        this.errorHandler()
        return
      }
      const { data } = response
      let unit = ''
      const series = {
        name: '中国原油出口量',
        type: 'bar',
        color: '#023985',
        data: []
      }
      data.时间.forEach((item, index) => {
        series.data.push([
          this.$dayjs(item.value).format('YYYY-MM-DD'),
          data.进出口数量[index].value,
          data.进出口数量单位[index].value
        ])
        if (!unit) unit = data.进出口数量单位[index].value
      })
      this.setOption(series, unit)
    },
    setOption(series = [], unit = '') {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      const option = {
        grid: {
          top: this.amplify ? '8%' : '15%',
          bottom: '13%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          top: '2%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            startValue: series.data?.length - 30
          }
        ],
        xAxis: {
          type: 'category'
        },
        yAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            name: unit
          }
        ],
        series
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div ref="CrudeOilBar" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'
import { getNumberOfString } from '@/utils/common'

export default {
  name: 'RiverChart',
  mixins: [Common], // 河道图
  data() {
    return {
      priceType: '主流价',
      option: {}
    }
  },
  watch: {
    chartTitle(val, oldVal) {
      if (val === oldVal) return
      if (!this.myChart) return
      const options = this.myChart.getOption()
      if (!options) return
      if (options.title) {
        options.title = [{ ...options.title[0], text: val }]
      }
      this.myChart.setOption(options)
    }
  },
  mounted() {
    this.initEchart(this.$refs.RiverChart)
  },
  methods: {
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      if (!response || response.length === 0) {
        this.errorHandler()
      } else {
        this.setOption(response)
      }
    },
    setOption(data) {
      let barX = []
      let unit = ''
      const seriesList = data.map((item, i) => {
        item.data.forEach(v => {
          barX.push(v.exhDate)
          if (!unit) unit = v.dataUnit
        })

        const series = {
          color: '#E28C1B',
          name: item.name,
          type: 'line',
          smooth: true,
          symbol: 'none',
          lineStyle: {
            width: 2
          },
          emphasis: {
            lineStyle: {
              width: 2
            }
          },
          data: item.data
            .map(v => [v.exhDate, v.mainPrice, v.dataUnit])
            .sort((a, b) => getNumberOfString(a[0]) - getNumberOfString(b[0]))
        }

        if (item.name.includes('最小值')) {
          series.color = '#3A466E'
          series.areaStyle = { color: '#fff', opacity: 1 }
          series.lineStyle = { type: 'solid', width: 2 }
        }
        if (item.name.includes('最大值')) {
          series.color = '#6899DD'
          series.areaStyle = { color: '#BFBFBF' }
          series.lineStyle = { type: 'solid', width: 2 }
        }
        if (item.name.includes('均值')) {
          series.lineStyle = { type: 'dashed', width: 2 }
          series.color = '#2263BD'
        }
        if (i === 3) {
          series.lineStyle = { width: 2 }
        }
        return series
      })

      barX = [...new Set(barX)].sort((a, b) => getNumberOfString(a) - getNumberOfString(b))

      this.option = {
        title: {
          text: this.chartTitle,
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14
          },
          left: 'center'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}${
                e.value[1] ? e.value[2] || '' : ''
              }<br>`
            })
            return html
          }
        },
        legend: {
          bottom: '13%'
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: '3%',
            left: '3%',
            right: '1%'
          }
        ],
        grid: {
          top: this.amplify ? '10%' : '18%',
          bottom: '25%',
          left: '1%',
          right: '1%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: barX
        },
        yAxis: {
          type: 'value',
          scale: true,
          name: unit
        },
        series: seriesList
      }

      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="RiverChart" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'
import { formatDayJsTime } from '@/utils/formatData'

export default {
  name: 'CrudeOilRefineryWorkRateYear',
  mixins: [Common], // 分年对比
  data() {
    return {
      yData: [],
      responseData: {},
      getShowYear: [],
      dataSetSource: [],
      unitY: '',
      priceType: '主流价',
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.CrudeOilRefineryWorkRateYear)
  },
  methods: {
    dataHandler(data) {
      this.responseData = data
      let getFullYearDay = []
      let getFullWeek = []
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      this.yData = []
      this.dataSetSource = []
      this.getShowYear = []
      if (!data) {
        this.errorHandler()
        return
      }

      for (const key in data) {
        data[key].forEach(item => {
          getFullYearDay.push(this.$dayjs(item.researchStopDate).format('MM-DD'))
          getFullWeek.push(item.exhDate)
        })
      }
      this.unitY = data[Object.keys(data)[0]][0].unitName
      getFullYearDay = [...new Set(getFullYearDay)].sort()
      getFullWeek = [...new Set(getFullWeek)].sort((a, b) => {
        return a.replace(/[^0-9]/gi, '') - b.replace(/[^0-9]/gi, '')
      })
      const { frequencyName } = this.queryData
      if (frequencyName === '日度') {
        this.getFullBarx(frequencyName, getFullYearDay)
      } else if (frequencyName === '周度' || frequencyName === '季度') {
        this.getFullBarx(frequencyName, getFullWeek)
      } else {
        const format = formatDayJsTime(frequencyName, true)
        const months = [...new Array(12)].map((k, i) => `${(i + 1).toString().padStart(2, '0')}月`)
        Object.keys(data)
          .reverse()
          .forEach((item, i) => {
            this.yData.push({
              type: 'line',
              name: item,
              data: [],
              connectNulls: true
            })
            months.forEach(v => {
              const arr = [v, '']
              data[item].forEach(val => {
                if (v === this.$dayjs(val.researchStopDate).format(format)) {
                  arr[1] = val.inputValue
                }
              })
              this.yData[i].data.push(arr)
            })
          })
      }
      this.setOption()
    },
    /**
     * 根据频率获取x轴对应
     */
    getFullBarx(type, getFull) {
      let params = ''
      getFull.forEach(full => {
        let obj = {}
        for (const key in this.responseData) {
          for (const key1 in this.responseData[key]) {
            if (type === '日度')
              params = this.$dayjs(this.responseData[key][key1].researchStopDate).format('MM-DD')
            else params = this.responseData[key][key1].exhDate
            if (full === params) {
              obj = {
                ...obj,
                [key]: this.responseData[key][key1].inputValue,
                [`${key}unitName`]: this.responseData[key][key1].unitName
              }
            }
          }
        }
        this.dataSetSource.push({ time: full, ...obj })
      })
      Object.keys(this.responseData)
        .reverse()
        .forEach(item => {
          this.getShowYear.push(item)
          this.yData.push({
            type: 'line',
            name: item,
            connectNulls: true
          })
        })
    },
    setOption() {
      const { frequencyName } = this.queryData
      this.option = {
        title: {
          show: false
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          position(point) {
            return [point[0] + 10, point[1] - 100]
          },
          formatter: params => {
            let tool = `${params[0].name}<br/>`
            const arr = []
            params.forEach(e => {
              const data = frequencyName === '月度' ? e.data[1] : e.data[e.seriesName]
              const unit = frequencyName === '月度' ? this.unitY : e.data[`${e.seriesName}unitName`]
              arr.push(`${e.marker}${e.seriesName}：${data || '-'}${unit || ''}`)
            })
            tool += arr.join('<br/>')
            return tool
          }
        },
        legend: {
          type: 'scroll',
          bottom: 0
        },
        grid: {
          left: 10,
          bottom: 30,
          top: 35,
          containLabel: true
        },
        dataset: {
          show: this.dataSetSource.length > 0,
          dimensions: ['time', ...this.getShowYear],
          source: this.dataSetSource
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          type: 'value',
          scale: true,
          name: this.unitY
        },
        series: this.yData
      }
      this.downLoadChartTitle('analysis')
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="CrudeOilRefineryWorkRateYear" v-loading="chartLoading" class="chart-container" />
</template>

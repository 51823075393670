import Vue from 'vue'
import VueRouter from 'vue-router'
import BreedRoutes from './breed/index.js'
import ContrastScreen from './contrastScreen'
import HomeRoutes from './home'

import Layout from '@/layouts/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      ...HomeRoutes, // 首页
      ...BreedRoutes, // 品种页面
      ...ContrastScreen, // 对比与筛选
      {
        path: 'enterprise',
        component: () => import('@/views/Enterprise/index.vue'),
        name: 'Enterprise',
        meta: { title: '企业详情' }
      },
      {
        path: 'myCustomization',
        component: () => import('@/views/MyCustomization/index.vue'),
        name: 'MyCustomizationIndex',
        redirect: '/myCustomization/myGoods',
        meta: { title: '我的定制' },
        children: [
          {
            path: 'myGoods',
            component: () => import('@/views/MyCustomization/myGoods.vue'),
            name: 'myGoods',
            meta: { title: '我的商品' }
          },
          {
            path: 'myDatas',
            component: () => import('@/views/MyCustomization/myDatas.vue'),
            name: 'myDatas',
            meta: { title: '我的数据' }
          },
          {
            path: 'myTemplate',
            component: () => import('@/views/MyCustomization/myTemplate.vue'),
            name: 'myTemplate',
            meta: { title: '我的模板' }
          }
          // {
          //   path: 'myWarning',
          //   component: () => import('@/views/MyCustomization/myWarning.vue'),
          //   name: 'myWarning',
          //   meta: { title: '我的预警' }
          // },
          // {
          //   path: 'myCompany',
          //   component: () => import('@/views/MyCustomization/myCompany.vue'),
          //   name: 'myCompany',
          //   meta: { title: '我的企业' }
          // }
        ]
      },
      {
        path: 'dataComparse',
        component: () => import('@/views/DataComparse/index.vue'),
        name: 'dataComparse',
        meta: { title: '数据对比' }
      },
      {
        path: 'reportCenter',
        component: () => import('@/views/ReportCenter/index.vue'),
        name: 'ReportCenterIndex',
        redirect: '/reportCenter/list',
        meta: { title: '报告中心' },
        children: [
          {
            path: 'list',
            component: () => import('@/views/ReportCenter/list.vue'),
            name: 'reportList',
            meta: { title: '报告中心', isReportCenter: true }
          },
          {
            path: 'details',
            component: () => import('@/views/ReportCenter/details.vue'),
            name: 'reportDetails',
            meta: { title: '报告中心详情', isReportCenter: true }
          }
        ]
      },
      {
        path: 'priceHomeIndex',
        component: () => import('@/views/PriceHome/index.vue'),
        name: 'PriceHomeIndex',
        redirect: '/priceHomeIndex/priceUpAndDown',
        meta: { title: '价格' },
        children: [
          {
            path: 'priceUpAndDown',
            component: () => import('@/views/PriceHome/priceUpAndDown.vue'),
            name: 'priceUpAndDown',
            meta: { title: '价格涨跌' }
          },
          {
            path: 'profitUpAndDown',
            component: () => import('@/views/PriceHome/profitUpAndDown.vue'),
            name: 'profitUpAndDown',
            meta: { title: '利润涨跌' }
          },
          {
            path: 'pricePercentiles',
            component: () => import('@/views/PriceHome/pricePercentiles.vue'),
            name: 'pricePercentiles',
            meta: { title: '价格分位' }
          },
          {
            path: 'priceAmplitude',
            component: () => import('@/views/PriceHome/priceAmplitude.vue'),
            name: 'priceAmplitude',
            meta: { title: '价格振幅' }
          }
        ]
      },
      {
        path: 'instruction/:type',
        component: () => import('@/views/Instruction/index.vue'),
        name: 'instruction',
        meta: { title: '使用手册' }
      }
    ]
  }
]

// 避免重复点击同一路由报错，可有可无
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({ routes, mode: 'history' })

export default router

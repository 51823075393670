<script>
import Common from './common.js'
export default {
  name: 'ProfitIndex', // 利润指数
  mixins: [Common],
  data() {
    return {
      unit: [],
      markData: false
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#ProfitIndex'))
  },
  methods: {
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      const data = []
      if (response.length > 0) {
        response.forEach((item, index) => {
          data.push([
            item.dataDate.split(' ')[0],
            item.profit,
            item.stockPrice,
            item.milepost,
            item.profitUnit,
            item.stockUnit
          ])
          if (index === 0) {
            this.unit.push(item.profitUnit)
            this.unit.push(item.stockUnit)
          }
        })
        this.setOption(data)
      } else {
        this.errorHandler()
      }
    },
    setOption(data) {
      const circleColor = '#fff'
      const option = {
        legend: {},
        grid: {
          bottom: '15%'
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            rotate: -30
          }
        },
        yAxis: [
          {
            name: '毛利率',
            splitNumber: 4,
            scale: true
          },
          {
            name: '股价',
            position: 'right',
            splitLine: {
              show: false
            },
            splitNumber: 4,
            scale: true
          }
        ],
        dataset: {
          source: data
        },
        graphic: {
          type: 'group',
          right: '8%',
          top: 12,
          children: [
            {
              type: 'circle',
              shape: {
                cx: 8,
                cy: 6,
                r: 8
              },
              style: {
                fill: '#fff',
                stroke: '#6899dd',
                lineWidth: 1
              }
            },
            {
              type: 'circle',
              shape: {
                cx: 8,
                cy: 6,
                r: 5
              },
              style: {
                fill: circleColor
              }
            },
            {
              type: 'text',
              left: 20,
              top: 0,
              style: {
                text: '企业里程碑',
                fill: ''
              }
            }
          ],
          onclick() {
            toggleEvent()
          }
        },
        dataZoom: [
          {
            type: 'inside',
            startValue: 0,
            endValue: 20
          },
          {
            type: 'slider',
            startValue: 0,
            endValue: 20
          }
        ],
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            if (params.length === 0) return
            let html = `<div class="toolDiv">${params[0].axisValue}<br>`
            params.forEach((e, index) => {
              html += `${e.marker}${e.seriesName} ：${e.value[index + 1] || '-'}${
                e.value[index + 1] ? ` ${this.unit[index]}` || '' : ''
              }<br>`
            })
            if (this.markData && params[0].value[3]) html = ''
            return `${html}</div>`
          }
        },
        series: [
          {
            type: 'line',
            name: '毛利润',
            connectNulls: true,
            encode: { y: 1 }
          },
          {
            type: 'line',
            name: '股价',
            yAxisIndex: 1,
            connectNulls: true,
            itemStyle: {
              // color: '#E28C1B'
            },
            markPoint: {
              symbolSize: 0,
              symbolOffset: [0, 0],
              label: {
                show: false,
                backgroundColor: '#fff',
                position: 'right',
                width: 130,
                offset: [0, 60],
                padding: 10,
                borderWidth: 1,
                borderColor: '#ddd',
                borderRadius: 3,
                lineHeight: 20,
                overflow: 'break',
                formatter: val => {
                  const arr = []
                  for (const key in val.value) {
                    arr.push(`{a|${key}}${val.value[key]}\n`)
                  }
                  return arr.join('\n')
                },
                rich: {
                  a: {
                    fontWeight: 'bold'
                  }
                }
              },
              emphasis: {
                label: {
                  show: true
                }
              },
              data: data
                .filter(item => {
                  return !!item[3]
                })
                .map(item => {
                  return {
                    value: {
                      '里程碑事件：': item[3],
                      '毛利润：': item[1] + item[4],
                      '股价：': item[2] + item[5]
                    },
                    coord: [item[0], item[2]]
                  }
                })
            },
            encode: { y: 2 }
          }
        ]
      }
      this.myChart.setOption(option)
      const _this = this
      function toggleEvent() {
        if (option.series[1].markPoint.symbolSize === 0) {
          // circleColor = '#6899dd'
          option.series[1].markPoint.symbolSize = 30
          option.graphic.children[1].style.fill = '#6899dd'
          _this.markData = true
        } else {
          // circleColor = '#fff'
          option.series[1].markPoint.symbolSize = 0
          option.graphic.children[1].style.fill = '#fff'
          _this.markData = false
        }
        _this.myChart.setOption(option)
      }
    }
  }
}
</script>

<template>
  <div id="ProfitIndex" v-loading="chartLoading" class="chart-container" />
</template>

<style lang="scss" scoped>
:deep(.toolDiv) {
  max-width: 300px;
  white-space: pre-wrap;
}
</style>

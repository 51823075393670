<script>
import Common from './common'

export default {
  name: 'Stock', // 库存 & 库容比
  mixins: [Common],
  props: {
    graphicName: {
      type: String,
      default: '库存'
    }
  },
  data() {
    return {
      seriesNameItem: '',
      option: {}
    }
  },
  watch: {
    graphicName: {
      immediate: true,
      handler(val) {
        if (val === '库存') {
          this.seriesNameItem = '库存数量'
        } else {
          this.seriesNameItem = '库容数量'
        }
      }
    }
  },
  mounted() {
    this.initEchart(this.$refs.Stock)
  },
  methods: {
    dataHandler(response) {
      if (response && response.data) {
        const { data } = response
        // console.log(this.queryData)
        // const xData =
        //   this.queryData.frequencyName !== '周度'
        //     ? data['展示日期'].map(e => e.value)
        //     : data['日期'].map(e => this.$dayjs(e.value).format('YYYY-MM-DD'))
        const xData = data['日期'].map(e => this.$dayjs(e.value).format('YYYY-MM-DD'))
        const seriesName = this.seriesNameItem
        const stockUnit = data[`${this.graphicName}单位`].find(e => e.value)?.value || ''
        const priceUnit = data['主流价格单位'].find(e => e.value)?.value || ''
        const series = [
          {
            name: seriesName === '库存数量' ? this.queryData.invType : seriesName,
            type: 'line',
            data: data[seriesName]
          },
          {
            name: '价格',
            type: 'line',
            data: data['主流价格'],
            yAxisIndex: 1
          }
        ]

        this.myChart.clear()
        this.myChart.hideLoading()
        this.setOption(xData, series, stockUnit, priceUnit)
      } else {
        this.errorHandler()
      }
      this.chartLoading = false
    },
    setOption(xData = [], series = [], stockUnit = '', priceUnit = '') {
      this.option = {
        title: {
          show: false
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}&nbsp;&nbsp;&nbsp;&nbsp;${e.value || '-'}${
                (e.seriesIndex === 0 ? stockUnit : priceUnit) || ''
              }<br>`
            })
            return html
          }
        },
        dataZoom: {
          show: true,
          bottom: 7,
          startValue: [...xData].length - 40
        },
        legend: {
          top: 6
        },
        grid: {
          top: 36,
          bottom: 40,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: xData
        },
        yAxis: [
          {
            name: stockUnit,
            type: 'value',
            scale: true,
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            name: priceUnit,
            scale: true,
            splitLine: {
              show: false
            }
          }
        ],
        series
      }
      const moduleName = this.$parent.$parent.title || this.$store.state.amplify.title || ''
      this.option.title.text = `${this.name}${moduleName}`
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="Stock" v-loading="chartLoading" class="chart-container" />
</template>

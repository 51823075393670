<script>
import dayjs from 'dayjs'
import Common from './common'
import { getData } from '@/apis/server'
export default {
  name: 'PriceComparisonCommon',
  mixins: [Common], // 分年对比(分年对比都用此图，此处以海关数据为例)
  data() {
    return {
      yData: []
    }
  },
  mounted() {
    this.initEchart(document.getElementById('PriceComparisonCommon'))
  },
  methods: {
    async getData() {
      const query = {
        queryField: {
          breedId: this.id,
          frequency: '月度',
          imExType: '进口'
        }
      }
      const { response } = await getData('/api/widget/queryData/9', query)
      this.dataHandler(response)
    },
    dataHandler(data) {
      const series = []
      if (!data) {
        this.setOption()
        return
      }
      Object.keys(data).forEach(item => {
        series.push({
          type: 'line',
          name: item,
          data: data[item].map(val => {
            return [dayjs(val.dataDate).format('YY年MM月'), val.currVolume]
          })
        })
      })
      this.setOption(series)
    },
    setOption(series = []) {
      const option = {
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        legend: {},
        grid: {
          bottom: 50
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: 20
          }
        ],
        xAxis: {
          type: 'category'
        },
        yAxis: {
          type: 'value'
        },
        series
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div id="PriceComparisonCommon" class="chart-container" />
</template>

<script>
import Common from './common'

export default {
  name: 'ProductionProcessComparison', // 生产工艺占比
  mixins: [Common],
  mounted() {
    this.initEchart(this.$refs.ProductionProcessComparison)
    // 没有参数无法监测，手动调用
    this.getData()
  },
  methods: {
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      if (!response) {
        this.errorHandler()
        return
      }
      const data = response.data.map(({ process: name, processRatio: value }) => {
        return {
          name,
          value
        }
      })
      this.setOption(data)
    },
    setOption(data = []) {
      const option = {
        legend: {
          type: 'scroll',
          y: 'bottom',
          x: 'center',
          icon: 'circle'
        },
        tooltip: {
          confine: true,
          formatter: params => {
            return `${params.name}<br/>${params.marker}${
              params.seriesName
            }：<b class="chart-content style="float:right;margin-left:10px">${
              params.value ? `${params.value} % ` : params.value
            }</b>`
          }
        },
        series: [
          {
            name: '生产工艺对比',
            type: 'pie',
            center: ['50%', '48%'],
            radius: ['45%', '55%'],
            data,
            label: {
              formatter: '{d}%'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div ref="ProductionProcessComparison" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Description: 企业-拟在产产能对比
 * @Date: 2022-04-20 14:00:24
 * @LastEditTime: 2022-05-16 10:43:55
-->
<script>
import Common from './common.js'
import { getDataByGet } from '@/apis/server.js'
export default {
  name: 'EnterpriseComparePlanBuildCapacity', // 企业-拟在产产能对比
  mixins: [Common],
  data() {
    return {}
  },
  watch: {
    activeTag() {
      this.getData()
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#EnterpriseComparePlanBuildCapacity'))
    this.getData()
  },
  methods: {
    getData() {
      this.chartLoading = true
      const params = {
        companyId: this.companyId,
        breedId: this.breedId
      }
      const { dataApi } = this.widget
      getDataByGet(dataApi, params)
        .then(({ response }) => {
          this.chartLoading = false
          this.myChart.clear()
          this.myChart.hideLoading()
          if (response.length) {
            this.setOption(response)
          } else {
            this.errorHandler()
          }
        })
        .catch(() => {
          this.errorHandler()
        })
    },
    setOption(data) {
      const unit = data.find(e => e.unit).unit
      const option = {
        grid: {
          top: '10%',
          right: '5%',
          bottom: '13%'
        },
        color: '#023985',
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: -30,
            lineHeight: 14,
            verticalAlign: 'top',
            overflow: 'break',
            formatter: value => this.showMultipleLabel(value)
          }
        },
        yAxis: {
          name: unit,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          formatter(param) {
            const { companyName, capacity, unit } = param[0].value
            return `${companyName}<br>${param[0].marker}${capacity || '-'}${unit || ''}`
          }
        },
        dataZoom: [
          {
            type: 'inside',
            startValue: 0,
            endValue: 20
          },
          {
            type: 'slider',
            startValue: 0,
            endValue: 20
          }
        ],
        dataset: {
          source: data
        },
        series: [
          {
            type: 'bar',
            encode: { x: 'companyName', y: 'capacity' },
            color: '#023985'
          }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div id="EnterpriseComparePlanBuildCapacity" v-loading="chartLoading" class="chart-container" />
</template>

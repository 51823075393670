<!--
 * @Description:炼油装置特殊页面柱状折线混合土-老接口数据
 * @Date: 2022年7月22日14:37:51
 * @LastEditTime: 2022-08-16 15:05:49
 @接收参数
 @ series: 数据取值 {lengend: '图例名称', key: '取值字段', unit: '单位字段，空显示%'}
-->
<script>
import Common from './common'

export default {
  name: 'OilSpecialLineBarMixOld', // 炼油装置特殊页面-柱状折线混合
  mixins: [Common],
  props: {
    series: {
      type: Array,
      default: () => [] // {lengend: '图例名称', key: '取值字段', unit: '单位字段，空显示%'}
    }
  },
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.OilSpecialLineBarMixOld)
  },
  methods: {
    /**
     * @description 处理数据的逻辑
     * @param {Object} response
     */
    dataHandler(response) {
      if (Object.keys(response)?.length === 0) {
        this.errorHandler()
      }
      const series = []
      const units = []
      // 初始化series
      this.series.forEach((v, i) => {
        series.push({
          name: v.legend,
          type: i === 0 ? 'bar' : 'line',
          yAxisIndex: i === 0 ? 0 : 1,
          color: i === 0 ? '#023985' : '#BC0008',
          connectNulls: true,
          data: []
        })

        response.data[v.key].forEach((value, index) => {
          const unit = v.unit ? response.data[v.unit][index].value : '%'
          if (!units[i]) units[i] = unit
          series[i].data.push([this.$dayjs(value.name).format('YYYY-MM-DD'), value.value, unit])
        })
      })

      this.setOption(series, units)
    },
    setOption(series = [], units = []) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        title: {
          show: false
        },
        grid: {
          top: this.amplify ? '8%' : '15%',
          bottom: '13%',
          right: '5%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          top: '2%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            startValue: series[0].data.length - 30
          }
        ],
        xAxis: {
          type: 'category',
          axisLabel: {
            rotate: -30
          }
        },
        yAxis: [
          {
            type: 'value',
            name: units[0],
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            name: units[1],
            scale: true,
            splitLine: {
              show: false
            }
          }
        ],
        series
      }
      this.downLoadChartTitle('analysis')
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="OilSpecialLineBarMixOld" v-loading="chartLoading" class="chart-container" />
</template>

<script>
export default {
  name: 'MarketSentiment',
  props: {
    widget: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    const myChart = this.$echarts.init(this.$refs.MarketSentiment)
    const option = {
      dataZoom: [
        {
          type: 'inside'
        },
        {
          type: 'slider',
          bottom: 20,
          backgroundColor: '#d7dae2',
          height: 16,
          moveHandleSize: 0,
          handleSize: '200%',
          showDataShadow: false,
          handleStyle: {
            color: '#3A466E',
            borderWidth: 0
          },
          fillerColor: 'rgb(58, 70, 110,0.4)'
        }
      ],
      legend: {
        icon: 'rect',
        itemWidth: 20,
        itemHeight: 20,
        data: ['看涨', '持平', '看空']
      },
      yAxis: {
        type: 'value',
        max: 100,
        interval: 100,
        axisLabel: {
          show: false
        }
      },
      xAxis: {
        type: 'category',
        data: [
          '2021/03/02',
          '2021/03/03',
          '2021/03/04',
          '2021/03/05',
          '2021/03/06',
          '2021/03/07',
          '2021/03/08',
          '2021/03/09',
          '2021/03/10',
          '2021/03/11',
          '2021/03/12',
          '2021/03/13',
          '2021/03/14E'
        ],
        axisLabel: {
          rotate: 45
        },
        axisTick: {
          alignWithLabel: true
        }
      },
      series: [
        {
          name: '看涨',
          type: 'bar',
          stack: 'total',
          label: {
            show: true,
            formatter: '{c}%'
          },
          data: [57, 37, 52, 66, 49, 46, 60, 58, 51, 35, 56, 65, 53]
        },
        {
          name: '持平',
          type: 'bar',
          stack: 'total',
          label: {
            show: true,
            formatter: '{c}%'
          },
          data: [13, 15, 17, 10, 10, 19, 11, 26, 30, 25, 11, 22, 18]
        },
        {
          name: '看空',
          type: 'bar',
          stack: 'total',
          label: {
            show: true,
            formatter: '{c}%'
          },
          data: [30, 48, 31, 24, 41, 35, 29, 16, 19, 40, 33, 13, 29]
        }
      ],
      color: ['#BA0C2F', '#2263BD', '#3A466E']
    }
    myChart.setOption(option)
  }
}
</script>

<template>
  <div ref="MarketSentiment" class="chart-container" />
</template>

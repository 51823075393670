<!--
 * @Author: bjp
 * @Description: 不同工艺的成本构成
-->
<script>
import Common from '../common'
import { getStructChart } from '@/apis/Breed/Cost'
import { formatDayJsTime } from '@/utils/formatData'

export default {
  name: 'CostStructureOfDifferentTechnology', // 不同工艺的成本构成
  mixins: [Common],
  props: {
    chartType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dataset: [],
      seriesList: [],
      dimensions: [],
      option: {}
    }
  },
  watch: {
    seriesList(val) {
      if (val.length > 0) this.myChart.hideLoading()
    },
    chartType() {
      this.myChart.clear()
      this.setOption()
    }
  },
  mounted() {
    this.initEchart(this.$refs.CostStructureOfDifferentTechnology)
  },
  methods: {
    async getData() {
      this.chartLoading = true
      this.dataListLine = []
      this.barX = []
      const query = { ...this.queryData }
      if (Object.keys(this.queryData).length > 0) {
        const { response } = await getStructChart(query)
        this.dataHandler(response)
      }
    },
    dataHandler(response) {
      this.myChart.clear()
      if (!response) {
        this.errorHandler()
        return
      }
      this.myChart.hideLoading()
      const formatTime = 'YYYY-MM-DD'
      // formatTime = formatDayJsTime(this.queryData.queryField.frequencyName)

      this.seriesList = []
      this.dimensions = []
      this.dataset = []
      this.unitObj = {}
      // 修改代码是为了对时间排序
      this.dimensions = [
        ...new Set(
          Object.values(response)
            .flat()
            .map(item => {
              return item.consElemName
            })
        )
      ]
      const barx = [
        ...new Set(
          Object.keys(response).map(item => {
            return this.$dayjs(item).format(formatTime)
          })
        )
      ].sort((a, b) => {
        return a.replace(/[^0-9]/gi, '') - b.replace(/[^0-9]/gi, '')
      })

      barx.forEach(time => {
        let temp = {}
        for (const key in response) {
          response[key].forEach(e => {
            if (this.$dayjs(key).format(formatTime) === time) {
              temp = {
                ...temp,
                [e.consElemName]: e.consElemCost
              }
            }
            this.unitObj[e.consElemName] = e.costUnit
          })
        }
        this.dataset.push({
          time,
          ...temp
        })
      })
      this.seriesList = this.dimensions.map(v => ({
        name: v,
        stack: '成本构成',
        connectNulls: true,
        type: 'bar',
        barMaxWidth: 60
      }))
      this.setOption()
      this.chartLoading = false
    },
    setOption() {
      if (this.seriesList.length === 0) {
        this.errorHandler()
        return
      }
      let yAxis = {}
      let seriesList = null
      const dataset = JSON.parse(JSON.stringify(this.dataset))
      if (this.chartType === 0) {
        // 堆积柱状图
        const a = JSON.parse(JSON.stringify(this.seriesList))
        yAxis = {
          type: 'value'
        }
        seriesList = a
      } else if (this.chartType === 1) {
        // 堆积面积图
        const b = JSON.parse(JSON.stringify(this.seriesList))
        b.forEach(item => {
          item.smooth = true
          item.type = 'line'
          item.showSymbol = false
          item.areaStyle = {
            opacity: 0.8
          }
        })
        yAxis = {
          type: 'value'
        }
        seriesList = b
      } else {
        // 百分比堆积面积图
        // let temp = {}
        const c = JSON.parse(JSON.stringify(this.seriesList))
        c.forEach(item => {
          item.smooth = true
          item.type = 'line'
          item.showSymbol = false
          item.areaStyle = {
            opacity: 0.8
          }
          yAxis = {
            type: 'value',
            max: 100,
            axisLabel: {
              formatter: '{value}%'
            }
          }
        })
        dataset.forEach(item => {
          item.start = {}
          let num = 0
          for (const dataKey in item) {
            if (dataKey !== 'time' && dataKey !== 'start') {
              num += item[dataKey] * 1
            }
            item.sum = num
          }
          this.dimensions.forEach(d => {
            if (item[d]) {
              item.start[d] = item[d]
              item[d] = ((item[d] / item.sum) * 100).toFixed(0)
            }
          })
        })
        seriesList = c
      }

      this.option = {
        title: {
          show: false
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[e.seriesName] || '-'}${
                this.chartType === 2 ? '%' : this.unitObj[e.seriesName] || ''
              }<br>`
            })
            return html
          }
        },
        legend: {
          bottom: '10%'
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: '3%',
            left: '3%',
            right: '2%'
          }
        ],
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis,
        dataset: {
          dimensions: ['time', ...this.dimensions],
          source: dataset
        },
        grid: {
          bottom: '19%',
          top: '10%',
          left: '1%',
          right: '1%',
          containLabel: true
        },
        series: seriesList
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="CostStructureOfDifferentTechnology" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import AmplifyModal from '@/components/AmplifyModal.vue'
import { judgePower } from '@/apis/server'

export default {
  name: 'AppMain',
  components: { AmplifyModal },
  data() {
    return {
      loadingPower: true
    }
  },
  created() {
    this.getPower()
  },
  methods: {
    async getPower() {
      this.loadingPower = true
      try {
        const { response } = await judgePower({ modularName: 'LZ_energy' })
        this.$store.commit('power/setPower', response)
        this.loadingPower = false
      } catch (error) {
        this.$message.error('请检查该账号的权限')
        this.loadingPower = false
      }
    }
  }
}
</script>

<template>
  <section v-if="!loadingPower" v-loading="loadingPower" class="app-main">
    <transition name="fade-transform" mode="out-in">
      <!-- <keep-alive> -->
      <router-view />
      <!-- </keep-alive> -->
    </transition>
    <amplify-modal v-if="['syntheticalAnalysis', 'syntheticAnalysis'].includes($route.name)" />
  </section>
</template>

<style lang="scss" scoped>
.app-main {
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

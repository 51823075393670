<script>
import Common from '../common'
import { getPartenrChart } from '@/apis/Breed/CustomsData.js'
export default {
  name: 'PartnerPie',
  components: {},
  mixins: [Common],
  props: {
    urlType: {
      type: String,
      default: ''
    },
    unit: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      chartLoading: false,
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.PartnerPie)
    // this.setOption()
    // this.getData()
  },
  methods: {
    getData() {
      this.chartLoading = true
      getPartenrChart(this.queryData)
        .then(res => {
          this.chartLoading = false
          this.myChart.clear()
          const { response } = res
          if (!response || response.data.length === 0) {
            this.errorHandler()
            return
          }

          if (this.setOption(response.data) === false) {
            this.errorHandler()
            return false
          }
          this.myChart.hideLoading()
        })
        .catch(() => {
          this.errorHandler()
        })
    },
    // dataHandler() {},
    setOption(chartData = []) {
      const data = chartData.splice(0, 10).map(m => {
        return {
          value: m.sum,
          name: m.key
        }
      })
      let sumLast = 0
      for (const item of chartData) {
        sumLast += Number(item.sum)
      }
      data.push({ value: sumLast, name: '其他' })
      this.option = {
        title: {
          show: false
        },
        tooltip: {
          trigger: 'item',
          formatter: params => {
            const { data, marker } = params
            return `${marker} ${data.name} ${data.value} ${this.unit}`
          }
        },
        legend: {
          width: 550,
          type: 'scroll',
          // y: 'bottom',
          x: 'center',
          left: '32%',
          bottom: 20
        },
        series: [
          {
            type: 'pie',
            data,
            label: {
              formatter: '{d}%'
            },
            radius: ['0%', '50%'],
            center: ['50%', '38%']
          }
        ]
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="PartnerPie" v-loading="chartLoading" class="chart-container" />
</template>

<style lang="scss" scoped></style>

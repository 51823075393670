import createApi from '@/apis/http'

const dcBaseURL = 'https://dc.oilchem.net'
const meetingBaseURL = 'https://meeting.oilchem.net'
const researchBaseURL = 'https://research.oilchem.net'
const listBaseURL = 'https://list.oilchem.net'

// 查询风口产品
export const queryHotProduct = createApi({ url: '/cmsindex/queryHotProduct', baseURL: dcBaseURL })

// 会议会展-最新报告
export const queryTopMeetings = createApi({
  url: '/cmsmeeting/queryTopMeetings',
  baseURL: meetingBaseURL
})
// 会议会展-会议报道
export const queryPopularization = createApi({
  url: '/cmsmeeting/queryPopularization',
  baseURL: meetingBaseURL
})

// 数据日历
export const getDatacalendar = createApi({
  url: '/research-remote-web/datacalendar/getDatacalendarTaskByDate',
  method: 'post',
  baseURL: researchBaseURL
})
// 数据日历
export const getDatacalendarTaskByMonth = createApi({
  url: '/research-remote-web/datacalendardetail/getDatacalendarTaskByMonth',
  method: 'post',
  baseURL: researchBaseURL
})
// 数据日历
export const getDatacalendarTaskDetailByDate = createApi({
  url: '/research-remote-web/datacalendardetail/getDatacalendarTaskDetailByDate',
  method: 'post',
  baseURL: researchBaseURL
})

// 热点资讯，报告
export const articles = createApi({ url: '/column-list/articles', baseURL: listBaseURL })
export const articlesNew = query => createApi({ url: '/api/article/hotNewsList' })(query)

// 隆重直播
// export const queryArticles = createApi({ url: '/meeting/queryArticles', baseURL: meetingBaseURL })
export const queryLiveBroadcast = createApi({
  url: '/column-list/query-live-broadcast-preview-list',
  baseURL: listBaseURL
})

// 文章详情
export const newsInfo = id => createApi({ url: `/article/${id}`, baseURL: listBaseURL })
export const newsInfoNew = id => createApi({ url: `/api/article/hotNewsDetail` })(id)

// 隆重直播
export const queryRankingList = createApi({
  url: '/cmsindex/queryRankingList',
  method: 'post',
  baseURL: dcBaseURL
})

// 隆重直播
export const queryRankingHistogram = createApi({
  url: '/cmsindex/queryRankingHistogram',
  method: 'post',
  baseURL: dcBaseURL
})

// 行业筛选项
export const getIndustryList = createApi({
  url: '/api/home/industryList',
  method: 'get'
})
// 行业筛选项
export const getCategoryNameList = createApi({
  url: '/api/home/categoryNameList',
  method: 'get'
})
// 价格指数河道图
export const getIndexRiver = createApi({
  url: '/api/home/indexRiverChart',
  method: 'get'
})

// 炼厂毛利
export const getGrosProfit = createApi({
  url: '/api/home/refineryRiverChart',
  method: 'post'
})

// 首页-根据栏目查文章列表
export const getColumnArticles = createApi({
  url: '/api/article/columnArticles'
})

// 根据栏目查文章详情
export const getColumnArticlesDetails = createApi({
  url: '/api/article/columnArticlesDetail'
})

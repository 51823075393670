/*
 * @Description: 资讯中心apis
 * @Date: 2021-09-27 08:47:00
 * @LastEditTime: 2021-11-15 09:45:13
 */
import { getData, getDataByGet } from '@/apis/server'
const url = '/api/article/query/'
// 隆众视点
export const pointView = params => getData(`${url}102/121`, params)
// 企业动态
export const enterpriseDynamic = params => getData(`${url}103/121`, params)
// 市场快讯
export const marketAlerts = params => getData(`${url}104/121`, params)
// 港口信息
export const portInformation = params => getData(`${url}105/121`, params)
// 调研统计
export const research = params => getData(`${url}106/121`, params)
// 装置动态
export const deviceDynamic = params => getData(`${url}107/121`, params)
// 相关商品
export const relatedGoods = params => getData(`${url}108/121`, params)
// 综合信息
export const comprehensive = params => getData(`${url}109/121`, params)
// 周报列表
export const weekReport = params => getData(`${url}112/256`, params)
// 月报列表
export const monthReport = params => getData(`${url}113/256`, params)
// 年报列表
export const yearReport = params => getData(`${url}114/256`, params)
// 产业链报告列表
// export const customReport = params => getData(`${url}115/298`, params)
// 资讯中心文章详情
export const newsInfo = params => getDataByGet('/api/article/detail', params)
// 报告中心文章详情
export const reportInfo = params => getDataByGet('/api/article/report/detail', params)

<!--
 * @Author: bjp
 * @Description: CR5 CR10折线图
-->
<script>
import Common from '../common.js'

export default {
  name: 'IndustryContinuous',
  mixins: [Common],
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    state: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      option: {}
    }
  },
  watch: {
    chartData: {
      handler() {
        this.myChart && this.setOption()
      },
      deep: true
    }
  },
  mounted() {
    this.initEchart(this.$refs.IndustryContinuous)
    this.chartLoading = true
    this.setOption()
  },
  methods: {
    setOption() {
      this.chartLoading = false
      this.myChart.clear()
      if (!this.chartData || this.chartData.length === 0) {
        this.errorHandler()
        return
      }
      this.myChart.hideLoading()
      const type = this.state === 2 ? 'cr5' : 'cr10'
      const chartDataCopy = JSON.parse(JSON.stringify(this.chartData))
      chartDataCopy.sort((a, b) => {
        return a.dataDate.replace(/[^0-9]/gi, '') - b.dataDate.replace(/[^0-9]/gi, '')
      })
      const data = chartDataCopy.map(item => {
        return [item.dataDate, item[type]]
      })
      this.option = {
        title: {
          text: type.toLocaleUpperCase(),
          x: 'center',
          top: 0,
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        calculable: true,
        dataset: {
          source: data
        },
        xAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          type: 'category'
        },
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              show: true,
              interval: 0,
              formatter: '{value}%'
            },
            scale: true,
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          }
        ],
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        dataZoom: {
          bottom: '5%'
        },
        grid: {
          top: '15%',
          bottom: '23%',
          left: '2%',
          right: '2%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          bottom: '13%'
        },
        series: [
          {
            name: type.toLocaleUpperCase(),
            type: 'line'
            // color: '#3A466E',
          }
        ]
      }

      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="IndustryContinuous" v-loading="chartLoading" class="breedSupplyCharts" />
</template>

<!--
 * @Author: bjp
 * @Description:圆滑折线图
-->
<script>
import Common from '../common'

export default {
  name: 'LineSmooth',
  mixins: [Common],
  props: {
    temp: {
      type: Object,
      default: () => ({})
    },
    queryParams: {
      type: Object,
      default: () => ({})
    },
    breedName: {
      type: String,
      default: ''
    },
    unitArr: {
      type: Array,
      default: () => []
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      option: {}
    }
  },
  watch: {
    queryParams: {
      deep: true,
      handler() {
        this.dataHandler()
      }
    }
  },
  mounted() {
    this.initEchart(this.$refs.LineSmooth)
    this.dataHandler()
  },
  methods: {
    dataHandler() {
      this.myChart.clear()
      this.myChart.hideLoading()
      const seriesList = []
      let yAxis = []
      const unitObj = {}
      const unitArr = []
      if (!this.queryParams.data || !this.queryParams.data.length) {
        this.errorHandler()
        return
      }
      // 获取单位
      this.unitArr.forEach(v => {
        const breed = v.split('(')[0]
        const unit = v.split('(')[1]?.split(')')[0] || ''
        unitObj[breed] = unit
        !unitArr.includes(unit) && unitArr.length < 2 && unitArr.push(unit)
      })
      // 深克隆图表数据
      const data = JSON.parse(JSON.stringify(this.queryParams.data))

      // 没传单位，默认为毛利%
      if (!this.unitArr.length) {
        yAxis = [
          {
            name: '%',
            type: 'value',
            scale: true,
            splitNumber: 5,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            }
          }
        ]

        Object.keys(data[0])
          .filter(v => v !== '单位' && v !== '日期')
          .forEach(v => {
            seriesList.push({
              name: v,
              type: 'line',
              yAxisIndex: 0,
              connectNulls: true,
              smooth: true,
              data: data
                .sort((a, b) => this.$dayjs(a.日期) - this.$dayjs(b.日期))
                .map(e => [e.日期, e[v], '%'])
            })
          })
      } else {
        // 一个单位一条Y轴
        yAxis = unitArr.map((item, i) => {
          return {
            name: item,
            type: 'value',
            scale: true,
            splitNumber: 5,
            splitLine: {
              show: i === 0,
              lineStyle: {
                type: 'dashed'
              }
            }
          }
        })

        for (const [key, value] of Object.entries(unitObj)) {
          let index = unitArr.findIndex(v => v === value)
          index = index > -1 ? index : 0
          seriesList.push({
            name: key,
            type: 'line',
            yAxisIndex: index,
            connectNulls: true,
            smooth: true,
            data: data
              .sort((a, b) => this.$dayjs(a.日期) - this.$dayjs(b.日期))
              .map(e => [e.日期, e[key], value])
          })
        }
      }
      this.setOption(yAxis, seriesList)
    },
    setOption(yAxis = [], seriesList = []) {
      this.chartLoading = false
      this.option = {
        title: {
          text: (this.breedName || this.name) + this.temp.name || '',
          x: 'center',
          top: '3%',
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14
          }
        },
        xAxis: {
          type: 'category'
        },
        yAxis,
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: '4%',
            left: '3%',
            right: '2%'
          }
        ],
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}${
                e.value[1] ? e.value[2] || '' : ''
              }<br>`
            })
            return html
          }
        },
        grid: {
          top: this.magnifier ? '10%' : '20%',
          bottom: '23%',
          left: '1%',
          right: '2%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          bottom: '12%',
          textStyle: {
            color: '#121212',
            fontSize: 14
          },
          itemGap: 20 // 设置间距
        },
        series: seriesList
      }
      this.myChart.setOption(this.option)

      this.myChart.on('legendselectchanged', obj => {
        if (yAxis.length === 1) return
        // 获取被取消的legend
        const cancelLegend = Object.keys(obj.selected).filter(v => !obj.selected[v])

        const option = this.myChart.getOption()
        let isLeftShow = true
        if (cancelLegend.length !== 0) {
          isLeftShow = option.series.every(v => v.yAxisIndex === 0 && cancelLegend.includes(v.name))
        }

        option.yAxis[1].splitLine.show = !isLeftShow

        this.myChart.setOption(this.option)
      })
    }
  }
}
</script>

<template>
  <div ref="LineSmooth" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Description: 企业-价格对比
 * @Date: 2022-04-20 13:57:26
 * @LastEditTime: 2022-05-10 14:22:21
-->
<script>
import Common from './common.js'
import { getData } from '@/apis/server.js'
import { storage } from '@/utils/common'
export default {
  mixins: [Common],
  data() {
    return {}
  },
  watch: {
    activeTag() {
      this.getData()
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#EnterpriseComparePrice'))
  },
  methods: {
    getData() {
      // 存取数据频率，首页导出报告使用
      storage.setSession('enterprisePriceFrequency', this.queryData.frequencyName)
      const params = {
        queryField: {
          companyId: this.companyId,
          breedId: this.breedId,
          frequency: this.queryData.frequencyName
        }
      }
      const { dataApi } = this.widget
      getData(dataApi, params)
        .then(({ response }) => {
          this.chartLoading = false
          this.myChart.clear()
          this.myChart.hideLoading()
          if (response?.data?.length) {
            this.dataHandler(response.data)
          } else {
            this.errorHandler()
          }
        })
        .catch(() => {
          this.errorHandler()
        })
    },
    dataHandler(data) {
      const seriesList = []
      data.forEach((item, index) => {
        if (index === 0) {
          // 第一次先push
          seriesList.push(
            {
              name: item.securityName,
              type: 'line',
              smooth: true,
              data: [[item.exhDate, item.comPrice, item.comPriceUint]]
            },
            {
              name: item.breedName,
              type: 'line',
              smooth: true,
              data: [[item.exhDate, item.mainPrice, item.dataUnit]]
            }
          )
        } else {
          // 后面往data里赋值
          seriesList[0].data.push([item.exhDate, item.comPrice, item.comPriceUint])
          seriesList[1].data.push([item.exhDate, item.mainPrice, item.dataUnit])
        }
      })
      this.setOption(seriesList)
    },
    setOption(series) {
      const option = {
        grid: {
          top: '5%',
          bottom: '20%'
        },
        legend: {
          show: true,
          bottom: '11%'
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          splitNumber: 5,
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}${
                e.value[1] ? e.value[2] || '' : ''
              }<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'inside',
            startValue: 0,
            endValue: 20
          },
          {
            type: 'slider',
            startValue: 0,
            endValue: 20,
            bottom: '3%'
          }
        ],
        series
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div
    id="EnterpriseComparePrice"
    ref="EnterpriseComparePrice"
    v-loading="chartLoading"
    class="chart-container"
  />
</template>

<style lang="scss" scoped></style>

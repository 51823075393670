<script>
import Common from './common'

export default {
  name: 'ContinuousGraph',
  mixins: [Common], // 连续图
  data() {
    return {
      unitY: '',
      priceType: '主流价',
      option: {}
    }
  },
  watch: {
    chartTitle(val, oldVal) {
      if (val === oldVal) return
      const options = this.myChart && this.myChart.getOption()
      options && this.myChart.setOption({ title: [{ ...options.title[0], text: val }] })
    }
  },
  mounted() {
    this.initEchart(this.$refs.ContinuousGraph)
  },
  methods: {
    dataHandler(response) {
      const formatTime = i => {
        // if (this.queryData.frequencyName === '周度') {
        return this.$dayjs(response.data['日期'][i].value).format('YYYY-MM-DD')
        // }
        // return response.data['展示日期'][i].value
      }
      const data = []
      const maData = []
      this.myChart.clear()
      if (!response) {
        this.errorHandler()
        return
      }
      this.myChart.hideLoading()
      // 图表数据
      this.unitY = response.data['单位'][0].value
      response.data['主流价格'].forEach((ele, i) => {
        data.push([
          formatTime(i),
          ele.value,
          [response.data['核心因素'][i].value || '', response.data['信息地雷'][i].value || '']
        ])
      })
      // 取ma值
      this.queryData.avg_line.forEach((val, index) => {
        maData.push({
          name: val,
          type: 'line',
          connectNulls: true,
          data: []
        })
        response.data[val] &&
          response.data[val].forEach((item, j) => {
            maData[index].data.push([formatTime(j), item.value || ''])
          })
      })
      this.chartLoading = false
      this.setOption(data, maData)
    },
    setOption(data = [], maData = []) {
      const getTooltip = params => {
        let html = `<div class="chart-panel">${params[0].value[params[0].encode.x[0]]}<br>`
        for (let i = 0; i < params.length; i++) {
          html += `<div class="chart-item">${params[i].marker}${
            params[i].seriesName
          }：<b class="chart-content style="float:right;margin-left:10px">${
            params[i].value[params[0].encode.y[0]]
              ? `${params[i].value[params[0].encode.y[0]] + this.unitY}</b><br>`
              : '-' + '</b><br>'
          }</div>`
        }
        if (params && params[0].value.length > 2 && params[0].value[2][0]) {
          html += `<div class="chart-content">核心因素：<b class="chart-tips">${params[0].value[2][0]}`
        }
        if (params && params[0].value.length > 2 && params[0].value[2][1]) {
          html += `</b></div><div class="chart-content text-overflow_3-xzh">信息地雷：<b class="chart-tips">${params[0].value[2][1]}`
        }
        html += '</b></div></div>'
        return html
      }

      this.option = {
        title: {
          text: this.chartTitle,
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14
          },
          left: 'center'
        },
        legend: {
          left: 'center',
          bottom: '13%'
        },
        grid: {
          left: '1%',
          top: this.amplify ? '10%' : '18%',
          bottom: '25%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#F2F2F2',
          formatter: getTooltip,
          confine: true
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: '3%'
            // left:'5.5%',
            // right:'5.5%',
          }
        ],
        xAxis: {
          type: 'category'
        },
        yAxis: {
          type: 'value',
          scale: true,
          name: this.unitY,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        dataset: {
          source: data
        },
        series: [
          {
            name: this.name,
            type: 'line',
            connectNulls: true,
            encode: {
              x: 0,
              y: 1
            }
          },
          ...maData
        ]
      }
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="ContinuousGraph" v-loading="chartLoading" class="chart-container" />
</template>

<style lang="scss" scoped>
:deep(.chart-panel) {
  width: 200px;
  font-size: 14px;

  .chart-item {
    width: 100%;
  }

  .chart-content {
    width: 100%;
    white-space: normal;

    .chart-tips {
      color: #cc3338;
    }
  }
}
</style>

/*
 * @Description: 成本/利润apis
 * @Date: 2021-06-07 14:52:19
 * @LastEditTime: 2022-06-22 15:39:32
 * @FilePath: /oilchem-data-terminal-web/src/apis/Breed/CustomsData/index.js
 */
import { getData, getDataByGet } from '@/apis/server'

const url = '/api/widget/queryData/'
// 生产成本走势-表格表头
export const getTrendHeader = params => getData(`${url}productionCostTrendHeader`, params)
// 生产成本走势-表格数据
export const getTrendList = params => getData(`${url}productionCostTrend`, params)
// // 生产成本走势-折线图
// export const getTrendChart = (params) => getData(`${url}41`, params)
// // 生产成本走势-折线图均线
// export const getTrendAverage = (params) => getData(`${url}63`, params)

// 生产成本走势-折线图
export const getTrendChart = params => getData(`${url}productionCostTrendChart`, params)

// 生产成本构成-柱状图
export const getStructChart = params => getData(`${url}60`, params)
// 生产成本构成-表格表头
export const getStructHeader = params => getData(`${url}costStructureHeader`, params)
// 生产成本构成-表格数据
export const getStructHeaderList = params => getData(`${url}costStructure`, params)
// 毛利/毛利率-折线图
// export const getProfitChart = params => getData(`${url}43`, params)
// 毛利-表格表头
export const getProfitHeader = params => getData(`${url}grossProfitHeader`, params)
// 毛利-表格数据
export const getProfitList = params => getData(`${url}grossProfit`, params)
// 毛利率-表格表头
export const getProfitRateHeader = params => getData(`${url}grossProfitRateHeader`, params)
// 毛利率-表格数据
export const getProfitRateList = params => getData(`${url}grossProfitRate`, params)

// 品种下工艺
const url2 = '/api/common/getProcessByBreedId'
export const getProcess = params => getDataByGet(`${url2}`, params)

// 生产成本走势-折线图无工艺
export const getTrendChartNoProcess = params =>
  getData(`${url}productionCostTrendChartNoProcess`, params)
// 生产成本走势-表格表头无工艺
export const getTrendTableHeaderNoProcess = params =>
  getData(`${url}productionCostTrendHeaderNoProcess`, params)
// 生产成本走势-表格数据无工艺
export const getTrendTableNoProcess = params =>
  getData(`${url}productionCostTrendNoProcess`, params)

// 毛利-表格表头无工艺
export const getProfitTableHeaderNoProcess = params =>
  getData(`${url}grossProfitHeaderNoProcess`, params)
// 毛利-表格数据无工艺
export const getProfitTableNoProcess = params => getData(`${url}grossProfitNoProcess`, params)

// 生产成本构成 - 构成公式
export const getFormulaOfCostProfit = params =>
  getDataByGet('/api/widget/queryData/getFormulaOfCostProfit', params)

// 主营单位综合炼油利润
// export const getMainRefiningProfit = params =>
//   getData('/api/oilRefining/inner/normal/67881/asc', params)

// 主营单位综合炼油利润获取数据频率
// export const getMainRefiningProfitFrequency = params =>
//   getData('/api/oilRefining/inner/frequency/67881', params)
//
// // 独立炼厂综合炼油利润
// export const getIntegratedRefiningProfit = params =>
//   getData('/api/oilRefining/inner/normal/67879/asc', params)
//
// // 独立炼厂综合炼油利润获取数据频率
// export const getIntegratedRefiningProfitFrequency = params =>
//   getData('/api/oilRefining/inner/frequency/67879', params)

export const queryFrequencyUpDownBreed = params =>
  getDataByGet('/api/breed/profit/queryFrequencyUpDownBreed', params)
export const industryChainProfitTableHeader = params =>
  getDataByGet('/api/breed/profit/industryChainProfitTableHeader', params)
export const industryChainProfitTableData = params => getData(`${url}445`, params)

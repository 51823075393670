<script>
import { getData } from '@/apis/server'

export default {
  name: 'CombineDeviceSummary',
  props: {
    widget: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      tableLoading: false,
      tableData: []
    }
  },
  mounted() {
    this.getDate()
  },
  methods: {
    async getDate() {
      this.tableLoading = true
      const { dataApi } = this.widget
      if (!dataApi) return
      const query = {
        queryField: {
          breedId: this.$route.query.id,
          ...this.queryData
        }
      }
      const { response } = await getData(dataApi, query)
      this.tableData = response
      this.tableLoading = false
    }
  }
}
</script>

<template>
  <el-table v-loading="tableLoading" :data="tableData" stripe>
    <el-table-column type="index" align="center" width="80" />
    <el-table-column prop="indexName" label="装置名称" align="center" />
    <el-table-column
      prop="inputValue"
      label="利润值"
      align="center"
      width="120"
      sortable
      :sort-method="(a, b) => Number(a.inputValue) - Number(b.inputValue)"
    />
    <el-table-column label="日期" align="center" width="120">
      <template #default="{ row }">
        {{ $dayjs(row.researchStopDate).format('YYYY-MM-DD') }}
      </template>
    </el-table-column>
  </el-table>
</template>

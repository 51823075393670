<script>
import Common from './common'
import { formatDayJsTime } from '@/utils/formatData'
export default {
  name: 'DownstreamConsumptionStructureLineBar', // 下游消费结构Bar
  mixins: [Common],
  mounted() {
    this.initEchart(this.$refs.DownstreamConsumptionStructureLineBar)
  },
  methods: {
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      if (!response) {
        this.errorHandler()
        return
      }
      this.setOption(response.data)
    },
    setOption(chartData = []) {
      const dimensions = this.$filterValue(chartData['日期'], this.queryData.frequencyName).sort(
        (a, b) => {
          return a.replace(/[^0-9]/gi, '') - b.replace(/[^0-9]/gi, '')
        }
      )
      const data1 = []
      const data2 = []
      const formatterTime = formatDayJsTime(this.queryData.frequencyName)
      dimensions.forEach(item => {
        chartData['日期'].forEach((time, index) => {
          if (item === this.$dayjs(time.value).format(formatterTime)) {
            data1.push(chartData['表观消费量'][index].value)
            data2.push(chartData['均价'][index].value)
          }
        })
      })
      // 默认展示范围
      const startValue = dimensions[dimensions.length - 30] || dimensions[0]
      const option = {
        title: {
          text: `${this.name}消费量与价格变化`,
          textStyle: {
            fontSize: 14
          },
          top: 6,
          left: 'center'
        },
        dataZoom: [
          {
            type: 'inside',
            startValue
          },
          {
            type: 'slider',
            bottom: 5,
            left: 30,
            right: 20
          }
        ],
        grid: {
          left: 24,
          bottom: 50,
          containLabel: true
        },
        legend: {
          data: ['表观消费量', '均价'],
          bottom: 25
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].name}<br/>`
            let unit = ''
            for (let i = 0; i < params.length; i++) {
              if (params[i].seriesName === '均价') unit = chartData['价格单位'][0].value
              if (params[i].seriesName === '表观消费量') unit = chartData['表观消费量单位'][0].value
              html += `${
                params[i].marker + params[i].seriesName
              }：<b class="chart-content style="float:right;margin-left:10px">${
                params[i].value || params[i].value === 0
                  ? `${params[i].value} ${unit || ''}</b><br>`
                  : '-' + '</b><br>'
              }`
            }
            return html
          }
        },
        xAxis: {
          type: 'category',
          data: dimensions,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: [
          {
            type: 'value',
            name: this.$filterValue(chartData['表观消费量单位'])[0],
            scale: true,
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            name: chartData['价格单位'][0] ? chartData['价格单位'][0].value : '',
            type: 'value',
            scale: true,
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '均价',
            type: 'line',
            yAxisIndex: 1,
            data: data2
          },
          {
            name: '表观消费量',
            type: 'bar',
            data: data1
          }
        ]
        // color: ['#6899DD', '#232E54'],
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div
    ref="DownstreamConsumptionStructureLineBar"
    v-loading="chartLoading"
    class="chart-container"
  />
</template>

/**
 * 判断有几年权限
 */
const state = {
  powerYear: 5
}

const mutations = {
  setPower(state, data) {
    state.powerYear = data.dictValue
  }
}

export default {
  namespaced: true,
  state,
  mutations
}

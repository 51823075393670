<script>
import Common from '../common'
export default {
  mixins: [Common],
  props: {
    upAndDownList: {
      type: Object,
      default: () => ({})
    },
    activeIndex: {
      type: [Number, String],
      default: 1
    }
  },
  data() {
    return {
      chartLoading: false,
      seriesData: [],
      barX: [],
      upNum: 0,
      downNum: 0,
      average: 0
    }
  },
  watch: {
    upAndDownList: {
      deep: true,
      immediate: true,
      handler() {
        this.changeUpAndDown()
      }
    },
    activeIndex() {
      this.chartLoading = true
      this.changeUpAndDown()
    }
  },
  mounted() {
    this.initEchart(document.getElementById('PriceSeasonalIncrease'))
  },
  methods: {
    /**
     * 数值变化
     */
    changeUpAndDown() {
      this.seriesData = []
      this.upNum = 0
      this.downNum = 0
      this.average = 0
      const index = this.activeIndex.toString().replace(/^(\d)$/, '0$1')
      if (this.upAndDownList && Object.keys(this.upAndDownList).length > 0) {
        for (const key in this.upAndDownList[index]) {
          if (this.upAndDownList[index][key] !== null) {
            if (this.upAndDownList[index][key] >= 0) this.upNum++
            if (this.upAndDownList[index][key] < 0) this.downNum++
            this.average += this.upAndDownList[index][key]
          }
          this.seriesData.push({
            value: this.upAndDownList[index][key],
            itemStyle: {
              color: this.upAndDownList[index][key] < 0 ? '#1A9016' : '#BA0C2F'
            }
          })
        }
        this.barX = Object.keys(this.upAndDownList[index])
        this.$nextTick(() => {
          this.setOption()
          this.chartLoading = false
        })
        this.myChart && this.myChart.hideLoading()
      } else {
        this.errorHandler()
      }
    },
    setOption() {
      const option = {
        title: {
          text: `统计次数：${this.upNum + this.downNum}\n上涨次数：${this.upNum}\n下跌次数：${
            this.downNum
          }\n平均涨跌：${(this.average / (this.upNum + this.downNum)).toFixed(2)}%`,
          textStyle: {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: 22
          },
          right: '1%',
          top: 'center'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: '15%',
          bottom: '15%',
          right: '20%',
          confineLabel: true
        },
        xAxis: {
          type: 'category',
          axisLine: { show: true },
          axisTick: { show: false },
          splitLine: { show: false },
          data: this.barX
        },
        yAxis: {
          type: 'value',
          splitNumber: 4,
          axisLabel: {
            formatter: value => {
              return `${value}%`
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '涨幅',
            type: 'bar',
            barWidth: 20,
            data: this.seriesData
          }
        ]
      }
      this.myChart.setOption(option)
    },
    errorHandler() {
      if (!this.myChart) return
      this.myChart.hideLoading()
      this.myChart.clear()
      this.myChart.showLoading({
        text: '暂无数据',
        fontSize: '30',
        color: '#ffffff',
        textColor: '#8a8e91',
        maskColor: 'rgba(255, 255, 255, 0.8)'
      })
    }
  }
}
</script>

<template>
  <div id="PriceSeasonalIncrease" v-loading="chartLoading" class="chart-container" />
</template>

<style lang="scss" scoped></style>

/*
 * @Author: cy
 * @Description: 船用油
 * @Date: 2022-08-30 08:40:54
 * @LastEditTime: 2022-09-08 16:48:06
 * meta参数：
 *  @title : 名称
 *  @isSpecial: 特殊品种新增页面
 *  @code 特殊品种页面code
 *  @interactId 交互器id
 *  @interactSelectType 交互器类型 1 单选 0 多选
 * props参数：
 *  @menuTitle: 当前显示路由名称
 */
export default {
  // 供应
  supply: [
    {
      path: 'MarineOilLowFuelOilYield',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'MarineOilLowFuelOilYield',
      meta: {
        title: '低硫燃料油产量',
        isSpecial: true,
        code: '67865,141404,67864'
      }
    },
    {
      path: 'MarineOilTradeVolume',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'MarineOilTradeVolume',
      meta: {
        title: '船用油贸易量',
        isSpecial: true,
        code: '124118'
      }
    }
  ],
  // 利润
  costAndProfit: [
    {
      path: 'MarineOilReconcileProfit',
      component: () => import('@/views/Breed/OilSpecialLine'),
      name: 'MarineOilReconcileProfit',
      meta: {
        title: '中国船用180CST调和利润',
        isSpecial: true,
        code: '4445'
      }
    }
  ],
  // 需求
  demand: [
    {
      path: 'MarineOilBondedSupply',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'MarineOilBondedSupply',
      meta: {
        title: '中国保税船用油港口供应量',
        titleSpecial: true,
        isSpecial: true,
        interactId: '204',
        interactSelectType: '1'
      }
    },
    {
      path: 'MarineOilBondedConsumption',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'MarineOilBondedConsumption',
      meta: {
        title: '中国内贸船用油港口消费量',
        titleSpecial: true,
        isSpecial: true,
        interactId: '206',
        interactSelectType: '1'
      }
    }
  ]
}

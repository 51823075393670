/*
 * @Author: cy
 * @Description:燃料油路由
 * @Date: 2022-08-30 08:40:54
 * @LastEditTime: 2022-09-08 16:47:59
 * meta参数：
 *  @title : 名称
 *  @isSpecial: 特殊品种新增页面
 *  @code 特殊品种页面code
 *  @interactId 交互器id
 *  @interactSelectType 交互器类型 1 单选 0 多选
 * props参数：
 *  @menuTitle: 当前显示路由名称
 */
export default {
  // 供应
  supply: [
    {
      path: 'FuelOilProduction',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'FuelOilProduction',
      meta: {
        title: '燃料油产量',
        isSpecial: true,
        code: '6330,81284,51289'
      }
    },
    {
      path: 'FueloilOutputOfLowSulfur',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'FueloilOutputOfLowSulfur',
      meta: {
        title: '低硫燃料油产量',
        isSpecial: true,
        code: '67865,141404,67864'
      }
    },
    {
      path: 'FueloilSlurryProduction',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'FueloilSlurryProduction',
      meta: {
        title: '油浆产量',
        isSpecial: true,
        code: '1359'
      }
    },
    {
      path: 'FueloilResidualOilProduction',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'FueloilResidualOilProduction',
      meta: {
        title: '渣油产量',
        isSpecial: true,
        code: '1356'
      }
    },
    {
      path: 'WaxOilProduction',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'WaxOilProduction',
      meta: {
        title: '蜡油产量',
        isSpecial: true,
        code: '1362'
      }
    },
    {
      path: 'FuelOilSlurryCommodityVolume',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'FuelOilSlurryCommodityVolume',
      meta: {
        title: '油浆商品量',
        isSpecial: true,
        code: '1360'
      }
    },
    {
      path: 'FuelOilCommodityVolumeOfResidualoil',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'FuelOilCommodityVolumeOfResidualoil',
      meta: {
        title: '渣油商品量',
        isSpecial: true,
        code: '1357'
      }
    },
    {
      path: 'FuelOilCommodityVolumeOfWaxoil',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'FuelOilCommodityVolumeOfWaxoil',
      meta: {
        title: '蜡油商品量',
        isSpecial: true,
        code: '1363'
      }
    },
    {
      path: 'FueloilCommodityVolumeOf',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'FueloilCommodityVolumeOf',
      meta: {
        title: '燃料油商品量',
        isSpecial: true,
        code: '73812'
      }
    },
    {
      path: 'FuelOilTradeVolumeOfMarineoil',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'FuelOilTradeVolumeOfMarineoil',
      meta: {
        title: '船用油贸易量',
        isSpecial: true,
        code: '124118'
      }
    }
  ],
  // 利润
  costAndProfit: [
    {
      path: 'FuelOilCHNMarine180CSTReconciliationProfit',
      component: () => import('@/views/Breed/OilSpecialLine'),
      name: 'FuelOilCHNMarine180CSTReconciliationProfit',
      meta: {
        title: '中国船用180CST调和利润',
        isSpecial: true,
        code: '4445'
      }
    },
    {
      path: 'FuelOilProfitOfDomesticFueloilOfCatalyticUnit',
      component: () => import('@/views/Breed/OilSpecialLine'),
      name: 'FuelOilProfitOfDomesticFueloilOfCatalyticUnit',
      meta: {
        title: '催化装置国产燃料油利润',
        isSpecial: true,
        code: '67467,81303,81304,81305'
      }
    },
    {
      path: 'FuelOilProfitOfDomesticFueloilofCokingUnit',
      component: () => import('@/views/Breed/OilSpecialLine'),
      name: 'FuelOilProfitOfDomesticFueloilofCokingUnit',
      meta: {
        title: '焦化装置国产燃料油利润',
        isSpecial: true,
        code: '67381,81309,81310,81311'
      }
    }
  ],
  // 需求
  demand: [
    {
      path: 'FuelOilCHNPortSupplyOfBondedShipOilinChina',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'FuelOilCHNPortSupplyOfBondedShipOilinChina',
      meta: {
        title: '中国保税船用油港口供应量',
        titleSpecial: true,
        isSpecial: true,
        interactId: '196',
        interactSelectType: '1'
      }
    },
    {
      path: 'FuelOilCHNPortConsumptionOfBondedShipOilinChina',
      component: () => import('@/views/Breed/OilSpecialBarLineMix'),
      name: 'FuelOilCHNPortConsumptionOfBondedShipOilinChina',
      meta: {
        title: '中国内贸船用油港口消费量',
        titleSpecial: true,
        isSpecial: true,
        interactId: '197',
        interactSelectType: '1'
      }
    }
  ],
  // 库存
  stock: [
    {
      path: 'FuelOilSlurryStock',
      component: () => import('@/views/Breed/OilSpecialLine'),
      name: 'FuelOilSlurryStock',
      meta: {
        title: '油浆库存',
        isSpecial: true,
        code: '1361'
      }
    },
    {
      path: 'FuelOilResidualoilInventory',
      component: () => import('@/views/Breed/OilSpecialLine'),
      name: 'FuelOilResidualoilInventory',
      meta: {
        title: '渣油库存',
        isSpecial: true,
        code: '1358'
      }
    },
    {
      path: 'FuelOilWaxOilInventory',
      component: () => import('@/views/Breed/OilSpecialLine'),
      name: 'FuelOilWaxOilInventory',
      meta: {
        title: '蜡油库存',
        isSpecial: true,
        code: '1364'
      }
    }
  ]
}

<script>
export default {
  name: 'CrudeOilBdti', // 波罗的海原油运价指数（BDTI）
  data() {
    return {
      a: 1
    }
  }
}
</script>

<template>
  <BreedCrudeOilLine v-bind="$attrs" />
</template>

<script>
import Common from './common'
export default {
  name: 'DownstreamConsumptionStructurePie', // 下游消费结构Pie
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.DownstreamConsumptionStructurePie)
    this.getData()
  },
  methods: {
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      if (!response) {
        this.errorHandler()
        return
      }
      const chartData = response.data.map(({ comsumProp: value, dowBreedName: name }) => ({
        name,
        value
      }))
      this.setOption(chartData)
    },
    setOption(chartData = []) {
      this.option = {
        title: {
          option: {}
        },
        legend: {
          type: 'scroll',
          y: 'bottom',
          x: 'center'
        },
        tooltip: {
          confine: true,
          trigger: 'item'
        },
        grid: {
          left: 10,
          bottom: 10
        },
        series: [
          {
            name: '下游消费结构',
            type: 'pie',
            radius: ['45%', '55%'],
            center: ['50%', '45%'],
            data: chartData,
            label: {
              formatter: '{d}%'
            }
            // silent: true
          }
        ]
      }
      this.downLoadChartTitle('analysis')
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="DownstreamConsumptionStructurePie" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'

export default {
  name: 'PriceCorrelationAnalysis', // 价格相关性分析
  mixins: [Common],
  data() {
    return {
      relatePrdX: [],
      relatePrdY: []
    }
  },
  mounted() {
    this.initEchart(this.$refs.PriceCorrelationAnalysis)
    // 监听窗口大小
    window.addEventListener('resize', () => this.setOption())
    // 组件销毁执行
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', () => {
        this.setOption()
      })
    })
  },
  methods: {
    /**
     * 处理数据
     */
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      if (!this.$DataIsNotEmpty(response)) {
        this.errorHandler()
        return false
      }
      this.relatePrdX = []
      this.relatePrdY = []
      this.dataList = []
      const tempX = {}
      const tempY = {}
      let relateData = []
      if (response && response.data) {
        const upData = []
        const midData = []
        const bottomData = []
        response.data.forEach(item => {
          switch (item.xBreedAttr) {
            case '上游':
              upData.push(item)
              break
            case '中间':
              midData.push(item)
              break
            case '下游':
              bottomData.push(item)
              break
          }
        })
        relateData = [...upData, ...midData, ...bottomData]
        relateData.forEach(item => {
          tempX[item.xBreedName + item.xBreedAttr] = {
            value: item.xBreedName,
            relate: item.xBreedAttr
          }
          tempY[item.yBreedName + item.yBreedAttr] = {
            value: item.yBreedName,
            relate: item.yBreedAttr
          }
          this.dataList.push([item.yBreedName, item.xBreedName, item.corCoeValue])
        })
        this.relatePrdX = Object.values(tempX)
        // relatePrdY = Object.values(tempY)
      }
      this.relatePrdX.forEach(item => {
        const data = relateData
          .filter(temp => temp.yBreedName === item.value)
          .map(item => ({
            value: item.yBreedName,
            relate: item.yBreedAttr
          }))
        this.relatePrdY.push(data[0])
      })
      this.setOption()
      this.chartLoading = false
    },
    /**
     * 设置chart的option
     */
    setOption(
      dataList = this.dataList,
      relatePrdX = this.relatePrdX,
      relatePrdY = this.relatePrdY
    ) {
      const labelWidth = 100
      this.$refs.PriceCorrelationAnalysis.style.width = '100%'
      this.$refs.PriceCorrelationAnalysis.style.height = '100%'
      const xLabelW = (this.myChart.getWidth() - labelWidth) / relatePrdX.length
      const yLabelH = (this.myChart.getHeight() - 50) / relatePrdY.length
      dataList = dataList?.map(item => [item[0], item[1], item[2] || '-'])
      const option = {
        animation: false,
        tooltip: {
          confine: true,
          position: 'top',
          formatter: params => {
            return `${params.seriesName}<br>${params.marker}${params.value[0]} x ${params.value[1]}：<span style="margin-left:10px">${params.value[2]}</span>`
          }
        },
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        },
        xAxis: {
          show: dataList.length > 0,
          type: 'category',
          data: relatePrdX,
          triggerEvent: true,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitArea: {
            show: true
          },
          axisLabel: {
            show: true,
            interval: 0,
            margin: 0,
            width: xLabelW,
            overflow: 'truncate',
            formatter: (value, index) => {
              return `{relate|${relatePrdX[index].relate}}{abg|}\n` + `{prd|${value}}{abg2|}`
            },
            rich: {
              relate: {
                color: '#121212',
                align: 'center',
                fontSize: this.getFontSize(relatePrdX.length)
              },
              abg: {
                backgroundColor: '#F2F2F2',
                borderWidth: 1,
                borderColor: '#fff',
                width: '100%',
                align: 'right',
                height: 25
              },
              abg2: {
                backgroundColor: '#c4ccd3',
                borderWidth: 1,
                borderColor: '#fff',
                width: '100%',
                align: 'right',
                height: 25
              },
              prd: {
                color: '#3A466E',
                align: 'center',
                fontSize: this.getFontSize(relatePrdX.length)
              }
            }
          },
          position: 'top'
        },
        yAxis: {
          show: dataList.length > 0,
          type: 'category',
          data: relatePrdY,
          triggerEvent: true,
          splitArea: {
            show: true
          },
          axisTick: false,
          axisLabel: {
            interval: 0,
            margin: 0,
            borderWidth: 1,
            borderColor: '#fff',
            backgroundColor: '#c4ccd3',
            width: labelWidth,
            overflow: 'truncate',
            formatter: (value, index) => {
              let prdValue = value
              if (value.length > 3) prdValue = `${value.substring(0, 3)}...`
              return `{relate|${relatePrdY[index].relate}}` + `{prd|${prdValue}}`
            },
            rich: {
              relate: {
                color: '#121212',
                align: 'center',
                width: labelWidth / 2,
                height: yLabelH,
                backgroundColor: '#F2F2F2',
                fontSize: this.getFontSize(relatePrdY.length)
              },
              prd: {
                color: '#3A466E',
                width: labelWidth / 2,
                overflow: 'truncate',
                align: 'center',
                fontSize: this.getFontSize(relatePrdY.length)
              }
            }
          },
          inverse: true
        },
        visualMap: {
          min: -1,
          max: 1,
          show: false,
          inRange: {
            color: ['#c9ceda', 'rgba(58, 70, 110, 1)']
          }
        },
        series: [
          {
            name: '相关性',
            type: 'heatmap',
            data: dataList,
            label: {
              show: true
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      this.myChart.setOption(option)
      this.myChart.on('mouseover', this.xAxisOver)
      this.myChart.on('mouseout', this.xAxisOut)
    },
    getFontSize(num) {
      return num > 5 ? 10 : 12
    },
    /**
     * 移入x/y轴
     */
    xAxisOver(params) {
      // 因为进入柱形图区域也会触发事件，需要对参数的值判断，只响应坐标轴触发事件
      if (params.componentType !== 'series') {
        const offsetX = params.event.offsetX + 10
        const offsetY = params.event.offsetY + 10
        this.myChart.setOption({
          tooltip: {
            confine: true,
            formatter: () => {
              return params.value
            },
            trigger: 'axis',
            axisPointer: {
              // 去掉移动的指示线
              type: 'none'
            },
            alwaysShowContent: true
          }
        })
        // 显示提示框
        this.myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: 0,
          position: [offsetX, offsetY]
        })
      }
    },
    /**
     * 移除
     */
    xAxisOut(params) {
      if (params.componentType !== 'xAxis' || params.componentType !== 'yAxis') {
        this.myChart.setOption({
          tooltip: {
            confine: true,
            trigger: 'item',
            formatter: params => {
              return `${params.seriesName}<br>${params.marker}${params.value[0]} x ${params.value[1]}：<span style="margin-left:10px">${params.value[2]}</span>`
            },
            alwaysShowContent: false
          }
        })
      }
    }
  }
}
</script>

<template>
  <div ref="PriceCorrelationAnalysis" v-loading="chartLoading" class="chart-container" />
</template>

const state = {
  channelId: '',
  channelName: '',
  breedId: '',
  breedName: ''
}

const mutations = {
  CHANGE_CHANNEL(state, { id, name, breedId = '' }) {
    // 统一转成number类型做判断
    state.channelId = +id
    state.channelName = name
    if (breedId) {
      state.breedId = +breedId
      state.breedName = name
    }
  },
  CHANGE_BREED(state, { breedId, breedName }) {
    // 统一转成number类型做判断
    state.breedId = +breedId
    state.breedName = breedName
  }
}

export default {
  namespaced: true,
  state,
  mutations
}

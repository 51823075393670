const state = {
  visible: false,
  title: '',
  params: {}
}

const mutations = {
  TOGGLE(state, visible) {
    state.visible = visible
  },
  PARAMS(state, params) {
    state.params = params
  },
  TITLE(state, title) {
    state.title = title
  }
}

const actions = {
  showModal({ commit }, data) {
    commit('TOGGLE', true)
    commit('TITLE', data.title)
    commit('PARAMS', data.params)
  },
  hideModal({ commit }) {
    commit('TOGGLE', false)
    commit('TITLE', '')
    commit('PARAMS', {})
  },
  // 修改
  changeParams({ commit }, params) {
    commit('PARAMS', { ...state.params, ...params })
  },
  // 设置params
  setParams({ commit }, params) {
    commit('PARAMS', params)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

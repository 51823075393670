<!--
 * @Author: bjp
 * @Description: 接口无数据，未调通
-->
<script>
import Common from './common'
export default {
  name: 'DeviceMaintenanceContinuous', // 装置检修连续图
  mixins: [Common],
  mounted() {
    this.initEchart(this.$refs.DeviceMaintenanceContinuous)
    this.chartLoading = true
    this.getData()
  },
  methods: {
    dataHandler(response) {
      if (response && response.data) {
        this.chartData = response.data
        const unitY = response.data['单位'][0].value
        this.myChart.hideLoading()
        this.setOption(response.data, unitY)
      } else {
        this.myChart.clear()
        this.errorHandler()
      }
      this.chartLoading = false
    },
    setOption(chartData = [], unitY = '') {
      const option = {
        dataZoom: {
          show: true,
          bottom: '3%'
        },
        legend: {
          top: '3%'
        },
        grid: {
          top: this.amplify ? '12%' : '20%',
          bottom: '13%',
          containLabel: true
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].name}<br/>`
            for (let i = 0; i < params.length; i++) {
              html += `${
                params[i].marker + params[i].seriesName
              }：<b class="chart-content style="float:right;margin-left:10px">${
                params[i].value || params[i].value === 0
                  ? `${params[i].value} ${unitY || ''}</b><br>`
                  : '-' + '</b><br>'
              }`
            }
            return html
          }
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: this.$filterValue(chartData['数据时间'], 'YYYY-MM-DD')
        },
        yAxis: {
          name: unitY,
          type: 'value',
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: this.name,
            type: 'line',
            smooth: true,
            data: this.$filterValue(this.chartData['损失量'])
          }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div ref="DeviceMaintenanceContinuous" v-loading="chartLoading" class="chart-container" />
</template>

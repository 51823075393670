<!--
 * @Author: bjp
 * @Description: 库存-库存&库容比
-->
<script>
import Common from '../common.js'
export default {
  name: 'StockRate',
  mixins: [Common],
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    invType: {
      type: String,
      default: '工厂库存'
    },
    unit: {
      type: String,
      default: ''
    },
    priceUnit: {
      type: String,
      default: ''
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      option: {}
    }
  },
  // 工厂库存
  watch: {
    chartData: {
      handler() {
        this.setChartData()
      },
      deep: true
    }
  },
  mounted() {
    this.initEchart(this.$refs.StockRateRef)
    this.setChartData()
  },
  methods: {
    setChartData() {
      // 清空画板/处理数据
      this.myChart.clear()
      this.chartLoading = false
      if (this.chartData.length === 0) {
        this.errorHandler()
        return
      }
      this.myChart.hideLoading()
      const chartData = JSON.parse(JSON.stringify(this.chartData))
      chartData.sort((a, b) => {
        return (
          this.$dayjs(a.dataDate)
            .format('YYYYMMDD')
            .replace(/[^0-9]/gi, '') -
          this.$dayjs(b.dataDate)
            .format('YYYYMMDD')
            .replace(/[^0-9]/gi, '')
        )
      })
      const data = chartData.map(item => {
        return [item.exhDate, item.comInventory, item.mainPrice]
      })
      const seriesName = chartData[0].quotaAlias
      this.option = {
        dataset: {
          source: data
        },
        title: {
          text: seriesName,
          x: 'center',
          top: '5%',
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[e.seriesIndex + 1] || '-'}${
                e.seriesIndex === 0 ? this.unit : this.priceUnit
              }<br>`
            })
            return html
          }
        },
        dataZoom: {
          show: true,
          bottom: '3%'
        },
        legend: {
          bottom: '10%'
        },
        grid: {
          top: this.magnifier ? '10%' : '18%',
          left: '2%',
          right: '2%',
          bottom: '20%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: [
          {
            type: 'value',
            name: this.unit,
            scale: true,
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            name: this.priceUnit,
            scale: true,
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: seriesName,
            type: 'line',
            showAllSymbol: false
          },
          {
            name: '价格',
            type: 'line',
            yAxisIndex: 1,
            showAllSymbol: false
          }
        ]
        // color: ['#2263BD', '#3A466E']
      }
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="StockRateRef" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from '../common'

export default {
  name: 'priceNewForecast',
  mixins: [Common],
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    magnifier: {
      type: Boolean,
      default: false
    },
    indexName: {
      // indexName
      type: String,
      default: ''
    },
  },
  data() {
    return {
      unitY: '',
      option: {},
      initSeriesDashed: [],
    }
  },
  watch: {
    chartData: {
      handler(val) {
        this.dataHandler(val)
      }
    },
    deep: true
  },
  mounted() {
    this.initEchart(this.$refs.priceNewForecast)
    this.dataHandler(this.chartData)
  },
  methods: {
    /**
     * @description 处理数据的逻辑
     * @param {Object} response
     */
    dataHandler(response) {
      if (response?.length === 0) {
        this.errorHandler()
        return
      }

      const seriesLine = []
      const seriesDashed = []
      const xData = new Set()
      this.unitY = Array.from(new Set(response.map(item => item.dataUnit)))[0]
      let countE = 0;
      // 如果只有一条线时显示蓝色
      response.forEach((value,index)=>{
        xData.add(value.exhDate)        
        if(!String(value.exhDate).includes('E')){
          seriesLine.push([value.exhDate, value.mainPrice, value.dataUnit, value.breedName])
          seriesDashed.push(null)
        }else{
          countE ++
          seriesLine.push(null)
          seriesDashed.push([value.exhDate, value.mainPrice, value.dataUnit, value.breedName])
        }
      })
      // 0 1 2 3 4 5 6 7 8 9 实线是 0 -7 虚线是 后三位
      // 以及实际值到未来值之间的线段过滤应是虚线 因此  第6位的实际数据放置未来数据数组里
      // seriesLine[seriesLine.length - countE] = seriesDashed[seriesLine.length - countE]
      seriesDashed[seriesLine.length - (countE + 1)] = {
        value: seriesLine[seriesLine.length - (countE + 1)],
        symbolSize: 0,
      }
      this.initSeriesDashed = seriesDashed
      const series = [{
        name: '实际价格',
        type: 'line',
        smooth: false,
        color: '#023985',
        data: seriesLine,
        itemStyle: {
          normal: {
            lineStyle: {
              width: 1
            }
          }
        }
      }]
      if(countE > 0){
        series.push({
          name: '预测价格',
          type: 'line',
          smooth: false,
          color: '#D11027',
          data: seriesDashed,
          itemStyle: {
            normal: {
              lineStyle: {
                width: 1,
                type:'dashed'
              }
            }
          }
        })
      }
      this.setOption(series, xData)
    },
    setOption(series = [], xData, legendSelected = false) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      let legendTypeData = {
        show: true,
        left: 'center',
        bottom: '13%',
      }
      if (legendSelected) {
        legendTypeData.selected = legendSelected
      }
      this.option = {
        title: {
          left: 'center',
          text: this.indexName,// 指标名称
          top: '5%',
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        grid: {
          top: this.magnifier ? '10%' : '20%',
          bottom: '23%',// 23
          containLabel: true
        },
        legend: legendTypeData, 
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            let e = params[0].data
            if(params.length > 1){
              e = params[0].data ? params[0].data : params[1].data
            }
            html += `${params[0].marker}${e[3]}：${e[1] || '-'}${e[2] || ''}<br>`             
            /* params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`            
            }) */
            return params[0].data.value?'':html
          }
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            startValue: [...xData].length - 30
          }
        ],
        xAxis: {
          type: 'category',
          data: [...xData],
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            showMaxLabel: true
          }
        },
        yAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            name: this.unitY
          }
        ],
        series
      }

      // 判断横坐标标签是否需要倾斜
      if (this.axisLabelRoute) {
        this.option.xAxis.axisLabel = {
          rotate: -30
        }
        // 标签倾斜时需要给右边留出足够显示标签的空白
        this.option.grid.right = '9%'
      }
      this.chartLoading = false
      this.myChart.setOption(this.option)
      let self = this
      this.myChart.on('legendselectchanged', function(params) {
        const { selected } = params
        if(selected['预测价格']){
          if(selected['实际价格'] && selected['预测价格'] && series.length > 1){
            series[1].data = self.initSeriesDashed
          }else{
            let newData = [...self.initSeriesDashed]
            let valueIndex = newData.findIndex(item=>item&&item.value)
            newData[valueIndex] = null
            series[1].data = newData
          }
          self.setOption(series, xData, selected)
        }        
      })
      
    }
  }
}
</script>

<template>
  <div ref="priceNewForecast" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'

export default {
  name: 'CombineCapacitySequencing', // 原油柱状图
  mixins: [Common],
  props: {
    // 是否初始化数据
    initData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      unitY: '',
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.CombineCapacitySequencing)
    this.initData && this.getData()
  },
  methods: {
    /**
     * @description 处理数据的逻辑
     * @param {Object} response
     */
    dataHandler(response) {
      if (response?.length === 0) {
        this.errorHandler()
        return
      }
      const seriesList = ['沥青', '常减压', '石油焦', '连续重整', '催化裂化']
      const xData = Array.from(new Set(response.map(item => item.exhDate)))
      this.unitY = Array.from(new Set(response.map(item => item.unitName)))[0]

      const series = [
        {
          name: xData[0],
          type: 'bar',
          data: seriesList.map(item => {
            const [current] = response.filter(res => res.indexName.includes(item))
            if (!current) return 0

            return Number(current.inputValue)
          })
        }
      ]
      // 如果只有一条线时显示蓝色
      if (series.length === 1) series[0].color = '#023985'
      this.setOption(series, ['沥青', '常减压', '延迟焦化', '连续重整', '催化裂化'])
    },
    setOption(series = [], xData) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        title: {
          show: false
        },
        grid: {
          top: this.amplify ? '10%' : '18%',
          bottom: '13%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          top: '2%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            startValue: [...xData].length - 30
          }
        ],
        xAxis: {
          type: 'category',
          data: [...xData]
        },
        yAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            name: this.unitY
          }
        ],
        series
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="CombineCapacitySequencing" v-loading="chartLoading" class="chart-container" />
</template>

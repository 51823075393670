<script>
import Common from './common'
import { getData } from '@/apis/server'

export default {
  name: 'GasolineRefineryCapacity', // 炼厂加工量
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.CrudeOilRefineryProcessingVolume)
  },
  methods: {
    async getData() {
      const { dataApi } = this.widget
      const query = {
        queryField: {
          ...this.queryData
        }
      }
      try {
        const { response } = await getData(dataApi, query)
        this.dataHandler(response)
      } catch (error) {
        this.errorHandler()
      }
    },
    /**
     * @description 处理数据的逻辑
     * @param {Object} response
     */
    dataHandler(response) {
      if (response?.length === 0) {
        this.errorHandler()
      }
      const indexName = response[0].indexName
      const series = [
        {
          name: '主营炼厂原油年度一次加工能力（中国）',
          type: 'bar',
          yAxisIndex: 0,
          data: [],
          stack: indexName
        },
        {
          name: '独立炼厂原油年度一次加工能力（中国）',
          type: 'bar',
          yAxisIndex: 0,
          data: [],
          stack: indexName
        },
        {
          name: '主营炼厂产能增速',
          type: 'line',
          yAxisIndex: 1,
          data: []
        },
        {
          name: '独立炼厂产能增速',
          type: 'line',
          yAxisIndex: 1,
          data: []
        }
      ]
      // 获取横坐标
      const xData = new Set(
        response.map(item => this.$dayjs(item.researchStopDate).format('YYYY-MM-DD'))
      )
      const units = []
      for (const value of response) {
        const timeAxisData = this.$dayjs(value.researchStopDate).format('YYYY-MM-DD')
        if (!units[0]) {
          units[0] = value.unitName
          units[1] = '%'
        }
        if (value.indexName.includes('主营')) {
          series[0].data.push([timeAxisData, value.inputValue, value.unitName])
          series[2].data.push([timeAxisData, value.growthRate, '%'])
        } else {
          series[1].data.push([timeAxisData, value.inputValue, value.unitName])
          series[3].data.push([timeAxisData, value.growthRate, '%'])
        }
      }
      this.setOption(series, [...xData], units)
    },
    setOption(series = [], xData, units = []) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        title: {
          show: false
        },
        grid: {
          top: this.amplify ? '8%' : '15%',
          bottom: '13%',
          right: '5%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          bottom: '2%',
          textStyle: {
            width: 120,
            overflow: 'breakAll'
          }
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`
            })
            return html
          }
        },
        // dataZoom: [
        //   {
        //     type: 'slider',
        //     bottom: '3%',
        //     startValue: xData.length - 30
        //   }
        // ],
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: [
          {
            type: 'value',
            name: units[0],
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            name: units[1],
            scale: true,
            splitLine: {
              show: false
            }
          }
        ],
        series
      }
      this.downLoadChartTitle('analysis')
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="CrudeOilRefineryProcessingVolume" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from '../common.js'
import { getPartenrChart } from '@/apis/Breed/CustomsData.js'
export default {
  name: 'MulberryChart', // 桑葚图
  mixins: [Common],
  props: {
    unit: {
      type: String
    }
  },
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.MulberryChart)
  },
  methods: {
    getData() {
      this.chartLoading = true
      getPartenrChart(this.queryData)
        .then(res => {
          this.chartLoading = false
          this.myChart.clear()
          const { response } = res
          if (!response || response.data.length === 0) {
            this.errorHandler()
            return
          }

          if (this.setOption(response.data) === false) {
            this.errorHandler()
            return false
          }
          this.myChart.hideLoading()
        })
        .catch(() => {
          this.errorHandler()
        })
    },
    setOption(chartData = []) {
      let data = []
      const links = []
      // 按照总额倒序排序
      chartData
        .sort((a, b) => {
          return b.sum - a.sum
        })
        .forEach(res => {
          if (!res.sum || res.key === '中国') return
          data.push({ name: res.key })
          links.push({
            source: '中国',
            target: res.key,
            value: res.sum
          })
        })

      if (data.length === 0) {
        return false
      }

      data = [
        {
          name: '中国',
          itemStyle: {
            color: '#f04864'
          }
        },
        ...data
      ]

      this.option = {
        title: {
          show: false
        },
        tooltip: {
          confine: true,
          trigger: 'item',
          triggerOn: 'mousemove',
          formatter: params => {
            const { data, dataType, value } = params
            let tooltipTemplate = ''
            if (dataType === 'node') {
              tooltipTemplate = `${data.name}  ${value} ${this.unit}`
            } else {
              tooltipTemplate = `${data.source} -- ${data.target}  ${value} ${this.unit}`
            }
            return tooltipTemplate
          }
        },
        series: {
          type: 'sankey',
          left: 'center',
          top: 50,
          bottom: 10,
          width: 800,
          nodeWidth: 20,
          layoutIterations: 1,
          nodeAlign: 'left',
          data,
          lineStyle: {
            color: '#fcdae0',
            opacity: 1,
            curveness: 0.5
          },
          emphasis: {
            focus: 'series'
          },
          links
        }
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="MulberryChart" v-loading="chartLoading" class="chart-container" />
</template>

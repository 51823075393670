<script>
export default {
  name: 'News',
  props: {
    newsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {
    /**
     * @description: 跳转详情页
     * @param {*} item
     */
    goDetail(item) {
      this.$emit('go-detail', item)
    }
  }
}
</script>

<template>
  <el-scrollbar
    view-class="scrollbar-view"
    wrap-class="onlyXHidden"
    style="height: 100%"
    :native="false"
  >
    <div v-for="(item, index) in newsList" :key="index" class="topic">
      <span class="text-overflow_1-xzh" @click="goDetail(item)">{{ item.title }}</span>
      <span>[{{ $dayjs(item.publishTime).format('YYYY-MM-DD') }}]</span>
    </div>
  </el-scrollbar>
</template>

<style scoped lang="scss">
@import '~@/assets/styles/breed/information.scss';
.news {
  height: 100%;
  width: 100%;
}

.el-scrollbar {
  height: 100%;
}
:deep(.scrollbar-view) {
  padding: 5px 15px;
}
:deep(.onlyXHidden) {
  overflow-x: hidden;
  height: 100%;
}
</style>

<script>
export default {
  name: 'HistoryPriceTableRange', // 历史价格统计分析因素分布范围
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      option: {}
    }
  },
  watch: {
    chartData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          this.$nextTick(() => this.setOption())
        }
      }
    }
  },
  mounted() {
    this.setOption()
  },
  methods: {
    setOption() {
      if (!this.chartData || this.chartData.length === 0) return
      let myChart = this.$echarts.init(this.$refs.HistoryPriceTableRange, 'charttheme')
      this.$once('hook:beforeDestroy', () => {
        this.$echarts.dispose(this.myChart)
        myChart = null
      })
      this.option = {
        backgroundColor: 'transparent',
        grid: {
          top: '10%',
          bottom: '5%',
          left: 0,
          right: '10%'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: {
          data: this.chartData,
          type: 'line',
          showSymbol: false,
          itemStyle: {
            color: '#0d5ce1'
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#7ca5db' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: '#dbe7f7' // 100% 处的颜色
                }
              ],
              global: false // 缺省为 false
            }
          }
        }
      }
      myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="HistoryPriceTableRange" class="chart-container" />
</template>

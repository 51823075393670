<script>
import Common from './common'
import NewsInformation from '@/components/Enterprise/NewsInformation.vue'
import { getData } from '@/apis/server'
export default {
  components: { NewsInformation },
  mixins: [Common],
  data() {
    return {
      articleInfoList: {}
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      const { dataApi } = this.widget
      getData(dataApi, {
        breedId: 197,
        pageSize: 8,
        pageNum: 1
      }).then(({ response }) => {
        if (response?.datas.length > 0) this.articleInfoList = response.datas
        // console.log(response)
      })
    }
  }
}
</script>

<template>
  <div class="news">
    <el-scrollbar view-class="scrollbar-view" wrap-class="onlyXHidden" :native="false">
      <news-information v-for="item in articleInfoList" :key="item.id" :article-info="item" />
      <!-- <news-information />
      <news-information />
      <news-information />
      <news-information />
      <news-information />
      <news-information />
      <news-information />
      <news-information />
      <news-information /> -->
    </el-scrollbar>
  </div>
</template>

<style lang="scss">
.news {
  .el-scrollbar {
    height: 300px;
  }
  .el-scrollbar__wrap {
    overflow: hidden !important;
    overflow-y: scroll !important;
  }
}
</style>

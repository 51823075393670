<script>
import Common from './common'
import { getData } from '@/apis/server'

export default {
  name: 'ImportsAndExportsCount', // 进出口量-分年对比
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.ImportsAndExportsCount)
  },
  methods: {
    async getData() {
      const { dataApi } = this.widget
      const query = {
        queryField: {
          breedId: this.id,
          ...this.queryData
        }
      }
      const { response } = await getData(dataApi, query)
      this.dataHandler(response)
    },
    dataHandler(response) {
      this.chartLoading = false
      this.myChart && this.myChart.clear()
      this.myChart && this.myChart.hideLoading()
      if (!response || !Object.keys(response).length) {
        this.errorHandler()
        return
      }
      let barx = []
      const dataListLine = []
      const dataSource = []
      for (const key in response) {
        response[key].forEach(item => {
          barx.push(this.$dayjs(item.dataDate).format('MM月'))
        })
        dataListLine.unshift({
          name: key,
          type: 'line',
          smooth: false
        })
      }
      barx = [...new Set(barx)].sort((a, b) => {
        return a.replace(/[^0-9]/, '') - b.replace(/[^0-9]/, '')
      })
      barx.forEach(time => {
        let temp = {}
        for (const key in response) {
          response[key].forEach(item => {
            if (time === this.$dayjs(item.dataDate).format('MM月')) {
              temp = {
                ...temp,
                [key]: item.currVolume
              }
            }
          })
        }
        dataSource.push({
          time,
          ...temp
        })
      })

      const dimensions = Object.keys(response).reverse()
      const unitY = response[dimensions[0]][0].currVolumeUnit
      // console.log(dimensions)
      this.myChart && this.myChart.hideLoading()
      this.setOption(dataSource, dataListLine, dimensions, unitY)
    },
    setOption(dataSource = [], dataListLine = [], dimensions = [], unitY = []) {
      this.option = {
        title: {
          show: false
        },
        dataZoom: {
          show: true,
          bottom: '3%'
        },
        grid: {
          top: this.amplify ? '12%' : '20%',
          left: '5%',
          bottom: '15%',
          containLabel: true
        },
        tooltip: {
          confine: true,
          show: true, // 是否显示
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].name}<br/>`
            for (let i = 0; i < params.length; i++) {
              html += `${
                params[i].marker + params[i].seriesName
              }：<b class="chart-content style="float:right;margin-left:10px">${
                params[i].value[params[i].seriesName] || params[i].value[params[i].seriesName] === 0
                  ? `${params[i].value[params[i].seriesName]} ${unitY || ''}</b><br>`
                  : '-' + '</b><br>'
              }`
            }
            return html
          }
        },
        legend: {
          top: '3%'
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          }
          // data: barX,
        },
        yAxis: {
          name: unitY,
          type: 'value',
          min(value) {
            return Math.round(value.min * 0.8)
          },
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          splitNumber: 4
        },
        dataset: {
          dimensions: ['time', ...dimensions],
          source: dataSource
        },
        series: dataListLine
      }
      this.downLoadChartTitle()
      this.myChart && this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="ImportsAndExportsCount" v-loading="chartLoading" class="chart-container" />
</template>

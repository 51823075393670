<!--
 * @Author: your name
 * @Date: 2021-07-05 14:42:26
 * @LastEditTime: 2022-03-30 14:28:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \oilchem-data-terminal-web\src\widgets\Breed\CustomsData\MainPriceSubyearComparison.vue
-->
<script>
import _ from 'lodash'
import Common from '../common.js'
import { getVolumeYear } from '@/apis/Breed/CustomsData.js'

export default {
  name: 'MainPriceSubyearComparison',
  mixins: [Common], // 分年对比
  props: {
    breedName: {
      type: String,
      default: ''
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.MainPriceSubyearComparison)
  },
  methods: {
    getData: _.debounce(async function () {
      getVolumeYear(this.queryData)
        .then(({ response }) => {
          this.chartLoading = false
          this.myChart.clear()
          if (!response || Object.keys(response).length === 0) {
            this.errorHandler()
            return
          }
          this.$emit('change-data', response)
          this.setOption(response)
          this.myChart.hideLoading()
        })
        .catch(() => {
          this.errorHandler()
        })
    }, 1000),
    setOption(response = {}) {
      const cusType = this.$route.path.includes('import') ? '进口' : '出口'
      // 处理数据
      let barX = []
      let yUnit = ''

      const chartData = []
      for (const key in response) {
        chartData.push({
          name: key,
          type: 'line',
          data: response[key].map(item => {
            let date = item.exhDate
            if (this.queryData.queryField.frequency === '周度') {
              date = this.$dayjs(item.researchStopDate).format('YYYY-MM-DD')
            }
            if (['月度', '季度'].includes(this.queryData.queryField.frequency)) {
              date = this.$dayjs(item.dataDate).format('MM月')
            }
            barX.push(date)
            if (item.currVolumeUnit && !yUnit) yUnit = item.currVolumeUnit
            return [date, item.currVolume]
          })
        })
      }

      barX = [
        ...new Set(
          barX.sort((a, b) => {
            return (a ? a.replace(/[^0-9]/gi, '') : 0) - (b ? b.replace(/[^0-9]/gi, '') : 0)
          })
        )
      ]

      this.option = {
        title: {
          text: `${this.breedName || this.name}${cusType}量`,
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14
          },
          left: 'center',
          top: this.magnifier ? '5%' : '10%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}&nbsp;&nbsp;&nbsp;&nbsp;${e.value[1] || '-'}${
                yUnit || ''
              }<br>`
            })
            return html
          }
        },
        grid: {
          top: this.magnifier ? '10%' : '30%',
          left: '2%',
          right: '2%',
          bottom: '20%',
          containLabel: true
        },
        legend: {
          bottom: '10%'
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%'
          }
        ],
        xAxis: {
          type: 'category',
          data: barX
        },
        yAxis: {
          type: 'value',
          name: yUnit,
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          splitNumber: 4
        },
        series: chartData
      }
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="MainPriceSubyearComparison" v-loading="chartLoading" class="chart-container" />
</template>

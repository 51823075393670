<script>
import Common from './common'
import { formatDayJsTime } from '@/utils/formatData'

export default {
  name: 'ButaneWeeklyOutputInChina', // 进出口分析
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.ButaneWeeklyOutputInChina)
  },
  methods: {
    dataHandler(response) {
      let barX = []
      const seriesList = []
      let unitY = ''
      this.myChart.clear()
      if (response) {
        console.log(response)
        // 数组分组
        const map = new Map()
        response.forEach((item, index, arr) => {
          if (!map.has(item.indexName)) {
            map.set(
              item.indexName,
              arr.filter(a => a.indexName === item.indexName)
            )
          }
        })
        const formatTime = formatDayJsTime(this.queryData.frequency, true)
        map.forEach((arr, index) => {
          seriesList.push({
            name: index,
            type: 'bar',
            stack: 'stack',
            data: arr.map(item => [
              this.$dayjs(item.researchStopDate).format(formatTime),
              Number(item.inputValue),
              item.unitName
            ])
          })
          barX = [...arr.map(item => this.$dayjs(item.researchStopDate).format(formatTime))]
        })

        barX = [...new Set(barX)].sort()

        unitY = response[0].unitName
        this.myChart.hideLoading()
      } else {
        this.errorHandler()
      }
      this.chartLoading = false

      this.setOption(barX, seriesList, unitY)
    },
    setOption(barX = [], seriesList = [], unitY = '') {
      // 初始化展示范围为30个单位
      const startValue = barX[barX.length - 30] || barX[0]
      this.option = {
        title: {
          show: false
        },
        legend: {
          show: true,
          left: 'center',
          top: '2%'
        },
        tooltip: {
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params
              .sort((a, b) => b.value - a.value)
              .forEach((e, i) => {
                // 2021年11月12日 因数目过多只取前10条
                if (i < 11)
                  html += `${e.marker}${e.seriesName}&nbsp;&nbsp;&nbsp;&nbsp;${e.value[1]}${
                    unitY || ''
                  }<br>`
              })
            return html
          }
        },
        yAxis: {
          name: unitY,
          type: 'value'
        },
        grid: {
          top: this.amplify ? '10%' : '18%',
          bottom: '13%',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'inside',
            startValue
          },
          {
            type: 'slider',
            bottom: '3%',
            left: '5%',
            right: '5%'
          }
        ],
        xAxis: {
          type: 'category',
          data: barX
        },
        series: seriesList
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="ButaneWeeklyOutputInChina" v-loading="chartLoading" class="chart-container" />
</template>

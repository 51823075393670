import createApi from '@/apis/http'
/* 收藏功能 */
// 添加模板
export const templateAdd = createApi({ url: '/api/generic/template/add', method: 'post' })
// 根据用户查询模板
export const templateQueryByUserId = createApi({ url: '/api/generic/template/queryByUserId' })
// 根据用户查询模板
export const templateRemove = createApi({ url: '/api/generic/template/remove', method: 'post' })
// 分页查看收藏的数据
export const collectionQuery = createApi({ url: '/api/generic/collection/query' })
// 移除收藏
export const collectionRemove = createApi({ url: '/api/generic/collection/remove', method: 'post' })
// 添加收藏
export const collectionAdd = createApi({ url: '/api/generic/collection/add', method: 'post' })

/** 商品功能 */
// 我的商品-商品信息(可分页)
export const queryMyGoods = createApi({
  url: '/api/generic/collection/queryMyGoods',
  method: 'post'
})
// 我的商品-删除商品(可批量删多)
export const goodsRemove = createApi({ url: '/api/generic/collection/goodsRemove', method: 'post' })
// 我的商品-删除商品(可批量删多)
export const goodsRemoveByIds = createApi({
  url: '/api/generic/collection/goodsRemoveByIds',
  method: 'post'
})
// 添加收藏
export const goodsAdd = createApi({ url: '/api/generic/collection/goodsAdd', method: 'post' })
// 添加收藏
export const goodsListAdd = createApi({
  url: '/api/generic/collection/goodsListAdd',
  method: 'post'
})
// 收藏品种-查看当前品种收藏状态
export const goodsStatus = createApi({ url: '/api/generic/collection/goodsStatus' })

/** 企业功能 */
// 分页查询
export const companyQuery = createApi({ url: '/api/generic/collection/company/query' })
// 取消收藏
export const companyRemove = createApi({
  url: '/api/generic/collection/company/remove',
  method: 'post'
})

/** 通用功能 */
// 查询所有行业
export const queryAllTopLevelChannel = createApi({ url: '/api/channel/queryAllTopLevelChannel' })
// 查询行业下关联的品种的频道信息
export const queryBreedsByTopLevelChannelId = createApi({
  url: '/api/channel/queryBreedsByTopLevelChannelId'
})

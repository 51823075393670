<!--
 * @Author: bjp
 * @Description: 炼厂毛利
-->
<script>
import { debounce } from 'lodash'
import * as echarts from 'echarts'
import Common from '@/widgets/Breed/common'
import { getNumberOfString } from '@/utils/common'
import { getGrosProfit } from '@/apis/quote'

export default {
  name: 'GrosProfitRiverChart', // 炼厂毛利河道图
  mixins: [Common],
  props: {
    indexObj: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      option: {},
      chartLoading: false
    }
  },
  watch: {
    indexObj: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.indexCode) {
          this.getData()
        }
      }
    }
  },
  mounted() {
    this.initEchart(this.$refs.GrosProfitRiverChart)
    // this.getData()
  },
  methods: {
    getData: debounce(async function () {
      this.chartLoading = true
      const { response } = await getGrosProfit({
        queryField: {
          indexCode: this.indexObj.indexCode
        }
      })
      this.dataHandler(response)
    }, 500),
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      if (!response || response.length === 0) {
        this.errorHandler()
      } else {
        this.setOption(response)
      }
    },
    setOption(data) {
      let barX = data.map(m => m.week)
      const info = data[0]
      const obj = {
        currentYearValue: this.$dayjs().format('YYYY'),
        historyMax: `${info.rangeYear}最大值`,
        historyAvg: `${info.rangeYear}均值`,
        historyMin: `${info.rangeYear}最小值`
      }
      const seriesList = []
      for (const key in obj) {
        seriesList.push({
          color: '#E28C1B',
          name: obj[key],
          type: 'line',
          smooth: true,
          symbol: 'none',
          lineStyle: {
            width: 2
          },
          emphasis: {
            lineStyle: {
              width: 2
            }
          },
          data: data
            .map(v => [v.week, v[key]])
            .sort((a, b) => getNumberOfString(a[0]) - getNumberOfString(b[0]))
        })
      }
      seriesList.forEach((item, i) => {
        if (item.name.includes('最小值')) {
          item.color = '#3A466E'
          item.areaStyle = { color: '#fff', origin: 'start', opacity: 0.6 }
          item.lineStyle = { type: 'solid', width: 2 }
        }
        if (item.name.includes('最大值')) {
          item.color = '#6899DD'
          // item.areaStyle = { color: '#BFBFBF', origin: 'start' }
          item.areaStyle = {
            origin: 'start',
            // 折线图颜色半透明
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(191, 191, 191, 1)' // 上部颜色
              },
              {
                offset: 1,
                color: 'rgba(191, 191, 191, .2)' // 下部颜色
              }
            ])
          }
          item.lineStyle = { type: 'solid', width: 2 }
        }
        if (item.name.includes('均值')) {
          item.lineStyle = { type: 'dashed', width: 2 }
          item.color = '#2263BD'
        }
        if (i === 3) {
          item.lineStyle = { width: 2 }
        }
      })

      barX = [...new Set(barX)].sort((a, b) => getNumberOfString(a) - getNumberOfString(b))
      this.option = {
        title: {
          text: '炼厂毛利',
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14,
            opacity: 0
          },
          left: 'center'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'} ${
                e.value[1] ? info.unitName : ''
              }<br>`
            })
            return html
          }
        },
        legend: {
          left: this.amplify ? 'center' : 2,
          itemWidth: this.amplify ? 25 : 18,
          itemGap: this.amplify ? 10 : 2,
          top: '3%'
        },
        dataZoom: [
          {
            type: 'inside',
            height: 12
          },
          {
            type: 'slider',
            height: this.amplify ? 15 : 10,
            bottom: '2%',
            left: this.amplify ? '4%' : '11%',
            right: this.amplify ? '4%' : '11%'
          }
        ],
        grid: {
          top: this.amplify ? '10%' : '20%',
          bottom: '11%',
          left: '1%',
          right: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: barX
        },
        yAxis: {
          type: 'value',
          scale: true
          // name: unit
        },
        series: seriesList
      }

      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div
    ref="GrosProfitRiverChart"
    v-loading="chartLoading"
    class="chart-container"
    style="width: 100%; height: 100%"
  />
</template>

<script>
import Common from './common'

export default {
  name: 'CrudeOilInnerLineBarMix', // 炼厂加工量
  mixins: [Common],
  props: {
    activeIndex: {
      type: String,
      default: ''
    },
    chartData: {
      type: [Array, Object],
      default: () => []
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      option: {}
    }
  },
  watch: {
    chartData: {
      handler() {
        this.dataHandler()
      },
      deep: true
    }
  },
  mounted() {
    this.initEchart(this.$refs.CrudeOilRefineryProcessingVolume)
    this.chartData?.length && this.dataHandler()
  },
  methods: {
    /**
     * @description 处理数据的逻辑
     */
    dataHandler() {
      const response = this.chartData
      if (response?.length === 0) {
        this.errorHandler()
        return
      }
      let unit = ''
      const series = [
        {
          name: response[0].indexName,
          type: 'bar',
          yAxisIndex: 0,
          color: '#023985',
          data: []
        },
        {
          name: '涨跌幅',
          type: 'line',
          yAxisIndex: 1,
          color: '#BC0008',
          data: []
        }
      ]
      for (const value of response) {
        const timeAxisData = this.$dayjs(value.researchStopDate).format('YYYY-MM-DD')
        series[0].data.push([timeAxisData, value.inputValue, value.unitName])
        series[1].data.push([timeAxisData, value.growthRate, '%'])
        if (!unit) unit = value.unitName
      }

      this.setOption(series, unit)
    },
    setOption(series = [], unit = '') {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        title: {
          text: this.$route.meta.title || '',
          left: 'center',
          top: '3%',
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        grid: {
          top: this.magnifier || this.amplify ? '8%' : '15%',
          bottom: '20%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          bottom: '10%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            startValue: series[0].data.length - 30
          }
        ],
        xAxis: {
          type: 'category'
        },
        yAxis: [
          {
            type: 'value',
            name: unit,
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            name: '%',
            type: 'value',
            scale: true,
            splitLine: {
              show: false
            }
          }
        ],
        series
      }

      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="CrudeOilRefineryProcessingVolume" v-loading="chartLoading" class="chart-container" />
</template>

/*
 * @Description:
 * @Date: 2021-11-16 10:38:50
 * @LastEditTime: 2023-05-25 15:30:29
 */
import {
  appConsumpComparseHeader,
  appConsumpComparseList,
  appConsumpComparseListGraph,
  capacityComparseHeader,
  capacityComparseList,
  capacityComparseListGraph,
  cpCapComparseHeader,
  cpCapComparseList,
  cpCapComparseListGraph,
  exVolumeComparseHeader,
  exVolumeComparseList,
  exVolumeComparseListGraph,
  getCommonFrequency,
  getDemandFrequency,
  // getFactoryFrequency, // 库存数据频率
  getLossFrequency,
  getLossGraph,
  getLossHeader,
  getLossTable,
  // getOperateFrequency, // 开工率数据频率
  // getYieldFrequency, // 产量数据频率
  imVolumeComparseHeader,
  imVolumeComparseList,
  imVolumeComparseListGraph,
  inventoryComparseHeader,
  inventoryComparseList,
  inventoryComparseListGraph,
  mainPriceComparseHeader,
  mainPriceComparseList,
  mainPriceComparseListGraph,
  operateRateComparseHeader,
  operateRateComparseList,
  operateRateComparseListGraph,
  profitComparseHeader,
  profitComparseList,
  profitComparseListGraph,
  profitRateComparseHeader,
  profitRateComparseList,
  profitRateComparseListGraph,
  utilizationComparseHeader,
  utilizationComparseList,
  utilizationComparseListGraph
} from '@/apis/Breed/dataCompare.js'

// 模块名和api对照
export const nameDic = {
  // 品种内页
  主流价: 'mainstreamPrice',
  市场价: 'marketPrice',
  企业价: 'enterprisePrice',
  国际价: 'internationalPrice',
  毛利: 'costGrossProfit',
  毛利率: 'costGrossProfitRate',
  产量: 'yield',
  开工率: 'operatingRate',
  产能: 'capacity',
  产能利用率: 'capacityUtilization',
  表观消费量: 'apparentConsumption',
  进口量: 'importVolume',
  出口量: 'exportVolume',
  库存: 'factoryStock',
  // 对比筛选
  价格: 'mainstreamPrice',
  利润: 'costGrossProfit',
  产能增速: 'capacity',
  产量增速: 'yield',
  表观增速: 'apparentConsumption',
  进口增速: 'importVolume',
  出口增速: 'exportVolume',
  损失量: 'lossCount'
}

/**
 * @description: 多产品对比字典
 * name: 名称 同namedic对应
 * tableUrl: 表格数据接口 表头和数据接口
 * graphUrl: 图表数据接口
 * frequency: 数据频率
 */
export const dataDic = {
  mainstreamPrice: {
    name: '主流价',
    tableUrl: [mainPriceComparseHeader, mainPriceComparseList],
    graphUrl: mainPriceComparseListGraph,
    frequency: ['日度', '周度', '月度', '年度']
  }, // 主流价
  marketPrice: {
    frequency: ['日度', '周度', '月度', '年度']
  }, // 市场价
  enterprisePrice: {
    frequency: ['日度', '周度', '月度', '年度']
  }, // 企业价
  internationalPrice: {
    frequency: ['日度', '周度', '月度', '年度']
  }, // 国际价
  costGrossProfit: {
    name: '毛利',
    tableUrl: [profitComparseHeader, profitComparseList],
    graphUrl: profitComparseListGraph,
    frequency: ['周度', '月度', '季度', '年度']
  }, // 毛利
  costGrossProfitRate: {
    name: '毛利率',
    tableUrl: [profitRateComparseHeader, profitRateComparseList],
    graphUrl: profitRateComparseListGraph,
    frequency: ['周度', '月度', '季度', '年度']
  }, // 毛利率
  yield: {
    name: '产量',
    tableUrl: [cpCapComparseHeader, cpCapComparseList],
    graphUrl: cpCapComparseListGraph,
    frequency: getCommonFrequency
  }, // 产量
  operatingRate: {
    name: '开工率',
    tableUrl: [operateRateComparseHeader, operateRateComparseList],
    graphUrl: operateRateComparseListGraph,
    frequency: getCommonFrequency
  }, // 开工率
  capacity: {
    name: '产能',
    tableUrl: [capacityComparseHeader, capacityComparseList],
    graphUrl: capacityComparseListGraph,
    frequency: getCommonFrequency
  }, // 产能
  capacityUtilization: {
    name: '产能利用率',
    tableUrl: [utilizationComparseHeader, utilizationComparseList],
    graphUrl: utilizationComparseListGraph,
    frequency: getCommonFrequency
  }, // 产能
  apparentConsumption: {
    name: '表观消费量',
    tableUrl: [appConsumpComparseHeader, appConsumpComparseList],
    graphUrl: appConsumpComparseListGraph,
    frequency: getDemandFrequency
  }, // 表观消费量
  importVolume: {
    name: '进口量',
    tableUrl: [imVolumeComparseHeader, imVolumeComparseList],
    graphUrl: imVolumeComparseListGraph,
    frequency: ['月度', '季度', '年度']
  }, // 进口量
  exportVolume: {
    name: '出口量',
    tableUrl: [exVolumeComparseHeader, exVolumeComparseList],
    graphUrl: exVolumeComparseListGraph,
    frequency: ['月度', '季度', '年度']
  }, // 出口量
  factoryStock: {
    name: '库存',
    tableUrl: [inventoryComparseHeader, inventoryComparseList],
    graphUrl: inventoryComparseListGraph,
    frequency: getCommonFrequency
  }, // 库存
  lossCount: {
    name: '损失量',
    tableUrl: [getLossHeader, getLossTable],
    graphUrl: getLossGraph,
    frequency: getLossFrequency
  } // 损失量
}

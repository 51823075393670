<script>
import Common from './common'
import { getData } from '@/apis/server'

export default {
  name: 'CapacityUtilizationYear', // 行业开工率
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.CapacityUtilizationYear)
  },
  methods: {
    async getData() {
      this.chartLoading = true
      const { dataApi } = this.widget
      const query = {
        queryField: {
          breedId: this.queryData.upAndDownBreed || this.id,
          frequencyName: this.queryData.frequencyName
        }
      }
      const { response } = await getData(dataApi, query)
      this.dataHandler(response)
    },
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      this.myChart.clear()
      if (response && Object.keys(response).length) {
        const { dataListLine, barX } = this.$formatGetData({
          response,
          frequencyName: 'MM月',
          valueType: 'capacityFactor'
        })
        this.myChart.hideLoading()
        this.setOption(barX, dataListLine)
      } else {
        this.errorHandler()
      }
      this.chartLoading = false
    },
    setOption(barX = [], dataListLine = []) {
      this.option = {
        title: {
          show: false
        },
        dataZoom: {
          show: true,
          bottom: '3%'
        },
        legend: {
          top: '3%'
        },
        grid: {
          top: this.amplify ? '10%' : '18%',
          bottom: '12%',
          right: '5%',
          containLabel: true
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].name}<br/>`
            for (let i = 0; i < params.length; i++) {
              html += `${
                params[i].marker + params[i].seriesName
              }：<b class="chart-content style="float:right;margin-left:10px">${
                params[i].value || params[i].value === 0
                  ? `${params[i].value[1]} %` + `</b><br>`
                  : '-' + '</b><br>'
              }`
            }
            return html
          }
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: barX,
          axisLabel: {
            interval: 0
          }
        },
        yAxis: {
          name: '%',
          type: 'value',
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: dataListLine
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="CapacityUtilizationYear" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'
export default {
  name: 'CostStructureOfDifferentTechnology', // 不同工艺的成本构成
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.CostStructureOfDifferentTechnology)
    // 手动调用不同装置成本构成
    this.getData()
  },
  methods: {
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      if (!response) {
        this.errorHandler()
        return
      }
      let dimensions = []
      const dataset = []
      let seriesList = []
      const dateArr = Object.keys(response).sort((a, b) => a - b)
      const unitY = response[dateArr[0]][0].costUnit
      dateArr.forEach(item => {
        const obj = {}
        response[item].forEach(ele => {
          obj[ele.consElemName] = ele.consElemCost
        })

        dataset.push({
          date: this.$dayjs(Number(item)).format('YYYY-MM-DD'),
          ...obj
        })
      })

      dimensions = this.getDimensions(response)
      seriesList = dimensions.map(e => {
        return {
          type: 'bar',
          name: e,
          stack: 'one',
          data: dataset.map(v => {
            return [v.date, v[e]]
          }),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0,0,0,0.3)'
            }
          },
          barWidth: '40%'
        }
      })
      this.setOption(dataset, seriesList, unitY)
    },
    setOption(dataset = [], seriesList = [], unitY = '') {
      const lastDate = dataset[dataset.length - 1].date
      const startValue = this.$dayjs(lastDate)
        .subtract(6, 'month')
        .endOf('month')
        .format('YYYY-MM-DD')
      this.option = {
        title: {
          show: false
        },
        legend: {
          type: 'scroll'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].name}<br/>`
            for (let i = 0; i < params.length; i++) {
              html += `${
                params[i].marker + params[i].seriesName
              }：<b class="chart-content style="float:right;margin-left:10px">${
                params[i].value[1] || params[i].value[1] === 0
                  ? `${params[i].value[1]} ${unitY || ''}</b><br>`
                  : '-' + '</b><br>'
              }`
            }
            return html
          }
        },
        dataZoom: [
          {
            type: 'inside',
            startValue
          },
          {
            type: 'slider',
            bottom: '3%',
            startValue
          }
        ],
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          name: unitY,
          type: 'value'
        },
        grid: {
          bottom: '13%',
          right: '5%',
          containLabel: true
        },
        series: seriesList
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    },
    // 根据返回数据的第一个日期数据来定义维度信息
    getDimensions(res) {
      const [key] = Object.keys(res)
      return Array.from(new Set(res[key].map(item => item.consElemName)))
    }
  }
}
</script>

<template>
  <div ref="CostStructureOfDifferentTechnology" v-loading="chartLoading" class="chart-container" />
</template>

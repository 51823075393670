<!--
 * @Description:炼油装置特殊页面折线图
 * @Date: 2022年7月22日14:37:51
 * @LastEditTime: 2023-09-26 10:35:02
 @接收参数
 @ axisLabelRoute: x轴名称是否倾斜 默认true
-->
<script>
import Common from './common'

export default {
  name: 'CrudeOilInnerLinePrice', // 原油折线图
  mixins: [Common],
  props: {
    chartData: {
      type: [Array, Object],
      default: () => []
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      unitY: ''
    }
  },
  watch: {
    chartData: {
      handler() {
        this.dataHandler()
      },
      deep: true
    }
  },
  mounted() {
    this.initEchart(this.$refs.CrudeOilLine)
    this.chartData?.length && this.dataHandler()
  },
  methods: {
    /**
     * @description 处理数据的逻辑
     */
    dataHandler() {
      const response = this.chartData
      if (response?.length === 0) {
        this.errorHandler()
        return
      }
      const series = []
      const xData = new Set()
      this.unitY = Array.from(new Set(response.map(item => item.unitName)))[0]
      for (const value of response) {
        const timeAxisData = this.$dayjs(value.researchStopDate).format('YYYY-MM-DD')
        xData.add(timeAxisData)
        const index = series.findIndex(item => item.name === value.indexName)
        if (index === -1) {
          series.push({
            name: value.indexName,
            type: 'line',
            smooth: false,
            data: [[timeAxisData, value.inputValue, value.unitName]]
          })
        } else {
          series[index].data.push([timeAxisData, value.inputValue, value.unitName])
        }
      }
      // 如果只有一条线时显示蓝色
      if (series.length === 1) series[0].color = '#023985'
      this.setOption(series, [...xData])
    },
    setOption(series = [], xData) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        grid: {
          top: this.magnifier || this.amplify ? '10%' : '18%',
          bottom: '13%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          top: '2%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            startValue: xData.length - 30
          }
        ],
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            name: this.unitY
          }
        ],
        series
      }

      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="CrudeOilLine" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'
import { getData } from '@/apis/server'

export default {
  name: 'CrudeOilUsProductionAndWellsDrilledCount', // 原油折线图
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.CrudeOilUsProductionAndWellsDrilledCount)
  },
  methods: {
    async getData() {
      const { dataApi } = this.widget
      const query = {
        queryField: {
          ...this.queryData
        }
      }
      try {
        const { response } = await getData(dataApi, query)
        this.dataHandler(response)
      } catch (error) {
        this.errorHandler()
      }
    },
    /**
     * @description 处理数据的逻辑
     * @param {Object} response
     */
    dataHandler(response) {
      if (response?.length === 0) {
        this.errorHandler()
      }
      let series = []
      const xData = new Set()
      for (const value of response) {
        const timeAxisData = this.$dayjs(value.researchStopDate).format('YYYY-MM-DD')
        xData.add(timeAxisData)
        const index = series.findIndex(item => item.name === value.indexName)
        const yAxisIndex = Number(value.indexName.includes('钻井'))
        if (index === -1) {
          series.push({
            name: value.indexName,
            type: 'line',
            yAxisIndex,
            smooth: false,
            data: [[timeAxisData, value.inputValue, value.unitName]]
          })
        } else {
          series[index].data.push([timeAxisData, value.inputValue, value.unitName])
        }
      }
      // 如果只有一条线时显示蓝色
      if (series.length === 1) series[0].color = '#023985'
      if (!series[0].name.includes('产量')) series = series.reverse()
      this.setOption(series, [...xData])
    },
    setOption(series = [], xData) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        title: {
          show: false
        },
        grid: {
          top: '10%',
          bottom: '25%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          bottom: '13%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            startValue: xData.length - 30
          }
        ],
        xAxis: {
          type: 'category',
          data: xData,
          axisLabel: {
            rotate: -30
          }
        },
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: series[0].data[0][2],
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            scale: true,
            name: series[1].data[0][2],
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          }
        ],
        series
      }
      this.downLoadChartTitle('analysis')
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div
    ref="CrudeOilUsProductionAndWellsDrilledCount"
    v-loading="chartLoading"
    class="chart-container"
  />
</template>

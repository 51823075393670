import { render, staticRenderFns } from "./ProfitDistribution.vue?vue&type=template&id=9a603250&scoped=true&"
import script from "./ProfitDistribution.vue?vue&type=script&lang=js&"
export * from "./ProfitDistribution.vue?vue&type=script&lang=js&"
import style0 from "./ProfitDistribution.vue?vue&type=style&index=0&id=9a603250&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a603250",
  null
  
)

export default component.exports
<!--
 * @Author: bjp
 * @Description:圆滑折线图
-->
<script>
import Common from '../common.js'
export default {
  name: 'LineSmooth',
  mixins: [Common],
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    tempName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      barX: [],
      dataListLine: []
    }
  },
  watch: {
    chartData: {
      handler() {
        this.setOption()
      },
      deep: true
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#LineSmooth'))
    this.chartData.length > 0 && this.setOption()
  },
  methods: {
    setOption() {
      this.chartLoading = false
      this.myChart.clear()
      if (!this.chartData && this.chartData.length === 0) {
        this.errorHandler()
        return
      }
      this.myChart.hideLoading()
      const data = this.chartData.map(item => {
        return [item.日期, item.消费量 || 0]
      })
      const option = {
        title: {
          text: this.$route.query.name + this.tempName,
          x: 'center',
          top: 15,
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        dataset: {
          source: data
        },
        // color: '#2263BD',
        xAxis: {
          type: 'category'
        },
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            }
          }
        ],
        dataZoom: [
          {
            type: 'slider',
            left: 20,
            right: 10,
            bottom: 10
          }
        ],
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        grid: {
          left: 15,
          right: 10,
          top: 52,
          bottom: 60,
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          bottom: 30,
          textStyle: {
            color: '#121212',
            fontSize: 14
          },
          itemGap: 20, // 设置间距
          data: [
            {
              name: '实际消费量'
            }
          ]
        },
        series: [
          {
            name: '实际消费量',
            type: 'line',
            smooth: true
          }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div id="LineSmooth" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'
export default {
  name: 'DownstreamConsumptionStructureBar', // 下游消费结构Bar
  mixins: [Common],
  props: {
    widget: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.chartLoading = true
    this.initEchart(this.$refs.DownstreamConsumptionStructureBar)
  },
  methods: {
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      if (response) {
        this.setOption(response.data)
      }
      this.chartLoading = false
    },
    setOption(chartData = []) {
      const option = {
        dataZoom: {
          show: true
        },
        legend: {
          data: ['表观消费量', '消费增速'],
          top: '5%'
        },
        xAxis: {
          type: 'category',
          data: this.filterValue(chartData['年份']),
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: [
          {
            type: 'value',
            name: this.filterValue(chartData['表观消费量单位'])[0],
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            name: '%',
            axisLabel: {
              formatter: '{value}%'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          }
        ],
        series: [
          {
            name: '消费增速',
            type: 'line',
            yAxisIndex: 1,
            data: this.filterValue(chartData['表观消费量环比(%)'])
          },
          {
            name: '表观消费量',
            type: 'bar',
            data: this.filterValue(chartData['表观消费量'])
          }
        ]
      }
      this.myChart.setOption(option)
    },
    // 格式化数据
    filterValue(data) {
      if (data && data.length > 0) {
        const arr = []
        data.forEach(item => {
          const value = item.value
          arr.push(value)
        })
        return arr
      }
    }
  }
}
</script>

<template>
  <div ref="DownstreamConsumptionStructureBar" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Description: 企业-拟在建项目
 * @Date: 2022-04-20 13:55:33
 * @LastEditTime: 2022-08-30 11:39:51
-->
<script>
import Common from './common.js'
import { getDataByGet } from '@/apis/server.js'
export default {
  name: 'EnterprisePlanToBuildProject',
  mixins: [Common],
  data() {
    return {
      listData: []
    }
  },
  watch: {
    activeTag() {
      this.getData()
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.listData = []
      const params = {
        companyId: this.companyId,
        breedId: this.breedId
      }
      const { dataApi } = this.widget
      getDataByGet(dataApi, params).then(res => {
        this.listData = res.response
      })
    }
  }
}
</script>

<template>
  <div id="EnterprisePlanToBuildProject" class="company-textline">
    <ul v-for="(item, key) in listData" :key="key" class="company-textline-item">
      <li>
        {{ item.deviceName }}: <span class="red-text">{{ item.capacity }}</span
        >{{ item.prdCapUnit }}
      </li>
      <li>
        工艺路线：<span class="red-text">{{ item.process }}</span>
      </li>
      <li>
        投产时间：<span class="red-text">{{ item.prodYear }}</span>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/styles/enterprise/companyText.scss';
</style>

<script>
import Common from './common'
import geoJSON from '@/assets/map/china.json'

export default {
  name: 'SupplyPattern',
  mixins: [Common],
  data() {
    return {
      geoCoordMap: {},
      option: {}
    }
  },
  mounted() {
    this.chartLoading = true
    this.$echarts.registerMap('china', { geoJSON })
    this.initEchart(this.$refs.SupplyPattern)
  },
  methods: {
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      const data = []
      if (response && Object.keys(response).length) {
        response.data['分类明细'].forEach((item, index) => {
          this.geoCoordMap[item.value] = [
            response.data['企业经度'][index].value || 0,
            response.data['企业维度'][index].value || 0
          ]
          data.push({
            name: item.value,
            value: response.data['产能'][index].value
          })
        })
        this.setOption(data)
        this.chartLoading = false
        this.myChart.hideLoading()
      } else {
        this.errorHandler()
      }
    },
    setOption(data = []) {
      this.option = {
        title: {
          show: false
        },
        tooltip: {
          confine: true,
          trigger: 'item',
          formatter: params => {
            const overlap = []
            // 经纬度向上取整取坐标相等的数据显示，包含本身
            for (const [key, value] of Object.entries(this.geoCoordMap)) {
              if (
                Math.ceil(params.value[0]) === Math.ceil(value[0]) &&
                Math.ceil(params.value[1]) === Math.ceil(value[1])
              ) {
                overlap.push([key, data.find(v => v.name === key).value])
              }
            }
            let html = ''
            overlap.forEach(e => {
              html += `${params.marker}${e[0]}：${e[1]}<br>`
            })
            return html
          },
          position: point => [point[0], point[1]]
        },
        visualMap: {
          left: '0',
          bottom: 'center',
          align: 'right',
          orient: 'vertical',
          calculable: true,
          inverse: false,
          inRange: {
            color: ['#3DA23C', '#6BA23A', '#D8561A', '#3F5676']
          }
        },
        geo: [
          {
            map: 'china',
            zoom: 1,
            // roam: true,
            left: '23%'
            // scaleLimit: { min: 1.2, max: 3 }, // 缩放级别
          }
        ],
        series: [
          {
            name: '产能',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: this.convertData(data),
            symbolSize: 8,
            encode: {
              value: 2
            },
            emphasis: {
              label: {
                show: true
              }
            }
          }
        ]
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    },
    // 数据处理
    convertData(data) {
      const res = []
      for (let i = 0; i < data.length; i++) {
        const geoCoord = this.geoCoordMap[data[i].name]
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value)
          })
        }
      }
      return res
    }
  }
}
</script>

<template>
  <div ref="SupplyPattern" class="chart-container" />
</template>

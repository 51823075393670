<script>
import Common from './common'
import { getData } from '@/apis/server'
export default {
  name: 'MarketPrice', // 市场价格
  mixins: [Common],
  props: {},
  data() {
    return {
      xData: [],
      seriesData: []
    }
  },
  watch: {
    activeTag: {
      // immediate: true,
      handler() {
        this.chartLoading = true
        this.getData()
      }
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#MarketPrice'))
    this.getData()
  },
  methods: {
    async getData() {
      this.chartLoading = true
      const { dataApi } = this.widget
      getData(dataApi, {
        queryField: {
          breedId: this.breedId,
          frequencyName: '月度'
        }
      })
        .then(({ response }) => {
          this.myChart.clear()
          this.myChart.hideLoading()
          if (!response || !response.data?.length) {
            this.errorHandler()
            return false
          }
          const data = Object.values(response)
            .flat(Infinity)
            .sort((a, b) => {
              if (a.key > b.key) {
                return 1
              }
              return -1
            })
          const xData = data.map(item => this.$dayjs(item.key).format('YYYY-MM'))
          const seriesData = data.map(item => item.mainPrice)
          this.setOption(xData, seriesData)
        })
        .finally(() => {
          this.chartLoading = false
        })
    },
    setOption(xData, seriesData) {
      const startValue = this.$dayjs().subtract(6, 'month').startOf('month').format('YYYY-MM')
      const option = {
        tooltip: {
          confine: true
        },
        xAxis: {
          type: 'category',
          data: xData,
          axisLabel: {
            interval: 'auto'
          },
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {},
        dataZoom: [
          { type: 'inside', startValue },
          { type: 'slider', startValue }
        ],
        series: [
          {
            type: 'line',
            smooth: true,
            data: seriesData
          }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div id="MarketPrice" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'
import { getData } from '@/apis/server'

export default {
  name: 'DeviceInformation',
  mixins: [Common], // 价格对比图
  mounted() {
    this.initEchart(this.$refs.PriceCompare)
  },
  methods: {
    getData() {
      let breedIds = []
      // 添加查询条件
      if (this.queryData.upAndDownBreed) {
        breedIds = [this.id, ...this.queryData.upAndDownBreed]
      } else {
        breedIds = [this.id]
      }
      const { dataApi } = this.widget
      getData(dataApi, {
        queryField: {
          frequencyName: '日度',
          breedIds
        }
      }).then(({ response }) => {
        if (!response) {
          this.setOption()
          return false
        }
        const eData = []
        const xData = []
        Object.values(response).forEach((item, i) => {
          eData.push({
            name: item[0].breedName,
            data: []
          })
          item.forEach(val => {
            eData[i].data.push(val.mainPrice)
            i === 0 && xData.push(val.dataDate.split(' ')[0])
          })
        })
        this.setOption(xData, eData)
      })
    },
    setOption(xData = [], eData = []) {
      const option = {
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        legend: {},
        grid: {
          bottom: 50,
          right: 40
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: 20
          }
        ],
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisLabel: {
            interval: 0,
            rotate: -40
          },
          data: xData
        },
        yAxis: {
          type: 'value'
        },
        series: eData.map(item => {
          return {
            type: 'line',
            name: item.name,
            data: item.data
          }
        })
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div ref="DeviceInformation" class="chart-container" />
</template>

<script>
import Common from './common'
export default {
  name: 'PriceYearForecast', // 价格预测
  mixins: [Common],
  mounted() {
    this.initEchart(this.$refs.PriceForecast)
  },
  methods: {
    dataHandler() {
      this.setOption()
    },
    setOption() {
      const option = {
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: 20,
            backgroundColor: '#d7dae2',
            height: 16,
            moveHandleSize: 0,
            handleSize: '200%',
            showDataShadow: false,
            handleStyle: {
              color: '#3A466E',
              borderWidth: 0
            },
            fillerColor: 'rgb(58, 70, 110,0.4)'
          }
        ],
        legend: {
          // data: ['+偏移程度', '-偏移程度', '实际值', '预测值']
        },
        xAxis: {
          type: 'category',
          // data: ['第1周', '第2周', '第3周', '第4周', '第5周', '第6周', '第7周', '第8周', '第9周', '第10周', '第11周', '第12周', '第13周E', '第14周#', '第15周E'],
          data: [],
          axisPointer: {
            type: 'shadow'
          },
          axisTick: { show: false },
          axisLabel: {
            rotate: 45
          },
          axisLine: {
            onZeroAxisIndex: 0
          }
        },
        yAxis: [
          {
            type: 'value',
            interval: 500,
            splitLine: {
              show: false
            },
            min: 'dataMin'
          },
          {
            type: 'value',
            interval: 10,
            max: 50,
            axisLabel: {
              formatter: '{value}%'
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          // {
          //   name: '实际值',
          //   type: 'line',
          //   data: [13500, 12725, 13500, 14700, 13400, 13700, 12476, 13288, 13574, 13742, 14530, 13300],
          //   yAxisIndex: 0,
          //   color: '#2263BD'
          // },
          // {
          //   name: '预测值',
          //   type: 'line',
          //   data: [12500, 11537, 11533, 13500, 11500, 13288, 11678, 13300, 12870, 13574, 14530, 13742],
          //   yAxisIndex: 0,
          //   color: '#232E54',
          //   lineStyle: {
          //     type: 'dashed'
          //   }
          // },
          // {
          //   name: '+偏移程度',
          //   type: 'bar',
          //   yAxisIndex: 1,
          //   data: [2, null, 3.3, null, 6.3, null, 10.2, null, 20.3, null, 23.4, null, 23.0, null, 16.5, null, 12, null, 6.2],
          //   color: '#BA0C2F'
          // },
          // {
          //   name: '-偏移程度',
          //   type: 'bar',
          //   yAxisIndex: 1,
          //   data: [null, -1, null, -3.3, null, -6.3, null, -9.8, null, -9.3, null, -9.4, null, -9.0, null, -9.5, null, -9.0, null, -6.2],
          //   color: '#1A9016',
          //   barGap: '-100%'
          // }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div ref="PriceForecast" class="chart-container" />
</template>

<script>
export default {
  name: 'NewsInformation',
  props: {
    articleInfo: {
      type: Object,
      default: () => {
        return {
          title: '',
          publishTime: '',
          content: ''
        }
      }
    }
  }
}
</script>

<template>
  <!-- <template v-if="expand">
      <div class="news-item-expand">
        <a
          href="#"
          class="title"
        >
          关注疫苗和需求 布伦特原油期特原油期货突破桶50美
        </a>
        <div class="content">
          <p>
            华为Mate40今早10时开启预售后，华为官网、预售后，华为官网、等各大平台瞬间被秒光。从知情
            悉，截...
          </p>
          <span>[详情]</span>
        </div>
        <div class="tag">
          <h2>标签：</h2>
          <a href="">px</a>
          <a href="">热点聚焦</a>
          <a href="">热点分析</a>
        </div>
      </div>
    </template> -->
  <div class="news-item-narrow">
    <div>{{ articleInfo.title }}</div>
    <!-- <div>新闻信息新闻信息新闻系新闻系....</div> -->
    <div>[{{ $dayjs(articleInfo.publishTime).format('YYYY-MM-DD') }}]</div>
  </div>
</template>

<style lang="scss" scoped>
.news-item-narrow {
  color: #121212;
  font-size: 12px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #bfbfbf;
  cursor: pointer;
  &:hover {
    color: $light-blue;
  }
}
</style>

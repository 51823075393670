<!--
 * @Author: bjp
 * @Description: 进出口量-柱状图
-->
<script>
import Common from './common'
import { getData } from '@/apis/server'
export default {
  name: 'ImportsAndExportsBar',
  mixins: [Common],
  mounted() {
    this.initEchart(document.querySelector('#ImportsAndExportsCountBarChart'))
    this.chartLoading = true
  },
  methods: {
    async getData() {
      const { dataApi } = this.widget
      const query = {
        queryField: {
          breedId: this.id,
          frequency: '月度',
          ...this.queryData
        }
      }
      const { response } = await getData(dataApi, query)
      this.dataHandler(response)
    },
    dataHandler(response) {
      let chartData = []
      if (response) {
        chartData = response.data
        this.myChart.hideLoading()
      } else {
        this.errorHandler()
      }
      this.chartLoading = false
      this.setOption(chartData)
    },
    setOption(chartData = []) {
      const option = {
        dataZoom: {
          show: true
        },
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        legend: {
          top: 0
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: this.$filterValue(chartData['时间'], 'YYYY-MM')
        },
        yAxis: {
          name:
            Object.keys(chartData).length > 0
              ? this.$filterValue(chartData['进出口数量单位'])[0]
              : '',
          type: 'value',
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: {
          name: this.name,
          type: 'bar',
          data: this.$filterValue(chartData['进出口数量'])
        }
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div id="ImportsAndExportsCountBarChart" v-loading="chartLoading" class="chart-container" />
</template>

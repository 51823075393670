<!--
 * @Description: 企业-相关重点企业
 * @Date: 2022-04-20 14:01:41
 * @LastEditTime: 2022-08-25 16:30:21
-->
<script>
import Common from './common'
import { getData } from '@/apis/server.js'
export default {
  mixins: [Common],
  data() {
    return {
      tableData: [],
      tableLoading: false
    }
  },
  watch: {
    activeTag() {
      this.getData()
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.tableData = []
      this.tableLoading = true
      const params = {
        queryField: {
          companyId: this.companyId,
          breedId: this.breedId
        }
      }
      const { dataApi } = this.widget
      getData(dataApi, params)
        .then(res => {
          this.dataHandler(res?.response?.data)
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    dataHandler(data = []) {
      const firstData = []
      const lastData = []
      const listData = []
      for (const index in data) {
        const item = data[index]
        if (index % 2) {
          const arr = {}
          Object.keys(item).forEach(key => {
            arr[`${key}Second`] = item[key]
          })
          lastData.push(arr)
        } else {
          firstData.push(item)
        }
      }
      for (const index in firstData) {
        listData.push({ ...firstData[index], ...lastData[index] })
      }
      this.tableData = listData
    }
  }
}
</script>

<template>
  <div class="enterprise-relation-companies chart-container">
    <el-table ref="tableA" v-loading="tableLoading" :data="tableData" stripe>
      <el-table-column prop="" label="企业" align="center" width="400">
        <template #default="scope">
          {{ scope.row.securityName }}
        </template>
      </el-table-column>
      <el-table-column prop="inProCap" label="产能" align="center">
        <template #default="scope"> {{ scope.row.inProCap }}{{ scope.row.unitName }} </template>
      </el-table-column>
      <el-table-column prop="process" label="工艺路线" align="center" />
      <el-table-column prop="" label="企业" align="center" width="400">
        <template #default="scope">
          {{ scope.row.securityNameSecond }}
        </template>
      </el-table-column>
      <el-table-column prop="inProCapSecond" label="产能" align="center">
        <template #default="scope">
          {{ scope.row.inProCapSecond }}{{ scope.row.unitNameSecond }}
        </template>
      </el-table-column>
      <el-table-column prop="processSecond" label="工艺路线" align="center" />
    </el-table>
  </div>
</template>

<style lang="scss" scoped>
.enterprise-relation-companies {
  @include tableMaxHeight(235px);
  @mixin borderRight {
    border-right: 2px solid rgba(#3a466e, 0.5) !important;
  }
  :deep(.el-table) {
    .el-table__header tr th:nth-child(3) {
      @include borderRight();
    }
    .el-table__body tr td:nth-child(3) {
      @include borderRight();
    }
    th,
    td {
      border-bottom: none;
    }
  }
}
</style>

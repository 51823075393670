export default [
  {
    path: 'home',
    component: () => import('@/views/Home/index.vue'),
    name: 'Home',
    meta: { title: '首页' }
  },
  // {
  //   path: 'attention',
  //   component: () => import('@/views/Home/attentionAndHotPic.vue'),
  //   name: 'attention',
  //   meta: { title: '隆众关注', columnId: 34872 }
  // },
  {
    path: 'newsInformationOne',
    component: () => import('@/views/Home/dataInterpretationAndGec.vue'),
    name: 'newsInformationOne',
    meta: { title: '热点资讯', columnTag: 1 }
  },
  {
    path: 'dataInterpretation',
    component: () => import('@/views/Home/dataInterpretationAndGec.vue'),
    name: 'dataInterpretation',
    meta: { title: '产业数据解读', columnTag: 2 }
  },
  {
    path: 'gec',
    component: () => import('@/views/Home/dataInterpretationAndGec.vue'),
    name: 'gec',
    meta: { title: '众行', columnTag: 3 }
  },
  {
    path: 'gecPrediction',
    component: () => import('@/views/Home/dataInterpretationAndGec.vue'),
    name: 'gecPrediction',
    meta: { title: '众行预告', columnTag: 4 }
  },
  {
    path: 'picDetail',
    component: () => import('@/views/Home/picDetail.vue'),
    name: 'picDetail',
    meta: { title: '隆众热图详情' }
  },
  {
    path: 'newsInformation',
    component: () => import('@/views/Home/newsInformation.vue'),
    name: 'newsInformation',
    meta: { title: '新闻资讯列表' }
  },
  {
    path: 'newsDetail',
    component: () => import('@/views/Home/newsDetail.vue'),
    name: 'newsDetail',
    meta: { title: '新闻资讯详情' }
  },
  {
    path: 'hotDetail',
    component: () => import('@/views/Home/hotDetail.vue'),
    name: 'hotDetail',
    meta: { title: '热点资讯详情' }
  },
  {
    path: 'calendar',
    component: () => import('@/views/Home/calendarMonth.vue'),
    name: 'calendarMonth',
    meta: { title: '隆重指数-月历' }
  },
  {
    path: 'calendarWeek',
    component: () => import('@/views/Home/calendarWeek.vue'),
    name: 'calendarWeek',
    meta: { title: '隆重指数-周历' }
  },
  {
    path: 'lzIndex',
    component: () => import('@/views/Home/lzIndex.vue'),
    name: 'lzIndex',
    meta: { title: '隆重指数' }
  }
]

/*
 * @Description: 对比筛选路由
 * @Date: 2021-05-20 13:29:14
 * @LastEditTime: 2023-08-21 09:31:02
 * meta配置项
 * @ title: 路由名称
 * @ showTitle: 上方显示标题名称，为空则显示query.title
 * @ buttons: 上方显示按钮
 * @ isRank: 是否是排行榜
 * @ isOutPage:  是否是外页
 */

export default [
  {
    path: '/contrastScreen',
    component: () => import('@/views/ContrastScreen/index.vue'),
    redirect: '/contrastScreen/price',
    children: [
      // 价格
      {
        path: 'price',
        component: () => import('@/views/ContrastScreen/Price/price.vue'),
        name: 'pricePrice',
        meta: {
          title: '价格',
          showTitle: '排行榜',
          buttons: [5],
          isRank: true,
          isOutPage: true,
          disableExportImage: true
        }
      },
      {
        path: 'priceInsidePage',
        component: () => import('@/views/ContrastScreen/Price/priceInsidePage.vue'),
        name: 'priceInsidePage',
        meta: { title: '价格', showTitle: '价格', buttons: [1, 2, 3, 5, 6] }
      },
      // 成本/利润
      {
        path: 'cost',
        component: () => import('@/views/ContrastScreen/CostProfit/cost.vue'),
        name: 'costCost',
        meta: { title: '成本', showTitle: '排行榜', buttons: [5], isRank: true, isOutPage: true }
      },
      {
        path: 'costInsidePage',
        component: () => import('@/views/ContrastScreen/CostProfit/costInsidePage.vue'),
        name: 'costInsidePage',
        meta: { title: '成本', showTitle: '成本', buttons: [2, 5, 6] }
      },
      {
        path: 'profit',
        component: () => import('@/views/ContrastScreen/CostProfit/profit.vue'),
        name: 'profitIndex',
        meta: {
          title: '价格',
          showTitle: '排行榜',
          buttons: [5],
          isRank: true,
          isOutPage: true,
          disableExportImage: true
        }
      },
      {
        path: 'profitInsidePage',
        component: () => import('@/views/ContrastScreen/CostProfit/profitInsidePage.vue'),
        name: 'profitInsidePage',
        meta: { title: '价格', showTitle: '利润', buttons: [1, 2, 3, 5, 6] }
      },
      // 供应
      {
        path: 'supply/:type',
        component: () => import('@/views/ContrastScreen/Supply/supplyIndex.vue'),
        meta: {
          title: '供应',
          showTitle: '排行榜',
          buttons: [5],
          isRank: true,
          isOutPage: true,
          disableExportImage: true
        }
      },
      {
        path: 'capacityInsidePage/:type',
        component: () => import('@/views/ContrastScreen/Supply/capacityInsidePage.vue'),
        name: 'capacityInsidePage',
        meta: { title: '供应', showTitle: '供应', buttons: [2, 3, 5, 6] }
      },
      {
        path: 'production/:type',
        component: () => import('@/views/ContrastScreen/Supply/production.vue'),
        meta: {
          title: '供应',
          showTitle: '排行榜',
          buttons: [5],
          isRank: true,
          isOutPage: true,
          disableExportImage: true
        }
      },
      {
        path: 'operatingRate',
        component: () => import('@/views/ContrastScreen/Supply/operatingRate.vue'),
        meta: {
          title: '供应',
          showTitle: '排行榜',
          buttons: [5],
          isRank: true,
          isOutPage: true,
          disableExportImage: true
        }
      },
      {
        path: 'operatingRateInside',
        component: () => import('@/views/ContrastScreen/Supply/operatingRateInsidePage.vue'),
        meta: { title: '供应', showTitle: '供应', buttons: [2, 3, 5, 6] }
      },
      {
        path: 'industry',
        component: () => import('@/views/ContrastScreen/Supply/industry.vue'),
        name: 'industryIndex',
        meta: {
          title: '供应',
          showTitle: '排行榜',
          buttons: [5],
          isRank: true,
          isOutPage: true,
          disableExportImage: true
        }
      },
      {
        path: 'industryInsidePage',
        component: () => import('@/views/ContrastScreen/Supply/industryInsidePage.vue'),
        name: 'industryInsidePage',
        meta: { title: '供应', showTitle: '行业集中度', buttons: [5, 6] }
      },
      {
        path: 'seasonalAnalysis',
        component: () => import('@/views/ContrastScreen/AnalysisForecast/seasonalAnalysis.vue'),
        name: 'seasonalAnalysis',
        meta: {
          title: '分析预测',
          showTitle: '季节性分析',
          buttons: [5],
          isOutPage: true,
          disableExportImage: true
        }
      },
      {
        path: 'relatedAnalysis',
        component: () => import('@/views/ContrastScreen/AnalysisForecast/relatedAnalysis.vue'),
        name: 'relatedAnalysis',
        meta: { title: '分析预测', showTitle: '价格相关性分析', buttons: [], isOutPage: true }
      },
      {
        path: 'returnAnalysis',
        component: () => import('@/views/ContrastScreen/AnalysisForecast/returnAnalysis.vue'),
        name: 'returnAnalysis',
        meta: { title: '分析预测', showTitle: '数据相关性分析', buttons: [2, 5], isOutPage: true }
      },
      {
        path: 'corporateResearchProduction',
        component: () => import('@/views/ContrastScreen/CorporateResearch/corporateResearch.vue'),
        meta: { title: '企业研究', showTitle: '在产', buttons: [4, 5], isOutPage: true }
      },
      {
        path: 'corporateResearchBuilding',
        component: () => import('@/views/ContrastScreen/CorporateResearch/corporateResearch.vue'),
        meta: { title: '企业研究', showTitle: '拟在建', buttons: [4, 5], isOutPage: true }
      },
      {
        path: 'corporateResearchShutdown',
        component: () => import('@/views/ContrastScreen/CorporateResearch/corporateResearch.vue'),
        meta: { title: '企业研究', showTitle: '关停', buttons: [4, 5], isOutPage: true }
      },
      {
        path: 'industryChainStructure',
        component: () =>
          import('@/views/ContrastScreen/ConsumptionStructure/industryChainStructure.vue'),
        name: 'industryChainStructure',
        meta: { title: '产业链结构', showTitle: '产业链结构', buttons: [5], isOutPage: true }
      },
      {
        path: 'consumptionStructure',
        component: () =>
          import('@/views/ContrastScreen/ConsumptionStructure/consumptionStructure.vue'),
        meta: { title: '产业链分析', showTitle: '消费结构', buttons: [5], isOutPage: true }
      },
      {
        path: 'profitFlow',
        component: () => import('@/views/ContrastScreen/ConsumptionStructure/profitFlow.vue'),
        name: 'profitFlow',
        meta: { title: '产业链分析', showTitle: '利润流向', buttons: [5], isOutPage: true }
      },
      {
        path: 'customsData/:type',
        component: () => import('@/views/ContrastScreen/CustomsData/customsData.vue'),
        name: 'customsData',
        meta: {
          title: '进出口数据',
          showTitle: '排行榜',
          buttons: [5],
          isRank: true,
          isOutPage: true,
          disableExportImage: true
        }
      },
      {
        path: 'customsDataInner/importPrice',
        component: () => import('@/views/ContrastScreen/CustomsData/customsDataInnerNew.vue'),
        name: 'customsDataInnerImportPrice',
        meta: { title: '进出口数据', buttons: [3, 5, 6] }
      },
      {
        path: 'customsDataInner/exportPrice',
        component: () => import('@/views/ContrastScreen/CustomsData/customsDataInnerNew.vue'),
        name: 'customsDataInnerExportPrice',
        meta: { title: '进出口数据', buttons: [3, 5, 6] }
      },
      {
        path: 'customsDataInner/importGrowth',
        component: () => import('@/views/ContrastScreen/CustomsData/customsDataInner.vue'),
        name: 'customsDataInnerImportGrowth',
        meta: { title: '进出口数据', buttons: [3, 5, 6] }
      },
      {
        path: 'customsDataInner/exportGrowth',
        component: () => import('@/views/ContrastScreen/CustomsData/customsDataInner.vue'),
        name: 'customsDataInnerExportGrowth',
        meta: { title: '进出口数据', buttons: [3, 5, 6] }
      },
      {
        path: 'customsDependence/:type',
        component: () => import('@/views/ContrastScreen/CustomsData/customsDependence.vue'),
        name: 'customsDependence',
        meta: { title: '进出口数据', buttons: [5, 6] }
      },
      {
        path: 'demand/:type',
        component: () => import('@/views/ContrastScreen/Demand/demand.vue'),
        name: 'demand',
        meta: {
          title: '需求',
          showTitle: '排行榜',
          buttons: [5],
          isRank: true,
          isOutPage: true,
          disableExportImage: true
        }
      },
      {
        path: 'demandInner/:type',
        component: () => import('@/views/ContrastScreen/Demand/demandInner.vue'),
        name: 'demandInner',
        meta: { title: '需求', buttons: [3, 5, 6] }
      },
      {
        path: 'dataComparse',
        component: () => import('@/views/ContrastScreen/dataComparse.vue'),
        name: 'contrastScreenDataComparse',
        meta: { title: '多产品对比', buttons: [2, 3, 5, 6] }
      }
    ]
  }
]

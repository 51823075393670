<!--
 * @Author: bjp
 * @Description: 库存-库容比
-->
<script>
import Common from '../common.js'
export default {
  name: 'StorageCapacityRatio',
  mixins: [Common], // 库存-库容比
  props: {
    chartData: {
      type: Array,
      default() {
        return []
      }
    },
    invType: {
      type: String,
      default: '工厂库容比'
    }
  },
  watch: {
    chartData: {
      handler() {
        this.setChartData()
      },
      deep: true
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#storageCapacityRatio'))
  },
  methods: {
    setChartData() {
      // 清空画板/处理数据
      this.myChart.clear()
      this.chartLoading = false
      if (this.chartData.length === 0) {
        this.errorHandler()
        return
      }
      this.myChart.hideLoading()
      const dateDic = {
        日度: 'YYYY年M月DD日',
        周度: 'YYYY年M月DD日',
        月度: 'YYYY年M月',
        年度: 'YYYY年'
      }
      const data = this.chartData.map(item => {
        return [
          this.$dayjs(item.dataDate).format(dateDic[this.$parent.activeIndex]),
          item.comInventory,
          item.mainPrice
        ]
      })

      const option = {
        dataset: {
          source: data
        },
        title: {
          text: this.invType,
          x: 'center',
          top: 20,
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        dataZoom: {
          show: true,
          left: 30,
          right: 20,
          bottom: 10
        },
        legend: {
          data: this.legendData,
          bottom: 30
        },
        grid: {
          top: 40,
          left: 20,
          right: 20,
          bottom: 60,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: [
          {
            type: 'value',
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            }
          }
        ],
        series: [
          {
            name: this.invType,
            type: 'line'
          },
          {
            name: '价格',
            type: 'line',
            yAxisIndex: 1
          }
        ]
        // color: ['#2263BD', '#3A466E']
      }
      this.myChart.setOption(option, true)
    }
  }
}
</script>

<template>
  <div id="storageCapacityRatio" v-loading="chartLoading" class="chart-container" />
</template>

/*
 * @Description: 品种内页-价格中心apis
 * @Date: 2021-06-07 14:52:04
 * @LastEditTime: 2022-07-19 15:16:43
 */
import { getData } from '@/apis/server'
import createApi from '@/apis/http'

const url = '/api/widget/queryData/'
/**
 * @description 主流价
 */
// 分年曲线对比
export const getAnnualCurve = params =>
  getData('/api/widget/priceCenter/mainPrice/comparePriceByYear', params)

// 连续图
export const getContinuous = params => getData(`${url}104`, params)

// K线图
export const getKLine = params => getData(`${url}105`, params)

/**
 * @description 市场价
 */
// 分年曲线对比
export const getMarketAnnualCurve = params =>
  getData('/api/widget/priceCenter/marketPrice/comparePriceByYear', params)

// 连续图
export const getMarketContinuous = params => getData(`${url}143`, params)

// K线图
export const getMarketKLine = params => getData(`${url}105`, params)

// 内页表格
export const getMarketInsideTable = params => getData('/api/price/marketPriceList', params)

/**
 * @description 企业价
 */
// 分年曲线对比
export const getCompanyAnnualCurve = params =>
  getData('api/widget/priceCenter/companyPrice/comparePriceByYear', params)

// 连续图
export const getCompanyContinuous = params => getData(`${url}141`, params)

// K线图
export const getCompanyKLine = params => getData(`${url}105`, params)

// 内页表格
export const getCompanyInsideTable = params => getData('/api/price/companyPriceList', params)

/**
 * @description 国际价
 */
// 分年曲线对比
export const getInternetAnnualCurve = params =>
  getData('/api/widget/priceCenter/internationalPrice/comparePriceByYear', params)

// 连续图
export const getInternetContinuous = params => getData(`${url}139`, params)

// K线图
export const getInternetKLine = params => getData(`${url}105`, params)

// 内页表格
export const getInternetInsideTable = params => getData('/api/price/internationalPriceList', params)

// 河道图
// export const getRiverChart = params => getData(`${url}24`, params)

// 主流价表格
export const getMainTable = params => getData('/api/widget/priceCenter/mainPrice/table', params)

// 市场价-表格
export const getMarketPriceTableHeader = params => getData(`${url}marketPriceHeader`, params)
export const getMarketPriceTable = params => getData(`${url}marketPrice`, params)

// 企业价-表格
export const getCompanyPriceTableHeader = params => getData(`${url}enterprisePriceHeader`, params)
export const getCompanyPriceTable = params => getData(`${url}enterprisePrice`, params)

// 国际价-表格
export const getInternationalPriceTableHeader = params =>
  getData(`${url}internationalPriceHeader`, params)
export const getInternationalPriceTable = params => getData(`${url}internationalPrice`, params)

// 历史价格形成因素分析-统计分析表格
export const getHistoryAnaTableHeader = params =>
  getData(`${url}priceCausationStatisticsTableHeader`, params)
export const getHistoryAnaTable = params => getData(`${url}priceCausationStatisticsTable`, params)

// 历史价格形成因素分析-价格汇总-表格
export const getHistoryPriceSummary = params => getData(`${url}179`, params)

// 分析与预测
// 历史因素形成因素分析
export const getHistoryFactorLine = params => getData('/api/widget/queryData/110', params)
export const getHistoryFactorPie = params => getData('/api/widget/queryData/111', params)
// 价格季节性分析
export const getSeasonAnalysis = params => getData('/api/widget/queryData/109', params)
// 价格季节性分析－日度
export const getSeasonAnalysisDay = params => getData('/api/price/getPriceGroupList', params)
export const getSeasonAmplitude = params => getData('/api/price/getAmplitude', params)

export const queryIndexName = createApi({
  url: '/api/widget/queryIndexName',
  method: 'post'
})

// 主流价-根据品种获取单位-new
export const getPriceUnit = params => getData('/api/price/mainPrice/unit', params)

// 原油-内页-查询频率
export const getInnerFrequency = code =>
  createApi({ url: `/api/oilRefining/inner/frequency/${code}`, method: 'post' })

// 原油-内页-全球原油期货价格
export const getGlobalOilFuturesPrice = createApi({
  url: '/api/oilRefining/inner/normal/null/asc',
  method: 'post'
})

// 原油-内页-国内原油现货价格
// export const getDomesticOilSpotPrice = createApi({
//   url: '/api/oilRefining/inner/normal/lz_gj_yy_000001/asc', method: 'post'
// })

// 原油-内页-获取表格数据
export const getInnerTableData = code =>
  createApi({
    url: `/api/oilRefining/inner/normal/${code}/asc`,
    method: 'post'
  })

// 内页-价格中心-市场价-表格筛选条件
export const marketPriceFilterItems = createApi({
  url: '/api/widget/queryData/marketPriceFilterItems',
  method: 'post'
})
// 内页-价格中心-企业价-表格筛选条件
export const enterprisePriceFilterItems = createApi({
  url: '/api/widget/queryData/enterprisePriceFilterItems',
  method: 'post'
})
// 内页-价格中心-国际价-表格筛选条件
export const internationalPriceFilterItems = createApi({
  url: '/api/widget/queryData/internationalPriceFilterItems',
  method: 'post'
})
// // 内页-价格中心
// export const getInteractionListById = createApi({ url: '/api/oilRefining/inner/getListByInteractId' })

// 内页-分析与预测-价格预测-表头
export const priceForecastTableHeader = createApi({
  url: '/api/breed/forecast/priceForecastTableHeader',
  method: 'post'
})
// 内页-分析与预测-价格预测-图表数据接口
export const priceForecastData = createApi({
  url: '/api/breed/forecast/priceForecastData',
  method: 'post'
})

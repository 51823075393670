<!--
 * @Description:炼油装置特殊页面折线图
 * @Date: 2022年7月22日14:37:51
 * @LastEditTime: 2023-09-27 08:52:50
 @接收参数
 @ axisLabelRoute: x轴名称是否倾斜 默认true
-->
<script>
import Common from './common'

export default {
  name: 'CrudeOilLinePrice', // 原油折线图
  mixins: [Common],
  props: {
    axisLabelRoute: {
      type: Boolean,
      default: true
    },
    // 是否初始化数据
    initData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      unitY: '',
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.CrudeOilLine)
    this.initData && this.getData()
  },
  methods: {
    /**
     * @description 处理数据的逻辑
     * @param {Object} response
     */
    dataHandler(response) {
      if (response?.length === 0) {
        this.errorHandler()
        return
      }

      let series = []
      const xData = new Set()
      let units = []
      for (const value of response) {
        const timeAxisData = this.$dayjs(value.researchStopDate).format('YYYY-MM-DD')
        xData.add(timeAxisData)
        const index = series.findIndex(item => item.name === value.indexName)

        if (index === -1) {
          const isPrice = value.dataType === '价格'
          const i = isPrice ? 1 : 0
          units[i] = value.unitName

          series.push({
            name: value.indexName,
            type: 'line',
            smooth: false,
            yAxisIndex: i,
            color: isPrice ? '#BC0008' : '#023985',
            data: [[timeAxisData, value.inputValue, value.unitName]]
          })
        } else {
          series[index].data.push([timeAxisData, value.inputValue, value.unitName])
        }
      }
      if (series[0].yAxisIndex !== 0) series = series.reverse()
      // 如果只有一条线时显示蓝色
      if (series.length === 1) {
        series[0].color = '#023985'
        series[0].yAxisIndex = 0
        units = [units[1]]
      }
      this.setOption(series, [...xData], units)
    },
    setOption(series = [], xData, units = []) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        title: {
          show: false
        },
        grid: {
          top: this.amplify ? '10%' : '18%',
          bottom: '13%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          top: '2%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            startValue: xData.length - 30
          }
        ],
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: [
          {
            type: 'value',
            name: units[0] || '',
            scale: true,
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            name: units[1] || '',
            scale: true,
            splitLine: {
              show: false
            }
          }
        ],
        series
      }

      // 判断横坐标标签是否需要倾斜
      if (this.axisLabelRoute) {
        this.option.xAxis.axisLabel = {
          rotate: -30
        }
        // 标签倾斜时需要给右边留出足够显示标签的空白
        this.option.grid.right = '9%'
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="CrudeOilLine" v-loading="chartLoading" class="chart-container" />
</template>

import axios from 'axios'
import { Message } from 'element-ui'
import { getUrlParams, myBrowser, storage } from '@/utils/common'
import 'url-search-params-polyfill'

import RegisterDialog from '@/components/RegisterDialog'
// 设置token
// 跨域请求携带cookie
axios.defaults.withCredentials = false

// 请求时的拦截
axios.interceptors.request.use(
  config => {
    const { method, data, url } = config
    const { modularName } = getUrlParams()
    config.headers.token = storage.get('token')
    config.headers['modular-name'] = modularName || data?.modularName || ''
    // get参数编码
    if (method === 'get' && data) {
      config.url = `${url}?${Object.keys(data)
        .map(key => `${key}=${encodeURIComponent(data[key])}`)
        .join('&')}`
      // 如果是IE浏览器，发送get请求时附加一个时间戳来禁用缓存
      if (myBrowser().includes('IE')) {
        config.url += `timestamp=${new Date().getTime()}`
      }
      delete config.data
    }
    return config
  },
  error => Promise.reject(error)
)

const common = options => {
  return new Promise((resolve, reject) => {
    axios({ ...options })
      .then(response => {
        const { data, status, config } = response
        // 二进制流文件下载
        if (data instanceof Blob) {
          resolve(response)
        } else if (status === 200 && data.status === '200') {
          resolve(data)
        } else {
          /**
           * 10004 用户无效
           * 10010 无权限时弹窗报错
           */
          if (data.status === '10010') {
            if (config.headers.token === 'e04924a6a985d282626fc25d67a59485') {
              const registerDialog = new RegisterDialog()
              registerDialog.$mount()
              document.body.appendChild(registerDialog.$el)
            } else {
              Message.error(data.message)
            }
          }
          reject(data)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 函数柯里化
const createApi = ({ method = 'get', url, baseURL = 'https://dt.oilchem.net', responseType }) => {
  return data => common({ url, method, data, baseURL, responseType })
}

export default createApi

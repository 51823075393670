<script>
import Common from '../common'
import { getSeasonAmplitude } from '@/apis/Breed/PriceCenter'
export default {
  mixins: [Common],
  props: {
    activeIndex: {
      type: [Number, String],
      default: 1
    },
    breedId: {
      // 传入breedId时优先
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      seriesData: [],
      barX: [],
      chartData: [],
      chartLoading: false
    }
  },
  watch: {
    activeIndex(newVal, val) {
      if (newVal !== val)
        setTimeout(() => {
          this.getData()
        }, 500)
    },
    breedId() {
      this.getData()
    }
  },
  mounted() {
    this.initEchart(document.getElementById('PriceSeasonalAmplitude'))
    this.getData()
  },
  methods: {
    async getData() {
      if (!this.breedId && !this.$route.query.id) return
      this.chartLoading = true
      const month = this.activeIndex ? this.activeIndex : '1'
      const { response } = await getSeasonAmplitude({
        queryField: {
          breedId: this.breedId || this.$route.query.id,
          month
        }
      })
      this.dataHandler(response)
    },
    dataHandler(response) {
      this.seriesData = []
      this.barX = []
      if (response && Object.keys(response).length > 0) {
        this.chartData = response
        if (response.amplitudeList.length > 0) {
          response.amplitudeList.forEach(item => {
            for (const key in item) {
              this.barX.push(key)
              this.seriesData.push((item[key] * 100).toFixed(2))
            }
          })
          this.myChart.hideLoading()
        } else {
          this.noneData()
        }
      } else {
        this.noneData()
      }
      this.setOption()
      this.chartLoading = false
    },
    setOption() {
      const { maxAmplitude, minAmplitude, avgAmplitude } = this.chartData
      const option = {
        title: {
          text: `最大振幅：${(maxAmplitude * 100).toFixed(2)}%\n最小振幅：${(
            minAmplitude * 100
          ).toFixed(2)}%\n平均振幅：${(avgAmplitude * 100).toFixed(2)}%`,
          textStyle: {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: 22
          },
          right: '2%',
          top: 'center'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: params => {
            return `${
              params[0].marker + params[0].seriesName
            }：<b class="chart-content style="float:right;margin-left:10px">${
              params[0].data ? `${params[0].data}%</b><br>` : '' + '</b><br>'
            }`
          }
        },
        grid: {
          top: '15%',
          bottom: '15%',
          right: '20%',
          confineLabel: true
        },
        xAxis: {
          type: 'category',
          axisLine: { show: this.seriesData.length > 0 },
          axisTick: { show: false },
          splitLine: { show: false },
          data: this.barX
        },
        yAxis: {
          type: 'value',
          axisLine: { show: this.seriesData.length > 0 },
          axisLabel: {
            formatter: value => {
              return `${value}%`
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '振幅',
            type: 'bar',
            barWidth: 20,
            itemStyle: {
              color: '#2263BD'
            },
            data: this.seriesData
          }
        ]
      }
      this.myChart.setOption(option)
    },
    noneData() {
      this.myChart.showLoading({
        text: '暂无数据',
        fontSize: '30',
        color: '#ffffff',
        textColor: '#8a8e91',
        maskColor: 'rgba(255, 255, 255, 0.8)'
      })
    }
  }
}
</script>

<template>
  <div id="PriceSeasonalAmplitude" v-loading="chartLoading" class="chart-container" />
</template>

<style lang="scss" scoped></style>

<script>
import Common from './common'
import { getData } from '@/apis/server'

export default {
  name: 'ButaneChnMonthlyOutput', // 炼厂加工量
  mixins: [Common],
  props: {
    // 是否初始化数据
    initData: {
      type: Boolean,
      default: false
    },
    axisLabelRoute: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      unitY: '',
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.AlkaneLineBarMix)
    this.initData && this.getData()
  },
  methods: {
    async getData() {
      this.chartLoading = true
      const { dataApi } = this.widget
      const query = {
        queryField: {
          ...this.queryData
        }
      }
      try {
        const { response } = await getData(dataApi, query)
        this.dataHandler(response)
      } catch (error) {
        this.errorHandler()
      }
    },
    /**
     * @description 处理数据的逻辑
     * @param {Object} response
     */
    dataHandler(response) {
      if (response?.length === 0) {
        this.errorHandler()
      }

      let xData = new Set()
      let series = [
        {
          name: response[0].indexName,
          type: 'bar',
          data: []
        },
        {
          name: '产量增速',
          type: 'line',
          data: []
        }
      ]
      this.unitY = response[0].unitName
      response.forEach(item => {
        const timeAxisData =
          this.queryData.frequencyName !== '周度'
            ? item.exhDate
            : this.$dayjs(item.researchStopDate).format('YYYY-MM-DD')
        xData.add(timeAxisData)

        series[0].data.push(item.inputValue)
        series[1].data.push(item.growthRate)
      })
      xData = Array.from(xData)

      if (series[0].yAxisIndex !== 0) {
        series = series.reverse()
      }
      this.setOption(series, [...xData])
    },
    setOption(series = [], xData) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        title: {
          show: false
        },
        grid: {
          top: this.amplify ? '10%' : '18%',
          bottom: '13%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：`
              if (e && e.data) {
                html += `${e.data[1] || '-'}${e.data[2] || ''}`
              } else {
                html += '-'
              }
              html += '<br>'
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            startValue: xData.length - 30
          }
        ],
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: [
          {
            type: 'value',
            name: this.unitY,
            nameTextStyle: {
              align: 'right'
            },
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            name: '%',
            nameTextStyle: {
              align: 'left'
            },
            scale: true,
            splitLine: {
              show: false
            }
          }
        ],
        series
      }

      // 判断横坐标标签是否需要倾斜
      if (this.axisLabelRoute) {
        this.option.xAxis.axisLabel = {
          rotate: -30
        }
        // 标签倾斜时需要给右边留出足够显示标签的空白
        this.option.grid.right = 36
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="AlkaneLineBarMix" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Author: bjp
 * @Description: 折柱混合
-->
<script>
export default {
  name: 'LineBarMix',
  components: {},
  props: {
    temp: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    let year = 2020
    const barX = []
    const dataListLine = []
    const dataListBar = [7, 6.9, 9.5, 14.5, 18.4, 25.2, 26.5, 23, 18, 13, 9, 16]
    for (let i = 1; i <= 12; i++) {
      if (i > 12) {
        i = 1
        year++
      }
      if (i < 10) i = `0${i}`
      barX.push(`${year}-${i}`)
    }
    dataListBar.forEach(item => {
      dataListLine.push(parseInt(item / 3))
    })
    const chartDom = document.querySelector('#capacityOrYield')
    const myChart = this.$echarts.init(chartDom, 'charttheme')

    const option = {
      title: {
        text: `丙烯${this.temp.name}`,
        x: 'center',
        top: 15,
        textStyle: {
          fontWeight: 'normal',
          fontSize: '14px'
        }
      },
      xAxis: {
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        type: 'category',
        data: barX
      },
      yAxis: [
        {
          type: 'value',
          min: 0,
          max: 30,
          splitLine: {
            show: false
          }
        },
        {
          type: 'value',
          min: 0,
          max: 15,
          axisLabel: {
            show: true,
            interval: 0,
            formatter: '{value}%'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        }
      ],
      dataZoom: [
        {
          type: 'slider',
          // left: '3%',
          // right: '3%',
          bottom: 50
        }
      ],
      tooltip: {
        confine: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        top: 52,
        bottom: 98
      },

      legend: {
        show: true,
        left: 'center',
        bottom: 10,
        textStyle: {
          color: '#121212',
          fontSize: 14
        },
        itemGap: 20, // 设置间距
        data: [
          {
            name: '产能',
            icon: 'rect'
          },
          {
            name: '产能增长率'
          }
        ]
      },
      series: [
        {
          name: '产能增长率',
          type: 'line',
          color: '#3A466E',
          yAxisIndex: 1,
          data: dataListLine
        },
        {
          name: '产能',
          type: 'bar',
          color: '#2263BD',
          data: dataListBar
        }
      ]
    }

    option && myChart.setOption(option)
    setTimeout(() => {
      window.onresize = function () {
        myChart.resize()
      }
    }, 200)
  },
  methods: {}
}
</script>

<template>
  <div id="capacityOrYield" class="breedSupplyCharts" />
</template>

<style lang="scss" scoped></style>

<script>
import Common from '../common'
export default {
  name: 'CompanyBarAd',
  components: {},
  mixins: [Common],
  props: {
    chartHeader: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      chartLoading: false,
      option: {}
    }
  },
  watch: {
    chartHeader: {
      deep: true,
      handler(val) {
        if (val.length) {
          const xArr = this.chartHeader.filter(m => m.label === m.prop).map(m2 => m2.label)
          this.unit = this.chartData.length ? this.chartData[0].prdCapUnit : ''
          this.setOption(xArr)
        }
      }
    }
  },
  mounted() {
    this.initEchart(this.$refs.CompanyBarAd)
    const xArr = this.chartHeader.filter(m => m.label === m.prop).map(m2 => m2.label)
    this.setOption(xArr)
    // this.getData()
  },
  methods: {
    setOption(xArr) {
      const series = []
      this.chartData.forEach(item => {
        const data = []
        for (const key in item) {
          // if(key !== 'prdCapUnit' && item !== 'process')
          if (xArr.includes(key)) {
            data.push([key, item[key]])
          }
        }
        series.push({
          name: item[this.type],
          type: 'bar',
          stack: 'Ad',
          data
        })
      })
      this.option = {
        title: {
          show: false
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `<div style="width:180px;display:flex;justify-content:space-between"><span>${
                e.marker
              }${e.seriesName}</span><b>${e.value[1]}${this.unit || ''}</b></div>`
            })
            return html
          }
        },
        legend: {
          show: true,
          width: '800px',
          bottom: '10px',
          type: 'scroll'
        },
        grid: {
          left: '3%',
          top: '3%',
          right: '4%',
          bottom: '13%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xArr
          }
        ],
        yAxis: [
          {
            type: 'value',
            max: this.unit === '%' ? '100' : null
          }
        ],
        series
      }
      this.downLoadChartTitle()
      this.$emit('chart-options', this.option)
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="CompanyBarAd" v-loading="tableLoading" class="chart-container" />
</template>

<style lang="scss" scoped></style>

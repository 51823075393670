<script>
import Common from './common'
import { getCrudeOilContinuous } from '@/apis/Breed/Supply'

export default {
  name: 'CrudeOilContinuous', // 原油折线图
  mixins: [Common],
  props: {
    temp: {
      type: Object,
      default: () => ({})
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      unitY: '',
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.CrudeOilLine)
  },
  methods: {
    async getData() {
      try {
        const { response } = await getCrudeOilContinuous(this.queryData, this.$route.meta.code)
        this.dataHandler(response)
      } catch (error) {
        this.errorHandler()
      }
    },
    /**
     * @description 处理数据的逻辑
     * @param {Object} response
     */
    dataHandler(response) {
      if (response?.length === 0) {
        this.errorHandler()
        return
      }
      const series = []
      const xData = new Set()
      this.unitY = Array.from(new Set(response.map(item => item.unitName)))[0]
      for (const value of response) {
        const timeAxisData = this.$dayjs(value.researchStopDate).format('YYYY-MM-DD')
        xData.add(timeAxisData)
        const index = series.findIndex(item => item.name === value.indexName)
        if (index === -1) {
          series.push({
            name: value.indexName,
            type: 'line',
            smooth: false,
            data: [[timeAxisData, value.inputValue, value.unitName]]
          })
        } else {
          series[index].data.push([timeAxisData, value.inputValue, value.unitName])
        }
      }
      // 如果只有一条线时显示蓝色
      if (series.length === 1) series[0].color = '#023985'
      this.setOption(series, [...xData])
    },
    setOption(series = [], xData) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        title: {
          text: this.$route.meta.title
        },
        grid: {
          top: this.magnifier || this.amplify ? '10%' : '18%',
          bottom: '23%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          bottom: '13%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%'
          }
        ],
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            name: this.unitY
          }
        ],
        series
      }

      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="CrudeOilLine" v-loading="chartLoading" class="chart-container" />
</template>

/*
 * @Description: 打开新标签页配置
 * @Date: 2021-11-15 15:13:04
 * @LastEditTime: 2021-12-16 09:31:06
 */
// 首页页签
export const TAB_HOME = {
  id: 'nenghuashouye',
  title: '能化综合分析'
}
// 数据排行的页签
export const TAB_RANK = {
  id: 'nenghuashujupaihangbang',
  title: '能化数据排行榜'
}
// 上市企业
export const TAB_COMPANY = {
  id: 'nenghuaqiye',
  title: '相关企业'
}
// 我的定制
export const TAB_MYCUSTOM = {
  id: 'longzongdingzhi',
  title: '隆众定制'
}
// 多产品对比
export const TAB_COMPARSE = {
  id: 'shujuduibi',
  title: '数据对比'
}

// 报告中心
export const TAB_REPORTCENTER = {
  id: 'baogaozhongxin',
  title: '报告中心'
}

/*
 * @Description:
 * @Date: 2021-08-02 08:47:59
 * @LastEditTime: 2023-05-25 15:31:11
 */
import { getData, getDataByGet } from '@/apis/server'

/**
 * @description: 市场价
 */
// 市场价-连续图多曲线对比列表表头
export const getMarketTableHeader = params =>
  getData('/api/price/marketPriceContrastListHeader', params)
// 市场价-连续图多曲线对比列表列表
export const getMarketTableList = params =>
  getData('/api/price/marketPriceContrastListBody', params)
// 市场价-连续图多曲线对比图
export const getMarketChart = params => getData('/api/price/marketPriceContrastList', params)
/**
 * @description: 企业价
 */
// 企业价-连续图多曲线对比列表表头
export const getCompanyTableHeader = params =>
  getData('/api/price/companyPriceContrastListHeader', params)
// 企业价-连续图多曲线对比列表列表
export const getCompanyTableList = params =>
  getData('/api/price/companyPriceContrastListBody', params)
// 企业价-连续图多曲线对比图
export const getCompanyChart = params => getData('/api/price/companyPriceContrastList', params)
/**
 * @description: 国际价
 */
// 国际价-连续图多曲线对比列表表头
export const getInternationalTableHeader = params =>
  getData('/api/price/internationalPriceContrastListHeader', params)
// 国际价-连续图多曲线对比列表列表
export const getInternationalTableList = params =>
  getData('/api/price/internationalPriceContrastListBody', params)
// 国际价-连续图多曲线对比图
export const getInternetChart = params =>
  getData('/api/price/internationalPriceContrastList', params)

/**
 * @description: 价格供需模块产品对比apis
 */
const compareUrl = '/api/productComparison/'
// 主流价
export const mainPriceComparseHeader = params =>
  getData(`${compareUrl}mainPriceComparisonHeader`, params)
export const mainPriceComparseList = params =>
  getData(`${compareUrl}mainPriceComparisonList`, params)
export const mainPriceComparseListGraph = params =>
  getData(`${compareUrl}mainPriceComparisonListGraph`, params)

// 毛利
export const profitComparseHeader = params => getData(`${compareUrl}profitComparisonHeader`, params)
export const profitComparseList = params => getData(`${compareUrl}profitComparisonList`, params)
export const profitComparseListGraph = params =>
  getData(`${compareUrl}profitComparisonListGraph`, params)

// 毛利率
export const profitRateComparseHeader = params =>
  getData(`${compareUrl}profitRateComparisonHeader`, params)
export const profitRateComparseList = params =>
  getData(`${compareUrl}profitRateComparisonList`, params)
export const profitRateComparseListGraph = params =>
  getData(`${compareUrl}profitRateComparisonListGraph`, params)

// 产量
export const cpCapComparseHeader = params => getData(`${compareUrl}cpCapComparisonHeader`, params)
export const cpCapComparseList = params => getData(`${compareUrl}cpCapComparisonList`, params)
export const cpCapComparseListGraph = params =>
  getData(`${compareUrl}cpCapComparisonListGraph`, params)

// 开工率
export const operateRateComparseHeader = params =>
  getData(`${compareUrl}operateRateComparisonHeader`, params)
export const operateRateComparseList = params =>
  getData(`${compareUrl}operateRateComparisonList`, params)
export const operateRateComparseListGraph = params =>
  getData(`${compareUrl}operateRateComparisonListGraph`, params)

// 产能
export const capacityComparseHeader = params =>
  getData(`${compareUrl}capacityComparisonHeader`, params)
export const capacityComparseList = params => getData(`${compareUrl}capacityComparisonList`, params)
export const capacityComparseListGraph = params =>
  getData(`${compareUrl}capacityComparisonListGraph`, params)

// 表观消费量
export const appConsumpComparseHeader = params =>
  getData(`${compareUrl}appConsumpComparisonHeader`, params)
export const appConsumpComparseList = params =>
  getData(`${compareUrl}appConsumpComparisonList`, params)
export const appConsumpComparseListGraph = params =>
  getData(`${compareUrl}appConsumpComparisonListGraph`, params)

// 进口量
export const imVolumeComparseHeader = params =>
  getData(`${compareUrl}imVolumeComparisonHeader`, params)
export const imVolumeComparseList = params => getData(`${compareUrl}imVolumeComparisonList`, params)
export const imVolumeComparseListGraph = params =>
  getData(`${compareUrl}imVolumeComparisonListGraph`, params)

// 出口量
export const exVolumeComparseHeader = params =>
  getData(`${compareUrl}exVolumeComparisonHeader`, params)
export const exVolumeComparseList = params => getData(`${compareUrl}exVolumeComparisonList`, params)
export const exVolumeComparseListGraph = params =>
  getData(`${compareUrl}exVolumeComparisonListGraph`, params)

// 库存
export const inventoryComparseHeader = params =>
  getData(`${compareUrl}inventoryComparisonHeader`, params)
export const inventoryComparseList = params =>
  getData(`${compareUrl}inventoryComparisonList`, params)
export const inventoryComparseListGraph = params =>
  getData(`${compareUrl}inventoryComparisonListGraph`, params)

// 产量数据频率
export const getYieldFrequency = params => getData('/api/widget/queryData/331', params)
// 开工数据率频率
export const getOperateFrequency = params => getData('/api/widget/queryData/332', params)
// 库存数据频率
export const getFactoryFrequency = params => getData('/api/widget/queryData/333', params)
// 表观消费量数据频率
export const getDemandFrequency = params => getData('/api/widget/queryData/334', params)

// 损失量

// 损失量-多品种对比曲线图的接口
export const getLossGraph = params => getData('/api/productComparison/lossGraph', params)
// 损失量-多品种对比表头
export const getLossHeader = params => getData('/api/productComparison/lossHeader', params)
// 损失量-多品种对比列表
export const getLossTable = params => getData('/api/productComparison/lossTable', params)
// 损失量-数据频率
export const getLossFrequency = params => getData('/api/widget/queryData/385', params)

// 产能 产量 开工率 产能利用率 库存 共用数据频率
export const getCommonFrequency = params =>
  getDataByGet('/api/breed/getFrequencyListByQuotaSampleIds', params)

// 产能利用率
export const utilizationComparseHeader = params =>
  getData(`${compareUrl}capacityUtilizationRateHeader`, params)
export const utilizationComparseList = params =>
  getData(`${compareUrl}capacityUtilizationRateList`, params)
export const utilizationComparseListGraph = params =>
  getData(`${compareUrl}capacityUtilizationRateGraph`, params)

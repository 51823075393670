<script>
import Common from '../common.js'
import { getCompanyProvinceChart, getTradeModeChart } from '@/apis/Breed/CustomsData.js'

export default {
  name: 'ProductionProcessComparison',
  mixins: [Common], // 中国进/出口（贸易方式）
  props: {
    unit: {
      type: String
    }
  },
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.ProductionProcessComparison)
  },
  methods: {
    getData() {
      let getChart
      if (this.$route.name.includes('TradeMode')) {
        // 贸易方式
        getChart = getTradeModeChart
      } else {
        // 企业注册地
        getChart = getCompanyProvinceChart
      }
      getChart(this.queryData)
        .then(res => {
          this.chartLoading = false
          this.myChart.clear()
          const { response } = res
          if (!response || response.data.length === 0) {
            this.errorHandler()
            return
          }
          this.setOption(response.data)
          this.myChart.hideLoading()
        })
        .catch(() => {
          this.errorHandler()
        })
    },
    setOption(data = []) {
      const chartData = data.map(item => {
        return { name: item.key, value: item.sum }
      })
      this.option = {
        title: {
          show: false
        },
        legend: {
          y: 'bottom',
          x: 'center'
        },
        tooltip: {
          confine: true,
          trigger: 'item',
          formatter: params => {
            const { data, marker } = params
            return `${marker} ${data.name} ${data.value} ${this.unit}`
          }
        },
        series: [
          {
            type: 'pie',
            data: chartData,
            label: {
              formatter: '{d}%'
            },
            radius: ['0%', '50%'],
            center: ['50%', '48%']
            // silent: true
          }
        ]
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="ProductionProcessComparison" v-loading="chartLoading" class="chart-container" />
</template>

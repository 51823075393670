<script>
import Common from './common'

export default {
  name: 'ProfitOfDifferentTechnology', // 不同工艺装置利润
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.ProfitOfDifferentTechnology)
  },
  methods: {
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      let barX = []
      let yAxis = []
      const seriesList = []
      const unitArr = []
      if (!this.$DataIsNotEmpty(response)) {
        this.errorHandler()
        return
      }

      Object.values(response).forEach(item => {
        barX = [
          ...new Set(
            item.map(val => {
              // 截取前两条单位做Y轴
              !unitArr.includes(val.profitUnit) &&
                unitArr.length < 2 &&
                unitArr.push(val.profitUnit)

              return val.dataDate.split(' ')[0]
            })
          )
        ].sort()
      })

      // 一个单位一条Y轴
      yAxis = unitArr.map((item, i) => {
        return {
          name: item,
          type: 'value',
          scale: true,
          splitNumber: 5,
          splitLine: {
            show: i === 0,
            lineStyle: {
              type: 'dashed'
            }
          }
        }
      })

      this.queryData.processIds.forEach(e => {
        Object.keys(response).forEach(res => {
          let index = unitArr.findIndex(v => v === response[res][0].profitUnit)
          index = index > -1 ? index : 0
          e === res &&
            seriesList.push({
              name: response[res][0].process,
              type: 'line',
              yAxisIndex: index,
              connectNulls: true,
              smooth: true,
              data: response[res]
                .sort(
                  (a, b) => this.$dayjs(a.dataDate).valueOf() - this.$dayjs(b.dataDate).valueOf()
                )
                .map(v => {
                  return [v.dataDate.split(' ')[0], v.profit, v.profitUnit]
                })
            })
        })
      })

      this.setOption(barX, seriesList, yAxis)
    },
    setOption(barX = [], seriesList = [], yAxis = []) {
      this.option = {
        title: {
          show: false
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}${
                e.value[1] ? e.value[2] || '' : ''
              }<br>`
            })
            return html
          }
        },
        legend: {
          top: '3%',
          type: 'scroll'
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: '3%',
            left: '5%',
            right: '3%'
          }
        ],
        grid: {
          bottom: '13%',
          right: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: barX,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis,
        series: seriesList
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option, true)

      if (yAxis.length === 1) return
      this.myChart.on('legendselectchanged', obj => {
        // 获取被取消的legend
        const cancelLegend = Object.keys(obj.selected).filter(v => !obj.selected[v])

        const option = this.myChart.getOption()
        let isLeftShow = true
        if (cancelLegend.length !== 0) {
          isLeftShow = option.series.every(v => {
            return v.yAxisIndex === 0 && cancelLegend.includes(v.name)
          })
        }

        option.yAxis[1].splitLine.show = !isLeftShow
        this.myChart.setOption(option)
        this.option = option
      })
    }
  }
}
</script>

<template>
  <div ref="ProfitOfDifferentTechnology" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Description: 企业-各工艺路线成本情况对比表格
 * @Date: 2022-04-20 14:09:57
 * @LastEditTime: 2022-05-10 14:11:25
-->
<script>
import Common from './common.js'
import { getDataByGet, getModularPermission } from '@/apis/server.js'
import { storage } from '@/utils/common'
export default {
  mixins: [Common],
  inject: ['breedList'],
  data() {
    return {
      tableLoading: false,
      tableData: []
    }
  },
  watch: {
    activeTag() {
      this.getData()
    }
  },
  created() {},
  methods: {
    getData() {
      // 存取数据频率，首页导出报告使用
      storage.setSession('enterpriseCostFrequency', this.queryData.frequencyName)
      this.tableLoading = true
      this.tableData = []
      const params = {
        companyId: this.companyId,
        breedId: this.breedId,
        frequencyName: this.queryData.frequencyName
      }
      const { dataApi } = this.widget
      getDataByGet(dataApi, params)
        .then(res => {
          res.response?.length && this.dataHandler(res.response)
        })
        .finally(() => (this.tableLoading = false))
    },
    dataHandler(data) {
      for (const [, item] of Object.entries(data)) {
        // 此时data数据已被改变 查询当前项所在的新下标
        const nowIndex = data.findIndex(v => item.companies[0] === v.companies[0])
        if (item.companies.length > 1) {
          for (const [index2, item2] of Object.entries(item.companies)) {
            if (Number(index2) === 0) continue
            // spanMethod: 需要合并列
            data.splice(Number(nowIndex) + 1, 0, { spanMethod: true, companies: [item2] })
          }
        }
      }
      this.tableData = data
    },
    spanMethod(data) {
      const { row, columnIndex } = data
      // 有多个companies时，需要合并列， columnIndex为4表示企业列 不需要合并
      if (row.companies.length > 1 && columnIndex !== 4) {
        return {
          rowspan: row.companies.length,
          colspan: 1
        }
      }
      // 有spanMehtod属性时不显示
      if (row.spanMethod && columnIndex !== 4) {
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    },
    // 跳转品种-企业-拟在建页面
    async goBreedCompany() {
      const params = {
        breedId: '197' || this.companyId,
        relationId: '26'
      }
      const { response } = await getModularPermission(params)
      if (!response) {
        this.$message.warning('本品种没有提供相关企业装置信息')
      }
      this.$openTab(
        `/breed/company/companyProAnalysis?id=${this.breedId}&name=${this.breedName}&modularName=${response}&type=1`,
        this.breedName,
        this.$md5(this.breedId + this.breedName)
      )
    }
  }
}
</script>

<template>
  <div id="enterpriseCompareCostTable" ref="enterpriseCompareCostTable">
    <div class="more-button" @click="goBreedCompany">查看更多<i class="el-icon-caret-right" /></div>
    <el-table
      ref="elTable"
      v-loading="tableLoading"
      :span-method="spanMethod"
      :data="tableData"
      border
      stripe
    >
      <el-table-column prop="showDate" label="日期" align="center" />
      <el-table-column prop="processName" label="工艺名称" align="center" />
      <el-table-column prop="sumConsElemCost" label="工艺平均生产成本" align="center" />
      <el-table-column prop="unit" label="单位" align="center" />
      <el-table-column prop="companies" label="代表性企业" align="center" width="350">
        <template #default="scope">
          <div v-if="scope.row.companies.length">
            {{ scope.row.companies[0] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
·

<style lang="scss" scoped>
@include tableMaxHeight(240px);
#enterpriseCompareCostTable {
  width: calc(100% - 2px);
  height: 100%;
  .more-button {
    display: block;
    float: right;
    font-size: 14px;
    color: #53adff;
    margin: 3px 0 6px;
    cursor: pointer;
  }
}
</style>

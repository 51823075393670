/*
 * @Description: 企业apis
 * @Date: 2021-06-23 09:51:47
 * @LastEditTime: 2024-06-11 11:19:27
 */
import { getData } from '@/apis/server'
const url = '/api/widget/queryCompanyDeviceData/57'
// 在产产业/拟在建/关停-表格数据
export const getCompanyDevice = params => getData(`${url}`, params)
// 中国地图
export const getChina = params => getData('/api/widget/queryData/269', params)
// 企业-在产/拟在建-综合分析-年份过滤器
export const getCompanyYearsList = params => getData('/api/breed/company/years', params)
// 企业-在产/拟在建-综合分析-区域/省份/工艺路线过滤器
export const getCompanyFilter = params => getData('/api/breed/company/filter', params)
// 企业-在产/拟在建-综合分析-图表
export const getChartTable = params => getData('/api/breed/company/chartTable', params)
// 按工艺/区域/省份
// 企业-在产/拟在建-动态列表(按工艺/区域/省份)[产能值]
export const getCapacityList = params => getData('/api/breed/company/queryCapacitySum', params)
// 企业-在产/拟在建-动态列表(按工艺/区域/省份)[百分比]
export const getCapacityRatioList = params =>
  getData('/api/breed/company/queryCapacityRatio', params)
// 企业-在产/拟在建-动态列表(按工艺/区域/省份)[饼图]
export const getCapacityPieList = params =>
  getData('/api/breed/company/queryCapacityPieChart', params)
// 按工艺分析-区域选择器
export const getAreaList = params => getData('/api/breed/company/queryDownList/513', params)
// 按区域/省份分析-工艺路线选择器
export const getProcessRouteList = params => getData('/api/breed/company/queryDownList/512', params)
// 企业-在产/拟在建-饼图表格数据
export const getPieTableList = params =>
  getData('/api/breed/company/queryCapacityPieChartTable', params)
// 企业-在产/拟在建-年份查询
export const getProcessYear = params => getData('/api/breed/company/dateRange', params)

<!--
 * @Author: bjp
 * @Description:企业-综合分析-内页中国地图
-->
<script>
import Common from '../common'
import geoJSON from '@/assets/map/china.json'
import { getChina } from '@/apis/Breed/Company'
export default {
  name: 'SupplyPattern',
  mixins: [Common],
  props: {
    magnifier: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: [],
      geoCoordMap: {},
      option: {}
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        if (this.chartData.length) {
          this.dataHandler2()
        } else {
          this.getData()
        }
      }
    }
  },
  mounted() {
    this.chartLoading = true
    this.$echarts.registerMap('china', { geoJSON })
    this.initEchart(this.$refs.SupplyPattern)
    if (this.chartData.length) {
      this.dataHandler2()
    } else {
      this.getData()
    }
  },
  methods: {
    async getData() {
      if (Object.keys(this.queryData).length > 0) {
        const { response } = await getChina(this.queryData)
        this.dataHandler(response)
      } else {
        this.dataHandler()
      }
    },
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      if (response && Object.keys(response).length) {
        response.data['分类明细'].forEach((item, index) => {
          this.geoCoordMap[item.value] = [
            response.data['企业经度'][index].value || 0,
            response.data['企业维度'][index].value || 0
          ]
          this.data.push({
            name: item.value,
            value: response.data['产能'][index].value
          })
        })
      } else {
        this.data = []
      }
      this.setOption()
      this.chartLoading = false
    },
    dataHandler2() {
      this.data = this.chartData.map(m => {
        return {
          name: m.companyName,
          value: [m.comLongitude, m.comDimension, m.capacity]
        }
      })
      this.setOption(true)
    },
    setOption(type) {
      this.option = {
        title: {
          show: false
        },
        tooltip: {
          confine: true,
          trigger: 'item'
        },
        visualMap: {
          left: this.magnifier ? '5%' : '20%',
          bottom: '10%',
          itemHeight: '260',
          align: 'right',
          orient: 'vertical',
          calculable: true,
          inverse: false,
          inRange: {
            color: ['#3DA23C', '#6BA23A', '#D8561A', '#3F5676']
          }
        },
        geo: [
          {
            map: 'china',
            zoom: 1.25,
            emphasis: {
              itemStyle: {}
            }
          }
        ],
        series: [
          {
            name: '产能',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: type ? this.data : this.convertData(this.data),
            encode: {
              value: 2
            },
            emphasis: {
              label: {
                show: true
              }
            }
          }
        ]
      }
      this.downLoadChartTitle()
      this.$emit('chart-options', this.option)
      this.myChart.setOption(this.option)
    },
    // 数据处理
    convertData(data) {
      const res = []
      for (let i = 0; i < data.length; i++) {
        const geoCoord = this.geoCoordMap[data[i].name]
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value)
          })
        }
      }
      return res
    }
  }
}
</script>

<template>
  <div ref="SupplyPattern" v-loading="tableLoading" class="chart-container" />
</template>

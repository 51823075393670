<!--
 * @Description:企业-现有产能
 * @Date: 2022-04-20 13:58:54
 * @LastEditTime: 2022-05-10 14:32:03
-->
<script>
import Common from './common.js'
import { getDataByGet } from '@/apis/server.js'
export default {
  mixins: [Common],
  data() {
    return {
      breedChange: true,
      listData: [],
      percent: null
    }
  },
  watch: {
    activeTag() {
      this.getData()
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.listData = []
      this.percent = null
      const params = {
        companyId: this.companyId,
        breedId: this.breedId
      }
      const { dataApi } = this.widget
      getDataByGet(dataApi, params).then(({ response }) => {
        response.length && this.dataHandler(response)
      })
    },
    dataHandler(data) {
      const [percent, ...listData] = data.reverse()
      this.percent = percent?.proportion
      this.listData = listData
    }
  }
}
</script>

<template>
  <div id="EnterpriseExistCapacity" class="company-textline">
    <ul v-for="item in listData" :key="item.process" class="company-textline-item">
      <li>
        {{ item.deviceName }}： <span class="red-text">{{ item.capacity }}</span
        >{{ item.prdCapUnit }}
      </li>
      <li>
        工艺路线：<span class="red-text">{{ item.process }}</span>
      </li>
    </ul>
    <div v-if="percent !== null" class="company-textline-percent">
      占全国总产能的<span class="red-text">{{ percent }}%</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/styles/enterprise/companyText.scss';
</style>

<!--
 * @Description: 企业-主营品种理论产值排行
 * @Date: 2022-04-14 13:42:31
 * @LastEditTime: 2022-04-19 14:25:12
-->
<script>
import Common from './common.js'
export default {
  name: 'MainBreedOutputValue', // 主营品种理论产值排行
  mixins: [Common],
  data() {
    return {}
  },
  mounted() {
    this.initEchart(this.$refs.MainBreedOutputValue)
  },
  methods: {
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      const { data } = response
      if (data.length) {
        this.setOption(data)
      } else {
        this.errorHandler()
      }
    },
    setOption(data) {
      const option = {
        grid: {
          top: '15%',
          right: '6%',
          bottom: '15%'
        },
        color: '#023985',
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: -30
          }
        },
        yAxis: {
          name: '万元',
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          formatter(param) {
            const { breedName, outputValue, unitName } = param[0].value
            return `${breedName}<br>${param[0].marker}${outputValue || '-'}${unitName || ''}`
          }
        },
        dataZoom: [
          {
            type: 'inside',
            startValue: 0,
            endValue: 20
          },
          {
            type: 'slider',
            startValue: 0,
            endValue: 20
          }
        ],
        dataset: {
          source: data
        },
        series: [
          {
            type: 'bar',
            encode: { x: 'breedName', y: 'outputValue' },
            color: '#023985'
          }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div ref="MainBreedOutputValue" v-loading="chartLoading" class="chart-container" />
</template>

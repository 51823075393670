<script>
import Common from './common'

const key = 'supply_trend'
export default {
  name: 'TrendOfSupply', // 工艺趋势
  mixins: [Common],
  props: {
    listDictData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dataX: [],
      chartLoading: false,
      chartSeries: [],
      chartYAxis: [],
      unitY: '',
      option: {}
    }
  },
  watch: {
    queryData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) this.getBarX()
      }
    }
  },
  mounted() {
    this.initEchart(this.$refs.TrendOfSupply)
    this.getBarX()
    this.chartLoading = true
  },
  methods: {
    setOption() {
      this.option = {
        title: {
          show: false
        },
        legend: {
          top: '3%',
          type: 'scroll'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].name}<br/>`
            for (let i = 0; i < params.length; i++) {
              html += `${
                params[i].marker + params[i].seriesName
              }：<b class="chart-content style="float:right;margin-left:10px">${
                params[i].value[1] || params[i].value[1] === 0
                  ? `${params[i].value[1]} ${params[i].value[2] ? params[i].value[2] : ''}</b><br>`
                  : '-' + '</b><br>'
              }`
            }
            return html
          }
        },
        xAxis: [
          {
            type: 'category',
            data: this.$filterValue(this.chartData['日期'], 'YYYY-MM-DD'),
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        grid: {
          left: '3%',
          right: '3%',
          bottom: '13%',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'inside',
            startValue: this.chartData['日期'].length - 30
          },
          {
            type: 'slider',
            bottom: '3%',
            left: '5%',
            right: '5%'
          }
        ],
        yAxis: this.chartYAxis,
        series: this.chartSeries
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option, true)
    },
    getBarX() {
      if (this.listDictData[key] && this.listDictData[key].length > 0 && this.queryData[key]) {
        this.dataX = []
        this.listDictData[key].forEach(item => {
          this.queryData[key].forEach(child => {
            if (item.dictValue === child) this.dataX.push(item.dictLabel)
          })
        })
      }
    },
    //* 处理数据的逻辑
    dataHandler(response) {
      this.myChart.clear()
      if (response) {
        this.chartData = response.data
        this.chartSeries = []
        this.chartYAxis = []
        // 处理单位
        this.handleUnit()
        this.unitY = this.$filterValue(this.chartData['产量单位'])[0]
        this.setOption()
        this.myChart.hideLoading()
      } else {
        this.errorHandler()
      }
      this.chartLoading = false
    },
    /**
     * 处理单位
     */
    handleUnit() {
      this.dataX.forEach((item, i) => {
        if (item === '价格') {
          this.chartYAxis.push({
            type: 'value',
            scale: true,
            name: this.$filterValue(this.chartData['价格单位']).filter(item => item != null)[0]
              ? this.$filterValue(this.chartData['价格单位']).filter(item => item != null)[0]
              : '价格'
          })
        }
        if (item === '开工率' || item === '产能利用率') {
          this.chartYAxis.push({
            type: 'value',
            scale: true,
            name: '%',
            axisLabel: {
              formatter: '{value}%'
            }
          })
        }
        let temp = {
          name: item.toString(),
          type: 'bar',
          yAxisIndex: 0,
          data:
            item === '开工率' || item === '价格' || item === '产能利用率'
              ? []
              : this.chartData[item].map((v, i) => [
                  i,
                  v.value,
                  this.chartData[`${item}单位`][i].value
                ])
        }
        if (item === '开工率') {
          temp.data = this.chartData[`${item}(%)`].map((v, i) => [i, v.value, '%'])
          temp = Object.assign(temp, {
            type: 'line',
            connectNulls: true,
            yAxisIndex: i > 1 ? (this.dataX.length > 1 ? 1 : 0) : i
          })
        } else if (item === '产能利用率') {
          temp.data = this.chartData[item].map((v, i) => [i, v.value, '%'])
          temp = Object.assign(temp, {
            type: 'line',
            connectNulls: true,
            yAxisIndex: i > 1 ? (this.dataX.length > 1 ? 1 : 0) : i
          })
        } else if (item === '价格') {
          temp.data = this.chartData[item].map((v, i) => [
            i,
            v.value,
            this.chartData[`${item}单位`][i].value
          ])
          temp = Object.assign(temp, {
            type: 'line',
            connectNulls: true,
            yAxisIndex: i > 1 ? (this.dataX.length > 1 ? 1 : 0) : i
          })
        } else if (item !== '产能') {
          temp = Object.assign(temp, {
            stack: '量'
          })
        }
        this.chartSeries.push(temp)
      })
      const flag = this.dataX.some(item => {
        return item === '产能' || item === '产量' || item === '新增产能'
      })
      if (flag) {
        this.chartYAxis.unshift({
          type: 'value',
          name: this.$filterValue(this.chartData['产量单位']).filter(item => item != null)[0]
            ? this.$filterValue(this.chartData['产量单位']).filter(item => item != null)[0]
            : ''
        })
      }
    }
  }
}
</script>

<template>
  <div ref="TrendOfSupply" v-loading="chartLoading" class="chart-container" />
</template>

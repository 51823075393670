import createApi from '@/apis/http'

export const getData = (url, query) => createApi({ url, method: 'post' })(query)
export const getDataByGet = (url, query) => createApi({ url, method: 'get' })(query)
export const getBlobFile = (url, query, responseType = 'blob') =>
  createApi({ url, method: 'post', responseType })(query)
// 获取中国地图信息
// export const getGEO = createApi({
//   url: 'http://geo.datav.aliyun.com/areas_v2/bound/100000.json'
// })

// 根据类型和ID查询对应的面板配置信息
export const getLayoutJson = createApi({ url: '/api/layout/getLayoutJson' })
// 查询品种价格
// export const breedHomeData = createApi({ url: '/api/home/breedHomeData' })
// 查询品种价格
export const breedHomeDataNew = createApi({ url: '/api/home/breedHomeDataNew' })

// 查询时间格式
export const timeAxis = createApi({ url: '/api/widget/timeAxis' })

// 查询该品种有出权限 true 有 false 无
export const getExportPermission = createApi({
  url: '/api/userInfo/checkUserExportPermission'
})

// 查询上下游品种
export const getUpDownBreed = query =>
  createApi({ url: '/api/common/getUpDwonBreedByToken' })(query)

// 量价关系
export const quantityPrice = createApi({
  url: '/api/widget/queryData/quantityPrice',
  method: 'post'
})

// 内页量价关系
export const innerQuantityPrice = createApi({
  url: '/api/widget/queryData/inner/quantityPrice',
  method: 'post'
})

// 能源首页-频道导航树状图
export const listTree = createApi({ url: '/api/channel/navigation/listTree' })

// 能源首页-判断有几年的权限
export const judgePower = createApi({
  url: '/api/userInfo/getPermissionYears',
  method: 'post'
})

// 能源首页-频道导航名称模糊搜索
export const listTreeQueryByName = query =>
  createApi({ url: '/api/channel/navigation/queryByName' })(query)

// 能源首页-行业筛选器
// export const channelSelector = createApi({ url: '/api/home/channel/selector' })

// 能源首页-价格排行榜-表格
// export const priceRank = createApi({ url: '/api/home/price/rank' })

// 能源首页-价格排行榜-柱状图
// export const priceChangeRateProportion = createApi({
//   url: '/api/home/price/changeRateProportion'
// })

// 能源首页-利润涨跌幅-表格
// export const profitRank = createApi({ url: '/api/home/profit/rank' })

// 能源首页-利润涨跌幅-柱状图
// export const profitChangeRateProportion = createApi({
//   url: '/api/home/profit/changeRateProportion'
// })

// 能源首页-价格振幅-表格
export const priceAmplitude = createApi({ url: '/api/home/price/amplitude' })

// 能源首页-价格振幅-柱状图
// export const priceAmplitudeProportion = createApi({
//   url: '/api/home/price/amplitudeProportion'
// })

// 能源首页-产品综合评价
export const productComprehensiveEvaluation = createApi({
  url: '/api/home/product/comprehensiveEvaluation'
})

// 能源首页-风口产品跳转查询
// export const queryHotProductBreed = query =>
//   createApi({
//     url: '/api/channel/getModularPermissionByBreedId',
//     method: 'post'
//   })(query)

// 通过品种获取品种模块modularName
export const getModularNameByBreedId = query =>
  createApi({ url: '/api/channel/getModularPermissionByBreedId' })(query)

// 通过企业code获取品种模块modularName
export const getModularNameByCompanyCode = query =>
  createApi({ url: '/api/channel/getModularPermissionBySecurityCode' })(query)

// 通过品种获取品种模块modularName
export const getModularName = createApi({
  url: '/api/channel/getModularPermissionByBreedId'
})

// 文章鉴权
export const getModularPermissionByArticleId = createApi({
  url: '/api/article/getModularPermissionByArticleId'
})
// 文章详情
export const articleReportDetail = createApi({
  url: '/api/article/report/detail'
})

// 品种与配置关联表
// export const navigationRelationListPage = createApi({ url: '/api/breednavigationrelation/list_page' })
// 品种与配置关联表
export const navigationDictFullTreeRelation = createApi({
  url: '/api/breednavigationdict/fullTreeRelation'
})

export const homeReportList = createApi({ url: '/api/home/homeReportList' })

// 供应趋势获取频率
export const getFrequencyBySupplyTrend = query =>
  createApi({ url: '/api/dict/getFrequencyBySupplyTrend', method: 'post' })(query)

// 判断此品种是否在品种页面配置了某模块
export const getModularPermission = query =>
  createApi({ url: '/api/company/getModularPermission', method: 'get' })(query)

// 根据breedId和工艺id查询频度
export const queryFrequencyByBreedAndProcess = query =>
  createApi({ url: '/api/common/queryFrequencyByBreedAndProcess', method: 'post' })(query)

/**
 * 通用-导出功能
 * @param {Object} params
 * @param {string} params.fileName 表格名称
 * @param {Object} params.sorted 排序方式
 * @param {asc|desc} params.sorted[dataDate] 排序字段
 * @param {array} params.column
 * @param {array} params.data
 * @returns {Promise | Promise<unknown>}
 */
export const universalExport = params =>
  createApi({ url: '/api/generic/universal/Export', method: 'post', responseType: 'blob' })(params)

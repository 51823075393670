<script>
import Common from './common.js'
export default {
  name: 'EnterpriseProportion', // 产业规模与企业占比
  mixins: [Common],
  data() {
    return {
      data: []
    }
  },
  watch: {
    chartActive() {
      this.setOption()
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#EnterpriseProportion'))
  },
  methods: {
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      this.data = response.data
      this.setOption()
    },
    setOption() {
      // 清空画布
      this.myChart.clear()
      const data = this.data
      if (data?.length === 0) {
        this.errorHandler()
        return
      }
      let yAxis = {}
      if (this.chartActive === 0) {
        yAxis = {
          max: 100,
          interval: 25,
          axisLabel: {
            formatter: '{value}%'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        }
      } else {
        yAxis = {
          axisLabel: {
            formatter: '{value}%'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        }
      }
      const option = {
        grid: {
          top: '12%',
          right: '6%',
          bottom: '15%'
        },
        color: '#023985',
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: -30
          }
        },
        yAxis,
        tooltip: {
          trigger: 'axis',
          confine: true,
          formatter(param) {
            const {
              breedName,
              comCapacity,
              comUnit,
              industryCapacity,
              industryUnit,
              industryRatio
            } = param[0].value
            return `<div style="color:#BA0C2F">${breedName}</div>
                  企业产能：${comCapacity || '-'}${comUnit || ''}<br>
                  行业产能：${industryCapacity || '-'}${industryUnit || ''}<br>
                  企业占比：${industryRatio || '-'}%
                 `
          }
        },
        dataZoom: [
          {
            type: 'inside',
            startValue: 0,
            endValue: 10
          },
          {
            type: 'slider',
            startValue: 0,
            endValue: 10
          }
        ],
        dataset: {
          source: data
        },
        series: [
          {
            type: 'bar',
            encode: { x: 'breedName', y: 'industryRatio' },
            showBackground: !this.chartActive,
            backgroundStyle: {
              show: false,
              color: '#fff',
              borderColor: '#BFBFBF',
              borderWidth: 1
            }
          }
        ]
      }
      this.myChart.setOption(option)

      // 百分比柱状图计算grid高度便宜label
      if (this.chartActive === 0) this.setLabel()
    },
    setLabel() {
      // 是否已经添加label
      let isSetLabel = false
      const opt = this.myChart.getOption()
      const setLabel = () => {
        if (!this.myChart) return
        const height = this.myChart.getHeight()
        let top = opt.grid[0].top
        let bottom = opt.grid[0].bottom
        top = (height * parseInt(top)) / 100
        bottom = (height * parseInt(bottom)) / 100
        const y = height - bottom - top - 35
        isSetLabel = true
        this.myChart.setOption({
          series: [
            {
              label: {
                show: true,
                position: 'bottom',
                offset: [0, -y],
                formatter: '{percentage|{@[4]}%}',
                rich: {
                  percentage: {
                    color: '#BC0008',
                    fontSize: 12,
                    fontWeight: 400
                  }
                }
              }
            }
          ]
        })
      }
      this.myChart.on('finished', () => {
        if (this.chartActive === 1 || isSetLabel) return
        setLabel()
      })
      window.addEventListener('resize', () => {
        if (this.chartActive === 1) return
        setLabel()
      })
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', () => {
          setLabel()
        })
      })
    }
  }
}
</script>

<template>
  <div id="EnterpriseProportion" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from '../common.js'
import { getHistoryChart } from '@/apis/Breed/analysisForecast.js'
import { getNumberOfString } from '@/utils/common'
export default {
  name: 'HistoryPriceCompare', // 历史价格对照分析
  mixins: [Common],
  props: {
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      unit: '',
      priceType: '主流价',
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.HistoryPriceCompare)
  },
  methods: {
    getData() {
      getHistoryChart(this.queryData)
        .then(res => {
          this.chartLoading = false
          this.myChart.hideLoading()
          this.myChart.clear()
          const data = res.response || []
          if (!data || data.length === 0) this.errorHandler()
          this.unit = data[0].dataUnit
          this.setOption(data)
        })
        .catch(() => {
          this.errorHandler()
        })
    },
    setOption(data = []) {
      // 取key值，日期第一个，一次是年份，最大值，平均值，最小值
      let unit = ''
      const dateArr = []
      data.forEach(e =>
        e.data.sort((a, b) => getNumberOfString(a.exhDate) - getNumberOfString(b.exhDate))
      )
      const series = data.map(e => {
        const item = {
          name: e.name,
          type: 'line',
          smooth: true,
          symbol: 'none',
          color: '#E28C1B',
          lineStyle: {
            width: 2
          },
          emphasis: {
            lineStyle: {
              width: 2
            }
          },
          data: e.data.map(v => {
            const timeAxisData =
              this.queryData.frequencyName !== '周度'
                ? v.exhDate
                : this.$dayjs(v.researchStopDate).format('YYYY-MM-DD')

            dateArr.push(timeAxisData)
            if (v.dataUnit && !unit) unit = v.dataUnit
            return [timeAxisData, v.mainPrice]
          })
        }
        if (e.name.includes('最大值')) {
          item.color = '#6899DD'
          item.lineStyle = {
            width: 2
          }
          item.areaStyle = {
            color: '#BFBFBF'
          }
        } else if (e.name.includes('最小值')) {
          item.color = '#3A466E'
          item.lineStyle = {
            width: 2
          }
          item.areaStyle = {
            color: '#ffffff',
            opacity: 1
          }
        } else if (e.name.includes('均值')) {
          item.color = '#2263BD'
          item.lineStyle = {
            type: 'dashed',
            width: 2
          }
        }
        return item
      })
      const Xdata = [...new Set(dateArr)].sort(
        (a, b) => getNumberOfString(a) - getNumberOfString(b)
      )
      this.option = {
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: '3%'
          }
        ],
        title: {
          text: this.chartTitle,
          left: 'center',
          top: '3%',
          textStyle: {
            fontSize: '14px',
            fontWeight: 'normal'
          }
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}${
                e.value[1] ? unit : ''
              }<br>`
            })
            return html
          }
        },
        grid: {
          top: this.magnifier ? '8%' : '17%',
          left: '2%',
          right: '2%',
          bottom: '20%',
          containLabel: true
        },
        legend: {
          bottom: '10%'
        },
        xAxis: {
          type: 'category',
          data: Xdata,
          axisTick: {
            alignWithLabel: true
          },
          boundaryGap: true
        },
        yAxis: {
          name: unit,
          type: 'value',
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series,
        color: ['#6899DD', '#2263BD', '#3A466E', '#E28C1B']
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="HistoryPriceCompare" v-loading="chartLoading" class="chart-container" />
</template>

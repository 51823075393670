<script>
import SideBar from '@/components/SideBar'
import AppMain from '@/components/AppMain'

export default {
  name: 'Layout',
  components: { SideBar, AppMain }
}
</script>

<template>
  <div class="app-wrapper">
    <side-bar />
    <app-main />
  </div>
</template>

<style lang="scss" scoped>
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
}
</style>

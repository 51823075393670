<!--
 * @Author: bjp
 * @Description:中国供需平衡-表格
-->
<script>
import Common from './common'
import { formatDayJsTime } from '@/utils/formatData'
export default {
  name: 'SupplyDemandBalanceTable',
  mixins: [Common],
  props: {
    interactors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableData: []
    }
  },
  methods: {
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      this.tableData = []
      if (response) {
        let formatTime = 'YYYY-MM-DD'
        formatTime = formatDayJsTime(this.queryData.frequencyName)
        response.data['日期'].forEach((item, index) => {
          this.tableData.push({
            日期: this.$dayjs(item.value).format(formatTime),
            期初库存: response.data['期初库存'][index].value,
            国内产量: response.data['国内产量'][index].value,
            进口量: response.data['进口量'][index].value,
            总供应量: response.data['总供应量'][index].value,
            消费量: response.data['消费量'][index].value,
            出口量: response.data['出口量'][index].value,
            总需求量: response.data['总需求量'][index].value,
            供需差: response.data['供需差'][index].value,
            供需比: response.data['供需比'][index].value,
            期末库存: response.data['期末库存'][index].value
          })
        })
      }
      this.chartLoading = false
    }
  }
}
</script>

<template>
  <div class="PatternTable">
    <el-table v-loading="chartLoading" :data="tableData" stripe height="290px">
      <el-table-column prop="日期" align="center" width="100" />
      <el-table-column prop="期初库存" label="期初库存" align="center" />
      <el-table-column prop="国内产量" label="国产" align="center" />
      <el-table-column prop="进口量" label="进口" align="center" />
      <el-table-column prop="总供应量" label="总供应" align="center" />
      <el-table-column prop="消费量" label="消费" align="center" />
      <el-table-column prop="出口量" label="出口" align="center" />
      <el-table-column prop="总需求量" label="总需求" align="center" />
      <el-table-column prop="供需差" label="供需差" align="center" />
      <el-table-column prop="供需比" label="供需比" align="center" />
      <el-table-column prop="期末库存" label="期末库存" align="center" />
    </el-table>
  </div>
</template>

<style lang="scss" scoped>
.PatternTable {
  overflow: hidden;
  .interactors {
    display: flex;
    justify-content: flex-end;
  }
}
// :deep.el-table {
//   .gutter {
//     width: 0;
//     display: table-cell !important;
//   }
// }
</style>

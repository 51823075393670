<script>
import dayjs from 'dayjs'
import Common from './common'
import { getData } from '@/apis/server'
export default {
  name: 'ContinuousGraphCommon', // 通用连续图
  mixins: [Common],
  mounted() {
    this.initEchart(document.querySelector('#ContinuousGraphCommon'))
  },
  methods: {
    async getData() {
      const { dataApi } = this.widget
      const query = {
        queryField: {
          breedId: this.id,
          frequency: '月度',
          imExType: '进口'
        }
      }
      const { response } = await getData(dataApi, query)
      this.dataHandler(response)
    },
    dataHandler(response) {
      if (response && response.data) {
        const xAxisData = response.data['时间'].map(item => dayjs(item.value).format('YYYYMMDD'))
        const seriesData = response.data['进出口数量'].map(({ name, value }) => ({
          name: dayjs(name).format('YYYYMMDD'),
          value
        }))
        this.setOption(xAxisData, seriesData)
      }
    },
    setOption(xAxisData = [], seriesData = []) {
      const option = {
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: 20
          }
        ],
        legend: {},
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: xAxisData,
          axisLabel: {
            interval: 0,
            rotate: -45
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: 'PTA',
            type: 'line',
            smooth: true,
            data: seriesData
          }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div id="ContinuousGraphCommon" class="chart-container" />
</template>

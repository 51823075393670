<!--
 * @Author: bjp
 * @Description: 折线不同区域不同颜色
-->
<script>
import Common from '../common'
export default {
  name: 'LineArea',
  mixins: [Common],
  props: {
    chartData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartLoading: false,
      option: {}
    }
  },
  watch: {
    chartData: {
      handler() {
        this.setOption()
      },
      deep: true
    }
  },
  mounted() {
    this.initEchart(this.$refs.industryConcentration)
    this.setOption()
  },
  methods: {
    // 初始化图表
    setOption() {
      this.chartLoading = false
      this.myChart.clear()
      if (!this.chartData || this.chartData.length === 0) {
        this.errorHandler()
        return
      }
      this.myChart.hideLoading()
      let maxY = 6000
      const chartDataCopy = JSON.parse(JSON.stringify(this.chartData))
      chartDataCopy.sort((a, b) => {
        return a.dataDate.replace(/[^0-9]/gi, '') - b.dataDate.replace(/[^0-9]/gi, '')
      })
      const data = chartDataCopy.map(item => {
        if (item.hhi > maxY) maxY = (item.hhi * 1.2).toFixed(0)
        return [item.dataDate, item.hhi]
      })
      this.option = {
        title: {
          show: false,
          // text: '丙烯' + this.temp.name,
          x: 'center',
          top: 15,
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        xAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          type: 'category'
        },
        yAxis: [
          {
            scale: true,
            type: 'value',
            min: 0,
            max: 6000,
            // splitNumber: 6,
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
                color: 'rgba(255,255,255,1)'
              }
            }
          }
        ],
        dataset: {
          source: data
        },
        color: '#2464BD',
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        dataZoom: [
          {
            show: false
          }
        ],
        grid: {
          top: '6%',
          bottom: '5%',
          left: '1%',
          right: '1%',
          containLabel: true
        },
        series: [
          {
            name: 'HHI',
            type: 'line',
            symbol: 'none',
            markArea: {
              silent: true,
              label: {
                position: 'inside',
                color: '#fff',
                fontSize: 14
              },
              data: [
                [
                  {
                    name: '高寡占I型',
                    yAxis: maxY,
                    itemStyle: {
                      color: 'rgba(58, 70, 110, .6)'
                    }
                  },
                  {
                    yAxis: '3000'
                  }
                ],
                [
                  {
                    name: '高寡占II型',
                    yAxis: '3000',
                    itemStyle: {
                      color: 'rgba(58, 70, 110, .5)'
                    }
                  },
                  {
                    yAxis: '1800'
                  }
                ],
                [
                  {
                    name: '低寡占型',
                    yAxis: '1800',
                    itemStyle: {
                      color: 'rgba(58, 70, 110, .4)'
                    }
                  },
                  {
                    yAxis: '1000'
                  }
                ],
                [
                  {
                    name: '竞争I型',
                    yAxis: '1000',
                    itemStyle: {
                      color: 'rgba(58, 70, 110, .3)'
                    }
                  },
                  {
                    yAxis: '500'
                  }
                ],
                [
                  {
                    name: '竞争II型',
                    yAxis: '500',
                    itemStyle: {
                      color: 'rgba(58, 70, 110, .2)'
                    }
                  },
                  {
                    yAxis: '0'
                  }
                ]
              ]
            }
          }
        ]
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="industryConcentration" v-loading="chartLoading" class="breedSupplyCharts" />
</template>

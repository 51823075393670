import dayjs from 'dayjs'
/**
 * 日度展示需要的天数
 */
const getFullYearDay = (function () {
  let monthDay = ''
  const allYearDay = []
  for (let i = 1; i <= 12; i++) {
    switch (i) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        monthDay = 31
        break
      case 4:
      case 6:
      case 9:
      case 11:
        monthDay = 30
        break
      case 2:
        monthDay = 29
        break
      default:
        break
    }
    for (let j = 1; j <= monthDay; j++) {
      const month = i < 10 ? `0${i}` : i
      const day = j < 10 ? `0${j}` : j
      // new Date().getFullYear() + '-' +
      allYearDay.push(`${month}-${day}`)
    }
  }
  return allYearDay
})()
/**
 * 12个月份
 */
const getFullMonth = (function () {
  const arr = []
  for (let i = 1; i <= 12; i++) {
    arr.push(i < 10 ? `0${i}` : i)
  }
  return arr
})()
/**
 * 根据年月日度返回格式化格式
 */
const formatDayJsTime = function (time = '', flag = false) {
  if (flag) {
    if (time === '月度') return 'MM月'
    else if (time === '周度' || time === '日度') return 'MM-DD'
  }
  switch (time) {
    case '日度':
    case '周度':
    case '':
      return 'YYYY-MM-DD'
    case '年度':
      return 'YYYY'
    case '月度':
    case '季度':
      return 'YYYY-MM'
    default:
      return time
  }
}
export default {
  install(Vue) {
    /**
     *
     * @param {*} num
     * @returns 数字格式化为千分位
     */
    Vue.prototype.$formatNum = num => {
      return `${num}`.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    }
    /**
     *
     * @param {*} row
     * @param {*} column
     * @returns 图表数据格式化
     */

    Vue.prototype.$filterValue = (data, time = '') => {
      if (data && data.length > 0) {
        const arr = []
        let format = time
        data.forEach(item => {
          let value = item.value
          if (time) {
            format = formatDayJsTime(time)
            value = dayjs(value).format(format)
          }
          arr.push(value)
        })
        return arr
      }
    }
    /**
     *
     * @param {*} response 接口返回的数据
     * @param {*} frequencyName 时间格式化
     * @param {*} valueType 展示值的键
     * @param {*} valueName 每条线的name
     * @returns series数据和x轴的数据barX
     */
    Vue.prototype.$formatGetData = ({
      response,
      frequencyName = '',
      valueType,
      valueName = '',
      smooth = true
    }) => {
      const dataListLine = []
      let barX = []
      let formatTime = frequencyName
      formatTime = formatDayJsTime(frequencyName)
      for (const key in response) {
        const arr = []
        response[key].forEach(item => {
          arr.push([dayjs(item.dataDate).format(formatTime), item[valueType]])
          barX.push(dayjs(item.dataDate).format(formatTime))
        })
        const temp = {
          name: valueName ? response[key][0][valueName] : key,
          type: 'line',
          smooth,
          data: arr
        }
        dataListLine.push(temp)
      }

      dataListLine.sort((a, b) => {
        return a.name.replace(/[^0-9]/gi, '') - b.name.replace(/[^0-9]/gi, '')
      })
      barX = [...new Set(barX)].sort()
      return {
        dataListLine,
        barX
      }
    }
    /**
     * @description: 判断接口返回是否有值
     * @param {any} data
     * @return {boolean} true 有  false 没有值
     */

    Vue.prototype.$DataIsNotEmpty = data => {
      // 是否为引用类型
      if (data !== null && typeof data === 'object') {
        if (Array.isArray(data)) {
          return data && data.length > 0
        } else {
          return data && Object.keys(data).length > 0
        }
      } else {
        return data
      }
    }
    /**
     * @description: 判断列表内数值的涨跌
     * @param {any}
     * @return {Object}
     */
    Vue.prototype.$getCellStyle = ({ row, column }) => {
      const arr = ['dataRiseOrFall', 'dataRate', 'priceRate']
      if (arr.includes(column.property)) {
        const num = parseFloat(row[column.property])
        if (!isNaN(num)) {
          if (num < 0) {
            return {
              color: 'green'
            }
          } else if (num > 0) {
            return {
              color: 'red'
            }
          }
        }
      }
    }
  }
}
export { getFullYearDay, getFullMonth, formatDayJsTime }

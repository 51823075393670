<script>
import Common from './common'
import { getData } from '@/apis/server'

export default {
  name: 'Profit', // 利润
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.Profit)
  },
  methods: {
    async getData() {
      let interval = 'day'
      switch (this.queryData.frequencyName) {
        case '年度':
          interval = 'year'
          break
        case '月度':
          interval = 'month'
          break
        case '季度':
          interval = 'quarter'
          break
        case '周度':
          interval = 'week'
          break
        case '日度':
          interval = 'day'
          break
        default:
          break
      }
      const { dataApi } = this.widget
      const query = {
        queryField: {
          breedId: this.id,
          ...this.queryData,
          interval
        }
      }
      try {
        const { response } = await getData(dataApi, query)
        this.dataHandler(response)
      } catch (e) {
        this.errorHandler()
      }
    },
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      if (!this.$DataIsNotEmpty(response)) {
        this.errorHandler()
        return false
      }

      const xAxisData = Object.keys(response).sort()
      const data = Object.values(response)
        .flat(Infinity)
        .sort((a, b) => {
          return a.key.replace(/[^0-9]/gi, '') - b.key.replace(/[^0-9]/gi, '')
        })
      const unitY = data[0] ? (data[0].profitUnit ? data[0].profitUnit : '') : ''
      const breedList = this.queryData.upAndDownBreed
      const seriesList = breedList.map(breed => {
        return {
          name: data.find(v => v.breedId === breed)?.breedName,
          type: 'bar',
          stack: 'one',
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0,0,0,0.3)'
            }
          },
          data: data
            .filter(item => item.breedId === breed)
            .map(item => [item.key, item.profit, item.profitUnit])
        }
      })
      this.chartLoading = false
      this.setOption(xAxisData, seriesList, unitY)
    },
    setOption(xAxisData, seriesList = [], unitY = '') {
      // 默认展示30个单位的数据
      this.option = {
        title: {
          show: false
        },
        legend: {
          type: 'scroll'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}&nbsp;&nbsp;&nbsp;&nbsp;${e.value[1] || '-'}${
                e.value[2] || ''
              }<br>`
            })
            return html
          }
        },
        dataZoom: {
          show: true,
          bottom: '3%',
          startValue: xAxisData.length - 30
        },
        xAxis: {
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true
          },
          type: 'category',
          data: xAxisData
        },
        yAxis: {
          name: unitY,
          type: 'value'
        },
        grid: {
          top: this.amplify ? '10%' : '25%',
          bottom: '13%',
          containLabel: true
        },
        series: seriesList
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="Profit" v-loading="chartLoading" class="chart-container" />
</template>

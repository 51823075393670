<script>
import exportTable2Excel from '@/utils/exportTable2Excel'
import IndexRiverChart from '@/components/Home/IndexItem.vue'
import GrosProfitRiverChart from '@/components/Home/GrosProfit.vue'

export default {
  name: 'AmplifyModal',
  components: {
    IndexRiverChart,
    GrosProfitRiverChart
  },
  mixins: [exportTable2Excel],
  data() {
    return {
      ratioValue: 'ppt',
      sizes: {
        word: [1597, 1154],
        ppt: [2014, 1154]
      }
    }
  },
  computed: {
    componentName() {
      const { graphic, active = 0, comName } = this.$store.state.amplify.params
      if (comName && comName.includes('RiverChart')) return comName
      if (!graphic || active === undefined) return ''
      return `Breed${graphic.tabs[active].widget.name}`
    },
    widget() {
      const { graphic, active = 0, comName } = this.$store.state.amplify.params
      if (comName && comName.includes('RiverChart')) return {}
      if (!graphic || active === undefined) return ''
      return graphic.tabs[active].widget
    },
    isShowAnalysis() {
      return this.$store.state.amplify.params.graphicName === '现货价格'
    }
  },
  methods: {
    changeTab(index) {
      // 放大时，切换tab与外面渲染的图同步
      const { componentName } = this
      const white = ['BreedIndustryOperatingRateContinuous', 'BreedIndustryOperatingRateYear']
      if (white.includes(componentName)) {
        this.$eventBus.$emit('amplify-tab-change', index)
      }
      this.$store.dispatch('amplify/changeParams', { active: index })
      const interactors = this.$store.state.amplify.params.graphic.tabs[index].interactors
      if (interactors.length === 0) {
        this.$store.dispatch('amplify/changeParams', {
          interactors: this.$store.state.amplify.params.nest.interactors
        })
      } else {
        if (
          !Object.keys(this.$store.state.amplify.params.nest.interactors) ||
          this.$store.state.amplify.params.nest.interactors.length <= 0
        ) {
          this.$store.dispatch('amplify/changeParams', { interactors })
        }
        this.initData()
      }
    },
    async initData() {
      if (
        this.$store.state.amplify.params.interactors &&
        this.$store.state.amplify.params.interactors.length > 0
      ) {
        // 遍历interactors是否包含relation  有则为动态交互器，取relation
        this.$store.state.amplify.params.interactors.forEach(e => {
          const relation = e.listDictData.find(
            v => v.dictValue === (e.defaultValue || e.listDictData[0].dictValue)
          )
          if (relation && relation.relation && Object.keys(relation.relation).length) {
            const index = this.$store.state.amplify.params.interactors.findIndex(
              v => v.interactorName === relation.relation.interactorName
            )
            index >= 0 && this.$store.state.amplify.params.interactors.splice(index, 1)
            const interactors = this.$store.state.amplify.params.interactors
            interactors[interactors.length] = relation.relation
            this.$store.dispatch('amplify/changeParams', { interactors })
            // this.$set(this.interactors, this.interactors.length, relation.relation)
          }
        })

        const _defaultValue =
          this.$store.state.amplify.params.interactors[0].defaultValue ||
          this.$store.state.amplify.params.interactors[0].listDictData[0]?.dictValue
        // 模块为库存时获取动态频率，液化气除外
        if (
          this.$store.state.amplify.params.nest.name === '库存' &&
          String(this.$route.query.id) !== '217'
        ) {
          // this.interactors[1].listDictData = await this.getStockFrequency(_defaultValue)
          const interactors = this.$store.state.amplify.params.interactors
          interactors[1].listDictData = await this.getStockFrequency(_defaultValue)
          this.$store.dispatch('amplify/changeParams', { interactors })
        }

        // 特殊装置页面-选择指标id获取动态频率 只有燃料油、船用油才需要根据指标查频率
        if (
          ['燃料油', '船用油'].includes(this.$route.query.name) &&
          this.$store.state.amplify.params.interactors[0].interactorName === 'indexCode' &&
          this.$store.state.amplify.params.interactors.length !== 1
        ) {
          const frequency = await this.getIndexCodeFrequency(_defaultValue)
          if (frequency?.length) {
            const interactors = this.$store.state.amplify.params.interactors
            interactors[1].listDictData = frequency
            interactors[1].defaultValue = frequency[0].dictValue
            this.$store.dispatch('amplify/changeParams', { interactors })
          }
        }

        // 供应趋势模块获取动态频率
        if (this.$store.state.amplify.params.nest.name === '供应趋势') {
          const interactors = this.$store.state.amplify.params.interactors
          const supplyFrequency = await this.getSupplyFrequency(_defaultValue)
          interactors[1].listDictData = supplyFrequency
          interactors[1].defaultValue = supplyFrequency[0].dictValue
          this.$store.dispatch('amplify/changeParams', { interactors })
        }

        // 遍历取默认值
        this.$store.state.amplify.params.interactors.forEach(
          ({ defaultValue, listDictData, interactorName, multiple }) => {
            // *不加括号会导致Bug
            let defaultVal = defaultValue
            if (this.$store.state.amplify.params.nest.name !== '行业开工率') {
              defaultVal =
                defaultValue || (listDictData.length > 0 ? listDictData[0].dictValue : '')
            }
            const listDictDataTemp = this.$store.state.amplify.params.listDictData
            listDictDataTemp[interactorName] = listDictData
            this.$store.dispatch('amplify/changeParams', { listDictData: listDictDataTemp })
            // 默认值是两个及以上
            if (!Array.isArray(defaultVal)) {
              if (defaultVal && defaultVal.includes(',')) {
                defaultVal = defaultVal.split(',').filter(v => v)
              } else {
                if (multiple) {
                  if (defaultValue) defaultVal = [defaultValue]
                  else if (listDictData.length > 0) defaultVal = [listDictData[0].dictValue]
                  else defaultVal = []
                }
              }
            }
            const selection = this.$store.state.amplify.params.selection
            selection[interactorName] = defaultVal
            this.$store.dispatch('amplify/changeParams', { selection })
          }
        )
      }
      this.$store.dispatch('amplify/changeParams', {
        queryData: this.$store.state.amplify.params.selection
      })
    },
    downloadChart() {
      let option
      // 炼油综合那里 有嵌套的组件，需要判断
      if (this.$refs.magnifierChart.option) {
        option = this.$refs.magnifierChart.option
      } else {
        option = this.$refs.magnifierChart.$children[0].option
      }
      this.exportChartToImage(
        [this.sizes[this.ratioValue][0], this.sizes[this.ratioValue][1]],
        option
      )
    }
  }
}
</script>

<template>
  <el-dialog
    id="amplifyModal"
    ref="amplifyModal"
    modal
    :visible="$store.state.amplify.visible"
    append-to-body
    top="5vh"
    :show-close="false"
    @close="$store.dispatch('amplify/hideModal')"
  >
    <template #title>
      <div class="header-container">
        <div style="font-size: 16px; font-weight: bold; color: #333">
          {{ $store.state.amplify.title }}
        </div>
        <span
          v-show="$store.state.amplify.params.isHaveHistoryPrice && isShowAnalysis"
          class="priceToAnalysis"
          style="margin-right: auto"
          @click="
            $router.push({
              path: '/breed/priceCenter/historyPriceFactorAnalysis',
              query: $route.query
            })
          "
          >查看历史价格形成因素分析</span
        >
        <div
          v-if="
            $store.state.amplify.params.interactors &&
            $store.state.amplify.params.interactors.length > 0
          "
          :class="{
            riverChartShow:
              $store.state.amplify.params.comName &&
              $store.state.amplify.params.comName?.includes('RiverChart')
          }"
          class="interactors"
        >
          <div
            v-for="(interactor, index) in $store.state.amplify.params.interactors"
            :key="`interactor_${index}`"
            class="interactorsItem"
            :class="`interactorsItem_${interactor.id}`"
          >
            <template v-if="interactor.interactorType === 0">
              <span v-if="interactor.description">{{ interactor.description }}：</span>
              <el-select
                :key="interactor.id"
                v-model="$store.state.amplify.params.selection[interactor.interactorName]"
                class="interactors-select"
                :class="{
                  disabledClose:
                    interactor.multiple &&
                    $store.state.amplify.params.selection[interactor.interactorName] &&
                    $store.state.amplify.params.selection[interactor.interactorName].length === 1,
                  frequencySelect:
                    interactor.description === '数据频率' ||
                    interactor.interactorName === 'frequencyName'
                }"
                name=""
                size="mini"
                :multiple="interactor.multiple"
                :multiple-limit="interactor.multipleLimit"
                collapse-tags
                @change="$store.state.amplify.params.changeSelect(interactor)"
              >
                <!-- 多选且选项只有一个时不可取消 -->
                <el-option
                  v-for="dictData in interactor.listDictData"
                  :key="dictData.id"
                  :value="dictData.dictValue"
                  :label="dictData.dictLabel"
                  :disabled="
                    dictData.disabled ||
                    (interactor.multiple &&
                      $store.state.amplify.params.selection[interactor.interactorName] &&
                      $store.state.amplify.params.selection[interactor.interactorName].length ===
                        1 &&
                      $store.state.amplify.params.selection[interactor.interactorName][0] ===
                        dictData.dictValue)
                  "
                />
              </el-select>
            </template>
            <div v-else-if="interactor.interactorType === 1" class="menu">
              <span
                v-for="dictData in interactor.listDictData"
                :key="dictData.id"
                class="subMenu"
                :class="{
                  active:
                    $store.state.amplify.params.selection[interactor.interactorName] &&
                    $store.state.amplify.params.selection[interactor.interactorName].includes(
                      dictData.dictValue
                    )
                }"
                @click="
                  () => {
                    const selection = $store.state.amplify.params.selection
                    selection[interactor.interactorName] = dictData.dictValue
                    $store.dispatch('amplify/changeParams', { selection })
                  }
                "
              >
                {{ dictData.dictLabel }}
              </span>
            </div>
            <!-- 如果是2的话，显示more图标 -->
            <img
              v-else-if="interactor.interactorType === 2"
              src="@/assets/images/Home/more.png"
              alt=""
            />
          </div>
        </div>
        <div
          v-if="
            !$store.state.amplify.params.comName &&
            !$store.state.amplify.params.comName?.includes('RiverChart')
          "
          class="downLoad"
        >
          <span style="margin-right: 5px">选择下载比例:</span>
          <el-select v-model="ratioValue" size="small">
            <el-option label="13*23 (适合ppt)" value="ppt" />
            <el-option label="13*18 (适合word)" value="word" />
          </el-select>
          <img
            src="@/assets/images/download.png"
            class="downLoadImg"
            alt="下载"
            @click="downloadChart"
          />
        </div>
        <img
          src="@/assets/images/reduce.png"
          alt="缩小"
          class="reduce-icon"
          style="cursor: pointer"
          @click="$store.dispatch('amplify/hideModal')"
        />
      </div>
    </template>
    <div>
      <div
        v-if="
          $store.state.amplify.params.graphic &&
          $store.state.amplify.params.graphic.tabs &&
          $store.state.amplify.params.graphic.tabs.length > 1
        "
        class="tabs"
      >
        <!-- 装置检修增加损失量标题显示 -->
        <a v-if="$store.state.amplify.title === '装置检修'" style="cursor: pointer"> 损失量 </a>
        <div>
          <div
            v-for="(tab, index) in $store.state.amplify.params.graphic.tabs"
            :key="`tab_${index}`"
            :class="{ active: $store.state.amplify.params.active === index }"
            @click="changeTab(index)"
          >
            {{ tab.name }}
          </div>
        </div>
      </div>
      <div style="height: 640px">
        <component
          :is="componentName"
          ref="magnifierChart"
          :widget="widget"
          :interactors="$store.state.amplify.params.interactors"
          :query-data="$store.state.amplify.params.queryData"
          :list-dict-data="$store.state.amplify.params.listDictData"
          :graphic-name="$store.state.amplify.params.graphicName"
          :chart-active="$store.state.amplify.params.active"
          :index-obj="$store.state.amplify.params.indexObj"
          v-bind="$attrs"
          :amplify="true"
        />
      </div>
    </div>
  </el-dialog>
</template>

<style scoped lang="scss">
#amplifyModal {
  :deep(.el-dialog) {
    width: 1490px;

    .el-dialog__header,
    .el-dialog__body {
      padding: 0 20px 5px;
    }

    .el-dialog__header {
      height: 40px;
      line-height: 40px;

      & > div {
        border-bottom: 2px solid #3a466e;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .interactors {
    margin-top: 3px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 10px;

    & > div {
      margin: 0 5px;
    }

    .interactorsItem {
      display: flex;
      align-items: center;

      span {
        width: fit-content;
        font-size: 14px;
      }

      .interactors-select {
        max-width: 150px;
        flex: 1;
      }

      .frequencySelect {
        max-width: 95px;
      }

      .menu {
        .subMenu {
          font-size: 14px;
          font-weight: bold;
          color: #121212;
          margin: 0 10px;
          cursor: pointer;
        }

        .active {
          border-bottom: 2px solid #d23023;
        }
      }
    }
    .interactorsItem_292 {
      margin-right: auto;

      .subMenu {
        font-weight: normal !important;
      }
    }
  }

  .riverChartShow {
    margin-right: -290px;
  }
  .priceToAnalysis {
    color: #d23023;
    text-decoration: underline;
    display: inline-block;
    // height: 28px;
    // line-height: 32px;
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    cursor: pointer;
    margin-left: 10px;
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    z-index: 999;

    & > a {
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      color: #333;
    }

    & > div {
      display: flex;

      & > div {
        padding: 0 10px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        color: #121212;
        border: 1px solid #3a466e;
        border-left: 1px solid transparent;
        cursor: pointer;
      }

      & > div:first-of-type {
        border-left: 1px solid #3a466e;
        border-radius: 5px 0 0 5px;
      }

      & > div:last-of-type {
        border-radius: 0 5px 5px 0;
      }
    }

    .active {
      background-color: #3a466e;
      color: #fff;
    }
  }
}
.header-container {
  position: relative;
}
.downLoad {
  position: absolute;
  right: 30px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  .downLoadImg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 10px;
  }
  span {
    line-height: 14px;
    font-size: 14px;
  }
  :deep(.el-select) {
    width: 150px;
  }
}
.reduce-icon {
  margin-left: 295px;
}
</style>

<!--
 * @Author: bjp
 * @Description: 供应-开工率
-->
<script>
import Common from './common'
import { getCrudeOilComparse } from '@/apis/Breed/Supply'
import { getNumberOfString } from '@/utils/common'
export default {
  name: 'CrudeOilCompare', // 行业开工率
  mixins: [Common],
  props: {
    showName: {
      type: String,
      default: ''
    },
    temp: {
      type: Object,
      default: () => ({})
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      barX: [],
      chartData: [],
      dataSetSource: [],
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.CrudeOilCompare)
  },
  methods: {
    getData() {
      if (Object.keys(this.queryData).length > 0) {
        getCrudeOilComparse(this.queryData, this.$route.meta.code)
          .then(res => {
            this.chartLoading = false
            if (!res.response || !Object.keys(res.response).length) {
              this.myChart.clear()
              this.errorHandler()
              return
            }
            this.dataHandler(res.response)
          })
          .catch(() => this.errorHandler())
      } else {
        this.dataHandler()
      }
    },
    /**
     * 处理数据的逻辑
     */
    dataHandler(data) {
      const series = []
      let unit = ''
      let xData = new Set()
      const _data = Object.entries(data).reverse()
      for (const [key, value] of _data) {
        series.push({
          name: key,
          type: 'line',
          data: value.map(v => {
            const timeAxisData =
              this.queryData.frequencyName !== '周度'
                ? v.exhDate
                : this.$dayjs(v.researchStopDate).format('YYYY-MM-DD')
            if (!unit) unit = v.unitName
            xData.add(timeAxisData)
            return [timeAxisData, v.inputValue, v.unitName]
          })
        })
      }
      xData = [...xData].sort((a, b) => getNumberOfString(a) - getNumberOfString(b))
      this.setOption(series, unit, xData)
    },
    setOption(series, unit, xData = []) {
      this.option = {
        title: {
          text: this.$route.meta.title
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}${
                e.value[1] ? unit || '' : ''
              }<br>`
            })
            return html
          }
        },
        legend: {
          bottom: '13%'
        },
        grid: {
          left: '1%',
          bottom: '23%',
          top: this.magnifier || this.amplify ? '8%' : '15%',
          containLabel: true
        },
        dataZoom: {
          type: 'slider',
          bottom: '3%'
        },
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: {
          type: 'value',
          scale: true,
          name: unit
        },
        series
      }
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="CrudeOilCompare" v-loading="chartLoading" class="chart-container" />
</template>

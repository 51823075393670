<script>
import Common from '../common'
import { getHistoryFactorLine } from '@/apis/Breed/PriceCenter'

export default {
  name: 'HistoryPriceFactorAnalysis', // 历史价格形成因素分析
  mixins: [Common],
  props: {
    infoShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      barX: [],
      chartData: [],
      // 备份数据
      copyChartData: [],
      copyBarX: [],
      info: {
        date: '',
        type: '',
        text: ''
      },
      option: {}
    }
  },
  watch: {
    infoShow() {
      this.setOption()
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#HistoryPriceFactorAnalysis'))
  },
  methods: {
    async getData() {
      const { response } = await getHistoryFactorLine(this.queryData)
      this.$emit('data-handler', response)
      this.dataHandler(response)
    },
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      this.myChart.clear()
      this.barX = []
      this.chartData = []
      const colorList = {}
      const color = ['#D23023', '#FFD123', '#0DB6FF', '#48AF92', '#0A392C', '#3AADBA', '#2F63BA']
      if (response && response.data) {
        this.myChart.hideLoading()
        const lengendData = [...new Set(this.$filterValue(response.data['核心因素']))]
        lengendData.forEach((set, index) => {
          let setIndex = index
          if (index > color.length - 1) setIndex = index - color.length
          colorList[set] = color[setIndex]
        })
        response.data['日期'].forEach((item, index) => {
          this.barX.push({
            type: response.data['核心因素'][index].value,
            value: this.$dayjs(item.value).format('YYYY/MM')
          })
          this.chartData.push({
            // time: this.$dayjs(item.value).format('YYYY/MM'),
            value: [
              this.$dayjs(item.value).format('YYYY/MM'),
              response.data['主流价格'][index].value,
              response.data['信息地雷'][index].value,
              response.data['单位'][index].value
            ],
            type: response.data['核心因素'][index].value,
            itemStyle: {
              color: colorList[response.data['核心因素'][index].value] // 改变折线点的颜色
            }
          })
        })
        this.copyChartData = JSON.parse(JSON.stringify(this.chartData))
        this.copyBarX = JSON.parse(JSON.stringify(this.barX))
        this.$parent.legendListShow = true
        this.$emit('legend-list', colorList)
      } else {
        this.$parent.legendListShow = false
        this.errorHandler()
        return
      }
      this.chartLoading = false
      this.info = {
        date: this.chartData[0].value[0] || '',
        type: this.chartData[0].type || '',
        text: this.chartData[0].value[2] || ''
      }
      this.setOption()
    },
    setOption() {
      this.option = {
        // title: {
        //   text: '标题标题标题',
        //   textStyle: {
        //     fontSize: '14px',
        //     fontWeight: 'normal'
        //   },
        //   left: 'center',
        //   top: '3%'
        // },
        grid: {
          top: '20%',
          left: '1%',
          right: '3%',
          bottom: '10%',
          containLabel: true
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let h = `<li style="display:flex;flex-direction:column;">
                      <span style="margin-bottom:10px">${params[0].axisValue}</span>
                      <span>${params[0].marker}价格：${params[0].value[1]} ${params[0].value[3]}</span>
                      <span>核心因素：${params[0].data.type}</span>`
            // if (this.infoShow) {
            //   h += `<span>核心因素：${params[0].data.type}</span>`
            // }
            h += '</li>'
            return h
          }
        },
        dataZoom: {
          show: true,
          bottom: '3%'
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: this.barX,
          axisLabel: {
            interval: 0,
            rotate: -45
          }
        },
        yAxis: {
          type: 'value',
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            type: 'line',
            smooth: true,
            itemStyle: {
              lineStyle: {
                color: '#BFBFBF'
              }
            },
            data: this.chartData
          }
        ]
      }
      this.myChart.setOption(this.option, true)
      this.myChart.getZr().on('mousemove', this.showInfo)
    },
    showInfo(params) {
      if (!this.infoShow) return
      const pointInPixel = [params.offsetX, params.offsetY]
      if (this.myChart.containPixel('grid', pointInPixel)) {
        const xIndex = this.myChart.convertFromPixel({ seriesIndex: 0 }, [
          params.offsetX,
          params.offsetY
        ])[0]
        this.info = {
          date: this.chartData[xIndex].value[0] || '',
          type: this.chartData[xIndex].type || '',
          text: this.chartData[xIndex].value[2] || ''
        }
      }
    },
    /**
     * 改变图例及数据
     */
    changeLegendList(allList) {
      const filterArr = allList.filter(item => {
        return item.abled
      })
      const arr = JSON.parse(JSON.stringify(this.copyChartData))
      const arrBarX = JSON.parse(JSON.stringify(this.copyBarX))
      if (filterArr.length > 0) {
        this.chartData = arr.filter(child => {
          return filterArr.every(item => {
            return child.type !== item.key
          })
        })
        this.barX = arrBarX.filter(child => {
          return filterArr.every(item => {
            return child.type !== item.key
          })
        })
      } else {
        this.chartData = arr
        this.barX = arrBarX
      }
      this.setOption()
    },
    /**
     * @description: 导出图片
     */
    exportImg() {
      if (!this.myChart) return
      const picInfo = this.myChart.getDataURL({
        type: 'png',
        pixelRatio: 2, // 放大两倍下载，之后压缩到同等大小展示。解决生成图片在移动端模糊问题
        backgroundColor: '#fff'
      }) // 获取到的是一串base64信息

      const elink = document.createElement('a')
      elink.download = '历史价格形成因素分析'
      elink.style.display = 'none'
      elink.href = picInfo
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    }
  }
}
</script>

<template>
  <div class="history-factor">
    <div id="HistoryPriceFactorAnalysis" v-loading="chartLoading" class="history-factor_chart" />
    <div v-if="infoShow && info.date" class="info-box">
      <div class="info-box_title">
        <span>{{ info.date }}</span>
        <span>影响价格的核心因素: {{ info.type }}</span>
      </div>
      <div class="info-box_text">
        {{ info.text }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.history-factor {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &_chart {
    height: 300px;
  }

  .info-box {
    min-height: 88px;
    padding: 10px;
    border: 1px solid #999;
    margin: 10px;

    &_title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;

      span {
        margin-right: 20px;
      }
    }

    &_text {
      line-height: 18px;
    }
  }
}
</style>

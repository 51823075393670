<!--
 * @Description:炼油装置特殊页面柱状折线混合图
 * @Date: 2022年7月22日14:37:51
 * @LastEditTime: 2023-09-25 14:26:59
 @接收参数
-->
<script>
import Common from './common'
import { getData } from '@/apis/server'

export default {
  name: 'CrudeOilLineBarMix', // 炼厂加工量
  mixins: [Common],
  props: {
    // 是否初始化数据
    initData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.CrudeOilRefineryProcessingVolume)
    this.initData && this.getData()
  },
  methods: {
    async getData() {
      this.chartLoading = true
      const { dataApi } = this.widget
      const query = {
        queryField: {
          ...this.queryData
        }
      }
      try {
        const { response } = await getData(dataApi, query)
        this.dataHandler(response)
      } catch (error) {
        this.errorHandler()
      }
    },
    /**
     * @description 处理数据的逻辑
     * @param {Object} response
     */
    dataHandler(response) {
      if (response?.length === 0) {
        this.errorHandler()
      }
      // 炼油装置特殊页面数据返回顺序不确定，取indexCode的第一个作为bar
      const { dataApi } = this.widget
      const indexCode = dataApi.split('/')[5]
      const barCode = indexCode.split(',')[0]

      const xData = new Set()
      let series = []
      const units = []

      for (const value of response) {
        const timeAxisData = this.$dayjs(value.researchStopDate).format('YYYY-MM-DD')
        xData.add(timeAxisData)
        const index = series.findIndex(item => item.name === value.indexName)
        if (index === -1) {
          const isRefinery = value.indexCode === barCode
          const i = isRefinery ? 0 : 1
          units[i] = value.unitName
          series.push({
            name: value.indexName,
            type: isRefinery ? 'bar' : 'line',
            yAxisIndex: isRefinery ? 0 : 1,
            color: isRefinery ? '#023985' : '#BC0008',
            data: [[timeAxisData, value.inputValue, value.unitName]]
          })
        } else {
          series[index].data.push([timeAxisData, value.inputValue, value.unitName])
        }
      }
      // 如果加工量不在第一项，则将其放到第一项
      if (series[0].yAxisIndex !== 0) {
        series = series.reverse()
      }
      this.setOption(series, [...xData], units)
    },
    setOption(series = [], xData, units = []) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        title: {
          show: false
        },
        grid: {
          top: this.amplify ? '10%' : '18%',
          bottom: '13%',
          right: '5%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            startValue: xData.length - 30
          }
        ],
        xAxis: {
          type: 'category',
          data: xData,
          axisLabel: {
            rotate: -30
          }
        },
        yAxis: [
          {
            type: 'value',
            name: units[0],
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            name: units[1],
            scale: true,
            splitLine: {
              show: false
            }
          }
        ],
        series
      }
      this.downLoadChartTitle('analysis')
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="CrudeOilRefineryProcessingVolume" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'
export default {
  name: 'StockConsumeRatio', // 库存消费比
  mixins: [Common],
  mounted() {
    this.initEchart(document.querySelector('#StockConsumeRatio'))
    this.chartLoading = true
    this.getData()
  },
  methods: {
    dataHandler(response) {
      this.myChart.clear()
      if (response && response.data) {
        const { data } = response
        const xData = data['日期'].map(({ value }) => this.$dayjs(value).format('YY年MM月'))
        const series = []

        const seriesName = ['库存数量', '消费量', '库存消费比']
        seriesName.forEach(item => {
          const s = {
            name: item,
            type: 'bar',
            data: data[item]
          }
          if (item === '库存消费比') {
            s.type = 'line'
            s.yAxisIndex = 1
          }
          series.push(s)
        })
        this.myChart.hideLoading()
        this.chartLoading = false
        this.setOption(xData, series)
      } else {
        this.errorHandler()
      }
    },
    setOption(xData = [], series = []) {
      const option = {
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        dataZoom: {
          show: true,
          bottom: 7
        },
        legend: {
          top: 10
        },
        grid: {
          bottom: 40,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: xData
          }
        ],
        yAxis: [
          {
            type: 'value'
          },
          {
            type: 'value',
            axisLabel: {
              formatter: value => `${value * 100}%`
            }
          }
        ],
        series
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div id="StockConsumeRatio" v-loading="chartLoading" class="chart-container" />
</template>

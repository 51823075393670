<script>
import * as echarts from 'echarts'
export default {
  name: 'DataCorrelationAnalysis', // 数据相关性分析
  mounted() {
    const LABELWIDTH = 50
    // const S_WIDTH = 45
    // const S_HEIGHT = 25
    const relatePrd = [
      { value: '价格' },
      { value: '开工率' },
      { value: '产量' },
      { value: '产能' },
      { value: '进口量' },
      { value: '出口量' },
      { value: '数据一' }
    ]
    this.$refs.DataCorrelationAnalysis.style.width = '100%'
    this.$refs.DataCorrelationAnalysis.style.height = '100%'
    const myChart = echarts.init(this.$refs.DataCorrelationAnalysis)
    let data = [
      ['价格', '价格', 1],
      ['开工率', '开工率', 1],
      ['产量', '产量', 1],
      ['产量', '价格', 0.2],
      ['数据一', '开工率', 0.5]
    ]
    const xLabelW = (myChart.getWidth() - LABELWIDTH) / relatePrd.length
    const yLabelH = (myChart.getHeight() - 25) / relatePrd.length
    data = data.map(item => {
      return [item[0], item[1], item[2] || '-']
    })
    const option = {
      tooltip: {
        confine: true,
        position: 'top',
        formatter: params => {
          return `${params.seriesName}<br>${params.marker}${params.value[0]} x ${params.value[1]}：<span style="margin-left:10px">${params.value[2]}</span>`
        }
      },
      grid: {
        left: LABELWIDTH,
        right: 1,
        top: 25,
        bottom: 0
      },
      xAxis: {
        type: 'category',
        data: relatePrd,
        splitArea: {
          show: true
        },
        axisLabel: {
          interval: 0,
          margin: 0,
          width: xLabelW,
          formatter: (value, index) => {
            return relatePrd[index].relate
              ? `{relate|${relatePrd[index].relate}}{abg|}\n`
              : `` + `{prd|${value}}{abg2|}`
          },
          rich: {
            relate: {
              color: '#121212',
              align: 'center'
            },
            abg: {
              backgroundColor: '#F2F2F2',
              borderWidth: 1,
              borderColor: '#fff',
              width: '100%',
              align: 'right',
              height: 25
            },
            abg2: {
              backgroundColor: '#c4ccd3',
              borderWidth: 1,
              borderColor: '#fff',
              width: '100%',
              align: 'right',
              height: 25
            },
            prd: {
              color: '#3A466E',
              align: 'center'
            }
          }
        },
        position: 'top'
      },
      yAxis: {
        type: 'category',
        data: relatePrd,
        splitArea: {
          show: true
        },
        axisTick: false,
        axisLabel: {
          interval: 0,
          margin: 0,
          borderWidth: 1,
          borderColor: '#fff',
          backgroundColor: '#c4ccd3',
          formatter: value => `{prd|${value}}`,
          rich: {
            prd: {
              color: '#3A466E',
              width: LABELWIDTH,
              align: 'center',
              height: yLabelH
            }
          }
        },
        inverse: true
      },
      visualMap: {
        min: 0,
        max: 1,
        show: false,
        inRange: {
          color: ['#6899DD', '#29538E']
        }
      },
      series: [
        {
          name: '相关性',
          type: 'heatmap',
          data,
          label: {
            show: true,
            formatter: params => {
              return `${params.value[2] * 100}%`
            }
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    }

    myChart.setOption(option)
  }
}
</script>

<template>
  <div ref="DataCorrelationAnalysis" class="chart-container" />
</template>

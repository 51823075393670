<script>
import Common from '../common.js'
import {
  getCompanyKLine,
  getInternetKLine,
  getKLine,
  getMarketKLine
} from '@/apis/Breed/PriceCenter.js'

export default {
  name: 'KLine',
  mixins: [Common], // K线图
  props: {
    priceType: {
      type: String,
      default: '主流价'
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chartData: {},
      option: {}
    }
  },
  watch: {
    chartTitle() {
      this.myChart && this.setOption()
    }
  },
  mounted() {
    this.initEchart(this.$refs.kLine)
  },
  methods: {
    getData() {
      let url = () => {}
      switch (this.priceType) {
        case '主流价':
          url = getKLine
          break
        case '市场价':
          url = getMarketKLine
          break
        case '企业价':
          url = getCompanyKLine
          break
        case '国际价':
          url = getInternetKLine
          break
        default:
          break
      }
      url(this.queryData)
        .then(res => {
          this.chartLoading = false
          this.myChart.clear()
          const { data = {} } = res.response || {}
          if (!data || Object.keys(data).length === 0) {
            this.errorHandler()
            return false
          }
          this.$emit('change-data', data)
          this.chartData = data
          this.setOption()
          this.myChart.hideLoading()
        })
        .catch(() => {
          this.errorHandler()
        })
    },
    setOption() {
      const data = this.chartData
      const xData = []
      const yData = []
      const volumeData = []
      const maData = []
      let unit = ''
      try {
        data['展示日期'].forEach((res, i) => {
          if (!unit) unit = data['单位'][i].value
          // 2023-07-24 日期重新格式化
          xData.push(this.$dayjs(res.name).format('YYYY-MM-DD'))
          yData.push([
            data['开盘价'][i].value,
            data['收盘价'][i].value,
            data['最高价'][i].value,
            data['最低价'][i].value
          ])
          volumeData.push(data.成交量[i].value || '')
        })
        this.$parent.averageValue.forEach((val, index) => {
          maData.push({
            name: val,
            type: 'line',
            data: [],
            smooth: true,
            showSymbol: false
          })
          data[val] &&
            data[val].forEach(item => {
              maData[index].data.push(item.value)
            })
        })
      } catch (e) {
        console.log(data)
      }

      const upColor = '#BA0C2F'
      const upBorderColor = '#8A0000'
      const downColor = '#1A9016'
      const downBorderColor = '#008F28'

      this.option = {
        animation: false,
        title: {
          // 标题是品种名称+属性
          text: this.chartTitle,
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14
          },
          left: 'center',
          top: '5%'
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          bordeRadius: 4,
          backgroundColor: 'rgba(255,255,255,0.94)',
          textStyle: {
            fontSize: 12,
            color: '#121212'
          },
          formatter(params) {
            const dimensionNames = ['开盘价', '收盘价', '最高价', '最低价']
            let result = `${params[0].name}<br/>`
            params.forEach(item => {
              if (item.value) {
                if (item.seriesName.includes('K')) {
                  result += `${item.marker} ${item.seriesName}</br>`
                  dimensionNames.forEach((child, index) => {
                    result += `&nbsp;&nbsp;&nbsp;&nbsp;${child}：${
                      item.value.toString().split(',')[index + 1]
                    }${unit}</br>`
                  })
                } else {
                  result += `&nbsp;&nbsp;&nbsp;&nbsp;${
                    item.seriesName
                  } ： ${item.value.toString()}${unit}</br>`
                }
              }
            })
            return result
          }
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: [0, 1]
            }
          ]
        },
        dataZoom: [
          {
            type: 'inside',
            xAxisIndex: [0, 1]
          },
          {
            type: 'slider',
            right: '1%',
            left: '1%',
            bottom: '3%',
            xAxisIndex: [0, 1]
          }
        ],
        xAxis: [
          {
            type: 'category',
            data: xData,
            boundaryGap: true,
            min: 'dataMin',
            max: 'dataMax'
          },
          {
            show: false,
            type: 'category',
            gridIndex: 1,
            data: xData,
            boundaryGap: true,
            splitLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false },
            min: 'dataMin',
            max: 'dataMax'
          }
        ],
        yAxis: [
          {
            name: unit,
            scale: true,
            splitNumber: 2,
            offset: 0,
            splitLine: { show: true },
            axisTick: { show: false },
            axisLabel: {
              formatter: '{value}\n'
            }
          },
          {
            scale: true,
            gridIndex: 1,
            splitNumber: 2,
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false }
          }
        ],
        visualMap: {
          show: false,
          seriesIndex: 2,
          dimension: 2,
          pieces: [
            {
              value: 1,
              color: upColor
            },
            {
              value: -1,
              color: downColor
            }
          ]
        },
        grid: [
          {
            bottom: '11%',
            top: this.magnifier ? '10%' : '29%',
            left: '1%',
            right: '1%',
            containLabel: true
          },
          {
            top: '65%',
            left: '1%',
            right: '1%',
            bottom: '10%',
            containLabel: true
          }
        ],
        series: [
          // {
          //   name: '成交量',
          //   type: 'bar',
          //   xAxisIndex: 1,
          //   yAxisIndex: 1,
          //   data: volumeData
          // },
          {
            type: 'candlestick',
            name: `${this.queryData.queryField.frequencyName[0]}K`,
            data: yData,
            itemStyle: {
              color: upColor,
              color0: downColor,
              borderColor: upBorderColor,
              borderColor0: downBorderColor
            }
          },
          ...maData
        ]
      }

      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="kLine" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from '../common'
import { getAnalysisChart } from '@/apis/Breed/analysisForecast.js'
export default {
  name: 'PriceCorrelationAnalysis', // 价格相关性分析
  mixins: [Common],
  props: {
    dateCheck: {
      type: String,
      default: '近1周'
    },
    breedId: {
      // 传入breedId时优先
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      relatePrdX: [],
      relatePrdY: [],
      dataList: []
    }
  },
  watch: {
    dateCheck() {
      this.getData()
    },
    breedId() {
      this.getData()
    }
  },
  mounted() {
    this.initEchart(document.getElementById('PriceCorrelationAnalysis'))
    this.getData()
    // 监听窗口大小
    window.addEventListener('resize', () => this.setOption())
    // 组件销毁执行
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', () => {
        this.setOption()
      })
    })
  },
  methods: {
    getData() {
      if (!this.breedId && !this.id) return
      const params = {
        queryField: {
          breedId: this.breedId || this.id,
          corCoeType: this.dateCheck
        }
      }
      this.chartLoading = true
      getAnalysisChart(params)
        .then(res => {
          if (res.response && res.response.data.length > 0) {
            this.chartData = res.response.data
            this.dataHandler()
          } else {
            this.errorHandler()
          }
        })
        .catch(() => {
          this.errorHandler()
        })
    },
    dataHandler() {
      this.myChart.clear()
      this.chartLoading = false
      this.myChart.hideLoading()
      this.relatePrdX = []
      this.relatePrdY = []
      this.dataList = []
      const tempX = {}
      const tempY = {}
      const upData = []
      const midData = []
      const bottomData = []
      this.chartData.forEach(item => {
        switch (item.xBreedAttr) {
          case '上游':
            upData.push(item)
            break
          case '中间':
            midData.push(item)
            break
          case '下游':
            bottomData.push(item)
            break
        }
      })
      const relateData = [...upData, ...midData, ...bottomData]
      relateData.forEach(item => {
        tempX[item.xBreedName + item.xBreedAttr] = {
          value: item.xBreedName,
          relate: item.xBreedAttr
        }
        tempY[item.yBreedName + item.yBreedAttr] = {
          value: item.yBreedName,
          relate: item.yBreedAttr
        }
        this.dataList.push([item.yBreedName, item.xBreedName, item.corCoeValue])
      })
      this.relatePrdX = Object.values(tempX)
      this.relatePrdX.forEach(item => {
        const data = relateData
          .filter(temp => temp.yBreedName === item.value)
          .map(item => ({
            value: item.yBreedName,
            relate: item.yBreedAttr
          }))
        this.relatePrdY.push(data[0])
      })
      this.setOption()
    },
    setOption(data = this.dataList, relatePrdX = this.relatePrdX, relatePrdY = this.relatePrdY) {
      const LABELWIDTH = 150
      document.getElementById('PriceCorrelationAnalysis').style.width = '100%'
      document.getElementById('PriceCorrelationAnalysis').style.height = '100%'
      const xLabelW = (this.myChart.getWidth() - LABELWIDTH) / relatePrdX.length
      const yLabelH = (this.myChart.getHeight() - 80) / relatePrdY.length
      data = data.map(item => {
        return [item[0], item[1], item[2] || '-']
      })
      const option = {
        animation: false,
        tooltip: {
          confine: true,
          position: 'top',
          formatter: params => {
            return `${params.seriesName}<br>${params.marker}${params.value[0]} x ${params.value[1]}：<span style="margin-left:10px">${params.value[2]}</span>`
          }
        },
        grid: {
          left: 10,
          right: 1,
          top: 0,
          bottom: 0
        },
        xAxis: {
          type: 'category',
          data: relatePrdX,
          splitArea: {
            show: true
          },
          triggerEvent: true,
          offset: 1,
          axisLabel: {
            interval: 0,
            margin: 0,
            width: xLabelW,
            overflow: 'truncate',
            formatter: (value, index) => {
              return `{relate|${relatePrdX[index].relate}}{abg|}\n` + `{prd|${value}}{abg2|}`
            },
            rich: {
              relate: {
                color: '#121212',
                align: 'center'
              },
              abg: {
                color: '#fff',
                backgroundColor: '#F2F2F2',
                borderWidth: 1,
                borderColor: '#fff',
                width: '100%',
                align: 'right',
                height: 40
              },
              abg2: {
                color: '#fff',
                backgroundColor: '#959baf',
                borderWidth: 1,
                borderColor: '#fff',
                width: '100%',
                align: 'right',
                height: 40
              },
              prd: {
                color: '#fff',
                align: 'center'
              }
            }
          },
          position: 'top'
        },
        yAxis: {
          type: 'category',
          data: relatePrdY,
          offset: 2.5,
          splitArea: {
            show: true
          },
          triggerEvent: true,
          axisTick: false,
          axisLabel: {
            interval: 0,
            margin: 0,
            borderWidth: 1,
            borderColor: '#fff',
            backgroundColor: '#c4ccd3',
            width: LABELWIDTH,
            overflow: 'truncate',
            formatter: (value, index) => {
              let prdValue = value
              if (value.length > 4) prdValue = `${value.substring(0, 4)}...`
              return `{relate|${relatePrdY[index].relate}}` + `{prd|${prdValue}}`
            },
            rich: {
              relate: {
                color: '#121212',
                align: 'center',
                width: LABELWIDTH / 2,
                height: yLabelH,
                backgroundColor: '#F2F2F2'
              },
              prd: {
                color: '#fff',
                width: LABELWIDTH / 2,
                backgroundColor: '#959baf',
                height: yLabelH,
                align: 'center'
              }
            }
          },
          inverse: true
        },
        visualMap: {
          min: 0,
          max: 1,
          show: false,
          inRange: {
            color: ['#c9ceda', 'rgba(58, 70, 110, 1)']
          }
        },
        series: [
          {
            name: '相关性',
            type: 'heatmap',
            data,
            label: {
              show: true
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      this.myChart.setOption(option)
      this.myChart.on('mouseover', this.xAxisOver)
      this.myChart.on('mouseout', this.xAxisout)
    },
    /**
     * 移入x/y轴
     */
    xAxisOver(params) {
      // 因为进入柱形图区域也会触发事件，需要对参数的值判断，只响应坐标轴触发事件
      if (params.componentType === 'xAxis' || params.componentType === 'yAxis') {
        const offsetX = params.event.offsetX + 10
        const offsetY = params.event.offsetY + 10
        this.myChart.setOption({
          tooltip: {
            confine: true,
            formatter: () => {
              return params.value
            },
            trigger: 'axis',
            alwaysShowContent: true
          }
        })
        // 显示提示框
        this.myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: 0,
          position: [offsetX, offsetY]
        })
      }
    },
    /**
     * 移除
     */
    xAxisout(params) {
      if (params.componentType !== 'xAxis' || params.componentType !== 'yAxis') {
        this.myChart.setOption({
          tooltip: {
            confine: true,
            trigger: 'item',
            formatter: params => {
              return `${params.seriesName}<br>${params.marker}${params.value[0]} x ${params.value[1]}：<span style="margin-left:10px">${params.value[2]}</span>`
            },
            alwaysShowContent: false
          }
        })
      }
    }
  }
}
</script>

<template>
  <div id="PriceCorrelationAnalysis" v-loading="chartLoading" class="chart-container" />
</template>

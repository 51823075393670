<!--
 * @Author: bjp
 * @Description: 供应-产能利用率
-->
<script>
import Common from '../common'
// import { getOperaYearLine } from '@/apis/Breed/Supply'
// import { getNumberOfString } from '@/utils/common'

export default {
  name: 'CapacityUtilization', // 产能利用率
  mixins: [Common],
  props: {
    chartData: {
      type: [Object, Array],
      default: () => []
    },
    dataKey: {
      type: [Array],
      default: () => []
    },
    showName: {
      type: String,
      default: ''
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      barX: [],
      getShowYear: [],
      dataSetSource: [],
      option: {}
    }
  },
  watch: {
    chartData: {
      handler() {
        this.dataHandler()
      },
      deep: true
    }
  },
  mounted() {
    this.initEchart(this.$refs.CapacityUtilization)
    this.dataHandler()
  },
  methods: {
    /**
     * 处理数据的逻辑
     */
    dataHandler() {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      const series = []
      if (this.chartData && Object.keys(this.chartData).length > 0) {
        this.dataSetSource = JSON.parse(JSON.stringify(this.chartData))
        Object.assign(this.dataKey)
          .sort((a, b) => b.replace(/[^0-9]/gi, '') - a.replace(/[^0-9]/gi, ''))
          .forEach(item => {
            this.getShowYear.push(item)
            series.push({
              type: 'line',
              name: item,
              connectNulls: true
            })
          })
        this.setOption(series)
      } else {
        this.errorHandler()
      }
    },
    setOption(series = []) {
      const seriesName = this.chartData[0].quotaAlias || `${this.showName}开工率`
      this.option = {
        title: {
          text: `${seriesName}`,
          x: 'center',
          top: 0,
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        dataZoom: {
          bottom: '3%'
        },
        legend: {
          bottom: '12%'
        },
        grid: {
          top: this.magnifier ? '8%' : '15%',
          bottom: '23%',
          left: '1%',
          right: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          name: '%',
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series,
        dataset: {
          show: this.dataSetSource.length > 0,
          dimensions: ['time', ...this.getShowYear],
          source: this.dataSetSource
        }
      }
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="CapacityUtilization" v-loading="chartLoading" class="chart-container" />
</template>

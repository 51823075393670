<script>
import Common from '../common'
import { getData } from '@/apis/server'
export default {
  name: 'ProductionProcessComparison', // 企业-按工艺路线
  mixins: [Common],
  props: {
    urlType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      chartLoading: false,
      option: {}
    }
  },
  watch: {
    urlType(val) {
      if (val) this.getData()
    }
  },
  mounted() {
    this.initEchart(this.$refs.ProductionProcessComparison)
    this.getData()
  },
  methods: {
    async getData() {
      this.chartLoading = true
      if (Object.keys(this.queryData).length > 0) {
        const { response } = await getData(`/api/widget/queryData/${this.urlType}`, this.queryData)
        this.dataHandler(response)
      } else {
        this.dataHandler()
      }
    },
    dataHandler(response) {
      this.chartLoading = false
      if (!response) {
        this.setOption()
        this.errorHandler()
        return false
      }
      this.myChart.hideLoading()
      const data = []
      for (const key in response) {
        response[key].forEach(({ sum: value, key: name }) =>
          data.push({
            name,
            value
          })
        )
      }
      this.setOption(data)
    },
    setOption(data = []) {
      this.option = {
        title: {
          show: false
        },
        legend: {
          bottom: '8%',
          x: 'center',
          icon: 'circle',
          type: 'scroll'
        },
        tooltip: {
          confine: true
        },
        series: [
          {
            type: 'pie',
            center: ['50%', '40%'],
            radius: [0, '63%'],
            data,
            label: {
              formatter: '{d}%'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      this.downLoadChartTitle()
      this.$emit('chart-options', this.option)
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="ProductionProcessComparison" v-loading="chartLoading" class="chart-container" />
</template>

/*
 * @Description: 品种-产业链分析apis
 * @Date: 2021-06-09 13:54:39
 * @LastEditTime: 2021-10-19 14:47:42
 */
import { getData, getDataByGet } from '@/apis/server'

const url = '/api/widget/queryData/'
// 下游消费结构chart
export const getDownStream = params => getData(`${url}272`, params)
// 下游消费结构table
const url2 = '/api/industrialChain/listUpAndDownStructure/59'
export const getDownStreamList = params => getData(`${url2}`, params)
// 产业链利润分布-表格
const url3 = '/api/industrialChain/profitDistribution'
export const getProfitTable = params => getDataByGet(`${url3}`, params)
// 产业链利润分布-chart
const url4 = '/api/widget/queryData/industryChainProfitChart'
export const getProfitChart = params => getData(`${url4}`, params)
// 产业链利润分布内页-chart
export const getInnerProfitTable = params =>
  getData('/api/widget/queryData/inner/industryChainProfitChart', params)

// 产业链-获取上下游
export const getAllUpDownBreed = params => getDataByGet('/api/common/getAllUpDownBreed', params)

<script>
import { quantityPrice } from '@/apis/server'

export default {
  name: 'QuantifyRelation', // 量价关系
  data() {
    return {
      mainBreed: {
        productDTO: {}
      },
      upstream: [],
      downstream: [],
      loading: true
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const { response } = await quantityPrice({
        queryField: {
          breedId: this.$route.query.id
        }
      })
      const { upstream, downstream, processInfo } = response
      this.upstream = upstream
      this.downstream = downstream
      if (!processInfo) {
        this.mainBreed = {}
      } else {
        this.mainBreed = processInfo
      }
    },
    decimalToPercent(num) {
      return `${(num * 100).toFixed(2)}%`
    },
    updownClass(num) {
      return num >= 0 ? 'el-icon-top' : 'el-icon-bottom'
    }
  }
}
</script>

<template>
  <el-scrollbar style="width: 100%; height: 100%" wrap-class="scrollbar-x-hidden">
    <div v-loading="loading" class="flow-chart">
      <!-- 上游 -->
      <div class="upper">
        <div class="flow-tit">
          <div class="tit" style="margin-left: 0.2865rem; width: 0.5208rem">
            <span>供应量</span>
            <div class="tit-sub"><span>国内产量</span><span>进口量</span></div>
          </div>
          <div class="tit" style="margin-left: 0.3333rem; width: 0.3646rem">
            <span>价格</span>
          </div>
        </div>
        <div class="upper-lst">
          <div v-for="item in upstream" :key="item.id" class="upper-i">
            <div class="craft">
              <div class="name">
                {{ item.processName }}
              </div>
              <div class="ratio">
                {{ item.processRate }}
              </div>
            </div>
            <div class="raw-lst">
              <div v-for="raw in item.products" :key="raw.breedId" class="product">
                <span class="name">{{ raw.breedName }}</span>
                <div class="supply">
                  <span>{{ raw.cpCap }}</span>
                  <span>{{ raw.imVolume }}</span>
                </div>
                <span class="updown" :class="updownClass(raw.supplyRate)">{{
                  decimalToPercent(raw.supplyRate)
                }}</span>
                <span class="price">{{ raw.price }}</span>
                <span class="updown" :class="updownClass(raw.priceChangeRate)">{{
                  raw.priceChangeRate ? `${raw.priceChangeRate}%` : ''
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end 上游 -->
      <div class="mainPrd">
        <div class="flow-tit">
          <div class="tit" style="margin-left: 0.3125rem; width: 0.5208rem">
            <span>供应量</span>
            <div class="tit-sub"><span>国内产量</span><span>进口量</span></div>
          </div>
          <div class="tit" style="margin-left: 0.3646rem; width: 0.3646rem">
            <span>价格</span>
          </div>
        </div>
        <div class="product">
          <span class="name">{{ mainBreed.processNamee }}</span>
          <div class="supply">
            <span>{{ mainBreed.productDTO ? mainBreed.productDTO.cpCap : '' }}</span>
            <span>{{ mainBreed.productDTO ? mainBreed.productDTO.imVolume : '' }}</span>
          </div>
          <span
            class="updown"
            :class="updownClass(mainBreed.productDTO ? mainBreed.productDTO.supplyRate : '')"
            >{{
              decimalToPercent(mainBreed.productDTO ? mainBreed.productDTO.supplyRate : '')
            }}</span
          >
          <span class="price">{{ mainBreed.productDTO ? mainBreed.productDTO.price : '' }}</span>
          <span
            class="updown"
            :class="updownClass(mainBreed.productDTO ? mainBreed.productDTO.priceChangeRate : '')"
            >{{ mainBreed.productDTO ? `${mainBreed.productDTO.priceChangeRate}%` : '' }}</span
          >
        </div>
      </div>
      <!-- 下游 -->
      <div class="downstream">
        <div class="flow-tit">
          <div class="tit" style="width: 0.3125rem">
            <span>消费占比</span>
          </div>
          <div class="tit" style="margin-left: 0.3125rem; width: 0.5208rem">
            <span>供应量</span>
            <div class="tit-sub"><span>国内产量</span><span>进口量</span></div>
          </div>
          <div class="tit" style="margin-left: 0.3646rem; width: 0.3646rem">
            <span>价格</span>
          </div>
        </div>
        <div class="down-lst">
          <div v-for="item in downstream" :key="item.id" class="down-i">
            <div class="product">
              <span class="consume">{{ item.processRate }}</span>
              <span class="name">{{ item.processName }}</span>
              <div class="supply">
                <span>{{ item.productDTO ? item.productDTO.cpCap : '' }}</span>
                <span>{{ item.productDTO ? item.productDTO.imVolume : '' }}</span>
              </div>
              <span
                class="updown"
                :class="updownClass(item.productDTO ? item.productDTO.supplyRate : '')"
                >{{ decimalToPercent(item.productDTO ? item.productDTO.supplyRate : '') }}</span
              >
              <span class="price">{{ item.productDTO ? item.productDTO.price : '' }}</span>
              <span
                class="updown"
                :class="updownClass(item.productDTO ? item.productDTO.priceChangeRate : '')"
                >{{ item.productDTO ? `${item.productDTO.priceChangeRate}%` : '' }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- end 下游 -->
    </div>
  </el-scrollbar>
</template>

<style scoped lang="scss">
$arrow: url(~@/assets/images/Breed/icon-arr.png);
$arrow_on_r: $arrow no-repeat right center;
$arrow_on_l: $arrow no-repeat left center;
$arrow_width: 50px;
$craft_w: 60px;
$red: #ba0c2f;
$green: #1a9016;
@mixin line {
  content: '';
  position: absolute;
  width: 0;
  border-left: 1px solid $red;
}
.flow-chart {
  display: flex;
  align-items: center;
  font-size: 14px;
  .upper,
  .mainPrd,
  .downstream {
    flex-shrink: 0;
  }
}
.flow-tit {
  border-bottom: 1px solid $red;
  .tit {
    height: 60px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    .tit-sub {
      font-size: 12px;
      color: $red;
      margin-top: 5px;
      span {
        display: inline-block;
        width: 50%;
        text-align: center;
      }
    }
  }
}
.upper {
  padding-right: $arrow_width;
  .flow-tit {
    margin-left: $craft_w;
  }
  .upper-lst {
    position: relative;
    margin-right: -$arrow_width;
    &::after {
      top: 10px;
      right: 0;
      bottom: 10px;
      @include line;
    }
    .upper-i {
      position: relative;
      padding-left: $craft_w;
      padding-right: $arrow_width;
      background: $arrow_on_r;
      .craft {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
        .name {
          font-weight: bold;
          margin: 10px 0;
        }
      }
      .raw-lst {
        border-bottom: 1px solid $red;
      }
    }
  }
}
.product {
  padding: 10px 0;
  font-size: 14px;
  span {
    vertical-align: middle;
    display: inline-block;
  }
  .name {
    width: 65px;
    text-align: center;
  }
  .consume {
    width: 60px;
    text-align: center;
  }
  .supply {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    font-size: 12px;
    width: 80px;
    height: 30px;
    color: #fff;
    border-radius: 15px;
    background: linear-gradient(120deg, #ba0c2f 50%, #e25c61 50%);
    span {
      position: absolute;
      width: 50%;
      white-space: normal;
      word-break: break-all;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
    }
    span:nth-child(2) {
      right: 0;
    }
  }
  .updown {
    width: 74px;
    font-size: 12px;
    text-align: center;
  }
  .el-icon-top {
    color: $red;
    &:before {
      margin-right: 2px;
      font-weight: bold;
    }
  }
  .el-icon-bottom {
    color: $green;
    &:before {
      margin-right: 2px;
      font-weight: bold;
    }
  }
  .price {
    height: 30px;
    width: 70px;
    font-size: 12px;
    background: #3a466e;
    border-radius: 15px;
    text-align: center;
    color: #fff;
    line-height: 30px;
  }
}
.mainPrd {
  padding-left: $arrow_width + 10;
  padding-right: $arrow_width + 10;
  position: relative;
  &::after,
  &::before {
    content: '';
    position: absolute;
    right: 0;
    width: $arrow_width;
    height: 10px;
    top: 55px;
    background: $arrow_on_r;
    background-size: 100%;
  }
  &::before {
    left: 0;
    background: $arrow_on_l;
    background-size: 100%;
  }
}
.downstream {
  padding-left: $arrow_width;
  margin-top: -50px;
  .down-lst {
    position: relative;
    margin-left: -$arrow_width;
    &::before {
      top: 24px;
      bottom: 24px;
      @include line;
    }
    .down-i {
      padding-left: $arrow_width;
      background: $arrow_on_l;
      .product {
        border-bottom: 1px dashed #dbdbdb;
      }
    }
  }
}
</style>

<script>
import Common from '../common.js'
import { getPriceChart } from '@/apis/Breed/CustomsData.js'
export default {
  name: 'ContinuousGraph',
  mixins: [Common], // 分年对比
  props: {
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timeFormat: 'MM',
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.ContinuousGraph)
  },
  methods: {
    getData() {
      this.barX = []
      getPriceChart(this.queryData)
        .then(res => {
          this.chartLoading = false
          this.myChart.clear()
          const { response } = res
          if (!response || Object.keys(response.data).length === 0) {
            this.errorHandler()
            return
          }
          this.setOption(response.data)
          this.myChart.hideLoading()
        })
        .catch(() => {
          this.errorHandler()
        })
    },
    setOption(chartData = {}) {
      const dayFormat = {
        月度: 'YYYY年MM月',
        季度: 'YYYY年MM月',
        年度: 'YYYY年'
      }
      let unit = ''
      const data = chartData.时间.map((ele, i) => {
        if (chartData.均价单位[i].value) unit = chartData.均价单位[i].value
        return [
          this.$dayjs(ele.value).format('YYYY-MM-DD'),
          chartData.均价[i].value,
          chartData.均价环比[i].value
        ]
      })
      const cusType = this.$route.path.includes('import') ? '进口' : '出口'
      this.option = {
        title: {
          text: `${cusType}金额`,
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14
          },
          left: 'center',
          top: '5%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}&nbsp;&nbsp;&nbsp;&nbsp;${
                e.value[e.encode.y] || '-'
              }${(e.seriesIndex === 0 ? unit : '%') || ''}<br>`
            })
            return html
          }
        },
        grid: {
          top: this.magnifier ? '10%' : '20%',
          bottom: '20%',
          left: '2%',
          right: '2%',
          containLabel: true
        },
        legend: {
          bottom: '10%'
        },
        dataset: {
          source: data
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%'
          }
        ],
        xAxis: {
          type: 'category',
          axisTick: {
            show: false
          }
        },
        yAxis: [
          {
            name: unit,
            type: 'value',
            scale: true,
            splitNumber: 4,
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            name: '%',
            type: 'value',
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: `${cusType}均价`,
            type: 'line'
          },
          {
            name: `${cusType}均价环比`,
            type: 'line',
            yAxisIndex: 1
          }
        ]
      }
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="ContinuousGraph" v-loading="chartLoading" class="chart-container" />
</template>

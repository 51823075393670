<script>
import { Loading } from 'element-ui'
import {
  getModularNameByBreedId,
  getModularNameByCompanyCode,
  listTree,
  listTreeQueryByName
} from '@/apis/server'
// import { getEnterpriseList } from '@/apis/Enterprise'
import { TAB_HOME, TAB_RANK, TAB_REPORTCENTER } from '@/assets/js/openTabData'
import RegisterDialog from '@/components/RegisterDialog'
import { storage } from '@/utils/common'

export default {
  data() {
    return {
      // active: 0, // 当前导航类型 0 产品导航 1 重点企业研究
      menus: [],
      secondTitle: '',
      secondMenus: [],
      thirdTitle: '',
      thirdMenus: [],
      // companyMenus: [], // 企业信息弹窗
      homeModularName: '',
      modularName: '',
      searchKey: '', // 搜索品种名称
      searchResult: [], // 搜索结果
      dialogVisible: false
      // companyList: []
    }
  },
  mounted() {
    window.addEventListener('mouseup', e => this.addMouseUp(e))
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('mouseup', e => this.addMouseUp(e))
    })
  },
  created() {
    // 企业页面默认展示企业导航
    // if (this.$route.name === 'Enterprise') this.active = 1
    this.getMenuData()
  },
  methods: {
    // 获取导航数据 active 0时获取产品导航  1 获取企业导航
    getMenuData() {
      // switch (this.active) {
      //   // case 1:
      //   //   !this.companyList.length && this.getCompanyList()
      //   //   break
      //   case 0:
      //   default:
      !this.menus.length && this.getMenus()
      // break
      // }
    },
    async getMenus() {
      const { response } = await listTree()
      this.homeModularName = response.secondLevelModular
      this.menus = response.childNode
    },
    // async getCompanyList() {
    //   const { response: list } = await getEnterpriseList()
    //   this.companyList = list
    // },
    /**
     * @description: 能化首页
     */
    goHome() {
      this.$openTab('/home', TAB_HOME.title, TAB_HOME.id)
    },
    // showItem(type) {
    //   if (this.active === type) return
    //   this.active = type
    //   this.getMenuData()
    // },
    /**
     * @description: 切换modularName
     * @param {String} name
     */
    changeModular(name) {
      const newQuery = JSON.parse(JSON.stringify(this.$route.query))
      newQuery.modularName = name
      this.$router.push({ query: newQuery })
    },
    /**
     * @description: 打开二三级导航时，鼠标点击其余内容任何一处，收起导航恢复默认
     */
    addMouseUp(e) {
      if (!this.secondTitle) return
      const second = this.$refs.second
      const third = document.querySelector('.third-side-bar')
      // const company = document.querySelector('.company-side-bar')
      // 产品导航和重点企业导航
      // const _third = this.active === 0 ? third : company
      if (second || third) {
        if (!second.contains(e.target) && !third.contains(e.target)) {
          this.secondTitle = ''
          this.secondMenus = []
          this.thirdMenus = []
          // this.companyMenus = []
        }
      }
    },
    showSecondMenu({ channelName = '', childNode = [], secondLevelModular = '', status }) {
      if (!status) {
        this.$message.warning('当前品种无权限！')
        return
      }
      // 存在modularName时切换
      if (secondLevelModular) this.modularName = secondLevelModular
      if (this.secondTitle === channelName) {
        this.secondTitle = ''
        this.secondMenus = []
        this.thirdMenus = []
        return false
      }
      this.secondTitle = channelName
      this.secondMenus = childNode
    },
    /**
     * 显示三级菜单
     * @param data 传入的数据
     * channelName 三级菜单标题及被选中二级菜单名称
     * childNode 三级菜单节点列表
     * breedId 品种id
     * secondLevelModular 二级菜单的modular
     * status 状态
     */
    showThirdMenu(data) {
      const { channelName = '', childNode = [], breedId, secondLevelModular = '', status } = data
      if (!status) {
        this.$message.warning('当前品种无权限！')
        return
      }
      // 重点企业导航
      // if (this.active === 1) {
      //   this.showCompanyList(data)
      //   return
      // }
      // 存在modularName时切换
      if (secondLevelModular) this.modularName = secondLevelModular
      // 产品导航没有子项时直接跳转
      if (childNode && childNode.length === 0) {
        this.openBreed({ breedId, channelName, status })
      }
      if (this.thirdTitle === channelName) {
        this.thirdTitle = ''
        this.thirdMenus = []
        return false
      }
      this.thirdTitle = channelName
      this.thirdMenus = childNode
    },
    // showCompanyList(data) {
    //   if (this.thirdTitle === data.channelName) {
    //     this.thirdTitle = ''
    //     this.companyMenus = []
    //     return
    //   }
    //   this.thirdTitle = data.channelName
    //   this.companyMenus = data.companyList
    // },
    openBreed({ breedId, channelName, status }) {
      if (!status) {
        if (storage.get('token') === 'e04924a6a985d282626fc25d67a59485') {
          // 收起菜单
          this.secondTitle = ''
          this.secondMenus = []
          this.thirdMenus = []
          // this.companyMenus = []
          const registerDialog = new RegisterDialog()
          registerDialog.$mount()
          document.body.appendChild(registerDialog.$el)
        } else {
          this.$message.warning('当前品种无权限！')
        }
        return
      }
      this.resetMenus()
      this.openBreedRouter(breedId, channelName)
    },
    openCompany({ companyCode, companyName, status }) {
      if (!status) {
        this.$message.warning('当前品种无权限！')
        return
      }
      this.resetMenus()
      this.openCompanyRouter(companyCode, companyName)
    },
    resetMenus() {
      this.secondTitle = ''
      this.secondMenus = []
      this.thirdTitle = []
      this.thirdMenus = []
      // this.companyMenus = []
    },
    /**
     * @description: 搜索品种名称
     */
    async searchBreed() {
      this.searchResult = []
      // 搜索名称为空
      if (!this.searchKey) return
      const loadingInstance = Loading.service({
        fullscreen: true,
        text: '正在搜索产品...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      loadingInstance.$el.style.fontSize = '30px'
      try {
        const { response } = await listTreeQueryByName({ name: this.searchKey })
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
        // 搜索结果为空
        if (!response.length) {
          this.$message.error('搜索不到此品种')
          return
        }

        this.searchResult = response
        this.dialogVisible = true
      } catch (error) {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
        this.$message.error(error)
      }
    },
    /**
     * @description: 搜索跳转
     * @param {*} modularName
     * @param {*} channelName 显示名称
     * @param {*} breedId id
     * @param {*} type 类型 breed品种 company企业
     */
    goBreedDetail({ modularName, channelName, breedId, type }) {
      this.searchKey = ''
      if (type === 'breed') {
        getModularNameByBreedId({ breedId })
          .then(() => {
            this.dialogVisible = false
            this.modularName = modularName
            this.openBreedRouter(breedId, channelName)
          })
          .catch(err => {
            this.$message.error(err.message)
          })
      } else if (type === 'company') {
        getModularNameByCompanyCode({ securityCode: breedId })
          .then(() => {
            this.dialogVisible = false
            this.modularName = modularName
            this.openCompanyRouter(breedId, channelName)
          })
          .catch(err => {
            this.$message.error(err.message)
          })
      }
    },
    openBreedRouter(breedId, channelName) {
      if (breedId === 5306) {
        this.$openTab(
          `/refineryUnit/syntheticAnalysis?id=${breedId}&name=${channelName}&modularName=${this.modularName}`,
          channelName,
          this.$md5(breedId + channelName)
        )
      } else {
        this.$openTab(
          `/breed?id=${breedId}&name=${channelName}&modularName=${this.modularName}&type=1`,
          channelName,
          this.$md5(breedId + channelName)
        )
      }
    },
    openCompanyRouter(code, name) {
      this.$openTab(
        `/enterprise?companyId=${code}&name=${name}&modularName=${this.modularName}`,
        name,
        this.$md5(code + name)
      )
    },
    jumpPage(path, name) {
      // 数据排行
      let tabData = TAB_RANK
      // 上市企业
      if (name === '报告中心') tabData = TAB_REPORTCENTER
      this.$openTab(path, tabData.title, tabData.id)
    }
  }
}
</script>

<template>
  <div>
    <div class="side-bar">
      <div class="home" @click="goHome">
        <img src="@/assets/images/common/home.png" alt="" />
        <span>能化首页</span>
      </div>
      <el-input
        v-model="searchKey"
        placeholder="搜索产品"
        size="mini"
        clearable
        style="width: 90%"
        @keyup.enter.native="searchBreed"
      >
        <i slot="suffix" class="el-input__icon el-icon-search search-button" @click="searchBreed" />
      </el-input>
      <div class="side-content">
        <!-- 产品导航 -->
        <div class="nav nav-active">
          <span>产品导航</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <transition name="fade">
          <ul class="side-menu">
            <li
              v-for="child in menus"
              :key="child.id"
              :class="{ secondActive: child.channelName === secondTitle }"
              @click="showSecondMenu(child)"
            >
              <span>{{ child.channelName }}</span>
            </li>
          </ul>
        </transition>
        <!-- 重点企业研究 -->
        <!--        <div -->
        <!--          class="nav" -->
        <!--          :class="{ 'nav-active': active === 1 }" -->
        <!--          @click="showItem(1)" -->
        <!--        > -->
        <!--          <span>重点企业研究</span> -->
        <!--          <i class="el-icon-caret-bottom" /> -->
        <!--        </div> -->

        <!--        <transition name="fade"> -->
        <!--          <ul -->
        <!--            v-if="active === 1" -->
        <!--            class="side-menu" -->
        <!--          > -->
        <!--            <li -->
        <!--              v-for="child in companyList" -->
        <!--              :key="child.id" -->
        <!--              :class="{ secondActive: child.channelName === secondTitle }" -->
        <!--              @click="showSecondMenu(child)" -->
        <!--            > -->
        <!--              <span>{{ child.channelName }}</span> -->
        <!--            </li> -->
        <!--          </ul> -->
        <!--        </transition> -->
      </div>

      <div class="side-button">
        <div @click="jumpPage('/contrastScreen/price?name=price&title=价格', '数据排行')">
          <img src="@/assets/images/common/vector.png" alt="" />
          <span>数据排行</span>
        </div>
        <div @click="jumpPage('/reportCenter/list', '报告中心')">
          <img src="@/assets/images/common/company.png" alt="" /><span>报告中心</span>
        </div>
      </div>
    </div>

    <!-- 二级菜单 -->
    <transition name="show">
      <div v-show="secondMenus.length > 0" ref="second" class="second-side-bar">
        <el-scrollbar
          view-class="scrollbar-view"
          wrap-class="scrollbar-view-onlyXHidden"
          style="height: 100%"
          :native="false"
        >
          <div
            v-for="item in secondMenus"
            :key="item.id"
            class="bar-item"
            @click="showThirdMenu(item)"
          >
            <span :class="{ active: item.channelName === thirdTitle }">{{ item.channelName }}</span>
          </div>
        </el-scrollbar>
      </div>
    </transition>
    <!-- 三级菜单 -->
    <transition name="show">
      <div v-show="thirdMenus.length > 0" ref="third" class="third-side-bar">
        <div class="title">
          {{ thirdTitle }}
        </div>
        <div class="group">
          <div v-for="item in thirdMenus" :key="item.id">
            <template v-if="item.childNode && item.childNode.length > 0">
              <span>
                {{ item.channelName }}
                <span>
                  （
                  <template v-for="child in item.childNode">
                    <span
                      v-if="child.channelName"
                      :key="child.id"
                      class="hover fourth-span"
                      @click="openBreed(child)"
                      >{{ child.channelName }}
                    </span>
                  </template>
                  ）
                </span>
              </span>
            </template>
            <template v-else>
              <span class="hover" @click="openBreed(item)">{{ item.channelName }} </span>
            </template>
          </div>
        </div>
      </div>
    </transition>

    <!-- 企业信息弹窗 -->
    <!--    <transition name="show"> -->
    <!--      <div -->
    <!--        v-show="companyMenus.length > 0" -->
    <!--        ref="third" -->
    <!--        class="third-side-bar company-side-bar" -->
    <!--      > -->
    <!--        <div class="group"> -->
    <!--          <div -->
    <!--            v-for="item in companyMenus" -->
    <!--            :key="item.id" -->
    <!--          > -->
    <!--            <span -->
    <!--              class="hover" -->
    <!--              @click="openCompany(item)" -->
    <!--            >{{ item.companyName }} </span> -->
    <!--          </div> -->
    <!--        </div> -->
    <!--      </div> -->
    <!--    </transition> -->

    <!-- 品种搜索弹窗 -->
    <el-dialog title="搜索结果" :visible.sync="dialogVisible" width="20%" append-to-body>
      <div class="search-content">
        <p>搜索到以下结果，点击跳转</p>
        <ul>
          <li v-for="(item, i) in searchResult" :key="i" @click="goBreedDetail(item)">
            {{ item.channelPath }}
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/styles/element.scss';

@include scrollbar(6px, #53adff, 2px);

.side-bar {
  background-color: #3a466e;
  width: 140px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  :deep(.el-input__suffix) {
    right: 0;

    .search-button {
      height: calc(100% - 2px);
      float: right;
      cursor: pointer;
      background-color: #f5f7fa;
      border-radius: 0 3px 3px 0;
      border: 1px solid #dcdfe6;
    }
  }

  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 6px 0 4px;
    width: 100px;
    border-bottom: 1px solid #4b5a89;

    img {
      width: 15px;
      margin-right: 8px;
    }

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      color: #fff;
    }
  }

  .nav {
    width: 90%;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    color: #fff;
    padding-left: 10px;
    cursor: pointer;

    i {
      margin-left: 8px;
      font-size: 14px;
      transition: all 0.3s ease;
    }
  }

  .nav-active {
    box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.3);

    i {
      transform: rotate(-180deg);
    }
  }

  .side-content {
    width: 100%;
    height: calc(100% - 180px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .side-menu {
    width: 100%;
    flex: 1;
    list-style: none;
    overflow-y: auto;
    background: #232e54;
    box-shadow: 3px 0 6px 1px rgba(0, 0, 0, 0.16);
    padding-top: 8px;
    box-sizing: border-box;

    & > li {
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      line-height: 20px;
      margin-bottom: 20px;
      padding-left: 35px;
      padding-right: 10px;

      &.secondActive,
      &:hover {
        box-sizing: border-box;
        padding-left: 20px;
        color: #53adff;

        &::before {
          content: '';
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #53adff;
          margin-right: 10px;
        }
      }
    }
  }

  .side-button {
    width: 100px;
    position: absolute;
    bottom: 0;
    left: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      color: #fff;
      font-size: 14px;
      line-height: 30px;
      margin-bottom: 15px;
      text-align: center;
      cursor: pointer;

      & > img {
        width: 14px;
        margin-right: 12px;
      }

      & > span,
      & > img {
        vertical-align: middle;
      }
    }
  }
}

.fade-leave-active,
.fade-enter-active {
  transition: all 0.5s ease;
  overflow: hidden !important;
}

.fade-leave-active,
.fade-enter {
  flex: 0 !important;
}

.fade-leave,
.fade-enter-active {
  flex: 1;
}

.second-side-bar,
.third-side-bar {
  position: absolute;
  top: 0;
  height: 100%;
  overflow-y: auto;
  background-color: #3a466e;
  box-shadow: 3px 0 6px 6px rgba(0, 0, 0, 0.16);
  color: #fff;
  box-sizing: border-box;
  font-size: 14px;
  z-index: 9999;
}

.second-side-bar {
  left: 140px;
  width: 150px;

  .bar-item {
    width: 90%;
    margin-left: 5%;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px dashed #bfbfbf;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      &:not(.active):hover {
        color: #53adff;
      }

      &.active {
        height: 30px;
        width: 100%;
        border: 1px solid $menuActive;
        border-radius: 5px;
        background-color: $menuActive;
      }
    }
  }

  & > div.active > span {
    height: 30px;
    line-height: 30px;
    width: 100%;
    padding: 0;
    background-color: #5873d5;
    border-radius: 4px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    border-bottom: none;
  }
}

.third-side-bar {
  left: 290px;
  width: 350px;
  padding: 0 10px;

  .title {
    padding: 15px 0;
    border-bottom: 1px dashed #bfbfbf;
    margin-bottom: 15px;
    position: relative;

    &::before {
      content: ' ';
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 3px;
      margin-right: 4px;
      display: inline-block;
    }
  }

  .group {
    & > div {
      display: inline;
      margin: 10px 20px 10px 0;
      cursor: pointer;
      line-height: 30px;
    }

    .fourth-span {
      margin: 0 8px 0;
    }

    .fourth-span:first-of-type {
      margin-left: 0;
    }

    .fourth-span:last-of-type {
      margin-right: 0;
    }

    & > div span.hover {
      &:hover {
        color: #53adff;
      }
    }
  }
}

//.company-side-bar {
//  width: 180px;
//
//  .group {
//    display: flex;
//    flex-wrap: wrap;
//
//    > div {
//      text-align: center;
//      width: 50%;
//      margin: 10px 0 0 0;
//    }
//  }
//}

:deep(.el-input-group__append) {
  padding: 0 10px;
}

@include dialogStyle;
.search-content {
  padding: 0 15px 10px 15px;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    li {
      height: 30px;
      color: $light-blue;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>

<style>
/* .fade-leave-active below version 2.1.8 */
.show-enter-active,
.show-leave-active {
  transition: opacity 0.5s;
}

.show-enter,
.show-leave-to {
  opacity: 0;
}

.scrollbar-view {
  padding: 5px 10px;
}

.scrollbar-view-onlyXHidden {
  overflow-x: hidden;
}

i {
  font-style: normal;
}
</style>

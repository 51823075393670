/**
 * @description: 品种页面路由
 * meta参数：
 *  @title : 名称
 *  @isSpecial: 特殊品种新增页面
 *  @code 特殊品种页面code
 * props参数：
 *  @menuTitle: 当前显示路由名称
 *
 */
import mergeWith from 'lodash/mergeWith'

import Diesel from './diesel'
import MarineOil from './marineOil'
import FuelOil from './fuelOil'

// 路由对象中的数组合并
function customizer(objValue, srcValue) {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue)
  }
}

// 合并特殊品种路由，customizer方法在最后
const specialBreedRouter = mergeWith(Diesel, MarineOil, FuelOil, customizer)

export default [
  {
    path: '/breed',
    component: () => import('@/views/Breed/index'),
    name: 'Breed',
    redirect: '/breed/syntheticalAnalysis',
    children: [
      {
        path: 'syntheticalAnalysis',
        component: () => import('@/views/Breed/SyntheticalAnalysis/index'),
        name: 'syntheticalAnalysis',
        meta: { title: '品种详情' }
      },
      {
        path: 'dataComparse',
        component: () => import('@/views/Breed/dataComparse'),
        name: 'breedDataComparse',
        meta: { title: '多产品对比' }
      },
      {
        path: 'priceDataComparse',
        component: () => import('@/views/Breed/priceDataComparse'),
        name: 'priceDataComparse',
        meta: { title: '多产品对比' }
      }, // 价格中心
      {
        path: 'priceCenter',
        component: () => import('@/layouts/RouterView'),
        redirect: '/breed/priceCenter/mainstreamPrice',
        children: [
          // 主流价
          {
            path: 'mainstreamPrice',
            component: () => import('@/views/Breed/PriceCenter/mainstreamPrice'),
            name: 'mainstreamPrice',
            props: { menuTitle: '主流价' },
            meta: { title: '主流价' }
          }, // 市场价
          {
            path: 'marketPrice',
            component: () => import('@/views/Breed/PriceCenter/marketPrice'),
            name: 'marketPrice',
            props: { menuTitle: '市场价' },
            meta: { title: '市场价' }
          }, // 企业价
          {
            path: 'enterprisePrice',
            component: () => import('@/views/Breed/PriceCenter/marketPrice'),
            name: 'enterprisePrice',
            props: { menuTitle: '企业价' },
            meta: { title: '企业价' }
          }, // 国际价
          {
            path: 'internationalPrice',
            component: () => import('@/views/Breed/PriceCenter/marketPrice'),
            name: 'internationalPrice',
            props: { menuTitle: '国际价' },
            meta: { title: '国际价' }
          }, // 历史价格形成因素分析
          {
            path: 'historyPriceFactorAnalysis',
            component: () => import('@/views/Breed/PriceCenter/historyPriceFactorAnalysis'),
            name: 'historyPriceFactorAnalysis',
            meta: { title: '历史价格形成因素分析' }
          }, // 内页
          {
            path: 'innerPrice',
            component: () => import('@/views/Breed/PriceCenter/mainstreamPrice'),
            name: 'innerPrice',
            props: { menuTitle: '主流价' },
            meta: { title: '主流价' }
          }, // 全球原油期货价格
          {
            path: 'CrudeOilFuturesPrices',
            component: () => import('@/views/Breed/PriceCenter/CrudeOilFuturesPrices'),
            name: 'CrudeOilFuturesPrices',
            props: { menuTitle: '全球原油期货价格' },
            meta: {
              title: '全球原油期货价格',
              isSpecial: true,
              code: 'lz_gj_zj_004684,lz_gj_zj_004685'
            }
          }, // 国内原油现货价格
          {
            path: 'CrudeOilSpotPrice',
            component: () => import('@/views/Breed/PriceCenter/CrudeOilSpotPrice'),
            name: 'CrudeOilSpotPrice',
            props: { menuTitle: '国内原油现货价格' },
            meta: {
              title: '国内原油现货价格',
              isSpecial: true,
              code: 'lz_gj_zj_004693'
            }
          },
          // 汽油组分价格
          {
            path: 'GasolineComponentPrice',
            component: () => import('@/views/Breed/GasolineComponentPrice'),
            name: 'GasolineComponentPrice',
            meta: {
              title: '汽油组分价格',
              isSpecial: true,
              code: 'lz_custom10_000194'
            }
          },
          // DES中国
          {
            path: 'NaturalGasDesCn',
            component: () => import('@/views/Breed/NaturalGasPrice'),
            name: 'NaturalGasDesCn',
            meta: {
              title: 'DES中国',
              code: 'lz_gj_zj_002633',
              isSpecial: true
            }
          },
          // NBP期货
          {
            path: 'NaturalGasNbpFutures',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'NaturalGasNbpFutures',
            meta: {
              title: 'NBP期货',
              indexCode: ['lz_gj_zj_002627'],
              isSpecial: true
            }
          },
          // HH期货结算价格
          {
            path: 'NaturalGasHhFuturesPrice',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'NaturalGasHhFuturesPrice',
            meta: {
              title: 'HH期货结算价格',
              indexCode: ['lz_gj_zj_002625'],
              isSpecial: true
            }
          },
          // 上海居民用天然气价格
          {
            path: 'NaturalGasShanghaiCivilPrice',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'NaturalGasShanghaiCivilPrice',
            meta: {
              title: '上海居民用天然气价格',
              indexCode: ['NY_0000116368'],
              isSpecial: true
            }
          },
          // 上海工业用天然气价格
          {
            path: 'NaturalGasShanghaiIndustryPrice',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'NaturalGasShanghaiIndustryPrice',
            meta: {
              title: '上海工业用天然气价格',
              indexCode: ['NY_0000116417'],
              isSpecial: true
            }
          },
          // 管道天然气-NBP期货
          {
            path: 'NBPFutures',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'NBPFutures',
            meta: {
              title: 'NBP期货',
              indexCode: ['lz_gj_zj_002627'],
              isSpecial: true
            }
          },
          // 管道天然气-HH期货结算价格
          {
            path: 'HHFuturesSettlementPrice',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'HHFuturesSettlementPrice',
            meta: {
              title: 'HH期货结算价格',
              indexCode: ['lz_gj_zj_002625'],
              isSpecial: true
            }
          },
          // 管道天然气-TTF价格
          {
            path: 'TTFPrice',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'TTFPrice',
            meta: {
              title: 'TTF价格',
              indexCode: ['lz_gj_zj_002632'],
              isSpecial: true
            }
          },
          // DES中国
          {
            path: 'DESChina',
            component: () => import('@/views/Breed/NaturalGasPrice'),
            name: 'DESChina',
            meta: {
              title: 'DES中国',
              code: 'lz_gj_zj_002633',
              isSpecial: true
            }
          },
          // 布伦特
          {
            path: 'Brent',
            component: () => import('@/views/Breed/NaturalGasPrice'),
            name: 'Brent',
            meta: {
              title: '布伦特',
              code: 'lz_gj_zj_004685',
              isSpecial: true
            }
          },
          // 欧洲TTF
          {
            path: 'EuropeanTtf',
            component: () => import('@/views/Breed/NaturalGasPrice'),
            name: 'EuropeanTtf',
            meta: {
              title: '欧洲TTF',
              code: 'lz_gj_zj_002632',
              isSpecial: true
            }
          },
          // 亨利港(HH)
          {
            path: 'PortHenryHh',
            component: () => import('@/views/Breed/NaturalGasPrice'),
            name: 'PortHenryHh',
            meta: {
              title: '亨利港(HH)',
              code: 'lz_gj_zj_002625',
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasInternationalSpotPrice',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'liquefiedGasInternationalSpotPrice',
            meta: {
              title: '国际现货价格',
              indexCode: ['lz_gj_rb_001897'],
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasCpContractPrice',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'liquefiedGasCpContractPrice',
            meta: {
              title: 'CP合约价',
              indexCode: ['NY_0000133620'],
              isSpecial: true
            }
          },
          {
            path: 'NbutanePrice',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'NbutanePrice',
            meta: {
              title: '正丁烷价格',
              indexCode: ['lz_gn_sz_013428'],
              isSpecial: true
            }
          },
          {
            path: 'IsobutanePrice',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'IsobutanePrice',
            meta: {
              title: '异丁烷价格',
              indexCode: ['lz_gn_sz_013422'],
              isSpecial: true
            }
          },
          {
            path: 'JapaneseNaphtha',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'JapaneseNaphtha',
            meta: {
              title: '日本石脑油',
              indexCode: ['NY_0000115737'],
              isSpecial: true
            }
          },
          {
            path: 'ButanePredictionValue',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'ButanePredictionValue',
            meta: {
              title: '丁烷预测价',
              indexCode: ['103575'],
              isSpecial: true
            }
          }
        ]
      }, // 供应
      {
        path: 'supply',
        component: () => import('@/layouts/RouterView'),
        redirect: '/breed/supply/capacity',
        children: [
          // 产能
          {
            path: 'capacity',
            component: () => import('@/views/Breed/Supply/capacityOrYield'),
            name: 'capacity',
            meta: { title: '产能', filter: true },
            props: {
              temp: {
                id: 'capacity',
                name: '产能'
              }
            }
          }, // 产量
          {
            path: 'yield',
            component: () => import('@/views/Breed/Supply/capacityOrYield'),
            name: 'yield',
            meta: { title: '产量', filter: true },
            props: {
              temp: {
                id: 'yield',
                name: '产量'
              }
            }
          }, // 开工率
          {
            path: 'operatingRate',
            component: () => import('@/views/Breed/Supply/operatingRate'),
            name: 'operatingRate',
            meta: { title: '开工率', filter: true },
            props: {
              temp: {
                id: 'operatingRate',
                name: '开工率'
              }
            }
          },
          // 产能利用率
          {
            path: 'capacityUtilization',
            component: () => import('@/views/Breed/Supply/capacityUtilization'),
            name: 'capacityUtilization',
            meta: { title: '产能利用率', filter: true },
            props: {
              temp: {
                id: 'capacityUtilization',
                name: '产能利用率'
              }
            }
          },
          // 行业集中度
          {
            path: 'industryConcentration',
            component: () => import('@/views/Breed/Supply/industryConcentration'),
            name: 'industryConcentration',
            meta: { title: '行业集中度' },
            props: {
              temp: {
                id: 'industryConcentration',
                name: '行业集中度'
              }
            }
          }, // 损失量
          {
            path: 'lossCount',
            component: () => import('@/views/Breed/Supply/lossCount'),
            name: 'lossCount',
            meta: { title: '损失量' },
            props: {
              temp: {
                id: 'lossCount',
                name: '损失量'
              }
            }
          }, // 全球原油供应量
          {
            path: 'CrudeOilSupply',
            component: () => import('@/views/Breed/PriceCenter/CrudeOilLineBarMixPage'),
            name: 'CrudeOilSupply',
            props: {
              menuTitle: '全球原油供应量'
            },
            meta: { title: '全球原油供应量', isSpecial: true, code: 67880 }
          }, // 美国原油产量
          {
            path: 'CrudeOilYieldUSA',
            component: () => import('@/views/Breed/PriceCenter/CrudeOilLineBarMixPage'),
            name: 'CrudeOilYieldUSA',
            props: { menuTitle: '美国原油产量' },
            meta: { title: '美国原油产量', isSpecial: true, code: 4202 }
          }, // 美国钻井数量
          {
            path: 'CrudeOilWellCountUSA',
            component: () => import('@/views/Breed/PriceCenter/CrudeOilLineBarMixPage'),
            name: 'CrudeOilWellCountUSA',
            props: { menuTitle: '美国钻井数量' },
            meta: { title: '美国钻井数量', isSpecial: true, code: 4225 }
          },
          {
            path: 'GasolineDeviceProcessCapacity',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'GasolineDeviceProcessCapacity',
            meta: {
              title: '常减压装置原油一次加工能力',
              isSpecial: true,
              code: 70213
            }
          },
          {
            path: 'GasolineMainProcessCapacity',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'GasolineMainProcessCapacity',
            meta: {
              title: '主营炼厂原油一次加工能力',
              isSpecial: true,
              code: 71145
            }
          },
          {
            path: 'GasolineCNOperateRate',
            component: () => import('@/views/Breed/Supply/crudeOilOperate'),
            name: 'GasolineCNOperateRate',
            meta: {
              title: '中国炼油总开工率',
              isSpecial: true,
              code: 28740
            }
          },
          // {
          //   path: 'GasolineMainDieselProduction',
          //   component: () => import('@/views/Breed/OilSpecialBarLineMix'),
          //   name: 'GasolineMainDieselProduction',
          //   meta: {
          //     title: '主营炼厂柴油产量',
          //     isSpecial: true,
          //     code: '79504'
          //   }
          // },
          {
            path: 'GasolineMainDieselProduction',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'GasolineMainDieselProduction',
            meta: {
              title: '主营炼厂汽油产量',
              isSpecial: true,
              code: '79477'
            }
          },
          {
            path: 'GasolineIndependentProcessCapacity',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'GasolineIndependentProcessCapacity',
            meta: {
              title: '独立炼厂原油一次加工能力',
              isSpecial: true,
              code: 71144
            }
          },
          {
            path: 'GasolineIndependentGasolineProduction',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'GasolineIndependentGasolineProduction',
            meta: {
              title: '独立炼厂汽油产量',
              isSpecial: true,
              code: 79423
            }
          },
          {
            path: 'GasolineMainUtilizationRate',
            component: () => import('@/views/Breed/Supply/crudeOilOperate'),
            name: 'GasolineMainUtilizationRate',
            meta: {
              title: '主营炼厂原油产能利用率',
              isSpecial: true,
              code: 67877
            }
          },
          {
            path: 'GasolineIndependentUtilizationRate',
            component: () => import('@/views/Breed/Supply/crudeOilOperate'),
            name: 'GasolineIndependentUtilizationRate',
            meta: {
              title: '独立炼厂原油产能利用率',
              isSpecial: true,
              code: 67876
            }
          },
          // 管道天然气-天然气产量
          {
            path: 'NaturalGasProduction',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'NaturalGasProduction',
            meta: {
              title: '天然气产量',
              indexCode: [8634],
              isSpecial: true
            }
          },
          // 管道天然气-产量（分区域）
          {
            path: 'OutputByRegion',
            component: () => import('@/views/Breed/Supply/OutputByRegion'),
            name: 'OutputByRegion',
            meta: {
              title: '产量（分区域）',
              code: '74618',
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasCivilProductVolume',
            component: () => import('@/views/Breed/NaturalGasBarLineMix'),
            name: 'liquefiedGasCivilProductVolume',
            meta: {
              title: '民用气商品量',
              code: '73257',
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasIndustryProductVolume',
            component: () => import('@/views/Breed/NaturalGasBarLineMix'),
            name: 'liquefiedGasIndustryProductVolume',
            meta: {
              title: '工业气商品量',
              code: '73255',
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasPropaneProductVolume',
            component: () => import('@/views/Breed/NaturalGasBarLineMix'),
            name: 'liquefiedGasPropaneProductVolume',
            meta: {
              title: '丙烷商品量',
              code: '73256',
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasButaneProductVolume',
            component: () => import('@/views/Breed/NaturalGasBarLineMix'),
            name: 'liquefiedGasButaneProductVolume',
            meta: {
              title: '丁烷商品量',
              code: '73182',
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasOlefinOperatingRate',
            component: () => import('@/views/Breed/NaturalGasBarLineMix'),
            name: 'liquefiedGasOlefinOperatingRate',
            meta: {
              title: '烯烃深加工开工率',
              code: '73110',
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasPdhOperatingRate',
            component: () => import('@/views/Breed/NaturalGasBarLineMix'),
            name: 'liquefiedGasPdhOperatingRate',
            meta: {
              title: 'PDH开工率',
              code: '96856',
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasIsobutaneOperatingRate',
            component: () => import('@/views/Breed/NaturalGasBarLineMix'),
            name: 'liquefiedGasIsobutaneOperatingRate',
            meta: {
              title: '异丁烷脱氢开工率',
              code: '73508',
              isSpecial: true
            }
          },
          {
            path: 'propanePdhOperatingRate',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'propanePdhOperatingRate',
            meta: {
              title: 'PDH装置丙烷开工率',
              code: '96856',
              isSpecial: true
            }
          },
          // 润滑油-检修产能
          {
            path: 'MaintenanceCapacity',
            component: () => import('@/views/Breed/NaturalGasBarLineMix'),
            name: 'MaintenanceCapacity',
            meta: {
              title: '检修产能',
              code: '146228',
              isSpecial: true
            }
          },
          ...specialBreedRouter.supply
        ]
      }, // 成本/利润
      {
        path: 'costAndProfit',
        component: () => import('@/layouts/RouterView'),
        redirect: '/breed/costAndProfit/costTrend',
        children: [
          // 生产成本走势
          {
            path: 'costTrend',
            component: () => import('@/views/Breed/Cost/trend'),
            name: 'costTrend',
            meta: { title: '生产成本走势' },
            props: {
              temp: {
                id: 'costTrend',
                name: '生产成本'
              }
            }
          }, // 生产成本构成
          {
            path: 'costConstitute',
            component: () => import('@/views/Breed/Cost/constitute'),
            name: 'costConstitute',
            meta: { title: '生产成本构成' },
            props: {
              temp: {
                id: 'costConstitute',
                name: '不同工艺生产成本构成'
              }
            }
          }, // 毛利
          {
            path: 'costGrossProfit',
            component: () => import('@/views/Breed/Cost/grossProfit'),
            name: 'costGrossProfit',
            meta: { title: '毛利' },
            props: {
              temp: {
                id: 'costGrossProfit',
                name: '毛利'
              }
            }
          },
          // 毛利率
          {
            path: 'costGrossProfitRate',
            component: () => import('@/views/Breed/Cost/grossProfitRate'),
            name: 'costGrossProfitRate',
            meta: { title: '毛利率' },
            props: {
              temp: {
                id: 'costGrossProfitRate',
                name: '毛利率'
              }
            }
          },
          // 产业链利润
          {
            path: 'industrialChainProfit',
            component: () => import('@/views/Breed/Cost/industrialChainProfit.vue'),
            name: 'industrialChainProfit',
            meta: { title: '产业链利润' },
            props: {
              temp: {
                id: 'industrialChainProfit',
                name: '产业链利润'
              }
            }
          },
          // 主营单位综合炼油利润
          {
            path: 'CrudeOilMainCompanyProfit',
            component: () => import('@/views/Breed/Cost/CrudeOilMainCompanyProfit'),
            name: 'CrudeOilMainCompanyProfit',
            meta: {
              title: '主营单位综合炼油利润',
              isSpecial: true,
              code: '67881'
            },
            props: {
              temp: {
                id: 'CrudeOilMainCompanyProfit',
                name: '主营单位综合炼油利润'
              }
            }
          }, // 毛利率
          {
            path: 'CrudeOilAloneRefineryProfit',
            component: () => import('@/views/Breed/Cost/CrudeOilMainCompanyProfit'),
            name: 'CrudeOilAloneRefineryProfit',
            meta: {
              title: '独立炼厂综合炼油利润',
              isSpecial: true,
              code: '67879'
            },
            props: {
              temp: {
                id: 'CrudeOilAloneRefineryProfit',
                name: '独立炼厂综合炼油利润'
              }
            }
          },
          {
            path: 'GasolineRefineryProfit',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'GasolineRefineryProfit',
            meta: {
              title: '独立炼厂利润',
              isSpecial: true,
              code: 100131
            }
          },
          {
            path: 'GasolineExportProfit',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'GasolineExportProfit',
            meta: {
              title: '出口利润',
              isSpecial: true,
              code: 141384
            }
          },
          {
            path: 'GasolineRetailProfit',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'GasolineRetailProfit',
            meta: {
              title: '零售利润',
              isSpecial: true,
              code: 122272
            }
          },
          {
            path: 'ImportProfit',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'ImportProfit',
            meta: {
              title: '进口利润',
              isSpecial: true,
              code: 75726
            }
          },
          {
            path: 'liquefiedGasChinaTotalProduction',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'liquefiedGasChinaTotalProduction',
            meta: {
              title: '烷基化利润',
              indexCode: ['77624'],
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasPdhProfit',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'liquefiedGasPdhProfit',
            meta: {
              title: 'PDH利润',
              indexCode: ['146535'],
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasImportSalesProfit',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'liquefiedGasImportSalesProfit',
            meta: {
              title: '进口销售利润',
              indexCode: ['79529'],
              isSpecial: true
            }
          },
          {
            path: 'propaneLiquefiedGasPlantProfit',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'propaneLiquefiedGasPlantProfit',
            meta: {
              title: '液化气装置利润',
              isSpecial: true,
              code: 146535
            }
          },
          {
            path: 'ProfitofNbutaneDehydrogenationUnit',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'ProfitofNbutaneDehydrogenationUnit',
            meta: {
              title: '正丁烷脱氧装置利润',
              indexCode: ['66290'],
              isSpecial: true
            }
          },
          {
            path: 'ProfitofIsobutaneDeoxidationUnit',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'ProfitofIsobutaneDeoxidationUnit',
            meta: {
              title: '异丁烷脱氧装置利润',
              indexCode: ['103449'],
              isSpecial: true
            }
          },
          {
            path: 'ProfitOfHydrotreatingTailOil',
            component: () => import('@/views/Breed/NaturalGasPrice'),
            name: 'ProfitOfHydrotreatingTailOil',
            meta: {
              title: '加氢尾油利润',
              code: 22119,
              isSpecial: true
            }
          },
          {
            path: 'ProfitofRegeneratedOil',
            component: () => import('@/views/Breed/NaturalGasPrice'),
            name: 'ProfitofRegeneratedOil',
            meta: {
              title: '再生油利润',
              code: 20316,
              isSpecial: true
            }
          },
          {
            path: 'ProfitOfImportedBaseOil',
            component: () => import('@/views/Breed/NaturalGasPrice'),
            name: 'ProfitOfImportedBaseOil',
            meta: {
              title: '加氢尾油利润',
              code: 74965,
              isSpecial: true
            }
          },
          ...specialBreedRouter.costAndProfit
        ]
      }, // 企业
      {
        path: 'company',
        component: () => import('@/layouts/RouterView'),
        redirect: '/breed/company/companyProAnalysis',
        children: [
          // 生产企业分析
          {
            path: 'companyProAnalysis',
            component: () => import('@/views/Breed/Company/index'),
            name: 'companyProAnalysis',
            meta: { title: '生产企业分析' },
            props: {
              temp: {
                id: 'companyProAnalysis',
                name: '生产企业分析'
              }
            }
          }, // 拟在建分析
          {
            path: 'companyPlannedAnalysis',
            component: () => import('@/views/Breed/Company/index'),
            name: 'companyPlannedAnalysis',
            meta: { title: '拟在建分析' },
            props: {
              temp: {
                id: 'companyPlannedAnalysis',
                name: '拟在建分析'
              }
            }
          }, // 关停分析
          {
            path: 'companyShutDownReason',
            component: () => import('@/views/Breed/Company/index'),
            name: 'companyShutDownReason',
            meta: { title: '关停分析' },
            props: {
              temp: {
                id: 'companyShutDownReason',
                name: '关停分析'
              }
            }
          }
        ]
      }, // 需求
      {
        path: 'demand',
        component: () => import('@/layouts/RouterView'),
        redirect: '/breed/demand/apparentConsumption',
        children: [
          // 表观消费量
          {
            path: 'apparentConsumption',
            component: () => import('@/views/Breed/Demand/apparentConsumption'),
            name: 'apparentConsumption',
            meta: { title: '表观消费量' },
            props: {
              temp: {
                id: 'apparentConsumption',
                name: '表观消费量'
              }
            }
          }, // 全球原油需求量
          {
            path: 'CrudeOilRequirement',
            component: () => import('@/views/Breed/PriceCenter/CrudeOilLineBarMixPage'),
            name: 'CrudeOilRequirement',
            props: {
              menuTitle: '全球原油需求量'
            },
            meta: { title: '全球原油需求量', isSpecial: true, code: 67882 }
          }, // 美国炼厂开工率
          {
            path: 'CrudeOilOperatingRateUSA',
            component: () => import('@/views/Breed/Supply/crudeOilOperate'),
            name: 'CrudeOilOperatingRateUSA',
            meta: { title: '美国炼厂开工率', isSpecial: true, code: '4294' },
            props: {
              temp: {
                id: 'CrudeOilOperatingRateUSA',
                name: '美国炼厂开工率'
              }
            }
          }, // 日本炼厂开工率
          {
            path: 'CrudeOilOperatingRateJPN',
            component: () => import('@/views/Breed/Supply/crudeOilOperate'),
            name: 'CrudeOilOperatingRateJPN',
            meta: { title: '日本炼厂开工率', isSpecial: true, code: '73530' },
            props: {
              temp: {
                id: 'CrudeOilOperatingRateJPN',
                name: '日本炼厂开工率'
              }
            }
          }, // 中国炼厂产能利用率
          {
            path: 'CrudeOilCapacityUtilizationCHN',
            component: () => import('@/views/Breed/Supply/crudeOilOperate'),
            name: 'CrudeOilCapacityUtilizationCHN',
            meta: {
              title: '中国炼厂产能利用率',
              isSpecial: true,
              code: '28740'
            },
            props: {
              temp: {
                id: 'CrudeOilCapacityUtilizationCHN',
                name: '中国炼厂产能利用率'
              }
            }
          }, // 美国炼厂原油加工量
          {
            path: 'CrudeOilProcessingVolumeUSA',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'CrudeOilProcessingVolumeUSA',
            meta: {
              title: '美国炼厂原油加工量',
              isSpecial: true,
              code: '67888'
            },
            props: {
              temp: {
                id: 'CrudeOilProcessingVolumeUSA',
                name: '美国炼厂原油加工量'
              }
            }
          }, // 日本炼厂原油加工量
          {
            path: 'CrudeOilProcessingVolumeJPN',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'CrudeOilProcessingVolumeJPN',
            meta: {
              title: '日本炼厂原油加工量',
              isSpecial: true,
              code: '73533'
            },
            props: {
              temp: {
                id: 'CrudeOilProcessingVolumeJPN',
                name: '日本炼厂原油加工量'
              }
            }
          }, // 中国炼厂原油加工量
          {
            path: 'CrudeOilProcessingVolumeCHN',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'CrudeOilProcessingVolumeCHN',
            meta: {
              title: '中国炼厂原油加工量',
              isSpecial: true,
              code: '79367'
            },
            props: {
              temp: {
                id: 'CrudeOilProcessingVolumeCHN',
                name: '中国炼厂原油加工量'
              }
            }
          },
          {
            path: 'GasolineSales',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'GasolineSales',
            meta: {
              title: '独立炼厂汽油销售数量',
              isSpecial: true,
              code: '70186,79275,80870'
            }
          },
          {
            path: 'GasolineSalesRatioShandong',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'GasolineSalesRatioShandong',
            meta: {
              title: '独立炼厂汽油产销率（山东）',
              isSpecial: true,
              code: '97413'
            }
          },
          {
            path: 'GasolineSalesRatioNortheast',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'GasolineSalesRatioNortheast',
            meta: {
              title: '独立炼厂汽油产销率（东北）',
              isSpecial: true,
              code: '97408'
            }
          },
          {
            path: 'GasolineSalesRatioNorthwest',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'GasolineSalesRatioNorthwest',
            meta: {
              title: '独立炼厂汽油产销率（西北）',
              isSpecial: true,
              code: '97414'
            }
          },
          {
            path: 'NaturalGasByProvinceConsumption',
            component: () => import('@/views/Breed/Demand/NaturalGasByProvinceConsumption'),
            name: 'NaturalGasByProvinceConsumption',
            meta: {
              isSpecial: true
            }
          },
          {
            path: 'ApparentConsumptionOfNaturalGas',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'ApparentConsumptionOfNaturalGas',
            meta: {
              title: '天然气表观消费量',
              indexCode: [11362],
              isSpecial: true
            }
          },
          {
            path: 'ConsumptionOfNaturalGasByProvince',
            component: () => import('@/views/Breed/Demand/ConsumptionOfNaturalGasByProvince'),
            name: 'ConsumptionOfNaturalGasByProvince',
            meta: {
              isSpecial: true
            }
          },
          // 接收站槽批量
          {
            path: 'TerminalSlotBatch',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'TerminalSlotBatch',
            meta: {
              title: '接收站槽批量',
              isSpecial: true,
              code: '141969'
            }
          },
          // 加气站零售量
          {
            path: 'RetailofGasStation',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'RetailofGasStation',
            meta: {
              title: '加气站零售量',
              isSpecial: true,
              code: '141969'
            }
          },
          // 加气站零售毛利
          {
            path: 'liquefiedNaturalGasRetailGrossProfitOfGasStation',
            component: () => import('@/views/Breed/NaturalGasPriceNull'),
            name: 'liquefiedNaturalGasRetailGrossProfitOfGasStation',
            meta: {
              title: '加气站零售毛利',
              indexCode: ['8718'],
              isSpecial: true
            }
          },
          {
            path: 'propanePdhDemand',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'propanePdhDemand',
            meta: {
              title: 'PDH需求量',
              code: '73184',
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasDemand',
            component: () => import('@/views/Breed/NaturalGasBarLineMix'),
            name: 'liquefiedGasDemand',
            meta: {
              title: '液化气需求量',
              code: '73241',
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasPropaneDemand',
            component: () => import('@/views/Breed/NaturalGasBarLineMix'),
            name: 'liquefiedGasPropaneDemand',
            meta: {
              title: '丙烷需求量',
              code: '73184',
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasButaneDemand',
            component: () => import('@/views/Breed/NaturalGasBarLineMix'),
            name: 'liquefiedGasButaneDemand',
            meta: {
              title: '丁烷需求量',
              code: '73507',
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasLpgProductionSalesRatio',
            component: () => import('@/views/Breed/Demand/LiquefiedGasLpgProductionSalesRatio.vue'),
            name: 'liquefiedGasLpgProductionSalesRatio',
            meta: {
              title: 'LPG产销比',
              code: '75452',
              isSpecial: true
            }
          },
          {
            path: 'liquefiedGasMarketMindsetSurvey',
            component: () => import('@/views/Breed/Demand/LiquefiedGasMarketMindsetSurvey.vue'),
            name: 'liquefiedGasMarketMindsetSurvey',
            meta: {
              title: '原油市场心态调查',
              code: '80142,80141,80140',
              isSpecial: true
            }
          },
          // 润滑油-销量
          {
            path: 'salesVolume',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'salesVolume',
            meta: {
              title: '销量',
              isSpecial: true,
              code: '73855'
            }
          },
          {
            path: 'Bidding',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'Bidding',
            meta: {
              title: '招标',
              isSpecial: true,
              code: '125126'
            }
          },
          {
            path: 'DownstreamInventoryStatistics',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'DownstreamInventoryStatistics',
            meta: {
              title: '下游库存统计',
              code: '146346',
              isSpecial: true
            }
          },
          ...specialBreedRouter.demand
        ]
      }, // 库存
      {
        path: 'stock/:type?',
        component: () => import('@/layouts/RouterView'),
        redirect: '/breed/stock/factoryStock/:type?',
        meta: { title: '库存' },
        children: [
          // 原油库存
          {
            path: 'CrudeOilBusinessInventoryUSA',
            component: () => import('@/views/Breed/Stock/crudeOilstock'),
            name: 'CrudeOilBusinessInventoryUSA',
            meta: { title: '美国商业原油库存', isSpecial: true, code: '23287' },
            props: {
              temp: {
                id: 'CrudeOilBusinessInventoryUSA',
                name: '美国商业原油库存'
              }
            }
          },
          {
            path: 'CrudeOilInventoryUSACushingRegion',
            component: () => import('@/views/Breed/Stock/crudeOilstock'),
            name: 'CrudeOilInventoryUSACushingRegion',
            meta: {
              title: '美国库欣地区原油库存',
              isSpecial: true,
              code: '23288'
            },
            props: {
              temp: {
                id: 'CrudeOilInventoryUSACushingRegion',
                name: '美国库欣地区原油库存'
              }
            }
          },
          {
            path: 'CrudeOilInventoryJPN',
            component: () => import('@/views/Breed/Stock/crudeOilstock'),
            name: 'CrudeOilInventoryJPN',
            meta: { title: '日本原油库存', isSpecial: true, code: '70967' },
            props: {
              temp: {
                id: 'CrudeOilInventoryJPN',
                name: '日本原油库存'
              }
            }
          },
          {
            path: 'CrudeOilBusinessInventoryCHN',
            component: () => import('@/views/Breed/Stock/crudeOilstock'),
            name: 'CrudeOilBusinessInventoryCHN',
            meta: {
              title: '中国港口商业原油库存',
              isSpecial: true,
              code: '78662'
            },
            props: {
              temp: {
                id: 'CrudeOilBusinessInventoryCHN',
                name: '中国港口商业原油库存'
              }
            }
          },
          {
            path: 'GasolineSocialInventory',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'GasolineSocialInventory',
            meta: {
              title: '汽油社会库存',
              isSpecial: true,
              code: '100301'
            }
          },
          {
            path: 'GasolineIndependentInventoryCN',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'GasolineIndependentInventoryCN',
            meta: {
              title: '独立炼厂汽油库存（中国）',
              isSpecial: true,
              code: '69942'
            }
          },
          {
            path: 'GasolineIndependentInventoryShandong',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'GasolineIndependentInventoryShandong',
            meta: {
              title: '独立炼厂汽油库存（山东）',
              isSpecial: true,
              code: '79088'
            }
          },
          {
            path: 'GasolineIndependentInventoryRatio',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'GasolineIndependentInventoryRatio',
            meta: {
              title: '独立炼厂汽油库存比（山东）',
              isSpecial: true,
              code: '72462'
            }
          },
          {
            path: 'GasolineMainSocialInventory',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'GasolineMainSocialInventory',
            meta: {
              title: '主营销售公司汽油社会库存',
              isSpecial: true,
              code: '100091'
            }
          },
          {
            path: 'DomesticInventory',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'DomesticInventory',
            meta: {
              title: '国产库存',
              isSpecial: true,
              code: '142963'
            }
          },
          {
            path: 'TerminalInventory',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'TerminalInventory',
            meta: {
              title: '接收站库存',
              isSpecial: true,
              code: '116829'
            }
          },
          {
            path: 'StorageCapacity',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'StorageCapacity',
            meta: {
              title: '库容',
              isSpecial: true,
              code: '72682'
            }
          },
          ...specialBreedRouter.stock,
          // 工厂库存
          {
            path: 'factoryStock/:type?',
            component: () => import('@/views/Breed/Stock/stock'),
            name: 'factoryStock',
            meta: { title: '库存', filter: true }
          },
          {
            path: 'liquefiedGasPortInventoryExpansionRatio',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'liquefiedGasPortInventoryExpansionRatio',
            meta: {
              title: '港口库存库容比',
              isSpecial: true,
              code: '125751'
            }
          },
          {
            path: 'liquefiedGasRefineryInventoryExpansionRatio',
            component: () => import('@/views/Breed/OilSpecialLine'),
            name: 'liquefiedGasRefineryInventoryExpansionRatio',
            meta: {
              title: '炼厂库存库容比',
              isSpecial: true,
              code: '125773'
            }
          }
        ]
      }, // 海关数据
      {
        path: 'customs',
        component: () => import('@/layouts/RouterView'),
        redirect: '/breed/customs/importVolume',
        children: [
          // 进口量
          {
            path: 'importVolume',
            component: () => import('@/views/Breed/CustomsData/customsVolume'),
            name: 'importVolume',
            meta: { title: '进口量' },
            props: {
              temp: {
                id: 'importVolume',
                name: '进口量'
              }
            }
          }, // 中国进口金额
          {
            path: 'importPrice',
            component: () => import('@/views/Breed/CustomsData/importPrice'),
            name: 'importPrice',
            meta: { title: '中国进口金额' },
            props: {
              temp: {
                id: 'importPrice',
                name: '中国进口金额',
                type: '进口'
              }
            }
          }, // 中国进口（贸易伙伴）
          {
            path: 'importTradePratners',
            component: () => import('@/views/Breed/CustomsData/tradePartner'),
            name: 'importTradePratners',
            meta: { title: '中国进口（贸易伙伴）' },
            props: {
              temp: {
                id: 'importTradePratners',
                name: '中国进口（贸易伙伴）'
              }
            }
          }, // 中国进口（贸易方式）
          {
            path: 'importTradeMode',
            component: () => import('@/views/Breed/CustomsData/tradeMode'),
            name: 'importTradeMode',
            meta: { title: '中国进口（贸易方式）' },
            props: {
              temp: {
                id: 'importTradeMode',
                name: '中国进口（贸易方式）'
              }
            }
          }, // 中国进口（企业注册地）
          {
            path: 'importIncorporationPlace',
            component: () => import('@/views/Breed/CustomsData/tradeMode'),
            name: 'importIncorporationPlace',
            meta: { title: '中国进口（企业注册地）' },
            props: {
              temp: {
                id: 'importIncorporationPlace',
                name: '中国进口（企业注册地）'
              }
            }
          }, // 进口依存度
          {
            path: 'importDependenceDegree',
            component: () => import('@/views/Breed/CustomsData/dependenceDegree'),
            name: 'importDependenceDegree',
            meta: { title: '进口依存度' },
            props: {
              temp: {
                id: 'importDependenceDegree',
                name: '进口依存度'
              }
            }
          }, // 出口量
          {
            path: 'exportVolume',
            component: () => import('@/views/Breed/CustomsData/customsVolume'),
            name: 'exportVolume',
            meta: { title: '出口量' },
            props: {
              temp: {
                id: 'exportVolume',
                name: '出口量'
              }
            }
          }, // 中国出口金额
          {
            path: 'exportPrice',
            component: () => import('@/views/Breed/CustomsData/importPrice'),
            name: 'exportPrice',
            meta: { title: '中国出口金额' },
            props: {
              temp: {
                id: 'exportPrice',
                name: '中国出口金额',
                type: '出口'
              }
            }
          }, // 中国出口（贸易伙伴）
          {
            path: 'exportTradePratners',
            component: () => import('@/views/Breed/CustomsData/tradePartner'),
            name: 'exportTradePratners',
            meta: { title: '中国出口（贸易伙伴）' },
            props: {
              temp: {
                id: 'exportTradePratners',
                name: '中国出口（贸易伙伴）'
              }
            }
          }, // 中国出口（贸易方式）
          {
            path: 'exportTradeMode',
            component: () => import('@/views/Breed/CustomsData/tradeMode'),
            name: 'exportTradeMode',
            meta: { title: '中国出口（贸易方式）' },
            props: {
              temp: {
                id: 'exportTradeMode',
                name: '中国出口（贸易方式）'
              }
            }
          }, // 中国出口（企业注册地）
          {
            path: 'exportIncorporationPlace',
            component: () => import('@/views/Breed/CustomsData/tradeMode'),
            name: 'exportIncorporationPlace',
            meta: { title: '中国出口（企业注册地）' },
            props: {
              temp: {
                id: 'exportIncorporationPlace',
                name: '中国出口（企业注册地）'
              }
            }
          }, // 出口依赖度
          {
            path: 'exportDependenceDegree',
            component: () => import('@/views/Breed/CustomsData/dependenceDegree'),
            name: 'exportDependenceDegree',
            meta: { title: '出口依赖度' },
            props: {
              temp: {
                id: 'exportDependenceDegree',
                name: '出口依赖度'
              }
            }
          },
          // 天然气进口依存度
          {
            path: 'NaturalGasImportDependency',
            component: () => import('@/views/Breed/NaturalGasPrice'),
            name: 'NaturalGasImportDependency',
            meta: {
              title: '天然气进口依存度',
              code: '11359',
              isSpecial: true
            }
          }
        ]
      }, // 产业链分析
      {
        path: 'industryChain',
        component: () => import('@/layouts/RouterView'),
        redirect: '/breed/industryChain/distribute',
        children: [
          // 产业链分布图
          {
            path: 'distribute',
            component: () => import('@/views/Breed/IndustryChain/distribution'),
            name: 'distribute',
            meta: { title: '产业链分布图' }
          }, // 量价分析
          {
            path: 'volumePriceAnalysis',
            component: () => import('@/views/Breed/IndustryChain/volumePriceAnalysis'),
            name: 'volumePriceAnalysis',
            meta: { title: '量价分析' }
          }, // 产业链利润分布
          {
            path: 'profit',
            component: () => import('@/views/Breed/IndustryChain/profit'),
            name: 'profit',
            meta: { title: '产业链利润分布' }
          }, // 下游消费结构
          {
            path: 'consumptionStructure',
            component: () => import('@/views/Breed/IndustryChain/downstreamConsumptionStructure'),
            name: 'consumptionStructure',
            meta: { title: '下游消费结构' }
          }
        ]
      },
      {
        path: 'analysisPrediction',
        component: () => import('@/layouts/RouterView'),
        redirect: '/breed/analysisPrediction/historyPriceComparse',
        children: [
          // 历史价格对照分析
          {
            path: 'historyPriceComparse',
            component: () => import('@/views/Breed/PriceCenter/historyPrice'),
            name: 'historyPriceComparse',
            meta: { title: '历史价格对照分析' }
          }, // 价格季节性分析
          {
            path: 'priceSeasonalAnalysis',
            component: () => import('@/views/Breed/PriceCenter/priceSeasonalAnalysis'),
            name: 'priceSeasonalAnalysis',
            meta: { title: '价格季节性分析' }
          }, // 价格相关性分析
          {
            path: 'priceCorrelationAnalysis',
            component: () => import('@/views/Breed/PriceCenter/priceCorrelationAnalysis'),
            name: 'priceCorrelationAnalysis',
            meta: { title: '价格相关性分析' }
          }, // 价格预测
          {
            path: 'priceForecast',
            component: () => import('@/views/Breed/PriceCenter/priceForecast'),
            name: 'priceForecast',
            meta: { title: '价格预测' }
          }, // 价格预测 - 新
          {
            path: 'pricePrediction',
            component: () => import('@/views/Breed/PriceCenter/priceNewForecast'),
            name: 'pricePrediction',
            meta: { title: '价格预测' }
          }, // 原油运价指数(波罗的海)
          {
            path: 'CrudeOilFreightIndexTheBaltic',
            component: () => import('@/views/Breed/OilSpecialBarLineMix'),
            name: 'CrudeOilFreightIndexTheBaltic',
            meta: {
              title: '原油运价指数(波罗的海)',
              isSpecial: true,
              code: '73591'
            },
            props: {
              temp: {
                id: 'CrudeOilFreightIndexTheBaltic',
                name: '原油运价指数(波罗的海)'
              }
            }
          },
          // 心态调查
          {
            path: 'CrudeOilMentalityExamine',
            component: () => import('@/views/Breed/PriceCenter/CrudeOilMentalityExamine'),
            name: 'CrudeOilMentalityExamine',
            meta: {
              title: '心态调查',
              isSpecial: true,
              code: '70257,70258,70259'
            },
            props: {
              temp: {
                id: 'CrudeOilMentalityExamine',
                name: '心态调查'
              }
            }
          },
          {
            path: 'LubricatingOilMindsetSurvey',
            component: () => import('@/views/Breed/PriceCenter/LubricatingOilMindsetSurvey'),
            name: 'LubricatingOilMindsetSurvey',
            meta: {
              title: '心态调查',
              isSpecial: true,
              code: '136953,136954,136955'
            }
          }
        ]
      },
      {
        path: 'informationCenter',
        component: () => import('@/layouts/RouterView'),
        redirect: '/breed/informationCenter/all',
        name: 'InformationCenter',
        meta: { title: '报告中心' },
        children: [
          // 资讯中心-所有
          {
            path: 'all',
            component: () => import('@/views/Breed/InformationCenter/all'),
            name: 'InformationCenterAll',
            meta: { title: '资讯中心-所有' }
          }, // 资讯中心-列表
          {
            path: 'list/:type',
            component: () => import('@/views/Breed/InformationCenter/list'),
            name: 'InformationCenterList',
            meta: { title: '资讯中心-列表', keepAlive: true }
          }, // 资讯中心-详情
          {
            path: 'info',
            component: () => import('@/views/Breed/InformationCenter/info'),
            name: 'InformationCenterInfo',
            meta: { title: '资讯中心-详情' }
          }
        ]
      },
      {
        path: 'reportCenter',
        component: () => import('@/layouts/RouterView'),
        redirect: '/breed/reportCenter/all',
        name: 'ReportCenter',
        meta: { title: '报告中心' },
        children: [
          // 报告中心-所有
          {
            path: 'all',
            component: () => import('@/views/Breed/InformationCenter/all'),
            name: 'ReportCenterAll',
            meta: { title: '报告中心-所有' }
          }, // 报告中心-所有
          {
            path: 'list/:type',
            component: () => import('@/views/Breed/InformationCenter/list'),
            name: 'ReportCenterList',
            meta: { title: '报告中心-列表', keepAlive: true }
          }, // 报告中心-详情
          {
            path: 'info',
            component: () => import('@/views/Breed/InformationCenter/info'),
            name: 'ReportCenterInfo',
            meta: { title: '资讯中心-详情' }
          }
        ]
      }
    ]
  },
  {
    path: '/refineryUnit',
    component: () => import('@/views/RefineryUnit/index'),
    redirect: '/refineryUnit/syntheticAnalysis',
    children: [
      {
        path: 'syntheticAnalysis',
        component: () => import('@/views/RefineryUnit/syntheticAnalysis'),
        name: 'syntheticAnalysis',
        meta: { title: '品种详情' }
      },
      {
        path: 'information',
        component: () => import('@/views/RefineryUnit/information'),
        name: 'information',
        meta: { title: '文章详情' }
      }
    ]
  }
]

<script>
import Common from './common'
export default {
  name: 'KLine',
  mixins: [Common], // K线图
  data() {
    return {
      unitY: '',
      priceType: '主流价',
      option: {}
    }
  },
  watch: {
    chartTitle(val, oldVal) {
      if (val === oldVal) return
      const options = this.myChart && this.myChart.getOption()
      options && this.myChart.setOption({ title: [{ ...options.title[0], text: val }] })
    }
  },
  mounted() {
    this.initEchart(this.$refs.KLine)
  },
  methods: {
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      if (!response) {
        this.errorHandler()
      } else {
        const { data } = response
        const xData = []
        const yData = []
        let volumeData = []
        const maData = []
        if (Object.keys(data).length > 0) {
          this.unitY = data['单位'][0].value
          data.日期.forEach((res, i) => {
            xData.push(this.$dayjs(res.value).format('YYYY-MM-DD'))
            yData.push([
              data.开盘价[i].value,
              data.收盘价[i].value,
              data.最高价[i].value,
              data.最低价[i].value
            ])
            volumeData.push(data.成交量[i].value || '')
          })
        }
        const flag = volumeData.every(v => v === '')
        if (flag) volumeData = []
        this.queryData.avg_line.forEach((val, index) => {
          maData.push({
            name: val,
            type: 'line',
            data: [],
            smooth: true,
            showSymbol: false
          })
          response.data[val] &&
            response.data[val].forEach(item => {
              maData[index].data.push(item.value)
            })
        })
        this.setOption(xData, yData, maData, volumeData)
      }
    },
    setOption(xData = [], yData = [], maData = [], volumeData = []) {
      const upColor = '#BA0C2F'
      const upBorderColor = '#8A0000'
      const downColor = '#1A9016'
      const downBorderColor = '#008F28'
      const that = this

      // 2021年11月5日 去掉成交量
      volumeData = []

      this.option = {
        title: {
          text: this.chartTitle,
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14
          },
          left: 'center'
        },
        animation: false,
        tooltip: {
          trigger: 'axis',
          confine: true,
          bordeRadius: 4,
          backgroundColor: 'rgba(255,255,255,0.94)',
          textStyle: {
            fontSize: 12,
            color: '#121212'
          },
          formatter(params) {
            const dimensionNames = ['开盘价', '收盘价', '最高价', '最低价']
            let result = `${params[0].name}<br/>`
            params.forEach(item => {
              if (item.value) {
                if (item.seriesName.includes('K')) {
                  result += `${item.marker} ${item.seriesName}</br>`
                  dimensionNames.forEach((child, index) => {
                    result += `&nbsp;&nbsp;&nbsp;&nbsp;${child}: ${
                      item.value.toString().split(',')[index + 1]
                    } ${that.unitY}</br>`
                  })
                } else {
                  result += `&nbsp;&nbsp;&nbsp;&nbsp;${
                    item.seriesName
                  } : ${item.value.toString()} ${that.unitY}</br>`
                }
              }
            })
            return result
          }
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: [0, 1]
            }
          ]
        },
        dataZoom: [
          {
            type: 'inside',
            xAxisIndex: [0, 1]
          },
          {
            type: 'slider',
            bottom: '3%',
            xAxisIndex: [0, 1]
          }
        ],
        xAxis: [
          {
            type: 'category',
            data: xData,
            boundaryGap: true
          },
          {
            show: volumeData.length,
            type: 'category',
            gridIndex: 1,
            data: xData,
            boundaryGap: true,
            splitLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false }
          }
        ],
        yAxis: [
          {
            name: this.unitY,
            scale: true,
            splitNumber: 2,
            splitLine: { show: true },
            axisTick: { show: false },
            axisLabel: {
              inside: !!(volumeData && volumeData.length > 0),
              formatter: '{value}\n'
            }
          },
          {
            scale: true,
            gridIndex: 1,
            splitNumber: 1,
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false }
          }
        ],
        visualMap: [
          {
            show: false,
            seriesIndex: 2,
            dimension: 2,
            pieces: [
              {
                value: 1,
                color: upColor
              },
              {
                value: -1,
                color: downColor
              }
            ]
          }
        ],
        grid: [
          {
            bottom: volumeData && volumeData.length > 0 ? '40%' : '15%',
            left: volumeData && volumeData.length > 0 ? '2%' : '10',
            top: this.amplify ? '10%' : '18%',
            containLabel: true
          },
          {
            top: '65%',
            left: volumeData && volumeData.length > 0 ? '2%' : '56',
            bottom: 42,
            containLabel: true
          }
        ],
        series: [
          {
            show: volumeData && volumeData.length > 0,
            name: '成交量',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: volumeData
          },
          {
            type: 'candlestick',
            name: `${this.queryData.frequencyName[0]}K`,
            data: yData,
            itemStyle: {
              color: upColor,
              color0: downColor,
              borderColor: upBorderColor,
              borderColor0: downBorderColor
            }
          },
          ...maData
        ]
      }
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="KLine" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Author: bjp
 * @Description: 进出口量-连续图
-->
<script>
import Common from './common'
import { getData } from '@/apis/server'
export default {
  name: 'ImportsAndExportsCountContinuous',
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.ImportsAndExportsCountContinuous)
    this.chartLoading = true
  },
  methods: {
    async getData() {
      const { dataApi } = this.widget
      const query = {
        queryField: {
          breedId: this.id,
          ...this.queryData
        }
      }
      const { response } = await getData(dataApi, query)
      this.dataHandler(response)
    },
    dataHandler(response) {
      let chartData = []
      let unitY = ''
      if (!response || !Object.keys(response).length) {
        this.errorHandler()
        return
      }
      chartData = response.data
      unitY = chartData['进出口数量单位'][0].value
      this.myChart.hideLoading()
      this.chartLoading = false
      this.setOption(chartData, unitY)
    },
    setOption(chartData = [], unitY = '') {
      this.option = {
        title: {
          show: false
        },
        dataZoom: {
          show: true,
          bottom: '3%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].name}<br/>`
            for (let i = 0; i < params.length; i++) {
              html += `${
                params[i].marker + params[i].seriesName
              }：<b class="chart-content style="float:right;margin-left:10px">${
                params[i].value || params[i].value === 0
                  ? `${params[i].value} ${unitY || ''}</b><br>`
                  : '-' + '</b><br>'
              }`
            }
            return html
          }
        },
        legend: {
          top: '3%'
        },
        grid: {
          top: this.amplify ? '12%' : '20%',
          left: '3%',
          bottom: '13%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: this.$filterValue(chartData['时间'], 'YYYY-MM-DD')
        },
        yAxis: {
          name:
            Object.keys(chartData).length > 0
              ? this.$filterValue(chartData['进出口数量单位'])[0]
              : '',
          type: 'value',
          min(value) {
            return Math.round(value.min * 0.8)
          },
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          splitNumber: 4
        },
        series: {
          name: this.name,
          type: 'line',
          data: this.$filterValue(chartData['进出口数量'])
        }
      }
      const moduleName = this.$parent.$parent.title || this.$store.state.amplify.title || ''
      this.option.title.text = `${this.name}-${moduleName}`
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="ImportsAndExportsCountContinuous" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Description: 企业-主营理论利润
 * @Date: 2022-04-14 13:43:07
 * @LastEditTime: 2022-08-25 19:18:04
-->
<script>
import Common from './common'
export default {
  name: 'EnterprisePlanBuildProject', // 企业-主营理论利润
  mixins: [Common],
  mounted() {
    this.initEchart(document.querySelector('#EnterprisePlanBuildProject'))
  },
  methods: {
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      if (response && Object.keys(response).length > 0) {
        let xData = []
        const series = []
        let unit = ''
        for (const [key, value] of Object.entries(response)) {
          xData.push(key)
          value.forEach(item => {
            const index = series.findIndex(v => v.name === item.breedName)
            if (!unit) unit = item.profitUnit
            if (index === -1) {
              // 不存在该品种 push
              series.push({
                name: `${item.breedName}-${item.process}`,
                type: 'bar',
                stack: 'profit',
                data: [[key, item.profit, item.profitUnit]]
              })
            } else {
              // 已经存在该品种
              series[index].data.push([key, item.profit, item.profitUnit])
            }
          })
        }
        xData = xData.sort((a, b) => this.$dayjs(a).valueOf() - this.$dayjs(b).valueOf())
        this.setOption(xData, series, unit)
      } else {
        this.errorHandler()
      }
    },
    setOption(xData, series, unit = '') {
      const option = {
        legend: {
          type: 'scroll',
          left: 'center'
        },
        grid: {
          top: '18%',
          bottom: '20%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`
            })
            return html
          }
        },
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: {
          name: unit,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        dataZoom: [
          {
            type: 'inside',
            startValue: 0,
            endValue: 20
          },
          {
            type: 'slider'
          }
        ],
        series
      }
      this.myChart.setOption(option, true)
    }
  }
}
</script>

<template>
  <div id="EnterprisePlanBuildProject" v-loading="chartLoading" class="chart-container" />
</template>

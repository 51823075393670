<script>
import Common from '../common.js'
import {
  getCompanyContinuous,
  getContinuous,
  getInternetContinuous,
  getMarketContinuous
} from '@/apis/Breed/PriceCenter.js'
import { formatDayJsTime } from '@/utils/formatData'

export default {
  name: 'ContinuousGraph',
  mixins: [Common], // 连续图
  props: {
    priceType: {
      type: String,
      default: '主流价'
    },
    breedName: {
      type: String,
      default: ''
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chartData: {},
      option: {}
    }
  },
  watch: {
    chartTitle() {
      this.myChart && this.setOption()
    }
  },
  mounted() {
    this.initEchart(this.$refs.ContinuousGraph)
  },
  methods: {
    getData() {
      let url = function () {}
      switch (this.priceType) {
        case '主流价':
          url = getContinuous
          break
        case '市场价':
          url = getMarketContinuous
          break
        case '企业价':
          url = getCompanyContinuous
          break
        case '国际价':
          url = getInternetContinuous
          break
        default:
          break
      }
      url(this.queryData)
        .then(res => {
          this.chartLoading = false
          this.myChart.clear()
          const { data = {} } = res.response || {}
          if (!data || Object.keys(data).length === 0) {
            this.errorHandler()
            return false
          }
          this.chartData = data
          this.setOption()
          this.myChart.hideLoading()
        })
        .catch(() => {
          this.errorHandler()
        })
    },
    setOption() {
      const data = this.chartData
      let unit = ''
      // 图表数据
      this.myChart.clear()
      this.myChart.hideLoading()
      this.chartLoading = false
      const Edata = []
      const maData = []
      const { frequency, frequencyName } = this.queryData.queryField
      const frequencyType = frequency || frequencyName
      const flag = frequencyType === '周度' || frequencyType === '季度'
      const formatTime = i => {
        // const value = data['展示日期'][i].value
        // 周度单独的判断逻辑源于后期迭代要求周度数据全部展示日期，屎山代码
        // if (frequencyType === '周度') {
        //   return this.$dayjs(data['日期'][i].value).format(formatDayJsTime(frequencyType))
        // } else if (!flag && this.priceType !== '主流价') {
        //   return this.$dayjs(value).format(formatDayJsTime(frequencyType))
        // } else {
        //   return value
        // }
        // 2023-07-24 日期重新格式化
        return this.$dayjs(data['日期'][i].value).format('YYYY-MM-DD')
      }
      if (this.priceType === '主流价') {
        try {
          data['主流价格'].forEach((ele, i) => {
            if (!unit) unit = data['单位'][i].value
            Edata.push([
              formatTime(i),
              ele.value,
              [data['核心因素'][i].value || '', data['信息地雷'][i].value || '']
            ])
          })
        } catch (e) {
          console.log(data)
        }
      } else {
        try {
          data['价格值'].forEach((ele, i) => {
            if (!unit) unit = data['计价单位名称'] ? data['计价单位名称'][i].value : ''
            Edata.push([this.$dayjs(ele.name).format('YYYY-MM-DD'), ele.value, ['', '']])
          })
        } catch (e) {
          console.log(data)
        }
      }
      // 取ma值
      this.queryData.queryField.avg_line?.forEach((val, index) => {
        maData.push({
          name: val,
          type: 'line',
          connectNulls: true,
          data: []
        })
        data[val] &&
          data[val].forEach((item, j) => {
            if (this.priceType === '主流价') {
              maData[index].data.push([formatTime(j), item.value])
            } else {
              maData[index].data.push([this.$dayjs(item.name).format('YYYY-MM-DD'), item.value])
            }
          })
      })
      this.option = {
        title: {
          // 标题是品种名称+属性
          text: this.chartTitle,
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14
          },
          left: 'center',
          top: '5%'
        },
        legend: {
          bottom: '11%'
        },
        grid: {
          top: this.magnifier ? '10%' : '28%',
          bottom: '20%',
          left: '1%',
          right: '1%',
          containLabel: true
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          backgroundColor: '#f2f2f2',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}${
                e.value ? unit || '' : ''
              }<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            right: '1%',
            bottom: '3%'
          }
        ],
        xAxis: {
          type: 'category'
        },
        yAxis: {
          name: unit,
          type: 'value',
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          splitNumber: 4
        },
        dataset: {
          source: Edata
        },
        series: [
          {
            name: this.breedName || this.name || this.priceType,
            type: 'line',
            connectNulls: true,
            encode: {
              x: 0,
              y: 1
            },
            smooth: true
          },
          ...maData
        ]
      }
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="ContinuousGraph" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'
import { getData } from '@/apis/server'
export default {
  name: 'CapacityChangeForecast', // 产能变化与预测
  mixins: [Common],
  data() {
    return {
      yearList: [],
      seriesData: {}
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#CapacityChangeForecast'))
    this.getData()
  },
  methods: {
    getData() {
      const { dataApi } = this.widget
      getData(dataApi, {
        queryField: {
          breedId: 4429
        }
      })
        .then(({ response }) => {
          this.myChart.clear()
          this.myChart.hideLoading()
          if (!response || response.length === 0) {
            this.errorHandler()
            return false
          }
          this.yearList = Object.keys(response).sort()
          const data = Object.values(response).flat(Infinity)
          const enterpriseList = Array.from(new Set(data.map(item => item.companyName)))
          const temp = {}
          enterpriseList.forEach(enterprise => {
            temp[enterprise] = data
              .filter(item => item.companyName === enterprise)
              .map(item => ({ name: item.key, value: item.capacity }))
          })
          this.seriesData = temp
          this.setOption()
        })
        .finally(() => {
          this.chartLoading = false
        })
    },
    setOption() {
      const option = {
        legend: {
          type: 'scroll'
        },
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: this.yearList
        },
        yAxis: {},
        dataZoom: [{ type: 'inside' }, { type: 'slider' }],
        series: Object.keys(this.seriesData).map(item => {
          return {
            type: 'bar',
            name: item,
            stack: '产能变化与预测',
            barWidth: 20,
            data: this.seriesData[item]
          }
        })
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div id="CapacityChangeForecast" class="chart-container" />
</template>

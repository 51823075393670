<script>
import Common from './common'
import { getData } from '@/apis/server'

export default {
  name: 'LiquefiedGasInventory', // 炼厂加工量
  mixins: [Common],
  props: {
    // 是否初始化数据
    initData: {
      type: Boolean,
      default: false
    },
    axisLabelRoute: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.NaturalGasLineBarMix)
    this.initData && this.getData()
  },
  methods: {
    async getData() {
      this.chartLoading = true
      const { dataApi } = this.widget
      const query = {
        queryField: {
          ...this.queryData
        }
      }
      try {
        const { response } = await getData(dataApi, query)
        this.dataHandler(response)
      } catch (error) {
        this.errorHandler()
      }
    },
    /**
     * @description 处理数据的逻辑
     * @param {Object} response
     */
    dataHandler(response) {
      if (response?.length === 0) {
        this.errorHandler()
      }

      const xData = new Set()
      let series = []
      const units = []
      const colorList = ['#023985', '#bfbfbf', '#8aa1c6']
      for (const value of response) {
        const timeAxisData =
          this.queryData.frequencyName !== '周度'
            ? value.exhDate
            : this.$dayjs(value.researchStopDate).format('YYYY-MM-DD')
        xData.add(timeAxisData)
        const index = series.findIndex(item => item.name === value.indexName)

        if (index === -1) {
          const isPrice = value.dataType === '价格'
          const i = isPrice ? 1 : 0
          units[i] = value.unitName
          const obj = {
            name: value.indexName,
            type: isPrice ? 'line' : 'bar',
            yAxisIndex: i,
            color: isPrice ? '#BC0008' : colorList.splice(0, 1),
            data: [[timeAxisData, value.inputValue, value.unitName]]
          }
          if (!isPrice) {
            obj.stack = 'stack'
          }
          series.push(obj)
        } else {
          series[index].data.push([timeAxisData, value.inputValue, value.unitName])
        }
      }
      // 如果加工量不在第一项，则将其放到第一项
      if (series[0].yAxisIndex !== 0) {
        series = series.reverse()
      }
      this.setOption(series, [...xData], units)
    },
    setOption(series = [], xData, units = []) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        title: {
          show: false
        },
        grid: {
          top: this.amplify ? '10%' : '18%',
          bottom: '13%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            startValue: xData.length - 30
          }
        ],
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: [
          {
            type: 'value',
            name: units[0],
            nameTextStyle: {
              align: 'right'
            },
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            name: units[1],
            nameTextStyle: {
              align: 'left'
            },
            scale: true,
            splitLine: {
              show: false
            }
          }
        ],
        series
      }

      // 判断横坐标标签是否需要倾斜
      if (this.axisLabelRoute) {
        this.option.xAxis.axisLabel = {
          rotate: -30
        }
        // 标签倾斜时需要给右边留出足够显示标签的空白
        this.option.grid.right = 36
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="NaturalGasLineBarMix" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Author: bjp
 * @Description: 需求-表观消费量
-->
<script>
import Common from './common'
export default {
  name: 'ApparentConsumption', // 下游消费结构Bar
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.chartLoading = true
    this.initEchart(this.$refs.ApparentConsumption)
    this.getData()
  },
  methods: {
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      this.myChart.clear()
      this.myChart.hideLoading()
      let unitY = ''
      let priceUnit = ''
      if (!response) {
        this.errorHandler()
        return
      }
      unitY = response.data['表观消费量单位'].find(v => v.value)?.value || ''
      priceUnit = response.data['价格单位'].find(v => v.value)?.value || ''
      this.chartLoading = false
      this.chartInit(response.data, unitY, priceUnit)
    },
    chartInit(chartData = [], unitY = '', priceUnit = '') {
      const unitArr = [unitY, '%', priceUnit]
      this.option = {
        title: {
          show: false
        },
        dataZoom: {
          show: true,
          bottom: '3%',
          startValue: [...this.$filterValue(chartData['日期'], 'YYYY-MM-DD')].length - 30
        },
        legend: {
          data: ['表观消费量', '消费增速', '价格'],
          bottom: '9%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].name}<br/>`
            for (let i = 0; i < params.length; i++) {
              html += `${
                params[i].marker + params[i].seriesName
              }：<b class="chart-content style="float:right;margin-left:10px">${
                params[i].value || params[i].value === 0
                  ? `${params[i].value} ${unitArr[i]}</b><br>`
                  : '-' + '</b><br>'
              }`
            }
            return html
          }
        },
        grid: {
          top: this.amplify ? '10%' : '20%',
          left: '3%',
          right: '2%',
          bottom: '18%'
        },
        xAxis: {
          type: 'category',
          data: this.$filterValue(chartData['日期'], 'YYYY-MM-DD'),
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: [
          {
            type: 'value',
            name: unitY,
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            splitNumber: 4
          },
          {
            type: 'value',
            name: '%',
            axisLabel: {
              formatter: '{value}%'
            },
            splitLine: {
              show: false
            },
            scale: true,
            splitNumber: 4
          },
          {
            type: 'value',
            alignTicks: true,
            position: 'left',
            offset: '36',
            name: priceUnit,
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '表观消费量',
            type: 'bar',
            data: this.$filterValue(chartData['表观消费量']),
            color: '#023985'
          },
          {
            name: '消费增速',
            type: 'line',
            yAxisIndex: 1,
            data: this.$filterValue(chartData['表观消费量环比(%)']),
            color: '#bc0008'
          },
          {
            name: '价格',
            type: 'line',
            yAxisIndex: 2,
            data: this.$filterValue(chartData['均价']),
            color: '#bfbfbf'
          }
        ]
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="ApparentConsumption" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'

export default {
  name: 'MainBusinessComposition', // 主营构成
  mixins: [Common],
  mounted() {
    this.initEchart(document.querySelector('#MainBusinessComposition'))
  },
  methods: {
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      if (response && Object.keys(response).length > 0) {
        const temp = {}
        const obj = {}
        for (const key in response) {
          if (response[key].length > 0) {
            response[key].forEach(item => {
              if (!temp[item.mainBusinessComp]) temp[item.mainBusinessComp] = ''
              temp[item.mainBusinessComp] += `${
                item.mainBusinessProfit || !item.mainBusinessProfit ? item.mainBusinessProfit : ''
              }=`
            })
          }
        }
        for (const key1 in temp) {
          obj[key1] = temp[key1].split('=').splice(0, temp[key1].split('=').length - 1)
        }
        this.setOption(response, obj)
      } else {
        this.errorHandler()
      }
    },
    setOption(response, data) {
      const option = {
        legend: {
          type: 'scroll',
          left: '2%'
        },
        grid: {
          bottom: '20%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: Object.keys(response)
        },
        yAxis: {
          name: '单位：万元'
        },
        dataZoom: [
          {
            type: 'inside',
            startValue: 0,
            endValue: 20
          },
          {
            type: 'slider',
            startValue: 0,
            endValue: 20
          }
        ],
        series: Object.keys(data).map(item => {
          return {
            type: 'bar',
            name: item,
            stack: '产能变化与预测',
            data: data[item],
            barWidth: 30
          }
        })
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div id="MainBusinessComposition" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from '../common.js'

export default {
  name: 'DependenceDegree',
  mixins: [Common], // 中国供需平衡
  props: {
    chartData: {
      type: Array,
      default() {
        return []
      }
    },
    unit: {
      type: String,
      default: '万吨'
    }
  },
  data() {
    return {
      option: {}
    }
  },
  watch: {
    chartData: {
      handler() {
        this.setOption()
      },
      deep: true
    }
  },
  mounted() {
    this.initEchart(this.$refs.DependenceDegree)
    this.setOption()
  },
  methods: {
    setOption() {
      this.myChart.clear()
      this.chartLoading = false
      if (this.chartData.length === 0) {
        this.errorHandler()
        return
      }
      this.myChart.hideLoading()
      let data = []
      let cusType = ''
      const chartData = JSON.parse(JSON.stringify(this.chartData))
      chartData.sort((a, b) => {
        return (
          this.$dayjs(a.dataDate).format('YYYYMMDD') - this.$dayjs(b.dataDate).format('YYYYMMDD')
        )
      })
      if (this.$route.path.includes('import')) {
        data = chartData.map(val => {
          return [
            this.$dayjs(val.dataDate).format('YYYY-MM-DD'),
            val.imDependDegree || '-',
            val.imVolume || '-',
            val.appConsump || '-'
          ]
        })
        cusType = '进口'
      } else {
        data = chartData.map(val => {
          return [
            this.$dayjs(val.dataDate).format('YYYY-MM-DD'),
            val.exDependDegree || '-',
            val.exVolume || '-',
            val.appConsump || '-'
          ]
        })
        cusType = '出口'
      }
      this.option = {
        title: {
          show: false
        },
        grid: {
          bottom: '20%',
          left: '2%',
          right: '2%'
        },
        dataZoom: {
          bottom: '5%'
        },
        legend: {
          bottom: '11%',
          data: [cusType === '进口' ? '进口依存度' : '出口依赖度', `${cusType}量`, '表观消费量']
        },
        dataset: {
          source: data
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            return `${params[0].axisValue}<br />
                    ${params[0].seriesName}：${
              params[0].data[1] && params[0].data[1] !== '-' ? `${params[0].data[1]}%` : '-'
            }<br/>
                    ${params[1].seriesName}：${params[0].data[2]}${this.unit}<br />
                    ${params[2].seriesName}：${params[0].data[3]}${this.unit}<br />`
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            name: this.unit,
            type: 'value'
          },
          {
            name: '%',
            type: 'value',
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: cusType === '进口' ? '进口依存度' : '出口依赖度',
            type: 'line',
            yAxisIndex: 1
          },
          {
            name: `${cusType}量`,
            type: 'bar',
            yAxisIndex: 0
          },
          {
            name: '表观消费量',
            type: 'bar',
            yAxisIndex: 0
          }
        ]
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div ref="DependenceDegree" v-loading="chartLoading" class="chart-container" />
</template>

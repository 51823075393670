<script>
import Common from '../common.js'
import {
  getAnnualCurve,
  getCompanyAnnualCurve,
  getInternetAnnualCurve,
  getMarketAnnualCurve
} from '@/apis/Breed/PriceCenter.js'

export default {
  name: 'PriceYearComparison',
  mixins: [Common], // 分年对比
  props: {
    priceType: {
      type: String,
      default: '主流价'
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      unitY: '',
      yData: {},
      title: '',
      dataSetSource: [],
      option: {}
    }
  },
  watch: {
    chartTitle(newVal) {
      let options = this.myChart && this.myChart.getOption()
      if (!options || !options.title) {
        options = {
          title: []
        }
      }
      this.myChart.setOption({ title: [{ ...options.title[0], text: newVal }] })
    }
  },
  mounted() {
    this.initEchart(this.$refs.PriceYearComparison)
  },
  methods: {
    getData() {
      let url = function () {}
      switch (this.priceType) {
        case '主流价':
          url = getAnnualCurve
          break
        case '市场价':
          url = getMarketAnnualCurve
          break
        case '企业价':
          url = getCompanyAnnualCurve
          break
        case '国际价':
          url = getInternetAnnualCurve
          break
        default:
          break
      }
      url(this.queryData)
        .then(res => {
          const data = res.response || {}
          this.responseData = data
          let getFullYearDay = []
          let getFullWeek = []
          this.chartLoading = false
          this.yData = []
          this.dataSetSource = []
          this.getShowYear = []
          if (!data || Object.keys(data).length === 0) {
            this.errorHandler()
            this.$emit('change-data', null)
            return false
          }

          for (const key in data) {
            data[key].forEach(item => {
              getFullYearDay.push(this.$dayjs(item.dataDate).format('MM-DD'))
              getFullWeek.push(item.exhDate)
            })
          }
          this.unitY =
            res.response[Object.keys(res.response)[0]][0].dataUnit ||
            res.response[Object.keys(res.response)[0]][0].unitValName
          getFullYearDay = [...new Set(getFullYearDay)].sort()
          getFullWeek = [...new Set(getFullWeek)].sort(
            (a, b) => a.replace(/[^0-9]/gi, '') - b.replace(/[^0-9]/gi, '')
          )
          const { frequency, frequencyName } = this.queryData.queryField
          const frequencyType = frequency || frequencyName
          if (frequencyType === '日度') {
            this.getFullBarx(frequencyType, getFullYearDay, 'MM-DD')
          } else if (frequencyType === '周度') {
            this.getFullBarx(frequencyType, getFullWeek)
          } else if (frequencyType === '月度') {
            const getMonth = []
            for (let month = 1; month <= 12; month++) {
              getMonth.push(`${month.toString().replace(/^(\d)$/, '0$1')}月`)
            }
            this.getFullBarx(frequencyType, getMonth, 'MM月')
          } else {
            this.getFullBarx(frequencyType, Object.keys(data).reverse(), 'YYYY')
          }
          this.setOption()
          this.myChart.hideLoading()
        })
        .catch(() => {
          this.errorHandler()
        })
    },
    /**
     * 根据频率获取x轴对应
     */
    getFullBarx(type, getFull, formatTime) {
      let params = ''
      getFull.forEach(full => {
        let obj = {}
        for (const key in this.responseData) {
          for (const key1 in this.responseData[key]) {
            if (type !== '周度') {
              params = this.$dayjs(this.responseData[key][key1].dataDate).format(formatTime)
            } else {
              params = this.responseData[key][key1].exhDate
            }
            // params = this.$dayjs(this.responseData[key][key1].dataDate).format(formatTime)
            if (full === params) {
              obj = {
                ...obj,
                [key]:
                  this.responseData[key][key1].mainPrice || this.responseData[key][key1].dataValue,
                [`${key}dataUnit`]:
                  this.responseData[key][key1].dataUnit || this.responseData[key][key1].unitValName
              }
            }
          }
        }
        this.dataSetSource.push({ time: full, ...obj })
      })
      this.$emit('change-data', this.dataSetSource)
      Object.keys(this.responseData)
        .reverse()
        .forEach(item => {
          this.getShowYear.push(item)
          this.yData.push({
            type: 'line',
            name: item,
            connectNulls: true
          })
        })
    },
    setOption() {
      this.myChart.clear()
      this.myChart.hideLoading()
      const data = this.yData
      this.option = {
        title: {
          // 标题是品种名称+属性
          text: this.chartTitle,
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14
          },
          left: 'center',
          top: this.magnifier ? '3%' : '15%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].name}<br/>`
            for (let i = 0; i < params.length; i++) {
              html += `${
                params[i].marker + params[i].seriesName
              }：<b class="chart-content style="float:right;margin-left:10px">${
                params[i].value[params[i].seriesName]
                  ? `${params[i].value[params[i].seriesName]} ${
                      params[i].value[`${params[i].seriesName}dataUnit`]
                        ? params[i].value[`${params[i].seriesName}dataUnit`]
                        : ''
                    }</b><br>`
                  : '-' + '</b><br>'
              }`
            }
            return html
          }
        },
        grid: {
          top: this.magnifier ? '8%' : '28%',
          bottom: '20%',
          left: '1%',
          right: '1%',
          containLabel: true
        },
        legend: {
          bottom: '11%'
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: '3%',
            right: '1%'
          }
        ],
        xAxis: {
          type: 'category'
          // axisLabel: {
          //   formatter: '{value}月'
          // }
        },
        yAxis: {
          type: 'value',
          scale: true,
          name: this.unitY,
          splitNumber: 4
        },
        dataset: {
          show: this.dataSetSource.length > 0,
          dimensions: ['time', ...this.getShowYear],
          source: this.dataSetSource
        },
        series: data
      }
      this.myChart.setOption(this.option, true)
      this.$nextTick(() => {
        this.myChart.resize()
      })
    }
  }
}
</script>

<template>
  <div ref="PriceYearComparison" v-loading="chartLoading" class="chart-container" />
</template>

<script>
import Common from './common'
import { formatDayJsTime } from '@/utils/formatData'

export default {
  name: 'ImportsAndExportsAnsley', // 进出口分析
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.ImportsAndExportsAnsley)
  },
  methods: {
    dataHandler(response) {
      let barX = []
      const seriesList = []
      let unitY = ''
      this.myChart.clear()
      if (response) {
        // const formatTime = formatDayJsTime(this.queryData.frequency, true)
        for (const key in response) {
          seriesList.push({
            name: key,
            type: 'bar',
            stack: 'total',
            data: response[key].map(child => [
              // this.$dayjs(child.dataDate).format(formatTime),
              this.$dayjs(child.dataDate).format('YYYY-MM-DD'),
              child.currVolume
            ])
          })
          barX.push(...response[key].map(v => this.$dayjs(v.dataDate).format('YYYY-MM-DD')))
        }
        barX = [...new Set(barX)].sort()
        unitY = response[Object.keys(response)[0]][0].currVolumeUnit
        this.myChart.hideLoading()
      } else {
        this.errorHandler()
      }
      this.chartLoading = false
      this.setOption(barX, seriesList, unitY)
    },
    setOption(barX = [], seriesList = [], unitY = '') {
      // 初始化展示范围为30个单位
      const startValue = barX[barX.length - 30] || barX[0]
      this.option = {
        title: {
          show: false
        },
        legend: {
          x: 'right',
          type: 'scroll',
          orient: 'vertical',
          bottom: '13%'
        },
        tooltip: {
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params
              .sort((a, b) => b.value - a.value)
              .forEach((e, i) => {
                // 2021年11月12日 因数目过多只取前10条
                if (i < 11)
                  html += `${e.marker}${e.seriesName}&nbsp;&nbsp;&nbsp;&nbsp;${e.value[1]}${
                    unitY || ''
                  }<br>`
              })
            return html
          }
        },
        yAxis: {
          name: unitY,
          type: 'value'
        },
        grid: {
          right: '16%',
          bottom: '13%',
          top: this.amplify ? '8%' : '18%',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'inside',
            startValue
          },
          {
            type: 'slider',
            bottom: '3%',
            left: '5%',
            right: '5%'
          }
        ],
        xAxis: {
          type: 'category',
          data: barX
        },
        series: seriesList
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="ImportsAndExportsAnsley" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Author: bjp
 * @Description: 折柱混合
-->
<script>
import Common from '../common'

export default {
  name: 'CapacityLineRate',
  components: {},
  mixins: [Common], // 产能产量
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    unit: {
      type: String,
      default: ''
    },
    temp: {
      type: Object,
      default: () => ({})
    },
    breedName: {
      type: String,
      default: ''
    },
    magnifier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      option: {}
    }
  },
  watch: {
    chartData: {
      handler() {
        this.initEchart(this.$refs.capacityLine)
        this.setOption()
      },
      deep: true
    }
  },
  mounted() {
    this.initEchart(this.$refs.capacityLine)
    this.setOption()
  },
  methods: {
    /**
     * 处理数据的逻辑
     */
    setOption() {
      this.myChart.clear()
      this.chartLoading = false
      if (this.chartData.length === 0) {
        this.errorHandler()
        return
      }
      this.myChart.hideLoading()
      const chartData = JSON.parse(JSON.stringify(this.chartData))
      chartData.sort((a, b) => {
        return (
          this.$dayjs(a.dataDate)
            .format('YYYYMMDD')
            .replace(/[^0-9]/gi, '') -
          this.$dayjs(b.dataDate)
            .format('YYYYMMDD')
            .replace(/[^0-9]/gi, '')
        )
      })
      const seriesName = chartData[0].quotaAlias
      this.option = {
        title: {
          text: seriesName,
          x: 'center',
          top: '5%',
          textStyle: {
            fontWeight: 'normal',
            fontSize: '14px'
          }
        },
        xAxis: {
          type: 'category',
          data: chartData.map(item => item.exhDate)
        },
        yAxis: [
          {
            name: '%',
            type: 'value',
            axisLabel: {
              show: true,
              interval: 0,
              formatter: '{value}%'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          }
        ],
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%'
          }
        ],
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              const unitY = e.seriesName.includes('增长率') ? '%' : this.unit || ''
              html += `${e.marker}${e.seriesName}：${e.value || '-'}${unitY || ''}<br>`
            })
            return html
          }
        },
        grid: {
          top: this.magnifier ? '10%' : '20%',
          bottom: '20%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          bottom: '12%',
          textStyle: {
            color: '#121212',
            fontSize: 14
          },
          itemGap: 20, // 设置间距
          data: [
            {
              name: `${seriesName}`
            }
          ]
        },
        series: [
          {
            name: `${seriesName}`,
            type: 'line',
            data: chartData.map(item => item.inputValue)
          }
        ]
      }

      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="capacityLine" v-loading="chartLoading" class="chart-container" />
</template>

/*
 * @Description: 海关数据apis
 * @Date: 2021-06-07 14:52:19
 * @LastEditTime: 2021-12-21 08:56:06
 * @FilePath: /oilchem-data-terminal-web/src/apis/Breed/CustomsData/index.js
 */
import { getData } from '@/apis/server'
// 贸易伙伴表头
const url = '/api/customs/getTradingPartnersHeader'
export const getTradePartenrHeader = params => getData(`${url}`, params)
// 贸易伙伴列表
const url2 = '/api/customs/getTradingPartnersList'
export const getTradePartenrList = params => getData(`${url2}`, params)
// 贸易方式表头
const url3 = '/api/customs/getTradingModeHeader'
export const getTradeModeHeader = params => getData(`${url3}`, params)
// 贸易方式列表
const url4 = '/api/customs/getTradingModeList'
export const getTradeModeList = params => getData(`${url4}`, params)
// 企业注册地表头
const url5 = '/api/customs/getCompanyProvinceHeader'
export const getCompanyProvinceHeader = params => getData(`${url5}`, params)
// 企业注册地列表
const url6 = '/api/customs/getCompanyProvinceList'
export const getCompanyProvinceList = params => getData(`${url6}`, params)
// 贸易伙伴税则号
// const url7 = '/api/widget/queryData/96'
// export const getPartenrTarrif = params => getData(`${url7}`, params)
// 企业注册地税则号
// const url8 = '/api/widget/queryData/97'
// export const getCompanyTarrif = params => getData(`${url8}`, params)
// 贸易方式税则号
// const url9 = '/api/widget/queryData/98'
// export const getTradeModeTarrif = params => getData(`${url9}`, params)
// 进/出口量税则号
// const url10 = '/api/widget/queryData/100'
// export const getVolumeTarrif = params => getData(`${url10}`, params)
// 进/出口量表格
const url11 = '/api/widget/queryData/99'
export const getVolumeTable = params => getData(`${url11}`, params)
// 进/出口金额-图表
const url12 = '/api/widget/queryData/50'
export const getPriceChart = params => getData(`${url12}`, params)
// 进/出口金额-表格
const url13 = '/api/widget/queryData/101'
export const getPriceTable = params => getData(`${url13}`, params)
// 进/出口金额-税则号
// const url14 = ''
// export const getPriceTarrif = params => getData(`${url14}`, params)
// 进口依存度/出口依赖度-表格/图表
const url15 = '/api/widget/queryData/102'
export const getDependenceData = params => getData(`${url15}`, params)
// 进出口-贸易伙伴-图表
const url16 = '/api/widget/queryData/49'
export const getPartenrChart = params => getData(`${url16}`, params)
// 进出口-贸易方式-图表
const url17 = '/api/widget/queryData/47'
export const getTradeModeChart = params => getData(`${url17}`, params)
// 进出口-企业注册地-图表
const url18 = '/api/widget/queryData/48'
export const getCompanyProvinceChart = params => getData(`${url18}`, params)
// 进出口量-分年对比
const url19 = '/api/customs/compareByYear'
export const getVolumeYear = params => getData(`${url19}`, params)
// 进出口量-连续图 柱状图
const url20 = '/api/widget/queryData/115'
export const getVolumeContinuous = params => getData(`${url20}`, params)
// 进出口依赖度-税则号
// const url21 = '/api/widget/queryData/164'
// export const getDependenceTarrif = params => getData(`${url21}`, params)
// 海关数据通用税则号
const url22 = '/api/widget/queryData/174'
export const getTarrif = params => getData(`${url22}`, params)

// 进出口页面列表获取单位
const url23 = '/api/customs/importOutputUnit'
export const getImportOutputUnit = params => getData(`${url23}`, params)

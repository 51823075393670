<!--
 * @Description: 品种首页-配置检修资讯列表
 * @Date: 2021-10-22 11:24:16
 * @LastEditTime: 2021-12-03 16:22:24
-->
<script>
import News from '@/components/News.vue'
import { deviceDynamic } from '@/apis/Breed/informationCenter'
import NoData from '@/components/NoData/NoData.vue'

export default {
  name: 'DeviceInformationList',
  components: { News, NoData },
  data() {
    return {
      listData: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    /**
     * @description: 获取文章列表
     */
    getData() {
      const params = {
        breedId: this.$route.query.id,
        pageSize: 6,
        pageNum: 1
      }
      deviceDynamic(params).then(res => {
        this.listData = res.response?.datas
      })
    },
    /**
     * @description: 跳转资讯中心详情页
     * @param {String} id 文章id
     */
    goDetail({ id }) {
      const { query } = this.$route
      this.$router.push({
        path: '/breed/informationCenter/info',
        query: { ...query, articleId: id }
      })
    }
  }
}
</script>

<template>
  <div class="breed-information-list">
    <News v-if="listData && listData.length" :news-list="listData" @go-detail="goDetail" />
    <NoData v-else />
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/styles/breed/information.scss';

.breed-information-list {
  @include blockshadow(0.16);
  @include br5bgfff;
  border-radius: 5px;
  height: inherit;
  max-height: inherit;
  overflow: auto;
}
</style>

/*
 * @Description:分析与预测apis
 * @Date: 2021-07-01 11:02:31
 * @LastEditTime: 2021-10-19 13:48:51
 */
import { getData } from '@/apis/server'
const url = '/api/widget/queryData/'
// 历史价格对照分析-表头
export const getHistoryTableHeader = params =>
  getData(`${url}historicalPriceComparisonHeader`, params)
// 历史价格对照分析-表格数据
export const getHistoryTable = params => getData(`${url}historicalPriceComparison`, params)
// 历史价格对照分析-图表
export const getHistoryChart = params => getData(`${url}historicalPriceComparisonChart`, params)
// TODO: 价格预测-图表

// TODO: 价格预测-表格

// 价格相关性分析-图表
export const getAnalysisChart = params => getData(`${url}270`, params)

<script>
import Common from '../common'
import { getHistoryFactorPie } from '@/apis/Breed/PriceCenter'
export default {
  name: 'HistoryPriceStatistical', // 历史价格统计分析
  mixins: [Common],
  data() {
    return {
      chartData: [],
      option: {}
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#HistoryPriceStatistical'))
  },
  methods: {
    async getData() {
      const { response } = await getHistoryFactorPie(this.queryData)
      this.dataHandler(response)
    },
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      this.myChart.clear()
      this.chartData = []
      if (response && response.data) {
        this.myChart.hideLoading()
        this.chartData = response.data.map(({ key: name, doc_count: value }) => ({ name, value }))
      } else {
        this.errorHandler()
        return
      }
      this.chartLoading = false
      this.setOption()
    },
    setOption() {
      this.option = {
        tooltip: {
          confine: true,
          trigger: 'item'
        },
        legend: {
          bottom: '3%',
          type: 'scroll',
          left: 'center'
        },
        series: [
          {
            type: 'pie',
            center: ['50%', '45%'],
            radius: ['45%', '65%'],
            data: this.chartData,
            label: {
              formatter: '{d}%'
            }
          }
        ]
      }
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div id="HistoryPriceStatistical" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Description: 多产品对比折线图
 * @Date: 2021-07-28 14:53:42
 * @LastEditTime: 2022-02-14 13:51:31
-->
<script>
import Common from './common'
import { formatDayJsTime } from '@/utils/formatData'

import { dataDic, nameDic } from '@/views/DataComparse/common'
import { getCompanyChart, getInternetChart, getMarketChart } from '@/apis/Breed/dataCompare.js'

export default {
  name: 'DataComparseLine',
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  }, // 连续图
  mounted() {
    this.initEchart(this.$refs.DataComparseLine)
  },
  methods: {
    async getData() {
      let compare__chart = ''
      if (Object.keys(this.queryData).length > 0) {
        // 市场价，国际价,企业价
        if (this.$route.query.priceType) {
          switch (this.$route.query.priceType) {
            case '市场价':
              compare__chart = getMarketChart
              break
            case '企业价':
              compare__chart = getCompanyChart
              break
            case '国际价':
              compare__chart = getInternetChart
              break
            default:
              break
          }
          // 图表
          try {
            const { response } = await compare__chart(this.queryData)
            this.dataHandler(response)
          } catch (error) {
            this.dataHandler()
          }
        } else {
          // 主流价等其他供需表格
          this.getDemandData()
        }
      }
    },
    getDemandData() {
      const { query } = this.$route
      let dicName = query.title
      dicName = nameDic[dicName]

      const url = dataDic[dicName].graphUrl || null
      if (!url) {
        console.log('未找到该栏目对比接口')
        return
      }
      url(this.queryData)
        .then(res => {
          this.dataHandler(res.response)
        })
        .catch(() => this.errorHandler())
    },
    dataHandler(response) {
      this.myChart.clear()
      this.myChart.hideLoading()
      this.chartLoading = false
      if (!response || response.length <= 0) {
        this.errorHandler()
        return
      }

      const seriesList = []
      const unitArr = []
      let yAxis = []

      let nameField = 'breedName'
      let unitField = 'dataUnit'
      // 市场价，国际价,企业价为indexName
      if (this.$route.query.priceType) {
        nameField = 'indexName'
        unitField = 'unitValName'
      }
      const formatTime = formatDayJsTime(this.queryData.queryField.frequency)

      // X坐标轴求值
      let barX = response.flat(Infinity).map(item => {
        !unitArr.includes(item[unitField]) && item[unitField] && unitArr.push(item[unitField])
        return this.$dayjs(item.dataDate).format(formatTime)
      })
      barX = [...new Set(barX)].sort((a, b) => this.sortDataByAsc(a, b))
      if (unitArr.length === 0) unitArr.push('')
      // 一个单位一条Y轴
      yAxis = unitArr.map((item, i) => {
        return {
          name: item,
          nameTextStyle: {
            align: 'right'
          },
          type: 'value',
          position: 'left',
          scale: true,
          offset: i * 42,
          splitNumber: 5,
          splitLine: {
            show: i === 0,
            lineStyle: {
              type: 'dashed'
            }
          }
        }
      })

      // seriesList
      response.forEach(res => {
        const index = unitArr.findIndex(v => v === res[0][unitField])
        seriesList.push({
          name: res[0][nameField],
          type: 'line',
          yAxisIndex: index > -1 ? index : 0,
          connectNulls: true,
          smooth: true,
          data: res
            .sort((a, b) => this.sortDataByAsc(a.dataDate, b.dataDate))
            .map(v => [this.$dayjs(v.dataDate).format(formatTime), v.dataValue, v[unitField]])
        })
      })
      this.setOption(barX, seriesList, yAxis)
    },
    setOption(barX = [], seriesList = [], yAxis = []) {
      this.myChart.clear()
      this.myChart.hideLoading()
      this.option = {
        legend: {
          left: 'center',
          top: 10
        },
        grid: {
          top: 60,
          bottom: 40,
          left: 20 * yAxis.length,
          containLabel: true
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider',
            bottom: 10
          }
        ],
        tooltip: {
          confine: true,
          trigger: 'axis',
          backgroundColor: '#f2f2f2',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}${
                e.value[1] ? e.value[2] || '' : ''
              }<br>`
            })
            return html
          }
        },
        xAxis: {
          type: 'category',
          data: barX
        },
        yAxis,
        series: seriesList
      }
      this.myChart.setOption(this.option, true)
    },
    sortDataByAsc(a, b) {
      return a.replace(/[^0-9]/gi, '') - b.replace(/[^0-9]/gi, '')
    }
  }
}
</script>

<template>
  <div ref="DataComparseLine" v-loading="chartLoading" class="chart-container" />
</template>

<style lang="scss" scoped>
:deep(.chart-content) {
  width: 150px;
  white-space: normal;

  .chart-tips {
    color: #cc3338;
  }
}
</style>

<script>
import Common from './common.js'
import { getData } from '@/apis/server'
export default {
  name: 'EquipmentMaintenance', // 历年装置产能情况
  mixins: [Common],
  data() {
    return {
      tableLoading: false,
      tableHeader: [
        { label: '企业名称', prop: 'companyName' },
        { label: '装置名称', prop: 'deviceName' },
        { label: '工艺路线', prop: 'process' },
        { label: '投产时间', prop: 'prodYear' },
        { label: '在产产能', prop: 'capacity' }
      ],
      tableData: [],
      allData: [],
      extraData: []
    }
  },
  watch: {
    activeTag: {
      // immediate: true,
      handler() {
        this.chartLoading = true
        this.getData()
      }
    }
  },
  mounted() {
    this.tableLoading = true
    this.getData()
  },
  methods: {
    getData() {
      this.tableLoading = true
      const { dataApi } = this.widget
      getData(dataApi, {
        queryField: {
          breedId: this.breedId
        }
      })
        .then(({ response }) => {
          this.tableHeader[4].label = `在产产能（ ${response[0].prdCapUnit} ）`
          this.allData = response
          this.allData.forEach(v => {
            if (!v.isFolded) {
              v.prodYear = this.$dayjs(v.prodYear).format('YYYY.MM.DD')
              v.closeYear = this.$dayjs(v.closeYear).format('YYYY.MM.DD')
            }
          })
          this.tableData = this.allData.filter(item => item.isFolded)
          this.extraData = this.allData.filter(item => !item.isFolded)
          this.tableData.forEach(item => {
            item.cellShow = false
            item.isAdd = false
          })
          this.$nextTick(() => {
            this.$refs.tableA.doLayout()
          })
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    // 点击表格行
    rowClick(row) {
      // isFolded=false是隐藏行
      if (!row.isFolded) return
      if (row.cellShow) {
        // 关闭
        this.tableData.splice(row.index + 1, row.extraCount)
        row.extraCount = 0
      } else {
        // 展开
        let count = 0
        this.extraData.forEach(item => {
          if (item.companyId === row.companyId && !item.isFolded) {
            this.tableData.splice(row.index + 1, 0, item)
            item.companyName = ''
            this.tableData[row.index + 1].isAdd = true
            this.tableData[row.index + 1].cellShow = true
            count++
          }
        })
        row.extraCount = count
      }
      row.cellShow = !row.cellShow
    }
  }
}
</script>

<template>
  <div id="EquipmentMaintenance" class="chart-container">
    <!-- 企业 -->
    <el-table
      ref="tableA"
      v-loading="tableLoading"
      :data="tableData"
      stripe
      :row-class-name="tableRowClassName"
      @row-click="rowClick"
    >
      <el-table-column
        v-for="(item, i) in tableHeader"
        :key="item.label"
        :label="item.label"
        :prop="item.prop"
        align="center"
        :width="i < 2 ? (i < 1 ? 180 : 140) : 'auto'"
      >
        <template #default="scope">
          <div
            v-if="
              !scope.row.isAdd &&
              ['process', 'prodYear', 'closeYear'].includes(scope.column.property)
            "
            style="cursor: pointer"
          >
            <img v-if="!scope.row.cellShow" src="@/assets/images/Breed/arrow_up.png" alt="" />
            <img v-else src="@/assets/images/Breed/arrow_down.png" alt="" />
            点击展开显示
          </div>
          <div v-else>
            {{ scope.row[scope.column.property] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style lang="scss" scoped>
// el-table 样式
:deep(.el-table) {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
  height: 300px;
  .gutter {
    width: 0;
    display: table-cell !important;
  }
  th,
  td {
    border-left: 2px solid #fff;
  }
  .el-table__header {
    th {
      background: #3a466e;
      opacity: 0.5;
      color: #fff;
      font-size: 14px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: none;
      > .cell {
        line-height: 18px;
      }
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border: none;
        border-left: none;
      }
    }
  }
  .el-table__body {
    overflow: hidden;
    tr td {
      height: 30px;
      text-align: center;
      border-bottom: none;
    }
    tr td:first-of-type {
      border-left: none;
    }
  }
  // 滚动条样式
  .el-table__body-wrapper {
    max-height: 240px;
    overflow-y: auto;
    // 滚动条样式
    &::-webkit-scrollbar {
      width: 10px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }
    // 滚动条的滑块
    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
      width: 10px;
    }
  }
}
</style>

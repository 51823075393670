<script>
import Common from './common'
import { getData } from '@/apis/server'

export default {
  name: 'CrudeOilUsWellsDrilledCountAndWTI', // 炼厂加工量
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.CrudeOilUsWellsDrilledCountAndWTI)
  },
  methods: {
    async getData() {
      const { dataApi } = this.widget
      const query = {
        queryField: {
          ...this.queryData
        }
      }
      try {
        const { response } = await getData(dataApi, query)
        this.dataHandler(response)
      } catch (error) {
        this.errorHandler()
      }
    },
    /**
     * @description 处理数据的逻辑
     * @param {Object} response
     */
    dataHandler(response) {
      if (response?.length === 0) {
        this.errorHandler()
      }
      let series = []
      const xData = new Set()
      for (const value of response) {
        const timeAxisData = this.$dayjs(value.researchStopDate).format('YYYY-MM-DD')
        xData.add(timeAxisData)
        const index = series.findIndex(item => item.name === value.indexName)
        if (index === -1) {
          const isRefinery = value.indexName.includes('原油周度钻井数量（美国）')
          series.push({
            name: value.indexName,
            type: isRefinery ? 'bar' : 'line',
            yAxisIndex: isRefinery ? 0 : 1,
            color: isRefinery ? '#023985' : '#BC0008',
            data: [[timeAxisData, value.inputValue, value.unitName]]
          })
        } else {
          series[index].data.push([timeAxisData, value.inputValue, value.unitName])
        }
      }
      // 如果加工量不在第一项，则将其放到第一项
      if (!series[0].name.includes('加工量')) series = series.reverse()
      this.setOption(series, [...xData])
    },
    setOption(series = [], xData) {
      this.chartLoading = false
      this.myChart.hideLoading()
      this.myChart.clear()
      this.option = {
        title: {
          show: false
        },
        grid: {
          top: '15%',
          bottom: '13%',
          right: '5%',
          containLabel: true
        },
        legend: {
          show: true,
          left: 'center',
          top: '2%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.data[1] || '-'}${e.data[2] || ''}<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '3%',
            startValue: xData.length - 30
          }
        ],
        xAxis: {
          type: 'category',
          data: xData,
          axisLabel: {
            rotate: -30
          }
        },
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              show: true,
              interval: 0
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            scale: true,
            splitLine: {
              show: false
            }
          }
        ],
        series
      }
      this.downLoadChartTitle('analysis')
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="CrudeOilUsWellsDrilledCountAndWTI" v-loading="chartLoading" class="chart-container" />
</template>

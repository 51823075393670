<script>
import Common from './common'
import { getData } from '@/apis/server'
export default {
  name: 'CapacityAndForecast', // 产能预测
  mixins: [Common],
  watch: {
    activeTag: {
      // immediate: true,
      handler() {
        this.chartLoading = true
        this.getData()
      }
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#CapacityAndForecast'))
    this.getData()
  },
  methods: {
    getData() {
      this.chartLoading = true
      const { dataApi } = this.widget
      getData(dataApi, {
        queryField: {
          breedId: this.companyId
        }
      })
        .then(({ response }) => {
          this.myChart.clear()
          this.myChart.hideLoading()
          if (!response || !response.data?.length) {
            this.errorHandler()
            return false
          }
          const data = Object.values(response)
            .flat(Infinity)
            .sort((a, b) => {
              if (a.dataDate.split(' ')[0] > b.dataDate.split(' ')[0]) {
                return 1
              }
              return -1
            })
          const xData = data.map(item => item.dataDate.split(' ')[0])
          const seriesData = data.map(item => [item.dataDate.split(' ')[0], item.capacity])
          this.setOption(xData, seriesData)
        })
        .finally(() => {
          this.chartLoading = false
        })
    },
    setOption(xData, seriesData) {
      const option = {
        xAxis: {
          type: 'category',
          data: xData
        },
        tooltip: {
          confine: true
        },
        yAxis: {},
        dataZoom: [{ type: 'inside' }, { type: 'slider' }],
        color: '#023985',
        series: [
          {
            type: 'bar',
            data: seriesData,
            barWidth: 20,
            itemStyle: {
              borderWidth: 1,
              // borderColor: '#8DADDA',
              color(param) {
                return param.data[param.encode.x[0]].includes('E') ? '#fff' : param.color
              }
            }
          }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div id="CapacityAndForecast" v-loading="chartLoading" class="chart-container" />
</template>

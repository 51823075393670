<script>
import { innerQuantityPrice, quantityPrice } from '@/apis/server'

export default {
  name: 'QuantifyRelation', // 量价关系
  props: {
    isInner: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      upstream: [],
      mainBreed: {
        productDTO: {}
      },
      downstream: [],
      statPeriod: '' // 统计周期
    }
  },
  computed: {
    updownClass() {
      return function (val) {
        if (val > 0) {
          return ['upordown', 'uppercent']
        } else {
          return ['upordown', 'downpercent']
        }
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      let api = quantityPrice
      if (this.isInner === 'inner') api = innerQuantityPrice
      const { response } = await api({
        queryField: {
          breedId: this.$route.query.id
        }
      })
      const { upstream, downstream, processInfo, statPeriod = '' } = response
      if (upstream?.length)
        this.upstream = upstream.filter(v => v.products && v.products.length > 0)
      this.downstream = downstream
      if (!processInfo) {
        this.mainBreed = {}
      } else {
        this.mainBreed = processInfo
      }
      this.statPeriod = statPeriod
      this.loading = false
    },
    decimalToPercent(num) {
      if (!num || num === '-') return '--'
      return `${(num * 100).toFixed(2)}%`
    },
    /**
     * @description: 四舍五入取整数
     * @param {*} val
     * @return {*} val
     */
    getInteger(val) {
      if (!val || val === '-') return val
      return Math.round(val)
    }
  }
}
</script>

<template>
  <div v-loading="loading" class="quantity-relation">
    <div class="cycle-date">
      {{ statPeriod }}
    </div>
    <!-- 上游 -->
    <div v-show="!loading" class="upstream">
      <div class="upstream_title">
        <div class="upstream_title_percent">工艺占比</div>
        <ul class="upstream_title_count">
          <li>总供应量</li>
          <li>
            <span class="left-count">国内产量</span>
            <span class="middle-add">+</span>
            <span class="right-count">进口量</span>
          </li>
        </ul>
        <div class="upstream_title_price">均价</div>
      </div>
      <div v-if="upstream && upstream.length" class="upstream_list kuohu">
        <span class="kuohu_item" />
        <div v-for="item in upstream" :key="item.processId" class="upstream_content">
          <div class="upstream_content_percent">
            <span>{{ item.processName }}</span>
            <span>{{ item.processRate }}%</span>
          </div>
          <div class="upstream_content_count">
            <ul v-for="raw in item.products" :key="raw.breedId" class="upstream_content_count_item">
              <li>
                {{ raw.breedName }}
              </li>
              <li class="supply-count">
                <span class="left-count count-borderA">{{ raw.cpCap }}</span>
                <span class="middle-add">+</span>
                <span class="right-count count-borderB">{{ raw.imVolume }}</span>
              </li>
              <li :class="updownClass(raw.supplyRate)">
                {{ decimalToPercent(raw.supplyRate) }}
              </li>
            </ul>
          </div>
          <div class="upstream_content_price">
            <ul v-for="raw in item.products" :key="raw.breedId" class="upstream_content_price_item">
              <li class="span">¥ {{ raw.price }}</li>
              <li :class="updownClass(raw.priceChangeRate)">
                {{ raw.priceChangeRate ? `${raw.priceChangeRate}%` : raw.priceChangeRate }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 中游 -->
    <div v-show="!loading" class="process-info">
      <ul class="upstream_title_count">
        <li>总供应量</li>
        <li>
          <span class="left-count">国内产量</span>
          <span class="middle-add">+</span>
          <span class="right-count">进口量</span>
        </li>
      </ul>
      <div class="process-content">
        <div class="process-content_name">
          {{ mainBreed.processName }}
        </div>
        <div class="process-content_count">
          <div class="content-top">
            <div class="supply-count">
              <span class="left-count count-borderA">{{
                mainBreed.productDTO ? mainBreed.productDTO.cpCap : ''
              }}</span>
              <span class="middle-add">+</span>
              <span class="right-count count-borderB">
                {{ mainBreed.productDTO ? mainBreed.productDTO.imVolume : '' }}</span
              >
            </div>
            <span :class="updownClass(mainBreed.productDTO.supplyRate)">{{
              decimalToPercent(mainBreed.productDTO ? mainBreed.productDTO.supplyRate : '')
            }}</span>
          </div>
          <div class="content-name">均价</div>
          <div class="content-price">
            <span>¥ {{ mainBreed.productDTO.price }}</span>
            <span :class="updownClass(mainBreed.productDTO.priceChangeRate)">{{
              mainBreed.productDTO ? `${mainBreed.productDTO.priceChangeRate}%` : ''
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 下游 -->
    <div v-show="!loading" class="upstream">
      <div class="upstream_title">
        <div class="upstream_title_percent">消费占比</div>
        <ul class="upstream_title_count downstream_count">
          <li>总供应量</li>
          <li>
            <span class="left-count">国内产量</span>
            <span class="middle-add">+</span>
            <span class="right-count">进口量</span>
          </li>
        </ul>
        <div class="upstream_title_price downstream_title_price">均价</div>
      </div>
      <div v-if="downstream && downstream.length" class="downstream_list kuohu">
        <span class="kuohu_item" />
        <div
          v-for="item in downstream"
          :key="item.processId"
          class="upstream_content bottom-dashed"
        >
          <div class="downstream_percent">{{ item.processRate }}%</div>
          <div class="upstream_content_count">
            <ul class="upstream_content_count_item">
              <li>
                {{ item.processName }}
              </li>
              <li class="supply-count">
                <span class="left-count count-borderA">{{
                  item.productDTO ? item.productDTO.cpCap : ''
                }}</span>
                <span class="middle-add">+</span>
                <span class="right-count count-borderB">{{
                  item.productDTO ? item.productDTO.imVolume : ''
                }}</span>
              </li>
              <li :class="updownClass(item.productDTO.supplyRate)">
                {{ decimalToPercent(item.productDTO ? item.productDTO.supplyRate : '') }}
              </li>
            </ul>
          </div>
          <div class="upstream_content_price">
            <ul class="upstream_content_price_item">
              <li class="span">¥ {{ item.productDTO.price }}</li>
              <li :class="updownClass(item.productDTO.priceChangeRate)">
                {{ item.productDTO ? `${item.productDTO.priceChangeRate}%` : '' }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$width-count: 310px;
$width-percent: 60px;
$width-price: 110px;
$redA: #ba0c2f;
$redB: #e25c61;
$redUp: #d23023;
$green: #48af92;
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantity-relation {
  position: relative;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;

  .cycle-date {
    position: absolute;
    top: 8px;
    left: 8px;
  }

  .bottom-dashed {
    padding-bottom: 10px;
    border-bottom: 1px dashed #bfbfbf;
  }

  .left-count {
    width: 70px;
    height: 30px;
    font-size: 12px;
    @include flex-center;
    color: $redA;
  }

  .middle-add {
    width: 30px;
    height: 30px;
    @include flex-center;
    font-size: 20px;
    font-weight: bold;
  }

  .right-count {
    width: 70px;
    height: 30px;
    font-size: 12px;
    @include flex-center;
    color: $redB;
  }

  .count-borderA {
    background-color: $redA;
    color: #ffffff;
    border-radius: 5px;
  }

  .count-borderB {
    background-color: $redB;
    color: #ffffff;
    border-radius: 5px;
  }

  .upstream {
    display: flex;
    flex-direction: column;

    &_title {
      display: flex;

      &_percent {
        width: $width-percent;
        text-align: center;
        font-weight: bold;
      }

      &_count {
        width: $width-count;
        display: flex;
        flex-direction: column;
        align-items: center;

        > li {
          &:first-child {
            font-weight: bold;
            height: 25px;
            width: 60%;
            text-align: center;
            border-bottom: 2px solid #ba0c2f;
          }

          &:last-child {
            display: flex;
            margin-top: 10px;
          }
        }
      }

      &_price {
        width: $width-price;
        font-weight: bold;
        text-align: center;
        padding-right: 25px;
        box-sizing: border-box;
      }
    }

    .upstream_content {
      display: flex;
      align-items: center;
      margin: 15px 0;

      &_percent {
        width: $width-percent;
        display: flex;
        flex-direction: column;
        justify-content: center;

        > span {
          text-align: center;

          &:first-child {
            font-weight: bold;
            margin-bottom: 10px;
          }
        }
      }

      &_count {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: $width-count;

        &_item {
          display: flex;
          align-items: center;
          margin: 10px 0;

          li {
            flex: 1;
            display: flex;
            justify-content: center;
          }

          .supply-count {
            font-size: 12px;
            width: 60%;
          }
        }
      }

      &_price {
        display: flex;
        justify-content: center;
        flex-direction: column;

        &_item {
          display: flex;
          align-items: center;
          margin: 10px 0;

          .span {
            &:first-child {
              @include flex-center;
              width: 80px;
              height: 30px;
              color: #fff;
              font-size: 12px;
              background-color: #3a466e;
              border-radius: 5px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .upordown {
    font-size: 12px;
    font-weight: bold;
    position: relative;
    min-width: 66px;
    text-align: center;
  }

  .uppercent {
    color: $redUp;

    &::before {
      content: '\2191';
    }
  }

  .downpercent {
    color: $green;

    &::before {
      content: '\2193';
    }
  }

  .process-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $width-count;
    margin: 0 30px;

    .process-content {
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;

      &_name {
        flex: 1;
      }

      &_count {
        width: 78%;

        .content-top {
          display: flex;
          align-items: center;
          margin-top: 10px;

          .supply-count {
            width: 73%;
            display: flex;
          }

          > span {
            flex: 1;
          }
        }

        .content-name {
          @include flex-center;
          width: 73%;
          height: 50px;
          font-weight: bold;
        }

        .content-price {
          flex: 1;
          width: 100%;
          @include flex-center;

          > span {
            &:first-child {
              @include flex-center;
              width: 73%;
              height: 30px;
              color: #fff;
              font-size: 12px;
              background-color: #3a466e;
              border-radius: 5px;
            }

            &:last-child {
              flex: 1;
              @include flex-center;
            }
          }
        }
      }
    }
  }

  .downstream_percent {
    width: 35px;
    margin-right: 10px;
    @include flex-center;
  }

  .downstream_count {
    transform: translateX(-15px);
  }

  .downstream_title_price {
    transform: translateX(-22px);
  }

  .kuohu {
    position: relative;

    &::before,
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      height: calc(50% - 20px);
      width: 10px;
      border-style: solid;
      border-color: #000;
      border-width: 0;
      right: -19.5px;
    }

    &::before {
      top: 19px;
      border-bottom-left-radius: 20px;
      border-bottom-width: 2px;
      border-left-width: 1px;
    }

    &::after {
      bottom: 19px;
      border-top-left-radius: 20px;
      border-top-width: 2px;
      border-left-width: 1px;
    }

    .kuohu_item {
      &::before,
      &::after {
        content: ' ';
        display: block;
        position: absolute;
        height: calc(50% - 20px);
        width: 10px;
        border-style: solid;
        border-color: #000;
        right: -10px;
        border-width: 0 1px 0 0;
      }

      &::before {
        top: 5px;
        border-top-right-radius: 20px;
        border-top-width: 2px;
      }

      &::after {
        bottom: 5px;
        border-bottom-right-radius: 20px;
        border-bottom-width: 2px;
      }
    }

    &.downstream_list {
      margin: 0;

      &::before,
      &::after {
        left: -36px;
      }

      &::before {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 20px;
        border-width: 0 1px 2px 0;
      }

      &::after {
        border-top-left-radius: 0;
        border-top-right-radius: 20px;
        border-width: 2px 1px 0 0;
      }

      .kuohu_item {
        &::before,
        &::after {
          left: -26px;
          border-width: 0 0 0 1px;
        }

        &::before {
          top: 5px;
          border-top-right-radius: 0;
          border-top-left-radius: 20px;
          border-top-width: 2px;
        }

        &::after {
          bottom: 5px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 20px;
          border-bottom-width: 2px;
        }
      }
    }
  }
}
</style>

<script>
import Common from './common'
export default {
  name: 'SupplyDemandBalance', // 中国供需平衡
  mixins: [Common],
  mounted() {
    this.initEchart(this.$refs.SupplyDemandBalance)
  },
  methods: {
    dataHandler(response) {
      this.myChart.clear()
      let seriesList = []
      if (response) {
        seriesList = response.data
        this.myChart.hideLoading()
        this.setOption(seriesList)
        this.chartLoading = false
      } else {
        this.errorHandler()
      }
    },
    setOption(seriesList = []) {
      const option = {
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          top: 10
        },
        grid: {
          bottom: 10,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.$filterValue(seriesList['日期'], 'YYYY-MM-DD'),
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            name: this.$filterValue(seriesList['单位'])[0],
            type: 'value'
          },
          {
            name: '%',
            type: 'value',
            axisLabel: {
              formatter: '{value}%'
            }
          }
        ],
        series: [
          {
            name: '供需差',
            type: 'line',
            yAxisIndex: 1,
            data: this.$filterValue(seriesList['供需差'])
          },
          {
            name: '总供应量',
            type: 'bar',
            data: this.$filterValue(seriesList['总供应量']),
            yAxisIndex: 0
          },

          {
            name: '总需求量',
            type: 'bar',
            data: this.$filterValue(seriesList['总需求量']),
            yAxisIndex: 0,
            barGap: '0%'
          }
        ]
      }
      this.myChart.setOption(option, true)
    }
  }
}
</script>

<template>
  <div ref="SupplyDemandBalance" v-loading="chartLoading" class="chart-container" />
</template>

<!--
 * @Description: 企业-各工艺路线成本情况对比图表
 * @Date: 2022-04-20 14:10:35
 * @LastEditTime: 2022-04-20 14:34:17
-->
<script>
import Common from './common.js'
import { getData } from '@/apis/server.js'
export default {
  mixins: [Common],
  data() {
    return {}
  },
  watch: {
    activeTag() {
      this.getData()
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#EnterpriseProductionCostTrendChart'))
  },
  methods: {
    getData() {
      this.chartLoading = true
      const params = {
        queryField: {
          companyId: this.companyId,
          breedId: this.breedId,
          frequencyName: this.queryData.frequencyName
        }
      }
      const { dataApi } = this.widget
      getData(dataApi, params)
        .then(({ response }) => {
          this.chartLoading = false
          this.myChart.clear()
          this.myChart.hideLoading()
          if (response && Object.keys(response).length) {
            this.dataHandler(response)
          } else {
            this.errorHandler()
          }
        })
        .catch(() => {
          this.errorHandler()
        })
    },
    dataHandler(data) {
      let xData = []
      const seriesList = []
      for (const [key, value] of Object.entries(data)) {
        seriesList.push({
          name: key,
          type: 'line',
          smooth: true,
          data: value.map(e => {
            xData.push(e.日期)
            return [e.日期, e.成本, e.单位]
          })
        })
      }
      xData = [...new Set(xData)].sort((a, b) => {
        return this.$dayjs(a).valueOf - this.$dayjs(b).valueOf
      })
      this.setOption(xData, seriesList)
    },
    setOption(xData, series) {
      const option = {
        grid: {
          top: '11%',
          bottom: '20%',
          left: 0
        },
        legend: {
          show: true,
          bottom: '11%'
        },
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: {
          splitNumber: 5,
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}${
                e.value[1] ? e.value[2] || '' : ''
              }<br>`
            })
            return html
          }
        },
        dataZoom: [
          {
            type: 'inside',
            startValue: 0,
            endValue: 20
          },
          {
            type: 'slider',
            startValue: 0,
            endValue: 20,
            bottom: '3%'
          }
        ],
        series
      }
      this.myChart.setOption(option)
      this.myChart.resize({
        height: '330px'
      })
    }
  }
}
</script>

<template>
  <div id="EnterpriseProductionCostTrendChart" v-loading="chartLoading" class="chart-container" />
</template>

<style lang="scss" scoped></style>

<script>
export default {
  name: 'GasolineChnImports', // 汽油-中国汽油进口量
  data() {
    return {
      series: [
        { legend: '进口量', key: '进出口数量', unit: '进出口数量单位' },
        { legend: '进口增速', key: '进出口数量环比' }
      ]
    }
  }
}
</script>

<template>
  <BreedOilSpecialLineBarMixOld :series="series" v-bind="$attrs" />
</template>

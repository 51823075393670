<script>
import Common from '../common'
import { getSeasonAnalysisDay } from '@/apis/Breed/PriceCenter'
import { getFullYearDay } from '@/utils/formatData'
export default {
  mixins: [Common],
  props: {
    breedId: {
      // 传入品种id时优先
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      chartLoading: false,
      dataSetSource: [],
      // 返回的数据有几年
      showYear: [],
      seriesList: [],
      axisValue: '',
      unit: ''
    }
  },
  watch: {
    breedId() {
      this.getData()
    }
  },
  mounted() {
    this.initEchart(document.querySelector('#PriceSeasonalAnalysis'))
    this.getData() // 图表
  },
  methods: {
    async getData() {
      if (!this.breedId && !this.$route.query.id) return
      this.chartLoading = true
      this.unit = ''
      this.myChart.clear()
      const { response } = await getSeasonAnalysisDay({
        queryField: {
          breedId: this.breedId || this.$route.query.id,
          frequencyName: '日度'
        }
      })
      this.dataHandlerDay(response)
    },
    /**
     * 日度
     */
    dataHandlerDay(response) {
      if (response) {
        this.showYear = []
        this.showYear = Object.keys(response).sort()
        const arr = []
        this.seriesList = this.showYear.map(e => {
          return {
            name: e,
            type: 'line',
            connectNulls: true,
            data: response[e].map(v => {
              if (!this.unit) this.unit = v.dataUnit
              const dataDate = this.$dayjs(v.dataDate).format('MM-DD')
              arr.push(dataDate)
              return [dataDate, v.mainPrice]
            })
          }
        })
        this.xData = getFullYearDay
        this.myChart.hideLoading()
      } else {
        this.errorHandler()
      }
      this.chartLoading = false
      this.setOption()
    },
    setOption() {
      const option = {
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            const month = this.$dayjs(params[0].axisValue).format('M')
            if (this.axisValue !== month) {
              this.axisValue = month
              this.$emit('chart-month', Number(this.axisValue))
            }
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}${this.unit || ''}<br>`
            })
            return html
          }
        },
        backgroundColor: 'rgba(255, 255, 255, 0)',
        grid: {
          top: 30,
          bottom: 0,
          left: '5.5%',
          right: '0.5%'
        },
        legend: {
          top: -5
        },
        xAxis: {
          type: 'category',
          show: false,
          data: this.xData
        },
        yAxis: [
          {
            name: this.unit,
            show: true,
            scale: true,
            offset: 20,
            splitNumber: 4,
            axisTick: {
              // y轴刻度线
              show: false
            },
            splitLine: {
              // 网格线
              show: false
            }
          }
        ],
        series: this.seriesList
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div id="PriceSeasonalAnalysis" v-loading="chartLoading" class="chart-container" />
</template>

<style lang="scss" scoped></style>

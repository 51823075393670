<script>
export default {
  name: 'GasolineChnExports', // 汽油-中国汽油出口量
  data() {
    return {
      series: [
        { legend: '出口量', key: '进出口数量', unit: '进出口数量单位' },
        { legend: '出口增速', key: '进出口数量环比' }
      ]
    }
  }
}
</script>

<template>
  <BreedOilSpecialLineBarMixOld ref="chart" :series="series" v-bind="$attrs" />
</template>

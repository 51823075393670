<script>
import dayjs from 'dayjs'
import Common from './common'
import { getData, timeAxis } from '@/apis/server'
export default {
  name: 'NationalImportAndExport', // 全国进出口流向分析
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  async mounted() {
    this.initEchart(this.$refs.NationalImportAndExport)
  },
  methods: {
    async getData() {
      const series = []
      const xAxisData = (
        await timeAxis({
          frequency: '月度',
          format: 'yyyy年MM月'
        })
      ).response
      const { dataApi } = this.widget
      const query = {
        queryField: {
          breedId: this.id,
          frequency: '月度',
          imExType: '进口'
        }
      }
      const { response } = await getData(dataApi, query)
      for (const key in response) {
        series.push({
          name: key,
          type: 'bar',
          stack: 'satck',
          data: response[key].map(item => {
            return {
              name: dayjs(item.dataDate).format('yyyy年MM月'),
              value: item.currValue
            }
          })
        })
      }
      this.setOption(xAxisData, series)
    },
    setOption(xAxisData = [], series = []) {
      this.option = {
        title: {
          show: false
        },
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: { splitLine: { show: false } },
        legend: {
          orient: 'vertical',
          right: 0,
          bottom: 20,
          itemWidth: 14
        },
        tooltip: {
          confine: true,
          trigger: 'axis'
        },
        grid: {
          left: '2%',
          right: 100,
          bottom: 0,
          containLabel: true
        },
        series
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="NationalImportAndExport" class="chart-container" />
</template>

const state = {
  stockType: []
}

const mutations = {
  SET_STOCKTYPE(state, data) {
    state.stockType = data
  }
}

const actions = {
  changeStockType({ commit }, data) {
    commit('SET_STOCKTYPE', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

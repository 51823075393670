/*
 * @Description: 表格导出为excel
 * @Date: 2021-10-18 10:53:07
 * @LastEditTime: 2024-05-30 14:06:23
 */

import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import html2canvas from 'html2canvas'
import { universalExport } from '@/apis/server'
import { downLoadFile } from '@/utils/common'

export default {
  methods: {
    /**
     * @description: 表格dom导出excel
     * @param {String} dom  表格ref名称
     * @param {String} title 表格名称
     */

    exportTableDom2Excel(dom, title = '导出表格') {
      if (!this.$refs[dom]) {
        console.log('dom未加载')
        return
      }
      const xlsxParam = { raw: true } // 导出的内容进行格式转换
      const wb = XLSX.utils.table_to_book(this.$refs[dom].$el, xlsxParam)
      /* get binary string as output */
      const wbOut = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
      try {
        FileSaver.saveAs(new Blob([wbOut], { type: 'application/octet-stream' }), `${title}.xlsx`)
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbOut)
      }
      return wbOut
    },
    /**
     * @description: json数据导出excel
     * @param {Array} data  表格数据  数据样例：[{ A:"S", B:"h", C:"e", D:"e", E:"t", F:"J", G:"S" },...]
     * @param {Array} header 表头数据  数据样例：["A","B","C","D","E","F","G"]
     * @param {String} title 导出名称
     */
    exportTableJson2Excel(data, header, title = '导出表格') {
      const ws = XLSX.utils.json_to_sheet(data, { header })
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'sheet1')
      XLSX.writeFile(wb, `${title}.xlsx`)
    },
    /**
     * @description: 导出图片
     * @param {*} chartDom
     * @param {*} title
     */

    exportImg(chartDom, title = '导出图片') {
      if (!this.$refs[chartDom]) {
        console.log('dom未加载')
        return
      }
      window.pageYoffset = 0
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      // 先获取你要转换为img的dom节点
      const dom = this.$refs[chartDom].$el // 传入的class
      const width = dom.offsetWidth // dom宽
      const height = dom.offsetHeight // dom高
      const scale = 2 // 放大倍数 这个相当于清晰度 调大一点更清晰一点
      html2canvas(dom, {
        width,
        height,
        backgroundColor: '#ffffff', // 背景颜色 为null是透明
        dpi: window.devicePixelRatio * 2, // 按屏幕像素比增加像素
        scale,
        X: 0,
        Y: 0,
        scrollX: -3, // 如果左边多个白边 设置该偏移-3 或者更多
        scrollY: 0,
        useCORS: true, // 是否使用CORS从服务器加载图像 !!!
        allowTaint: true // 是否允许跨域图像污染画布  !!!
      }).then(canvas => {
        const url = canvas.toDataURL() // 这里上面不设值cors会报错
        const a = document.createElement('a') // 创建一个a标签 用来下载
        a.download = `${title}.png` // 设置下载的图片名称
        const event = new MouseEvent('click') // 增加一个点击事件
        a.href = url // 此处的url为base64格式的图片资源
        a.dispatchEvent(event) // 触发a的单击事件 即可完成下载
      })
    },
    /**
     * 导出echarts图片
     * @param width
     * @param height
     * @param option
     * @param fileName
     * @returns {Promise<void>}
     */
    async exportChartToImage(
      [width, height],
      option,
      fileName = this.$refs.magnifierChart.myChart
        ? this.$refs.magnifierChart.myChart.getOption().title[0].text
        : this.$refs.magnifierChart.$children[0].myChart.getOption().title[0].text // 有的页面有嵌套
    ) {
      this.$exportImage({ type: 'jpg', size: [width, height], option }, fileName)
    },
    /**
     * 通过通用接口导出表格数据（以解决表格数据导出后数字不能正确运算的问题）
     * @param {string} order - 排序
     * @param {string} fileName - 文件名
     * @param {object} column - 表头
     * @param {object} dateMap - 映射
     * @param {object} data - 数据
     * @param {string} sorted - 排序字段 number, string, percentage
     */
    async exportTableInterface(order, fileName, column, dateMap, data, sorted) {
      if (data === undefined) {
        data = this.tableData
      }
      if (dateMap) {
        column.unshift(dateMap)
      }
      const params = {
        fileName,
        column,
        data
      }
      if (dateMap) {
        params.sorted = {
          [dateMap.prop]: order
        }
      }
      if (sorted) {
        params.sorted = {
          [sorted]: order
        }
      }
      const res = await universalExport(params)

      downLoadFile(res, true)
    }
  }
}

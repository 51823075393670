import Vue from 'vue'
import axios from 'axios'
import { cityList, provinceList } from '@/assets/js/area'
import '@/assets/styles/register.scss'

import registerImg from '@/assets/images/register.png'

// 新建一个axios实例，忽略axios拦截器
const request = axios.create()

const RegisterDialog = Vue.extend({
  data() {
    const validateArea = (rule, value, callback) => {
      if (this.form.pro === '' || this.form.city === '') {
        callback(new Error('请选择主要工作城市'))
      } else {
        callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      const reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
      if (!value) {
        callback(new Error('请输入手机号码'))
      } else if (value.length < 11 || !reg.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    const validateEmail = (rule, value, callback) => {
      const reg =
        /^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
      if (!value) {
        callback(new Error('请输入您的电子邮箱'))
      } else if (value.length < 11 || !reg.test(value)) {
        callback(new Error('请输入正确的电子邮箱'))
      } else {
        callback()
      }
    }
    const validateName = (rule, value, callback) => {
      const reg = /^[\u4E00-\u9FA5]+$/
      if (!value) {
        callback(new Error('请输入您的姓名'))
      } else if (value.length < 2 || !reg.test(value)) {
        callback(new Error('请输入正确的姓名'))
      } else {
        callback()
      }
    }
    return {
      show: true,
      registerImg,
      form: {
        phone: '',
        verificationCode: '',
        companyName: '',
        email: '',
        address: '',
        name: '',
        codeVerifyUuid: '',
        pro: '',
        city: ''
      },
      codeTime: false,
      timer: null,
      countdown: 60,
      province: provinceList,
      cityList: [],
      checked: false,
      isSuccess: false,
      rules: {
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: 'change'
          }
        ],
        verificationCode: [
          {
            required: true,
            message: '请输入短信验证码',
            trigger: 'change'
          }
        ],
        companyName: [
          {
            required: true,
            message: '请输入您的公司',
            trigger: 'change'
          }
        ],
        email: [
          {
            required: true,
            validator: validateEmail,
            trigger: 'change'
          }
        ],
        address: [
          {
            required: true,
            validator: validateArea,
            trigger: 'change'
          }
        ],
        name: [
          {
            required: true,
            validator: validateName,
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    agreementInfo() {
      window.open('https://data.mysteel.com/permit.html', '_blank')
    },
    getCode() {
      this.$refs.form.validateField('phone', async () => {
        if (this.form.phone) {
          const { data } = await request({
            method: 'get',
            url: `https://client.mysteel.com/mysteel-api/api/common/register/code/${this.form.phone}`
          })
          const { success, result, message } = data
          if (success) {
            this.form.codeVerifyUuid = result
            this.$message({ type: 'success', message: '发送成功' })
            this.codeTime = true
            this.timer = setInterval(() => {
              this.countdown--
              if (this.countdown === 0) {
                clearInterval(this.timer)
                this.codeTime = false
                this.countdown = 60
              }
            }, 1000)
          } else {
            this.$message({ type: 'error', message })
          }
        }
      })
    },
    // 注册
    register() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (!this.checked) {
            this.$message({ type: 'error', message: '请勾选用户许可协议' })
            return false
          }
          if (this.form.pro && this.form.city) {
            this.form.address = [this.form.pro.split('-')[0], this.form.city].join(',')
          }
          const { phone, verificationCode, companyName, email, address, name, codeVerifyUuid } =
            this.form
          // 埋点
          const eventInfo = {
            mainCode: '101', // 主体编码 上海钢联为101 事件编码的1-3位
            productCode: '136', // 产品编码 埋点管理自动生成 事件编码的4-6位
            functionCode: '0001', // 功能编码 埋点管理自动生成 事件编码的 7-10位
            eventCode: '101136000100001', // 注册事件 事件编码 埋点管理生成
            propertyMap: {
              // 事件属性集合
              '101136000100001001': phone,
              '101136000100001002': email,
              '101136000100001003': '',
              '101136000100001004': '',
              '101136000100001005': '隆众登录界面注册'
            }
          }
          try {
            const { data } = await request({
              method: 'post',
              url: 'https://client.mysteel.com/mysteel-api/api/common/register/user',
              data: {
                regType: 0,
                phone,
                verificationCode,
                companyName,
                email,
                address,
                name,
                leadsSource: '隆众登录界面注册',
                codeVerifyUuid
              }
            })
            const { success, message } = data

            eventInfo.propertyMap['101136000100001003'] = success ? '注册成功' : '注册失败'

            if (success) {
              this.$message({ type: 'success', message: '注册成功' })
              // 备份信息
              await this.copyInfo()
              this.isSuccess = true
            } else {
              eventInfo.propertyMap['101136000100001004'] = message
              this.$message({ type: 'error', message })
            }
          } catch (e) {
            eventInfo.propertyMap['101136000100001004'] = message || ''
            this.$message({ type: 'error', message: '注册失败' })
          }
          eventCollect(eventInfo)
        }
      })
    },
    // 备份
    async copyInfo() {
      const formData = new FormData()
      for (const key in this.form) {
        // realName
        if (key === 'name') {
          formData.append('realName', this.form[key])
        } else {
          formData.append(key, this.form[key])
        }
      }
      formData.append('regType', '0')
      formData.append('leadsSource', '隆众登录界面注册')
      return request({
        method: 'post',
        url: 'https://member.oilchem.net/membercenter/user/save-terminal-info',
        data: formData
      })
    },
    // code倒计时
    proChange(val) {
      this.form.city = ''
      this.cityList = cityList[val.split('-')[1]]
    },
    // 下载
    download() {
      /** * 是否为mac系统（包含iphone手机） * */
      const isMac = (function () {
        return /macintosh|mac os x/i.test(navigator.userAgent)
      })()
      window.open(`https://data.mysteel.com/thank.html?type=${isMac ? '2' : '1'}`)
    }
  },
  template: `
    <el-dialog :visible="show" id="register-dialog" append-to-body @close="show=false">
    <div class="content">
      <div class="title">隆众石化行业数据终端</div>
      <div class="registerCard">
        <div class="process">
          <dl :class="{ active: !isSuccess }">
            <dt>1</dt>
            <dd>填写账号信息</dd>
          </dl>
          <dl :class="{ active: isSuccess }">
            <dt>2</dt>
            <dd>注册成功</dd>
          </dl>
        </div>
        <div class="step1" v-if="!isSuccess">
          <div class="form">
            <el-form ref="form" :model="form" label-width="120px" size="small" :rules="rules">
              <el-form-item label="手机号码" prop="phone">
                <el-input v-model="form.phone" placeholder="请输入手机号码，为您开通服务"></el-input>
              </el-form-item>
              <el-form-item label="短信验证码" prop="verificationCode">
                <div class="code">
                  <el-input v-model="form.verificationCode" placeholder="请输入短信验证码"></el-input>
                  <el-button id="getCode" @click="getCode" :disabled="codeTime">
                    {{ codeTime ? (countdown + '后获取验证码') : '获取短信验证码' }}
                  </el-button>
                </div>
              </el-form-item>
              <el-form-item label="电子邮箱" prop="email">
                <el-input v-model="form.email" placeholder="请输入您的电子邮箱"></el-input>
              </el-form-item>
              <el-form-item label="公司" prop="companyName">
                <el-input v-model="form.companyName" placeholder="请输入您的公司"></el-input>
              </el-form-item>
              <el-form-item label="姓名" prop="name">
                <el-input v-model="form.name" placeholder="请输入您的姓名" :minlength="2"></el-input>
              </el-form-item>
              <el-form-item label="主要工作城市" prop="address" class="address">
                <el-select v-model="form.pro" @change="proChange">
                  <el-option :label="item" :value="item + '-' + index" v-for="(item, index) in province" :key="index"
                  ></el-option>
                </el-select>
                <el-select class="el_select" v-model="form.city">
                  <el-option :label="item" :value="item" v-for="(item, index) in cityList" :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="agree">
              <el-checkbox v-model="checked">
                <div class="checkbox">
                  <span class="agreement">同意</span>
                  <span class="agreementSpan" @click.stop="agreementInfo">《钢联数据用户许可协议》</span>
                </div>
              </el-checkbox>
            </div>
          </div>
          <el-button class="submit" @click="register">注册</el-button>
        </div>
        <div class="step2" v-if="isSuccess">
          <img :src="registerImg" alt/>
          <div class="success">恭喜您，注册成功。</div>
          <div class="download" @click="download">点击下载</div>
          <p>感谢下载钢联数据，开启数据探索旅游。</p>
          <p>
            如果下载没有自动开始，请
            <span style="cursor: pointer" @click="download">重试</span>
          </p>
          <p>如果安装不成功，请咨询客服电话：400-400-400</p>
        </div>
      </div>
    </div>
    </el-dialog>
  `
})
export default RegisterDialog

// 数据格式转换 json转formData
import { Message } from 'element-ui'
export const formData = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

// 判断浏览器类型
export const myBrowser = () => {
  const { userAgent } = window.navigator
  // 判断是否IE浏览器
  if (
    userAgent.includes('compatible') &&
    userAgent.includes('MSIE') &&
    !userAgent.includes('Opera')
  ) {
    const reIE = /MSIE (\d+\.\d+);/
    reIE.test(userAgent)
    const fIEVersion = parseFloat(RegExp.$1)

    if ([7, 8, 9, 10].includes(fIEVersion)) {
      return `IE${fIEVersion}`
    }
    return 'IE'
  }
  // 判断是否是IE11
  if (userAgent.includes('Trident') && userAgent.includes('rv:11.0')) {
    return 'IE11'
  }
  // 判断是否Opera浏览器
  if (userAgent.includes('Opera')) {
    return 'Opera'
  }
  // 判断是否IE的Edge浏览器
  if (userAgent.includes('Edge')) {
    return 'Edge'
  }
  // 判断是否Firefox浏览器
  if (userAgent.includes('Firefox')) {
    return 'FF'
  }
  // 判断是否Safari浏览器
  if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    return 'Safari'
  }
  // 判断Chrome浏览器
  if (userAgent.includes('Chrome') && !userAgent.includes('Safari')) {
    return 'Chrome'
  }
  return ''
}

// localstorage操作
export const storage = {
  // localstorage存储 - 同域名下共享
  set: (key, data) => {
    try {
      return localStorage.setItem(key, JSON.stringify(data))
    } catch (err) {
      return localStorage.setItem(key, data)
    }
  },
  get: key => {
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch (err) {
      return localStorage.getItem(key)
    }
  },
  remove: key => {
    localStorage.removeItem(key)
  },
  // sessionStorage存储 - 不同页面相互隔离
  setSession: (key, data) => {
    try {
      return sessionStorage.setItem(key, JSON.stringify(data))
    } catch (err) {
      return sessionStorage.setItem(key, data)
    }
  },
  getSession: key => {
    try {
      return JSON.parse(sessionStorage.getItem(key))
    } catch (err) {
      return sessionStorage.getItem(key)
    }
  },
  removeSession: key => {
    sessionStorage.removeItem(key)
  }
}

/**
 * @description: 提取字符串中的数字
 * @param {string} data
 * @return {string}
 */
export const getNumberOfString = data => {
  if (!data) return data
  return data.replace(/[^0-9]/gi, '')
}

/**
 * @description: 获取url所有参数
 */
export function getUrlParams() {
  // 获取到Url并且解析Url编码
  const url = decodeURI(window.location.href)
  const theRequest = {}
  if (url.includes('?')) {
    const str = url.split('?')[1]
    const strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
  }
  return theRequest
}

/**
 * @description: 二进制流文件下载
 * @param {*} res 接口返回结果
 * @param haveMsg 是否有消息提示
 */
export const downLoadFile = (res, haveMsg) => {
  // 获取文件名
  const filename = res.headers['content-disposition'].split('filename=')[1]
  const url = window.URL.createObjectURL(new Blob([res.data], res.headers))
  // 创建隐藏的可下载链接
  let link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', decodeURIComponent(filename))
  // 下载
  document.body.appendChild(link)
  link.click()
  if (haveMsg) {
    Message.success('下载成功')
  }
  // 移除元素
  document.body.removeChild(link)
  link = null
  window.URL.revokeObjectURL(url)
}

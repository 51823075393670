import Vue from 'vue'
import Vuex, { Store } from 'vuex'

// 引入element
import ElementUI, { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 引入lodash
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

// 引入echarts
import * as echarts from 'echarts'

// 引入normalize
import 'normalize.css/normalize.css'

import '@/assets/styles/common.scss'

// 引入分辨率适配
import 'amfe-flexible'
// import '@/utils/viewport'
// 引入dayjs
import dayjs from 'dayjs'
// 引入formatData
// 引入Vuex
// 引入MD5
import md5 from 'md5'
import axios from 'axios'
import App from './App.vue'

import router from './routers'
// 轻量化
import fetchLocalData from '@/utils/fetch-localData'
import stores from '@/stores/index'
import formatData from '@/utils/formatData'
import theme from '@/assets/theme/theme.json'
import DatePicker from '@/components/DatePicker/datePicker.vue'
import { pickerMixin } from '@/components/DatePicker/picker'
import { downLoadFile, getUrlParams, storage } from '@/utils/common'

Vue.use(Vuex)
const store = new Store(stores)

Vue.use(formatData)

Vue.use(ElementUI)
echarts.registerTheme('charttheme', theme)

Vue.prototype.$echarts = echarts
Vue.prototype.$dayjs = dayjs

// 全局日期选择器
Vue.component('DatePicker', DatePicker)
Vue.mixin(pickerMixin)

Vue.prototype.$openTab = (url, title = '', contentId = new Date().getTime()) => {
  if (!url.includes('http') && process.env.NODE_ENV !== 'development') {
    url = `https://dt.oilchem.net${url}`
  }
  // 轻量版参数
  const cefObj = {
    funcname: 'CreateTab',
    param: {
      pageid: contentId,
      url,
      title
    }
  }
  // 智能版
  const nwObj = {
    type: 'f9', // 动态tab类型，不可修改
    params: {
      // from: 'report',//关闭新开页面后需要切换回去的页面，可不设置
      // columnId: 0,//没用的参数
      contentId, // 代表tab唯一性的ID，可根据业务需要自行定义
      action: 'web', // 固定参数，不可修改
      url, // 新开的tab对应的url
      title // 新开的tab标题
    }
  }
  if (window.CefViewQuery || window.expCallback || window.parent.expCallback) {
    // window.expCallback.next({
    //   type: 'f9', // 动态tab类型，不可修改
    //   params: {
    //     // from: 'report',//关闭新开页面后需要切换回去的页面，可不设置
    //     // columnId: 0,//没用的参数
    //     contentId, // 代表tab唯一性的ID，可根据业务需要自行定义
    //     action: 'web', // 固定参数，不可修改
    //     url, // 新开的tab对应的url
    //     title // 新开的tab标题
    //   }
    // })
    fetchLocalData(cefObj, nwObj)
    return
  }
  window.open(url, '_blank')
}

Vue.prototype.$openInBrowser = url => {
  if (!url) return false
  const cefObj = {
    funcname: 'OpenUrlFromBrowser',
    param: {
      url
    }
  }
  const nwObj = {
    type: 'nw.open',
    params: { url }
  }
  if (window.CefViewQuery || window.expCallback || window.parent.expCallback) {
    // window.expCallback.next({
    //   type: 'nw.open',
    //   params: { url }
    // })
    fetchLocalData(cefObj, nwObj)
    return
  }
  window.open(url, '_blank')
}

Vue.prototype.$md5 = md5

Vue.prototype.$exportImage = async (params, fileName) => {
  const { type = 'jpg', size, base64 = false, download = true, option = {} } = params
  const [width, height] = size
  let responseType = 'blob'
  if (base64) {
    responseType = 'json'
  }
  try {
    const res = await axios.post(
      'https://charts.oilchem.net/echarts/getImageOfAnySizeByOption',
      {
        fileName,
        type,
        width,
        height,
        base64,
        download,
        option
      },
      {
        responseType
      }
    )
    const { status, data } = res
    if (status !== 200) {
      return false
    }
    if (base64) {
      const downloadLink = document.createElement('a')
      downloadLink.href = data.data
      downloadLink.download = `${fileName}.svg`
      downloadLink.click()
    } else {
      downLoadFile(res, true)
    }
  } catch (e) {
    console.log(e)
    Message.error('下载失败，请重试')
  }
}
// 注册全局事件总线-eventBus
Vue.prototype.$eventBus = new Vue()

Vue.config.productionTip = false

// 全局注册图表组件
const requireComponent = require.context(
  // 其组件目录的相对路径
  '@/widgets',
  // 是否查询其子目录
  true,
  // 匹配基础组件文件名的正则表达式
  /[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName)

  // 获取组件的 PascalCase 命名
  const componentName = upperFirst(
    camelCase(
      // 获取和目录深度无关的文件名
      fileName
        .split('/')
        .slice(1)
        .join('')
        .replace(/\.\w+$/, '')
    )
  )

  // 全局注册组件
  Vue.component(
    componentName,
    // ! 如果这个组件选项是通过 `export default` 导出的，
    // ! 那么就会优先使用 `.default`，
    // ! 否则回退到使用模块的根。
    componentConfig.default
  )
})

// 设置token
async function getToken() {
  let token = null
  const tokenObj = {
    funcname: 'GetToken',
    param: []
  }
  const tokenRes = await fetchLocalData(tokenObj)
  if (tokenRes && tokenRes.successed) {
    token = tokenRes.token
  }
  return token
}
async function setToken() {
  const terminalToken = getUrlParams().token
  // terminalToken && storage.set('token', terminalToken)
  // 开发环境配置本地token
  // 生产环境的token跳转注册
  const localToken =
    process.env.NODE_ENV === 'development' ? '1e99e67e4ae5c0a34a16d24607966ae5' : ''
  let token = null
  if (window.CefViewQuery) {
    // 轻至版终端下舰token方式
    token = await getToken()
  } else {
    token = terminalToken || localToken
  }
  token && storage.set('token', token)
}
setToken()
// 禁止复制页面内容
document.onselectstart = () => false

// process.env.VUE_APP_MOCK === 'true' && require('./mocks/index')

new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')

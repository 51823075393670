import { getData } from '@/apis/server'
import { storage } from '@/utils/common'

export default {
  name: 'Common',
  props: {
    widget: {
      type: Object,
      default: () => ({})
    },
    queryData: {
      type: Object,
      default: () => ({})
    },
    activeTag: {
      type: Number,
      default: 0
    },
    chartActive: {
      type: Number,
      default: 0
    }
  },
  computed: {
    companyId() {
      return this.$route.query.companyId
    },
    securityCode() {
      return this.$route.query.companyId
    }
  },
  data() {
    return {
      myChart: null,
      chartLoading: false,
      tableLoading: false,
      breedId: storage.getSession('CompanyBreed')?.id,
      breedName: storage.getSession('CompanyBreed')?.name
    }
  },
  methods: {
    async getData() {
      const { dataApi } = this.widget
      const query = {
        queryField: {
          securityCode: this.securityCode,
          companyId: this.companyId,
          ...this.queryData
        }
      }
      try {
        const { response } = await getData(dataApi, query)
        this.dataHandler(response)
      } catch (error) {
        this.errorHandler()
      }
    },
    dataHandler(response) {
      // this.setOption()
      console.log(response)
    },
    initEchart(dom) {
      // 存在echarts实例时不再重新实例化
      this.$echarts.init(dom).dispose()
      const chart = this.$echarts.getInstanceByDom(dom)
      if (chart !== undefined) return
      this.myChart = this.$echarts.init(dom, 'charttheme')

      // 当窗口缩放后echart自动缩放
      window.addEventListener('resize', () => {
        this.myChart && this.myChart.resize()
      })

      // 路由切换时销毁echarts实例，防止内存泄漏
      this.$once('hook:beforeDestroy', () => {
        if (!this.myChart) return
        this.$echarts.dispose(this.myChart)
        this.myChart = null
        window.removeEventListener('resize', () => {
          this.myChart && this.myChart.resize()
        })
      })
    },
    setOption() {},
    // 空数据/接口错误处理
    errorHandler() {
      this.chartLoading = false
      this.tableLoading = false
      this.myChart &&
        this.myChart.showLoading({
          text: '暂无数据',
          fontSize: '30',
          color: '#ffffff',
          textColor: '#000',
          maskColor: 'rgba(255, 255, 255, 0.8)'
        })
    },
    showMultipleLabel(value) {
      if (!value) return
      // 最终结果  拼接\n换行
      let result = ''
      // 最大长度
      const MAX_LENGTH = 12
      // 显示行数
      const ROW_NUMBER = 2
      // 超出拼接...
      if (value.length > MAX_LENGTH) value = value.substring(0, MAX_LENGTH)
      // 单行文字长度 加上省略号2个长度
      const singleLength = Math.ceil((MAX_LENGTH + 2) / ROW_NUMBER)
      if (value.length > singleLength) {
        for (let i = 0; i < ROW_NUMBER; i++) {
          let temp = ''
          const start = i * singleLength
          const end = start + singleLength
          // 如果是最后一行则添加省略号
          const ellipsis = i === ROW_NUMBER - 1 ? '...' : ''
          temp = `${value.substring(start, end) + ellipsis}\n`
          result += temp
        }
        return result
      } else {
        return value
      }
    }
  },
  watch: {
    queryData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val || Object.keys(val).length === 0) return false
        this.query = val
        this.chartLoading = true
        this.getData()
      }
    },
    activeTag() {
      this.breedId = storage.getSession('CompanyBreed').id
      this.breedName = storage.getSession('CompanyBreed').name
    }
  }
}

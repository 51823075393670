<!--
 * @Author: bjp
 * @Description: 指数
-->
<script>
import { debounce } from 'lodash'
import Common from '@/widgets/Breed/common'
import { getNumberOfString } from '@/utils/common'
import { getIndexRiver } from '@/apis/quote'

export default {
  name: 'IndexRiverChart', // 指数河道图
  mixins: [Common],
  props: {
    indexObj: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      option: {},
      chartLoading: false
    }
  },
  watch: {
    indexObj: {
      // immediate: true,
      deep: true,
      handler(val) {
        if (val.industryName && val.categoryName) {
          this.getData()
        }
      }
    }
  },
  mounted() {
    this.initEchart(this.$refs.IndexRiverChart)
  },
  methods: {
    getData: debounce(async function () {
      this.chartLoading = true
      const { industryName, categoryName } = this.indexObj
      const { response } = await getIndexRiver({
        industryName,
        categoryName
      })
      this.dataHandler(response)
    }, 500),
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      if (!response || Object.keys(response).length === 0) {
        this.errorHandler()
      } else {
        this.setOption(response)
      }
    },
    setOption(data) {
      let barX = data.dataList.map(m => m.exhDate)
      const obj = {
        currentYear: 'currentYearValue',
        maxValueRangeYear: 'maxValue',
        avgValueRangeYear: 'avgValue',
        minValueRangeYear: 'minValue'
      }
      const seriesList = []
      for (const key in obj) {
        seriesList.push({
          color: '#E28C1B',
          name: data[key].replace(/\s/gi, ''),
          type: 'line',
          smooth: true,
          symbol: 'none',
          lineStyle: {
            width: 2
          },
          emphasis: {
            lineStyle: {
              width: 2
            }
          },
          data: data.dataList
            .map(v => [v.exhDate, v[obj[key]]])
            .sort((a, b) => getNumberOfString(a[0]) - getNumberOfString(b[0]))
        })
      }
      seriesList.forEach((item, i) => {
        if (item.name.includes('最小值')) {
          item.color = '#3A466E'
          item.areaStyle = { color: '#fff', opacity: 1 }
          item.lineStyle = { type: 'solid', width: 2 }
        }
        if (item.name.includes('最大值')) {
          item.color = '#6899DD'
          item.areaStyle = { color: '#BFBFBF' }
          item.lineStyle = { type: 'solid', width: 2 }
        }
        if (item.name.includes('均值')) {
          item.lineStyle = { type: 'dashed', width: 2 }
          item.color = '#2263BD'
        }
        if (i === 3) {
          item.lineStyle = { width: 2 }
        }
      })

      barX = [...new Set(barX)].sort((a, b) => getNumberOfString(a) - getNumberOfString(b))

      this.option = {
        title: {
          text: '指数',
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14,
            opacity: 0
          },
          left: 'center'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].axisValue}<br>`
            params.forEach(e => {
              html += `${e.marker}${e.seriesName}：${e.value[1] || '-'}<br>`
            })
            return html
          }
        },
        legend: {
          left: this.amplify ? 'center' : 2,
          itemWidth: this.amplify ? 25 : 16,
          itemGap: this.amplify ? 10 : 2,
          top: '3%'
        },
        dataZoom: [
          {
            type: 'inside',
            height: 12
          },
          {
            type: 'slider',
            height: this.amplify ? 15 : 10,
            bottom: '2%',
            left: this.amplify ? '4%' : '11%',
            right: this.amplify ? '4%' : '11%'
          }
        ],
        grid: {
          top: this.amplify ? '10%' : '20%',
          bottom: '11%',
          left: '1%',
          right: '1%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: barX
        },
        yAxis: {
          type: 'value',
          scale: true
          // name: unit
        },
        series: seriesList
      }

      this.myChart.setOption(this.option, true)
    }
  }
}
</script>

<template>
  <div
    ref="IndexRiverChart"
    v-loading="chartLoading"
    class="chart-container"
    style="width: 100%; height: 100%"
  />
</template>

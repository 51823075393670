export const pickerMixin = {
  methods: {
    pickerChangeDate_common(val, field) {
      let arr = ''
      if (field) {
        arr = this[field]
      } else {
        arr = this.form?.year
      }
      this.$set(arr, 0, val.startTime)
      this.$set(arr, 1, val.endTime)
    }
  }
}

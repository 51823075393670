<script>
import Common from './common.js'
export default {
  name: 'MainProduct', // 市场价格
  mixins: [Common],
  data() {
    return {
      tableLoading: false,
      tableData: []
    }
  },
  mounted() {
    this.tableLoading = true
    this.getData()
  },
  methods: {
    dataHandler(response) {
      this.tableData = []
      this.tableData = response?.data || []
      this.tableLoading = false
      this.$nextTick(() => {
        this.$refs.elTable.doLayout()
      })
    }
  }
}
</script>

<template>
  <div id="MainProduct" class="chart-container">
    <!-- 企业 -->
    <el-table ref="elTable" v-loading="tableLoading" :data="tableData" stripe border>
      <el-table-column prop="breedName" label="产品" align="center">
        <template #default="scope">
          <span
            style="cursor: pointer"
            @click="
              $openTab(
                `/breed?id=${scope.row.breedId}&name=${scope.row.breedName}&type=1`,
                scope.row.breedName,
                $md5(scope.row.breedId + scope.row.breedName)
              )
            "
            >{{ scope.row.breedName }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="inPrdCap" label="产能（在产）" align="center" width="120" />
      <el-table-column prop="planPrdCap" label="产能（拟在建）" align="center" width="130" />
      <el-table-column prop="prdCapUnit" label="单位" align="center" width="100" />
    </el-table>
  </div>
</template>

<style lang="scss" scoped>
// el-table 样式
:deep(.el-table) {
  width: 100%;
  // margin-top: 10px;
  // overflow: auto;
  border-radius: 5px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
  height: 300px;
  .gutter {
    width: 0;
    display: table-cell !important;
  }
  th,
  td {
    border-left: 2px solid #fff;
  }
  .el-table__header {
    th {
      background: #3a466e;
      opacity: 0.5;
      color: #fff;
      font-size: 14px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: none;
      > .cell {
        line-height: 18px;
      }
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border: none;
        border-left: none;
      }
    }
  }
  .el-table__body {
    overflow: hidden;
    tr td {
      height: 30px;
      text-align: center;
      border-bottom: none;
    }
    tr td:first-of-type {
      border-left: none;
    }
  }
  .el-table__row:hover {
    color: #53adff;
  }
  // 滚动条样式
  .el-table__body-wrapper {
    max-height: 240px;
    overflow-y: auto;
    // 滚动条样式
    &::-webkit-scrollbar {
      width: 10px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }
    // 滚动条的滑块
    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
      width: 10px;
    }
  }
}
</style>

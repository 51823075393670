<!--
 * @Author: bjp
 * @Description: 接口无数据，未调通
-->
<script>
import Common from './common'
export default {
  name: 'DeviceMaintenanceYear', // 装置检修分年对比
  mixins: [Common],
  mounted() {
    this.initEchart(this.$refs.DeviceMaintenanceYear)
    this.chartLoading = true
    this.getData()
  },
  methods: {
    dataHandler(response) {
      const dataSource = []
      let seriesList = []
      let barYear = []
      let unitY
      if (this.$DataIsNotEmpty(response)) {
        if (Object.keys(response).length > 0) {
          barYear = [...new Set(Object.keys(response).map(item => item.split('-')[0]))].sort()
          for (let i = 1; i <= 12; i++) {
            let temp = {}
            for (const key in response) {
              if (i === parseInt(key.split('-')[1])) {
                temp = {
                  ...temp,
                  [key.split('-')[0]]: response[key][0].lossAmount
                }
              }
            }
            dataSource.push({
              time: `${i.toString().replace(/^(\d)$/, '0$1')}月`,
              ...temp
            })
          }
          unitY = response[Object.keys(response)[0]][0].unitName
          seriesList = barYear.reverse().map(year => ({
            name: year,
            type: 'line',
            connectNulls: true
          }))
        }
        this.myChart.hideLoading()
        this.chartLoading = false
        this.setOption(barYear, dataSource, seriesList, unitY)
      } else {
        this.errorHandler()
      }
    },
    setOption(barYear = [], dataSource = [], seriesList = [], unitY = '') {
      const option = {
        dataZoom: {
          show: true,
          bottom: '3%'
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].name}<br/>`
            for (let i = 0; i < params.length; i++) {
              html += `${
                params[i].marker + params[i].seriesName
              }：<b class="chart-content style="float:right;margin-left:10px">${
                params[i].value[params[i].seriesName] || params[i].value[params[i].seriesName] === 0
                  ? `${params[i].value[params[i].seriesName]} ${unitY || ''}</b><br>`
                  : '-' + '</b><br>'
              }`
            }
            return html
          }
        },
        grid: {
          bottom: '13%',
          top: this.amplify ? '12%' : '20%',
          containLabel: true
        },
        legend: {
          top: '3%'
        },
        dataset: {
          dimensions: ['time', ...barYear],
          source: dataSource
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          name: unitY,
          type: 'value',
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: seriesList
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<template>
  <div ref="DeviceMaintenanceYear" v-loading="chartLoading" class="chart-container" />
</template>

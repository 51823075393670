<script>
import { getData } from '@/apis/server'

export default {
  name: 'ArticleList',
  props: {
    widget: {
      type: Object,
      default: () => ({})
    },
    queryData: {
      type: [Object, Array],
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      articleList: [],
      pager: {
        pageNum: 1,
        pageSize: 6
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      const { dataApi } = this.widget
      const query = {
        ...this.pager,
        breedId: this.$route.query.id
      }
      try {
        const { response } = await getData(dataApi, query)
        this.dataHandler(response.datas)
      } catch (error) {
        // 显示loading
        this.loading = true
      }
    },
    /**
     * 处理数据的方法
     * @param data 待处理的数据
     */
    dataHandler(data) {
      this.loading = data.length === 0
      this.articleList = data
    },
    /**
     * @description: 跳转详情页
     * @param {string} articleId
     */
    goInfo(articleId) {
      const { query } = this.$route
      this.$router.push({
        path: '/breed/informationCenter/info',
        query: { ...query, articleId }
      })
    }
  }
}
</script>

<template>
  <div class="report">
    <div v-if="!loading" class="content">
      <div v-for="article in articleList" :key="article.articleId" class="topic">
        <span class="text-overflow_1-xzh" @click="goInfo(article.id)">{{ article.title }}</span>
        <span>[{{ $dayjs(article.publishTime).format('YYYY-MM-DD') }}]</span>
      </div>
    </div>
    <div v-else class="loading">暂无数据</div>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/styles/breed/information.scss';

.el-scrollbar {
  height: 100%;
}

:deep(.scrollbar-view) {
  padding: 5px 15px;
}

:deep(.onlyXHidden) {
  overflow-x: hidden;
  height: 100%;
}

.report {
  width: 100%;
  height: 100%;

  .content {
    //@include blockshadow(0.16);
    //@include br5bgfff;
    //border-radius: 5px;
    height: 300px;
    overflow: hidden;

    .monthSelect {
      height: 60px;
      background-color: #f2f2f2;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #121212;
      font-size: 14px;

      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;

        &.active {
          background-color: #3a466e;
          border-radius: 50%;
          color: #fff;
        }
      }
    }
  }

  .loading {
    height: 300px;
    line-height: 300px;
    font-size: 32px;
    text-align: center;
  }
}
</style>

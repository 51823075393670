<script>
import Common from '../common.js'
import { getDownStream } from '@/apis/Breed/IndustryChain.js'

export default {
  name: 'ProductionProcessComparison',
  mixins: [Common], // 下游消费结构
  props: {
    breedId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      chartData: [],
      chartLoading: false,
      option: {}
    }
  },
  watch: {
    breedId(val) {
      if (!val) return
      this.getData()
    }
  },
  mounted() {
    this.initEchart(this.$refs.ProductionProcessComparison)
    this.getData()
  },
  methods: {
    async getData() {
      if (!this.breedId && !this.id) return
      this.chartLoading = true
      const query = {
        queryField: {
          breedId: this.breedId || this.id
        }
      }
      const { response } = await getDownStream(query)
      this.dataHandler(response)
      this.chartLoading = false
    },
    dataHandler(response) {
      this.chartLoading = false
      this.myChart.clear()
      this.myChart.hideLoading()
      if (!response || Object.keys(response).length === 0) {
        this.errorHandler()
        return
      }
      this.chartData = response.data.map(({ comsumProp: value, dowBreedName: name }) => ({
        name,
        value
      }))
      this.setOption()
    },
    setOption() {
      this.option = {
        title: {
          show: false
        },
        legend: {
          y: 'bottom',
          x: 'center'
        },
        tooltip: {
          confine: true,
          trigger: 'item'
        },
        series: [
          {
            name: '下游消费结构',
            type: 'pie',
            center: ['50%', '45%'],
            radius: ['45%', '55%'],
            data: this.chartData,
            label: {
              formatter: '{d}%'
            }
            // silent: true,
          }
        ]
      }
      this.downLoadChartTitle()
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="ProductionProcessComparison" v-loading="chartLoading" class="chart-container" />
</template>

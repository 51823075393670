<script>
import Common from '../common'
export default {
  name: 'PieChart',
  components: {},
  mixins: [Common],
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chartLoading: false,
      option: {}
    }
  },
  watch: {
    chartData: {
      deep: true,
      // immediate: true,
      handler(val) {
        if (val.length) {
          this.setOption()
        } else {
          this.errorHandler()
        }
      }
    }
  },
  mounted() {
    this.initEchart(this.$refs.PieChart)
    this.setOption()
    // this.getData()
  },
  methods: {
    dataHandler() {},
    setOption() {
      this.option = {
        title: {
          show: false
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          type: 'scroll',
          width: 700,
          bottom: '20px'
        },
        grid: {
          bottom: '15%'
        },
        series: [
          {
            type: 'pie',
            radius: '60%',
            label: {
              show: true,
              formatter: '{d}%'
            },
            center: ['50%', '40%'],
            data: this.chartData
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: 'rgba(0, 0, 0, 0.5)'
            //   }
            // }
          }
        ]
      }
      this.downLoadChartTitle()
      this.$emit('chart-options', this.option)
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="PieChart" v-loading="tableLoading" class="chart-container" />
</template>

<style lang="scss" scoped></style>

<!--
 * @Author: bjp
 * @Description:圆滑折线图
-->
<script>
export default {
  name: 'LineSmooth',
  props: {
    widget: {
      type: Object,
      default: () => ({})
    },
    temp: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    let year = 2020
    const barX = []
    const dataListLine = []
    for (let i = 1; i <= 12; i++) {
      if (i > 12) {
        i = 1
        year++
      }
      if (i < 10) i = `0${i}`
      barX.push(`${year}-${i}-01`)
      dataListLine.push(Math.floor(Math.random() * 30))
    }
    const myChart = this.$echarts.init(document.querySelector('#LineSmooth'), 'charttheme')
    this.option = {
      title: {
        text: `丙烯${this.temp.name}`,
        x: 'center',
        top: 15,
        textStyle: {
          fontWeight: 'normal',
          fontSize: '14px'
        }
      },
      color: '#2263BD',
      xAxis: {
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        type: 'category',
        data: barX
      },
      yAxis: [
        {
          type: 'value',
          min: 0,
          max: 30,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        }
      ],
      dataZoom: [
        {
          type: 'slider',
          left: '5.2%',
          right: '5.2%',
          bottom: 50
        }
      ],
      tooltip: {
        confine: true,
        trigger: 'axis',
        formatter: params => {
          let res = params[0].name
          for (let i = 0; i < params.length; i++) {
            //  params[i].marker +
            res +=
              `<br>${params[i].seriesName}：${params[i].data}<br>` +
              `20日移动平均: ${(params[i].data * 12) / 20}`
          }
          return res
        }
      },
      grid: {
        top: 52,
        bottom: 98
      },
      legend: {
        show: true,
        left: 'center',
        bottom: 10,
        textStyle: {
          color: '#121212',
          fontSize: 14
        },
        itemGap: 20, // 设置间距
        data: [
          {
            name: '工艺-生产成本'
          }
        ]
      },
      series: [
        {
          name: '工艺-生产成本',
          type: 'line',
          smooth: true,
          data: dataListLine
        }
      ]
    }
    myChart.setOption(this.option)
    setTimeout(() => {
      window.onresize = function () {
        myChart.resize()
      }
    }, 200)
  }
}
</script>

<template>
  <div id="LineSmooth" class="chart-container" />
</template>

<script>
import { getInnerProfitTable } from '@/apis/Breed/IndustryChain.js'

export default {
  name: 'ProfitDistribution', // 产业链利润分布
  props: {
    breedId: {
      // 对比筛选传入品种id
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      IndustrialChain: {
        上游: [],
        中游: {},
        下游: []
      },
      chartLoading: false
    }
  },
  watch: {
    breedId(val) {
      if (!val) return
      this.getData()
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      if (!this.breedId && !this.$route.query.id) return
      this.chartLoading = true
      const params = {
        queryField: {
          breedId: this.breedId || this.$route.query.id
        }
      }
      getInnerProfitTable(params)
        .then(res => {
          this.IndustrialChain = res.response || {}
        })
        .catch(() => {
          this.chartLoading = false
          this.IndustrialChain = {
            上游: [],
            中游: {},
            下游: []
          }
        })
        .finally(() => (this.chartLoading = false))
    }
  }
}
</script>

<template>
  <el-scrollbar
    style="height: 100%; width: 100%"
    wrap-class="onlyXHidden"
    view-class="scrollbar-view"
  >
    <div v-loading="chartLoading" class="profit-distribution">
      <!-- upper -->
      <div class="upper">
        <div class="upper-lst">
          <div v-for="item in IndustrialChain.上游" :key="item.id" class="upper-i">
            <div class="raw-i">
              <div class="craft">
                {{ item.process || '' }}
              </div>
              <div class="product">
                <span class="name">{{ item.breedName }}</span>
                <span class="rate" :class="[item.profitRate > 0 ? 'red' : 'green']">{{
                  item.profitRate ? `${item.profitRate}%` : ''
                }}</span>
              </div>
              <!-- <div class="remark">
              {{ item.remark }}
            </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- end upper -->
      <div
        class="mainPrd"
        :class="{
          'mainPrd-up': IndustrialChain.上游 && IndustrialChain.上游.length > 0,
          'mainPrd-down': IndustrialChain.下游 && IndustrialChain.下游.length > 0
        }"
      >
        <div class="product">
          <span class="name">{{ IndustrialChain.中游.breedName || $route.query.name }}</span>
          <span class="rate" :class="[IndustrialChain.中游.profitRate > 0 ? 'red' : 'green']">{{
            IndustrialChain.中游.profitRate ? `${IndustrialChain.中游.profitRate}%` : ''
          }}</span>
        </div>
      </div>
      <!-- downstream -->
      <div v-if="IndustrialChain.下游.length > 0" class="downstream">
        <div class="head-tit">
          <span class="tit tit-percent">消费占比</span>
          <span class="tit tit-profit">毛利率</span>
        </div>
        <div class="down-lst">
          <div v-for="item in IndustrialChain.下游" :key="item.id" class="down-i">
            <div class="box-01">
              <span :class="[item.comsumProp > 0 ? 'red' : 'green']">{{
                `${item.comsumProp}%`
              }}</span>
            </div>
            <div class="box-02">
              <div class="product">
                <span class="name">{{ item.dowBreedName }}</span>
                <span class="rate" :class="[item.profitRate > 0 ? 'red' : 'green']">{{
                  item.profitRate ? `${item.profitRate}%` : ''
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped lang="scss">
$arrow: url(~@/assets/images/Breed/icon-arr.png);
$arrow_on_r: $arrow no-repeat right center;
$arrow_on_l: $arrow no-repeat left center;
$arrow_width: 60px;
.red {
  color: #ba0c2f;
}
.green {
  color: #1a9016;
}
@mixin line {
  content: '';
  position: absolute;
  width: 0;
  border-left: 1px solid #ba0c2f;
}
@mixin box {
  background: #f5f5f5;
  border-radius: 5px;
}
.profit-distribution {
  width: 100%;
  min-height: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  .upper,
  .mainPrd,
  .downstream {
    flex-shrink: 0;
  }
  .upper {
    .upper-lst {
      position: relative;
      &::after {
        @include line;
        top: 25px;
        bottom: 25px;
        right: 0;
      }
      .upper-i {
        margin: 10px 0;
        padding-right: $arrow_width;
        background: $arrow_on_r;
      }
      .raw-i {
        position: relative;
        padding: 5px 10px;
        @include box;
        .remark {
          display: none;
          position: absolute;
          left: 100%;
          top: 5px;
          width: 100%;
          line-height: 1.4;
          padding: 5px;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
          &:before {
            content: '';
            position: absolute;
            left: -10px;
            width: 0;
            border-right: 10px solid rgba(0, 0, 0, 0.5);
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
          }
        }
        &:hover {
          .remark {
            display: block;
          }
        }
      }
    }
  }
  .mainPrd {
    padding: 0 $arrow_width;
    position: relative;
    .product {
      padding: 15px;
      @include box;
      span {
        margin: 0 5px;
      }
    }

    &-up::before,
    &-down::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 50px;
      background: $arrow_on_r;
      left: 0;
    }
    &::after {
      left: auto;
      right: 0;
    }
  }

  .downstream {
    .head-tit {
      height: 20px;
      span {
        text-align: center;
        display: inline-block;
        font-weight: bold;
      }
      .tit-percent {
        margin-left: 60px;
        width: 80px;
      }
      .tit-profit {
        margin-left: 70px;
        width: 200px;
      }
    }
    .down-lst {
      position: relative;
      &::before {
        left: 0;
        top: 15px;
        bottom: 15px;
        @include line;
      }
    }
    .down-i {
      overflow: hidden;
      padding-left: $arrow_width;
      background: $arrow_on_l;
      margin: 10px 0;
      display: flex;
      align-items: center;
      .box-01 {
        float: left;
        width: 80px;
        line-height: 14px;
        text-align: center;
        padding: 10px 0;
        @include box;
      }
      .box-02 {
        float: left;
        padding-left: $arrow_width;
        margin-left: 10px;
        background: $arrow_on_l;
        .product {
          padding: 10px;
          width: 180px;
          @include box;
        }
      }
    }
  }
  .craft {
    width: 160px;
    height: 22px;
    margin-bottom: 6px;
    text-align: center;
    line-height: 20px;
    background: #e25c61;
    border-radius: 10px;
    font-size: 12px;
    color: #fff;
  }
  .product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    line-height: 14px;
    .name {
      flex: 1;
    }
    .rate {
      min-width: 20%;
      text-align: right;
      float: right;
    }
  }
}

:deep(.scrollbar-view) {
  height: 100%;
  padding: 10px 0;
}
</style>

<!--
 * @Author: bjp
 * @Description: 公共日期选择器组件
-->
<script>
export default {
  name: 'DatePicker',
  components: {},
  props: {
    yearField: {
      type: String,
      default: ''
    },
    startTime: {
      type: String,
      default: ''
    },
    endTime: {
      type: String,
      default: ''
    },
    customVerify: { // 是否开启自定义校验
      type: Boolean,
      default: false
    },
    initTime: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      date: {
        startTime:
          this.startTime ||
          this.$dayjs()
            .subtract(this.$store.state.power.powerYear, 'year')
            .startOf('year')
            .format('YYYY-MM-DD'),
        endTime: this.endTime || this.$dayjs().format('YYYY-MM-DD')
      },
      optionsStart: {
        disabledDate: time => {
          if(this.customVerify){
            return (
              time.getTime() <
              this.$dayjs()
                .subtract(this.$store.state.power.powerYear, 'year')
                .startOf('year')
                .valueOf() || time.getTime() > this.$dayjs(this.endTime).valueOf()
            )
          }
          return (
            time.getTime() <
              this.$dayjs()
                .subtract(this.$store.state.power.powerYear, 'year')
                .startOf('year')
                .valueOf() || time.getTime() > Date.now()
          )
        }
      },
      optionsEnd: {
        disabledDate: time => {
          if(this.customVerify){
            return (
              time.getTime() > this.$dayjs(this.initTime[1]).valueOf() ||
              time.getTime() < this.$dayjs(this.date.startTime).valueOf()
            )
          }
          return (
            time.getTime() > Date.now() ||
            time.getTime() < this.$dayjs(this.date.startTime).valueOf()
          )
        }
      }
    }
  },
  watch: {
    date: {
      // immediate: true,
      deep: true,
      handler(val) {
        if (val.startTime && val.endTime) {
          this.$emit('picker-date', val, this.yearField)
          this.$emit('change-data')
        }
      }
    }
  },
  mounted() {},
  methods: {}
}
</script>

<template>
  <div class="datePicker" style="display: inline-block">
    <el-date-picker
      v-model="date.startTime"
      :clearable="false"
      format="yyyy年MM月dd日"
      value-format="yyyy-MM-dd"
      size="mini"
      style="width: 155px"
      :picker-options="optionsStart"
    />
    <span style="padding: 0 10px">——</span>
    <el-date-picker
      v-model="date.endTime"
      :clearable="false"
      range-separator="—"
      format="yyyy年MM月dd日"
      value-format="yyyy-MM-dd"
      size="mini"
      style="width: 155px"
      :picker-options="optionsEnd"
    />
  </div>
</template>

<style lang="scss" scoped></style>

/*
 * @Description: 供应apis
 * @Date: 2021-06-22 14:14:50
 * @LastEditTime: 2023-11-13 11:59:51
 */

import { getData } from '@/apis/server'

const url = '/api/widget/queryData/'
// 产能-图
export const getCapacityChart = params => getData(`${url}79`, params)
// 产量-图
export const getYidleChart = params => getData(`${url}80`, params)
// 产能-图/表格
export const getCapacity = params => getData(`${url}39`, params)
// 产能利用率-图/表格
export const getCapacityRate = params => getData(`/breed/supply/commonContinue`, params)
// 产量-图/表格
export const getYield = params => getData(`${url}80`, params)
// 开工率-分年曲线对比
export const getOperaYearLine = params => getData(`${url}operatingRateViewByYear`, params)
// 开工率-连续图
export const getOperaContinuse = params => getData(`${url}81`, params)
// // 开工率-数据排行-表格表头
export const getOperaRateTableHeaderScreen = params => getData(`${url}operatingRateHeader`, params)
// // 开工率-数据排行-表格数据
export const getOperaRateTableScreen = params => getData(`${url}operatingRate`, params)
// 开工率-表格表头
export const getOperaRateTableHeader = params =>
  getData(`/api/breed/supply/operateDateCompareByYearTableHeader`, params)
// 开工率-表格数据
export const getOperaRateTable = params =>
  getData(`/api/breed/supply/operateDateCompareByYear`, params)
// 行业集中度-图表/表格
// export const getConcentrationTable = params => getData(`${url}84`, params)
export const getConcentrationTable = params =>
  getData(`/api/breed/supply/industryConcentration`, params)
// 开工率-获取数据频率
export const getOperaeFrequency = params => getData(`${url}309`, params)
// 产量-获取数据频率
export const getProductFrequency = params => getData(`${url}308`, params)
// 损失量-获取表格数据
export const getLossCountTable = params => getData(`${url}386`, params)
// 损失量-获取数据频率
export const getLossCountFrequency = params => getData(`${url}388`, params)

// 原油开工率-分年曲线对比
export const getCrudeOilComparse = (params, indexCode) =>
  getData(`/api/oilRefining/inner/compareByYear/${indexCode}`, params)
// 原油开工率-连续图
export const getCrudeOilContinuous = (params, indexCode, order = 'asc') =>
  getData(`/api/oilRefining/inner/normal/${indexCode}/${order}`, params)

// 损失量-季节性分析图表数据
export const getLossCountLineCompareData = params =>
  getData(`/api/widget/lossCompareByYear`, params)

// 数据排行页面—开工率连续图
export const getOperaContinueOfDataRank = params => getData(`${url}488`, params)

// 供应-通用-分年对比 表头
export const getCommonCompareByYearTableHeader = params =>
  getData(`/api/breed/supply/commonCompareByYearTableHeader`, params)

// 供应-通用-分年对比
export const getCommonCompareByYear = params =>
  getData(`/api/breed/supply/commonCompareByYear`, params)

<script>
import Common from './common'
import { getData } from '@/apis/server'

export default {
  name: 'IndustryOperatingRateContinuous', // 行业开工率-连续图
  mixins: [Common],
  data() {
    return {
      option: {}
    }
  },
  mounted() {
    this.initEchart(this.$refs.IndustryOperatingRateContinuous)
  },
  methods: {
    async getData() {
      // 没有数据频率时不发请求
      if (!this.queryData.frequencyName) return
      this.chartLoading = true
      if (this.queryData.upAndDownBreed) {
        this.breedIds = [this.id, ...this.queryData.upAndDownBreed]
      } else {
        this.breedIds = [this.id]
      }
      const { dataApi } = this.widget
      const response = await getData(dataApi, {
        queryField: {
          breedIds: this.breedIds,
          frequencyName: this.queryData.frequencyName
        }
      })
      this.dataHandler(response.response)
    },
    /**
     * 处理数据的逻辑
     */
    dataHandler(response) {
      if (response && Object.keys(response).length) {
        let barX = []
        const series = []
        if (Array.isArray(this.queryData.upAndDownBreed)) {
          this.queryData.upAndDownBreed.forEach(res => {
            Object.keys(response).forEach(v => {
              v === res &&
                series.push({
                  name: response[v][0].breedName,
                  type: 'line',
                  smooth: true,
                  connectNulls: true,
                  data: response[v].map(e => {
                    const date = this.$dayjs(e.dataDate).format('YYYYMMDD')
                    barX.push(date)
                    return [date, e.operateRate]
                  })
                })
            })
            barX = [...new Set(barX)].sort()
          })
          this.setOption(barX, series)
          this.myChart.hideLoading()
        }
      } else {
        this.errorHandler()
      }
      this.chartLoading = false
    },
    setOption(barX = [], dataListLine = []) {
      this.myChart.clear()
      this.option = {
        title: {
          show: false
        },
        dataZoom: {
          show: true,
          bottom: '3%'
        },
        legend: {
          type: 'scroll',
          top: '0',
          left: '10%'
        },
        grid: {
          top: this.amplify ? '10%' : '18%',
          bottom: '10%',
          right: '5%',
          containLabel: true
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          formatter: params => {
            let html = `${params[0].name}<br/>`
            for (let i = 0; i < params.length; i++) {
              html += `${
                params[i].marker + params[i].seriesName
              }：<b class="chart-content style="float:right;margin-left:10px">${
                params[i].value || params[i].value === 0
                  ? `${params[i].value[1]} %` + `</b><br>`
                  : '-' + '</b><br>'
              }`
            }
            return html
          }
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          // data: this.$filterValue(this.chartData['日期'], 'YYYYMMDD'),
          data: barX,
          axisLabel: {
            fontSize: 11,
            rotate: -45
          }
        },
        yAxis: {
          name: '%',
          type: 'value',
          scale: true,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: dataListLine
      }
      const moduleName = this.$parent.$parent.title || this.$store.state.amplify.title || ''
      this.option.title.text = `${this.name}${moduleName}`
      this.myChart.setOption(this.option)
    }
  }
}
</script>

<template>
  <div ref="IndustryOperatingRateContinuous" v-loading="chartLoading" class="chart-container" />
</template>
